import React, { FC } from 'react';
import * as Styled from './styles';
import { BorderStyle, Color, Grey } from '../../utils/types/theme';
import Typography from '../Typography/Typography';

export interface Props {
  id?: string;
  color?: Color | Grey;
  text?: string;
  size?: number;
  type?: BorderStyle;
  orientation?: 'horizontal' | 'vertical';
  hidden?: boolean;
}

const Divider: FC<Props> = ({ id = 'divider', text, hidden = false, ...restProps }) => {
  return (
    <Styled.Divider id={id} text={text} hidden={hidden} {...restProps}>
      {text && <Typography level="h3">{text}</Typography>}
    </Styled.Divider>
  );
};

export default Divider;
