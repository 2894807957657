import { connect } from 'react-redux';

import { RootState } from '../../redux';

import { adaptGoalById } from '../../utils/adapters/goals';
import { actions as goalActions } from '../../redux/goals.slice';

import GoalAddReflection from './GoalReflection';

const { updateGoal } = goalActions;

const mapState = (state: RootState, props: any) => {
  try {
    const { id, rid } = props.match.params;
    return {
      reflectionId: rid,
      ...adaptGoalById(state, id),
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, { updateGoal })(GoalAddReflection);
