import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import logger from '../../utils/logger';
import {
  PageContainer,
  Header,
  HeaderContentWrapper,
  CtaContainer,
  PageContentWrapper,
  DescriptionWrapper,
  Heading,
} from './styles';
import { Logo } from '../../components/Header/HeaderWithLogo/styles';
import Button from '../../components/Button';

interface Props {
  logout: Function;
}

const Unauthorized: FC<Props> = ({ logout }): JSX.Element => {
  const history = useHistory();
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);

  const onClickLogoutButton = () => {
    logger('info', 'LOG_OUT');
    logout();

    const redirectUrl = `/${isNativeWebView ? window.location.search : ''}`;
    history.push(redirectUrl);
  };

  const mailId = process.env.REACT_APP_SUPPORT_EMAIL;
  return (
    <>
      <Header>
        <HeaderContentWrapper>
          <Logo src="/images/strata_logo.svg" alt="Strata" />
        </HeaderContentWrapper>
      </Header>
      <PageContainer>
        <PageContentWrapper>
          <Heading>
            OOPS! <br />
            It seems there was an issue logging you in.{' '}
          </Heading>
          <DescriptionWrapper>
            <b>You may not have been assigned to any learning programs yet.</b>
            Please reach out to your Facilitator for assistance in resolving this matter. Or contact
            <a href={'mailto:' + mailId}> {mailId}</a>
            <br />
            <br />
            <CtaContainer>
              <Button color="primary" onClick={onClickLogoutButton}>
                Log out
              </Button>
            </CtaContainer>
          </DescriptionWrapper>
        </PageContentWrapper>
      </PageContainer>
    </>
  );
};
export default Unauthorized;
