import React, { FC, PropsWithChildren } from 'react';
import Button from '../Button';
import { ExperimentQuitModalProps } from './types';
import { QuitModalContainer, QuitModalOptions, ModalTitle, ModalMessage } from './styles';

const ExperimentQuitModal: FC<ExperimentQuitModalProps> = ({
  onCancel,
  onQuit,
}: PropsWithChildren<ExperimentQuitModalProps>): JSX.Element => {
  return (
    <QuitModalContainer>
      <ModalTitle>Quit experiment?</ModalTitle>
      <ModalMessage>
        Are you sure you want to quit this experiment now? Any insights or reflections you have added will be lost.
      </ModalMessage>

      <QuitModalOptions>
        <Button onClick={onQuit} variant="outline">
          Quit
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </QuitModalOptions>
    </QuitModalContainer>
  );
};

export default ExperimentQuitModal;
