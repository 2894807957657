import React, { FC } from 'react';
import Icon from '../Icon';
import styles from './styles.module.scss';

type Props = {
  title: string;
  onClick: any;
};

const CreateGoalCard: FC<Props> = ({ onClick, title }: Props) => {
  return (
    <a className={styles.container} onClick={onClick}>
      <div className={styles.icon}>
        <Icon name="goal" size="medium" color="white" />
      </div>
      <div>
        <div className={styles.type}>GOAL</div>
        <span>{title || 'Create a goal now'}</span>
      </div>
      <div className={styles.chevron}>
        <Icon name="chevron-right" />
      </div>
    </a>
  );
};

export default CreateGoalCard;
