import React, { PropsWithChildren } from 'react';
import Button from '../../../../../../components/Button';
import { CardRatersButtonProps } from './types';

const CardRatersButton: React.FC<CardRatersButtonProps> = ({
  status,
  onClick,
  cardIndex,
  isLoading,
}: PropsWithChildren<CardRatersButtonProps>): JSX.Element => {
  const { completed, expired, closed } = status;
  const buttonStyle: object = { paddingHorizontal: `0px`, paddingVertical: `6px`, minWidth: `160px` };
  const staticButtonProperties: object = {
    isBold: false,
    style: buttonStyle,
    testID: 'raterButton',
  };

  if (!completed && closed) {
    return (
      <Button disabled={true} onClick={() => onClick('raters', cardIndex)} {...staticButtonProperties}>
        View raters
      </Button>
    );
  }

  if (!completed && expired && !closed) {
    return (
      <Button
        loading={isLoading}
        disabled={isLoading}
        variant="outline"
        onClick={() => onClick('raters', cardIndex)}
        {...staticButtonProperties}>
        Select raters
      </Button>
    );
  }

  if (completed && closed) {
    return (
      <Button disabled={true} onClick={() => onClick('raters', cardIndex)} {...staticButtonProperties}>
        View raters
      </Button>
    );
  }

  return (
    <Button
      loading={isLoading}
      disabled={isLoading}
      variant="outline"
      onClick={() => onClick('raters', cardIndex)}
      {...staticButtonProperties}>
      Select raters
    </Button>
  );
};

export default CardRatersButton;
