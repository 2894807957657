import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '.';
import { IconName } from '../components/Icon';
import { assignBy } from '../utils/adapters/dataNormalization';
import { getAllCohorts } from '../utils/api/cohorts';
import { getExperimentsByIds, getInsights } from '../utils/api/experiments';
import { getJourney } from '../utils/api/journey';
import { getCohortUsersAnyCohort, getUserById, GetUserResponse } from '../utils/api/user';
import { Activity, ActivityContentBlock, Cohort, Experiment, Module, UserRecord } from '../utils/types';
import { generateReauthenticatingThunkApiAction } from './helpers';

interface InitialState {
  experiments: Experiment[];
  experimentsById: { [k: string]: Experiment };
  activityRecord: UserRecord;
  userRecords: UserRecord[];
  entryResponses: JournalEntryPayload[];
  currentEntryResponse: JournalEntryPayload;
  currentEntryQuestionIds: string[];
  insights: InsightResponse[] | null;
  currentInsightJournalEntryId: string;
  currentInsightJournalEntryUserId: string;
}

export interface Insight {
  id?: string;
  content: string;
  iconName?: IconName | null;
  imageUrl?: string | null;
  title: string;
  color: string;
  type?: string;
  experimentId: string;
}

export interface InsightResponse extends Insight {
  cohortId: string;
  publisherName: string;
  userId: string;
  activityId: string;
  createdAt: string;
}

export interface InsightPayload {
  cohortId: string;
  learningJourneyId: string;
}

const initialState: InitialState = {
  experiments: [],
  experimentsById: {},
  activityRecord: {
    journeyId: '',
    moduleId: '',
    activityId: '',
    cohortId: '',
    goalId: '',
    completedDate: '',
    startDate: '',
    experimentId: '',
  },
  userRecords: [],
  entryResponses: [],
  currentEntryResponse: {} as JournalEntryPayload,
  insights: [],
  currentEntryQuestionIds: [],
  currentInsightJournalEntryId: '',
  currentInsightJournalEntryUserId: '',
};

export interface JournalEntryPayload {
  journalEntryId?: string;
  userId?: string;
  sourceId: string;
  response: string;
  question: string;
  parentId: string;
  meta?: { [key: string]: string | number };
}

export type JournalEntryResponse = JournalEntryPayload;

export type ActivityRecordResponse = UserRecord;

export type ActivityRecordPayload = {
  activityId: string;
  moduleId: string;
};

export type GetExperimentsPayload = string[];

const getActivityRecord = createAsyncThunk<
  ActivityRecordResponse,
  ActivityRecordPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'experiment/getActivityRecord',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: ActivityRecordPayload) => {
    let currentRecord: UserRecord = {} as UserRecord;

    const module: Module | undefined = state.journey.modules.find((module: Module) => module._id == payload.moduleId);
    if (module) {
      const activity: Activity | undefined = module.activities.find(
        (activity: Activity) => activity?._id == payload.activityId,
      );
      if (activity) {
        if (activity.record) currentRecord = activity.record;
      }
    }

    if (currentRecord !== undefined) return currentRecord;
    return initialState;
  }),
);

const getExperiments = createAsyncThunk<
  Experiment[],
  GetExperimentsPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'experiment/getExperiments',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: GetExperimentsPayload) => {
    const { accessToken } = state.auth;
    return await getExperimentsByIds(payload, accessToken);
  }),
);

const getUserRecords = createAsyncThunk<
  ActivityRecordResponse[],
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'experiment/getUserRecords',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const recordList: UserRecord[] = [];
    state.journey.modules.forEach((module: Module) =>
      module.activities.forEach((activity: Activity) => {
        if (activity.record) {
          recordList.push(activity.record);
        }
      }),
    );
    return recordList;
  }),
);

const getExperimentInsights = createAsyncThunk<
  InsightResponse[] | null,
  InsightPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'experiment/getInsights',
  generateReauthenticatingThunkApiAction(
    async (state: RootState, payload: InsightPayload): Promise<InsightResponse[] | null> => {
      const { roles, id } = state.user;
      const { selectedCohort } = state.cohorts;
      const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
      const { accessToken } = state.auth;
      let searchableExperiments: Experiment[];
      let cohortIdInit: string | null, initUserIds: string[] | null, initLearningJourneyId: string | null;
      let  initFacilitatorIds: string[] | [];
      const adaptedInsightList: InsightResponse[] = [];
      let learningJourneyModules: Module[];

      if (!payload?.cohortId) {
        // No payload cohortId? Use store data
        const { userIds, cohortId, learningJourneyId, facilitatorIds } = state.cohorts.selectedCohort;
        cohortIdInit = cohortId;
        initUserIds = userIds;
        initLearningJourneyId = learningJourneyId;
        learningJourneyModules = state.journey.modules;
        initFacilitatorIds = facilitatorIds;
      } else {
        // With payload cohortId use api data
        const data: Cohort[] = await getAllCohorts(accessToken);                  
        
        // ISSUE HERE
        let selectedCohort = data.find((cohort: Cohort) => cohort.cohortId === payload.cohortId);    
        cohortIdInit = selectedCohort!.cohortId;
        initUserIds = selectedCohort!.userIds;
        initFacilitatorIds = selectedCohort!.facilitatorIds;
        initLearningJourneyId = selectedCohort!.learningJourneyId;
        learningJourneyModules = (
          await getJourney(accessToken, { learningJourneyId: initLearningJourneyId, cohortId: cohortIdInit })
        ).modules;
      }
      const isFacilitatorOfCohort:boolean = isFacilitator && initFacilitatorIds.includes(id);
      if(isFacilitatorOfCohort){
        initUserIds.push(id);
      }
      let apiInsights: any = await getInsights(initUserIds, accessToken);
      const filteredInsights = apiInsights.filter(
        (insight: any): boolean => !!insight.meta?.experimentId && !!insight.meta?.type,
      );
      let userDetails : GetUserResponse[] = [];
      if(isFacilitatorOfCohort){
        const userIds = filteredInsights.map((insight:any):void => insight.userId);        
        userIds.forEach(async (id:string) => {
          const res:GetUserResponse = await getUserById(accessToken, id);
          userDetails.push(res);
        });
      }
      // get all experimentIds within journey
      let activityExperimentLookUp: { [key: string]: string[] } = {};
      learningJourneyModules.map((module: Module): void => {
        module.activities.map((activity: Activity): void => {
          activity.content.body.map((activityContent: ActivityContentBlock): void => {
            if (activityContent.blockType == 'experiment') {
              if (activityExperimentLookUp[activityContent.text]) {
                activityExperimentLookUp = {
                  ...activityExperimentLookUp,
                  [activityContent.text]: [...activityExperimentLookUp[activityContent.text], activity._id],
                };
              } else {
                activityExperimentLookUp = {
                  ...activityExperimentLookUp,
                  [activityContent.text]: [activity._id],
                };
              }
            }
          });
        });
      });

      // all experiment by journey output
      searchableExperiments = await getExperimentsByIds(Object.keys(activityExperimentLookUp), accessToken);
      const { users } = await getCohortUsersAnyCohort(cohortIdInit, accessToken);
      // construct insights from experiments output, users & journal entries
      filteredInsights.map((insight: any): void=> {
        const user = users[insight.userId];
        const experiment: Experiment | undefined = searchableExperiments.find(
          (experiment: Experiment): boolean => experiment.id === insight.meta.experimentId,
        );
        if(isFacilitatorOfCohort && experiment && insight.meta.journeyId === initLearningJourneyId){
          const curUser = userDetails.filter((item:GetUserResponse) => item.id === insight.userId);
          const fName = `${curUser[0].firstName}`;
          const lName = `${curUser[0].lastName}`;
          if(insight.meta.cohortId === selectedCohort.cohortId || insight.userId === id) {
            adaptedInsightList.push({
              id: insight._id,
              content: insight.response,
              ...insight.meta,
              title: experiment?.title,
              publisherName: `${fName} ${lName}`,
              userId: insight.userId,
              createdAt: insight.createdAt,
              updatedAt: insight.updatedAt,
            });
          }
        } else if (!isFacilitatorOfCohort && user && experiment && insight.meta.journeyId === initLearningJourneyId) {
          if(insight.meta.cohortId === selectedCohort.cohortId) {
            adaptedInsightList.push({
              id: insight._id,
              content: insight.response,
              ...insight.meta,
              title: experiment?.title,
              publisherName: `${user.FirstName} ${user.LastName}`,
              userId: user.id,
              createdAt: insight.createdAt,
              updatedAt: insight.updatedAt,
            });
          }
        }
      });
      return adaptedInsightList.length ? adaptedInsightList : null;
    },
  ),
);

const experiment = createSlice({
  name: 'experimentRecord',
  initialState: {
    ...initialState,
  },
  reducers: {
    setCurrentEntryMeta(state, { payload }) {
      if (payload) {
        state.currentEntryResponse.meta = { ...state.currentEntryResponse.meta, ...payload };
      }
    },
    setCurrentEntryResponse(state, { payload }) {
      if (payload) {
        state.currentEntryResponse.response = payload;
      }
    },
    setCurrentInsightJournalEntryId(state, { payload }) {
      if (payload) {
        state.currentInsightJournalEntryId = payload;
      }
    },
    setCurrentInsightJournalEntryUserId(state, { payload }) {
      if (payload) {
        state.currentInsightJournalEntryUserId = payload;
      }
    },
    setJournalEntries(state, { payload }) {      
      if (payload == null) {
        state.currentEntryResponse = {} as JournalEntryResponse;
        return;
      }

      const isExistingEntry = state.entryResponses.find((entry) => entry.sourceId == payload.sourceId);

      if (isExistingEntry) {
        state.entryResponses.map((entry: JournalEntryResponse, index: number) => {
          if (entry.sourceId === payload.sourceId) {
            state.entryResponses[index].response = payload.response;
            state.currentEntryResponse = entry;
            return;
          }
        });
      }

      if (!isExistingEntry) {
        state.entryResponses.push(payload);
        state.currentEntryResponse = payload;
      }
    },
    resetCachedJournalEntries(state) {
      state.entryResponses = [];
    },
    setCurrentEntryQuestionIds(state, { payload }) {
      state.currentEntryQuestionIds = payload;
    },
    resetCurrentEntryQuestionIds(state) {
      state.currentEntryQuestionIds = [];
    },
    updateInsightContent(state, { payload }) {
      if (!state?.insights?.length) return;
      if (!payload) return;
      const idx: number = state.insights.findIndex(
        (insight: InsightResponse): boolean => insight.id === payload.journalEntryId,
      );
      state.insights[idx] = { ...state.insights[idx], ...payload };
    },
    removeInsightFromStore(state, { payload }) {
      if (!state?.insights?.length) return;
      if (!payload) return;
      state.insights = state?.insights?.filter((insight: InsightResponse): boolean => insight.id !== payload);
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getActivityRecord.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activityRecord = payload;
      }
    });
    builder.addCase(getUserRecords.fulfilled, (state, { payload }) => {
      if (payload) {
        state.userRecords = payload;
      }
    });
    builder.addCase(getExperiments.fulfilled, (state, { payload }) => {
      if (payload) {
        state.experiments = payload;
        state.experimentsById = payload.reduce(assignBy('id'), {});
      }
    });
    builder.addCase(getExperimentInsights.fulfilled, (state, { payload }) => {
      if (payload) {
        state.insights = payload;
      }
    });
  },
});

export default experiment.reducer;

export const actions = {
  ...experiment.actions,
  getActivityRecord,
  getUserRecords,
  getExperimentInsights,
  getExperiments,
};
