import React, { FC, PropsWithChildren } from 'react';
import Button from '../../components/Button';
import {
  PolicyPageContainer,
  Header,
  HeaderContentWrapper,
  PolicyPageContentWrapper,
  PolicyDescriptionWrapper,
  PolicyList,
  PolicyHeading,
  CtaContainer,
} from './styles';
import { PolicyProps } from './types';
import { Logo } from '../../components/Header/HeaderWithLogo/styles';

const Policy: FC<PolicyProps> = ({}: PropsWithChildren<PolicyProps>): JSX.Element => {
  const onClickCta = () => {
    window.open('https://www.accenture.com/us-en/services/consulting/leadership-and-culture');
  };

  return (
    <>
      <Header>
        <HeaderContentWrapper>
          <Logo src="/images/strata_logo.svg" alt="Strata" />
        </HeaderContentWrapper>
      </Header>
      <PolicyPageContainer>
        <PolicyPageContentWrapper>
          <PolicyHeading>About Strata</PolicyHeading>

          <PolicyDescriptionWrapper>
            <p>Strata guides you through our Leadership development and Culture activation programs.</p>
            <p>We’ve designed the App to help you move from Intention into Action.</p>
            <br />
            <PolicyList>
              <li>Personal development journey with everything you need to succeed on the programme</li>
              <li>Relevant, practical bitesize content to get you ready for our live sessions</li>
              <li>High quality ‘toolkits’ help you master new techniques</li>
              <li>Reflective journalling - a key ingredient to your development</li>
              <li>Try new challenges from our 'experiment bank'</li>
              <li>Share your milestones with your peers on our Insights Wall and read their stories</li>
            </PolicyList>
            <br />

            <CtaContainer>
              <Button color="primary" onClick={onClickCta}>
                Discover more
              </Button>
            </CtaContainer>
          </PolicyDescriptionWrapper>
        </PolicyPageContentWrapper>

        <PolicyPageContentWrapper>
          <PolicyHeading>Privacy Policy</PolicyHeading>

          <PolicyDescriptionWrapper>
            <p>
              By logging into Strata, you are accepting that Accenture processes your personal data on behalf and upon
              the instruction of your employer and your employer privacy notice applies.{' '}
            </p>

            <p>
              Please refer to your employer privacy notice if you have any questions about how your personal data are
              being processed or if you wish to exercise your rights in relation to your personal data.
            </p>
          </PolicyDescriptionWrapper>
        </PolicyPageContentWrapper>
      </PolicyPageContainer>
    </>
  );
};
export default Policy;
