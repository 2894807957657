import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';

import { adaptGoals } from '../../utils/adapters/goals';

import Goals from './Goals';

const mapState = (state: RootState) => ({
  goals: adaptGoals(get(state, 'goals.goals', null)),
});

export default connect(mapState)(Goals);
