import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions } from '../../redux/auth.slice';

import LoginEmail from './LoginEmail';

const { setEmail } = actions;

const mapState = (state: RootState) => ({
  email: get(state, 'auth.email', ''),
});

export default connect(mapState, { setEmail })(LoginEmail);
