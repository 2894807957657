import styled, { css } from 'styled-components';
import { LearningJourneysContainerStyleProps } from './types';

export const LearningJourneysContainer = styled.div<LearningJourneysContainerStyleProps>`

  ${(p) =>
    p.isMobile &&
    css`
      > div:first-child {
        margin-bottom: ${(p) => p.theme.spacing(3)};
      }
    `}
`;

