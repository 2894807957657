import { connect } from 'react-redux';
import get from 'lodash.get';
import InsightsWall from './InsightsWall';
import { RootState } from '../../redux';
import { actions as experimentActions } from '../../redux/experiment.slice';

const mapState = (state: RootState) => {
  const params: URLSearchParams = new URL(document.location.href).searchParams;
  const cohortId: string | null = params.get('cohortId');
  const ljId: string | null = params.get('ljId');
  const cohortTitle: string | null = params.get('cohortTitle');
  const orgTitle: string | null = params.get('orgTitle');
  const accessOrigin: string | null = params.get('accessOrigin');

  return {
    cohortId: cohortId || null,
    ljId: ljId || null,
    cohortTitle: cohortTitle || null,
    orgTitle: orgTitle || null,
    accessOrigin: accessOrigin || null,
    auth: get(state, 'auth', []),
    roles: get(state, 'user.roles') || [],
  };
};

const { getExperimentInsights } = experimentActions;

export default connect(mapState, { getExperimentInsights })(InsightsWall);
