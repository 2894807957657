import React, { ReactElement, useState } from 'react';

import WizardHeader from '../../components/WizardHeader';

import CreateHabitAmbition from '../../containers/CreateHabitAmbition';
import CreateHabitTarget from '../../containers/CreateHabitTarget';
import CreateHabitSuccessPlan from '../../containers/CreateHabitSuccessPlan';
import CreateHabitReminders from '../../containers/CreateHabitReminders';

import { FormattedHabit } from '../../utils/adapters/goals';

import styles from './styles.module.scss';

type Props = FormattedHabit & {
  createHabit: any;
  goalId: string;
};

const steps = [CreateHabitAmbition, CreateHabitTarget, CreateHabitSuccessPlan];

const HabitCreate = ({ goalId, createHabit }: Props): ReactElement => {
  const [step, setStep] = useState(0);
  const [habit, setHabit] = useState<any>({ goalId });

  const update = (newHabit: any) => {
    setHabit({ ...habit, ...newHabit });
  };

  const StepComponent = steps[step];
  return (
    <>
      <div className={styles.container}>
        <WizardHeader step={step} />
        <StepComponent update={update} habit={habit} goalId={goalId} setStep={setStep} createHabit={createHabit} />
      </div>
    </>
  );
};

export default HabitCreate;
