import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { IconName } from '../../Icon';
import NotificationHeader from '../components/NotificationHeader';
import { ExperimentNotificationHeaderProps } from './types';
import { useHistory } from 'react-router-dom';
import { actions } from '../../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../utils/hooks';
import { RootState } from '../../../redux';

import { NotificationState } from '../../../redux/notification.slice';

const { insightNotificationsDismissAll } = actions;

const ExperimentNotificationHeader: FC<ExperimentNotificationHeaderProps> = ({
  dismissNotification,
  setVisitedInsightsPage,
}: PropsWithChildren<ExperimentNotificationHeaderProps>): JSX.Element => {
  const { cohortId } = useAppSelector((state: RootState) => state.cohorts.selectedCohort);
  const { insights: allInsightData } = useAppSelector((state: RootState) => state.experiment);
  const { insights: insightNotifications } = useAppSelector(
    (state: RootState): NotificationState => state.notifications,
  );

  const [unseenInsights, setUnseenInsights] = useState<any>([]);

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  useEffect((): (() => void) => {
    if (!insightNotifications) {
      return (): void => {};
    }

    const foundUnseenInsights = insightNotifications.filter(
      (insight: any) => !insight.seen && insight.cohortId === cohortId,
    );

    if (!foundUnseenInsights || !allInsightData) {
      return (): void => {};
    }

    const filteredInsights = allInsightData.filter((insightData: any) =>
      foundUnseenInsights.find((unseenInsight: any) => unseenInsight.insightId === insightData.id),
    );

    setUnseenInsights(filteredInsights);

    return (): void => {};
  }, [insightNotifications, allInsightData]);

  const renderNotification: (notifications: any) => JSX.Element | null = (notifications: any) => {
    let shouldRender: boolean;

    const notifiable = notifications.find((notification: any): boolean => !notification.seen);
    shouldRender = !!notifications.length && !!notifiable;

    if (shouldRender && !window.location.pathname.includes('app/home')) {
      const iconName: IconName = 'experiment-info';
      const title = 'New experiment insights shared';
      const description = `There have been new insights posted for an experiment you selected: <strong>${notifiable.title}</strong>. Find out what others participants thought!`;
      const buttonText = 'View insights';

      return (
        <NotificationHeader
          iconName={iconName}
          title={title}
          description={description}
          buttonText={buttonText}
          onClick={(): void => {
            history.push('insightswall');
            dispatch(insightNotificationsDismissAll(null));
            dispatch(setVisitedInsightsPage());
          }}
          onClose={(): any => dismissNotification({ type: 'insights', id: notifiable.id })}
        />
      );
    } else {
      return null;
    }
  };

  return <>{renderNotification(unseenInsights)}</>;
};

export default ExperimentNotificationHeader;
