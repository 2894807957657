import React, { FC } from 'react';
import * as Module from './styles';

import { ModuleDisplayData } from '../../screens/Module/adapters';

interface Props extends ModuleDisplayData {
  progress: number;
}

const ModuleHeader: FC<Props> = ({ title, description, iconName, iconColor, progress, active }) => {
  const comingSoon = !active;
  const completed = progress === 100;

  if (comingSoon && !completed) progress = 0;
  if (comingSoon && completed) progress = 100;

  return (
    <Module.ModuleHeader>
      <Module.Title level="h2">{title}</Module.Title>

      <Module.Meta>
        <Module.Progress icon={iconName} progress={progress} color={iconColor} testID={'moduleProgress'} index={0} />

        <Module.Description>{description}</Module.Description>
      </Module.Meta>
    </Module.ModuleHeader>
  );
};

export default ModuleHeader;
