import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilterModalContainer, LoadingContainer } from './FilterModal.styles';
import { FilterModalProps } from './types';

import Dropdown from '../../../Dropdown';

import { getOrganisations, getProgrammes, getOrganisationsByIds } from '../../../JourneySelectModal/helpers';
import { getCohortsInOrganisation } from '../../../../utils/api/cohorts';
import { getJourney } from '../../../../utils/api/journey';
import Button from '../../../Button';

import {useAppDispatch, useAppSelector } from '../../../../utils/hooks';
import { renderFilterModalHeader } from './components/UIElements';
import { Activity, ActivityContentBlock, Cohort, Experiment, Journey } from '../../../../utils/types';
import { getActivities, getExperimentIdsFromModules, getExperimentIdsFromContent } from './helpers';
import { getExperimentsByIds } from '../../../../utils/api/experiments';
import { RootState } from '../../../../redux';
import { FilterDetails } from '../../types';
import { UserState } from '../../../../redux/user.slice';
import Loader from '../../../Loader';
import { JourneyState } from '../../../../redux/journey.slice';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { actions } from '../../../../redux/app.slice';

const FilterModal: FC<FilterModalProps> = ({
  isOpen,
  onClose,
  isFacilitator,
  filter,
  tokens,
  setSelectedFacCohort,
}: PropsWithChildren<FilterModalProps>): JSX.Element => {
  const { accessToken } = useAppSelector((state) => state.auth);
  const { cohortId, learningJourneyId, title } = useAppSelector(
    (state: RootState): Cohort => state.cohorts.selectedCohort,
  );
  const { roles } = useAppSelector((state: RootState): UserState => state.user);
  const isSuperAdmin: boolean = roles.includes('system:administrator');
  const superUser: boolean = isSuperAdmin || isFacilitator;
  const [orgs, setOrgs] = useState<any>(null);
  const [programmes, setProgrammes] = useState<any>(null);
  const [cohorts, setCohorts] = useState<any>(null);
  const [filteredCohorts, setFilteredCohorts] = useState<Cohort[] | any>([]);
  const [journey, setJourney] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  const [experiments, setExperiments] = useState<any>(null);

  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedOrgName, setSelectedOrgName] = useState<string>('');

  const [selectedProgramme, setSelectedProgramme] = useState<{ _id: string; journeys: string[] }>({
    _id: '',
    journeys: [],
  });
  const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>('');

  const [selectedCohort, setSelectedCohort] = useState<{ cohortId: string; ljId: string }>({ cohortId: '', ljId: '' });
  const [selectedCohortName, setSelectedCohortName] = useState<string>('');

  const [selectedActivity, setSelectedActivity] = useState<{ _id: string; contentBody: ActivityContentBlock[] }>({
    _id: '',
    contentBody: [],
  });
  const [selectedActivityName, setSelectedActivityName] = useState<string>('');

  const [selectedExperiment, setSelectedExperiment] = useState<string>('');
  const [selectedExperimentName, setSelectedExperimentName] = useState<string>('');

  const anyAvailableExperiment: boolean = experiments?.length === 0 || experiments === undefined ? false : true;
  const experimentDropdownLabel = anyAvailableExperiment ? 'Name of experiment' : 'No experiments available';
  const [experimentsFound, setExperimentsFound] = useState<boolean>(false);

  const { cohortsOfFacilitator } = useAppSelector((state) => state.cohorts);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isActivityLoading, setIsActivityLoading] = useState<boolean>(false);
  const [isActDisabled, setIsActDisabled] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const [diplayTopFilters, setDiplayTopFilters] = useState<boolean>(true);
  const location = useLocation();
  const appInsights = useAppInsightsContext();
  const { tracking } = actions;

  useEffect(() => {
    if (window.location.href.includes('/app/insightswall')) {
      setDiplayTopFilters(false);
      setIsActDisabled(false);
      setExperimentsFound(false)
    }
  }, [isOpen]);

  useEffect((): (() => void) | undefined => {
    if (isOpen && superUser) {
      let active = true;
      (async (): Promise<void> => {
        const returnValue = cohortsOfFacilitator
          .map((element) => {
            return element.organisationId;
          })
          .reverse();
        const allOrgIds = returnValue.filter((item, index) => returnValue.indexOf(item) === index);
        const result: any = await getOrganisationsByIds(tokens, allOrgIds);
        if (active) {
          setOrgs(result);
        }
        setIsLoading(false);
      })();
      return (): void => {
        active = false;
      };
    } else {
      setOrgs([]);
    }
  }, [isOpen]);

  // get programme data when org selected
  useEffect((): (() => void) | undefined => {
    if (!selectedOrg || !superUser) {
      setProgrammes([]);
      return;
    }
    let active = true;
    (async (): Promise<void> => {
      const result: any[] = await getProgrammes(tokens, selectedOrg);
      const cohortsOfFacilitatorFiltered = cohortsOfFacilitator
      .filter(
        (element: Cohort) => element.organisationId === selectedOrg,
      );
      const retrunFacCohProgrammes = cohortsOfFacilitatorFiltered
        .map((element: Cohort) => {
          return element.learningJourney.programmeName;
        })
        .reverse();  
      const facCohProgrammesIds = retrunFacCohProgrammes.filter(
        (item, index) => retrunFacCohProgrammes.indexOf(item) === index,
      );
      const filteredProgrammes = result
        .filter((element) => {
          return facCohProgrammesIds.includes(element.name);
        })
        .reverse();
      if (active) {
        setProgrammes(filteredProgrammes);
      }
    })();
    return (): void => {
      active = false;
    };
  }, [tokens, selectedOrg]);

  // get cohorts when org selected
  useEffect((): (() => void) | undefined => {
    if (!selectedOrg || !superUser) {
      setCohorts([]);
      setSelectedCohort({ cohortId: cohortId, ljId: learningJourneyId });
      return;
    }
    let active = true;
    (async (): Promise<void> => {
      const result: any = await getCohortsInOrganisation(selectedOrg, accessToken);
      const retrunFacCoh = cohortsOfFacilitator
        .map((element: Cohort) => {
          return element.cohortId;
        })
        .reverse();
      const facCohIds = retrunFacCoh.filter((item, index) => retrunFacCoh.indexOf(item) === index);
      const filteredCohorts = result.results
        .filter((element: Cohort) => {
          return facCohIds.includes(element.cohortId);
        })
        .reverse();
      if (active) {
        setCohorts(filteredCohorts);
        localStorage.setItem("previousPage",window.location.pathname)
      }
    })();
    return (): void => {
      active = false;
    };
  }, [accessToken, selectedOrg]);

  // filter cohorts when programme selected by matching LJ ids
  useEffect((): (() => void) | undefined => {
    if (!cohorts) {
      setFilteredCohorts([]);
      setIsActivityLoading(false);
      return;
    }
    const filteredCohorts: Cohort[] = cohorts.filter((cohort: Cohort): boolean =>
      selectedProgramme.journeys.includes(cohort.learningJourneyId),
    );

    setIsActivityLoading(false);
    // setFilteredCohorts(filteredCohorts);
    setFilteredCohorts(cohorts);
    return (): void => {};
  }, [selectedProgramme, cohorts]);

  // get journey of selected cohort
  useEffect((): (() => void) | undefined => {
    if (!selectedCohort.cohortId || !selectedCohort.ljId) {
      return;
    }

    let active = true;

    (async (): Promise<void> => {
      const result: Journey = await getJourney(accessToken, {
        learningJourneyId: selectedCohort.ljId,
        cohortId: selectedCohort.cohortId,
      });
      if (active) {
        setJourney(result);
      }
    })();
    return (): void => {
      active = false;
    };
  }, [tokens, selectedCohort]);

  // get activities & experiment when journey is loaded
  useEffect((): (() => void) | undefined => {
    if (!journey) return;
    const result: Activity[] = getActivities(journey);
    const experimentTypeActivities: Activity[] = [];
    result.forEach((result: Activity): number | null =>
      result.type === 'experiment' && result.meta?.experimentType === 'insight'
        ? experimentTypeActivities.push(result)
        : null,
    );
    setActivities(experimentTypeActivities);
    const experimentIds: string[] = getExperimentIdsFromModules(journey);

    let active = !!experimentIds.length;

    if (!active) {
      setExperimentsFound(false);
      setExperiments([]);
    }

    (async (): Promise<void> => {
      const result: Experiment[] = await getExperimentsByIds(experimentIds, accessToken);
      setIsLoading(false);
      setSelectedActivityName('All activities');
      setSelectedExperimentName('All experiments');
      if (active) {
        setExperiments(result);
        if (result?.length > 0) {
          if(isActDisabled === true){
            setExperimentsFound(false);
          } else {
            setExperimentsFound(true);
          }
        } else {
          setExperimentsFound(false);
        }
      }
    })();
    return (): void => {
      active = false;
    };
  }, [journey]);

  // get experiment within activity (network workaround can be done by temporal experiments and filter)
  useEffect((): (() => void) | undefined => {
    if (!selectedActivity.contentBody || (!selectedActivity.contentBody.length && selectedActivityName === '')) return;

    let experimentIds: string[] = [];
    if (selectedActivity._id === '' && selectedActivityName === 'All activities') {
      experimentIds = getExperimentIdsFromModules(journey);
    } else {
      experimentIds = getExperimentIdsFromContent(selectedActivity.contentBody);
    }

    let active = !!experimentIds.length;

    if (!active) {
      setExperimentsFound(false);
      setExperiments([]);
    }

    (async (): Promise<void> => {
      const result: Experiment[] = await getExperimentsByIds(experimentIds, accessToken);
      setTimeout((): void => {
        if (active) {
          setExperiments(result);
          if (result?.length > 0) {
            if(isActDisabled === true){
              setExperimentsFound(false);
            } else {
              setExperimentsFound(true);
            }
          } else {
            setExperimentsFound(false);
          }
        }
        setIsActivityLoading(false);
      }, 300);
    })();
    return (): void => {
      active = false;
    };
  }, [selectedActivity, selectedActivityName]);
  // REQUIRED DATA LOADERS END

  // if end-user, load cohort, then journey, acitivity and experiment from state
  useEffect((): (() => void) | undefined => {
    if (!isFacilitator) {
      setSelectedCohort({ cohortId: cohortId, ljId: learningJourneyId });
      setSelectedCohortName(title);
    }

    return (): void => {};
  }, [isFacilitator, cohortId, learningJourneyId]);

  const handleSave: () => void = async () => {
    if(location.pathname == '/app/home'){
      dispatch(setSelectedFacCohort(selectedCohort.cohortId));
    }
    const selectedFilters: FilterDetails = {
      org: {
        id: selectedOrg || '',
        title: selectedOrgName || '',
      },
      programme: {
        id: selectedProgramme._id,
        title: selectedProgrammeName,
        journeys: selectedProgramme.journeys,
      },
      cohort: {
        id: selectedCohort.cohortId || '',
        title: selectedCohortName || '',
        ljId: selectedCohort.ljId || '',
      },
      activity: {
        id: selectedActivity._id || '',
        title: selectedActivityName || '',
        contentBody: selectedActivity.contentBody || [],
      },
      experiment: { id: selectedExperiment || '', title: selectedExperimentName || '' },
    };

    filter && filter(selectedFilters);
    if(location.pathname == '/app/home')
      onClose(selectedCohort.cohortId, selectedActivity._id, selectedExperiment);
    else
      onClose();
  };

  const isDisabled: boolean = experimentsFound === false;
  const areExperimentsFound: boolean = experimentsFound === true || experimentsFound === null;

  const loader: JSX.Element = <Loader size="large" color="purple" />;

  const smloader: JSX.Element = <Loader size="small" color="purple" />;
  return (
    <FilterModalContainer>
      {renderFilterModalHeader({ isFacilitator: isFacilitator, location: location})}
      {isLoading ? (
        loader
      ) : (
        <>
          {diplayTopFilters && (
            <Dropdown
              loading={!orgs}
              label="Select Organisation"
              placeholder={selectedOrgName || 'Please Select'}
              emptyLabel="Failed to load organisations"
              onValueChange={({ label, value }: any): void => {
                setJourney(null);
                setActivities(null);
                setExperiments(null);
                setSelectedOrg(value || '');
                setSelectedOrgName(label);
                setSelectedProgrammeName('');
                setSelectedCohort({ cohortId: '', ljId: '' });
                setSelectedCohortName('');
                setSelectedActivity({ _id: '', contentBody: [] });
                setSelectedActivityName('');
                setSelectedExperiment('');
                setSelectedExperimentName('');
                setIsActivityLoading(false);
              }}
              items={(orgs || []).map((org: any): any => ({
                label: org.name,
                value: org.id,
              }))}
              value={selectedOrg}
            />
          )}
          {diplayTopFilters && (
            <Dropdown
              loading={!programmes}
              label="Select Programme"
              placeholder={selectedProgrammeName || 'Please Select'}
              emptyLabel={selectedProgrammeName === '' ? 'Please Select' : 'No programmes available'}
              onValueChange={({ label, value, journeys }: any): void => {
                setJourney(null);
                setActivities(null);
                setExperiments(null);
                setSelectedProgramme({ _id: value, journeys: journeys } || { _id: '', journeys: '' });
                setSelectedProgrammeName(label);
                setSelectedCohortName('');
                setSelectedActivity({ _id: '', contentBody: [] });
                setSelectedActivityName('');
                setSelectedExperiment('');
                setSelectedExperimentName('');
                setIsActivityLoading(false);
              }}
              items={(programmes || []).map((programme: any): any => ({
                label: programme.name,
                value: programme._id,
                journeys: programme.journeys,
              }))}
              value={selectedCohort.cohortId}
            />
          )}
          {diplayTopFilters &&  (
            <Dropdown
              label="Select Cohort"
              placeholder={selectedCohortName || 'Please Select'}
              emptyLabel={selectedCohortName === '' ? 'Please Select' : 'No cohorts available'}
              onValueChange={({ label, value, ljId }: any): void => {
                setJourney(null);
                setActivities(null);
                setExperiments(null);
                setSelectedCohort({ cohortId: value, ljId: ljId } || { cohortId: '', ljId: '' });
                setSelectedCohortName(label);
                setSelectedActivity({ _id: '', contentBody: [] });
                setSelectedActivityName('');
                setSelectedExperiment('');
                setSelectedExperimentName('');
                setIsActivityLoading(true);
                setIsActDisabled(false);
              }}
              items={(filteredCohorts || []).map((cohort: any): any => ({
                label: cohort.title,
                value: cohort.cohortId,
                ljId: cohort.learningJourneyId,
              }))}
              value={selectedCohort.cohortId }
            />
          )}
                <Dropdown
                  label="Select Activity"
                  placeholder={selectedActivityName || 'Name of activity'}
                  emptyLabel="No activities available"
                  onValueChange={({ label, value, contentBody }: any): void => {
                    setSelectedActivity({ _id: value, contentBody: contentBody });
                    setSelectedActivityName(label);
                    setSelectedExperiment('');
                    setSelectedExperimentName('');
                  }}
                  items={activities && activities.length ?([{ title: 'All activities', _id: '', content: { body: [] } }, ...activities] || []).map(
                    (activity: any): any => ({
                      label: activity.title,
                      value: activity._id,
                      contentBody: activity.content.body,
                    }),
                  ):[]}
                  value={selectedActivity._id}
                  disabled={ isActDisabled ? true : false}

                />
             
          {((
            <Dropdown
              label="Select Experiment"
              placeholder={selectedExperimentName || experimentDropdownLabel}
              emptyLabel="No experiments available"
              onValueChange={({ label, value }: any): void => {
                setSelectedExperiment(value);
                setSelectedExperimentName(label);
              }}
              items={experiments && experiments.length?(
                [
                  { title: anyAvailableExperiment ? 'All experiments' : 'No experiments available', id: '' },
                  ...experiments,
                ] || []
              ).map((experiment: any): any => ({
                label: experiment.title,
                value: experiment.id,
              })):[]}
              value={selectedExperiment}
              disabled={ isActDisabled ? true : false}
            />
          )) ||
            null}
          <div style={{ maxWidth: '177px', paddingTop: '10px' }}>
            <Button onClick={(): void => handleSave()} disabled={isDisabled}>
              Show insights
            </Button>
          </div>
        </>
      )}
    </FilterModalContainer>
  );
};
export default FilterModal;
