import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logger from '../../../utils/logger';
import theme from '../../../utils/theme';
import Button from '../../Button';
import ModalListItem from '../../ModalListItem';
import {
  Header,
  HeaderContentWrapper,
  ContentWrapper,
  Logo,
  MenuWrapper,
  DesktopMenu,
  MenuButton,
  List,
} from './styles';
import Modal from '../../Modal';
import ExperimentModalHeader from '../../ExperimentModalHeader';
import Icon from '../../Icon';
import { useAppSelector, useViewport } from '../../../utils/hooks';
import { RootState } from '../../../redux';
import { InsightNotification, actions, NotificationState } from '../../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { getSafeAreaTop } from '../../../utils/safeArea';
import { CohortsState } from '../../../redux/cohorts.slice';
import { JourneyState } from '../../../redux/journey.slice';
import FilterModal from '../../InsightWallFilter/components/FilterModal';
import { FilterDetails } from '../../InsightWallFilter/types';

const safeAreaTop = getSafeAreaTop();

const { insightNotificationsDismissAll, setVisitedInsightsPage } = actions;

interface Props {
  type?: 'mobile' | 'desktop';
  roles: string[];
  menu: [boolean, Dispatch<SetStateAction<boolean>>];
  insightWall: [boolean, Dispatch<SetStateAction<boolean>>];
  logout: Function;
  setShowJourneySelect: Function;
  closeButtonRef?: any;
  isFacilitatorHomePage?:boolean;
  isFacilitator?:boolean;
}

const HeaderWithLogo: FC<Props> = ({
  type,
  roles,
  menu,
  insightWall,
  logout,
  setShowJourneySelect,
  closeButtonRef,
  isFacilitatorHomePage = false,
  isFacilitator = false,
}) => {
  const [showInsightsWallFilterModal, setShowInsightsWallFilterModal] = insightWall;

  const dispatch = useDispatch();
  const history = useHistory();
  const [showMenu, setShowMenu] = menu;
  const closeMenu = () => showMenu && setShowMenu(false);
  const [shouldNotify, setShouldNotify] = useState<boolean>(false);
  const insightNotifications: InsightNotification[] = useAppSelector(
    (state: RootState): InsightNotification[] => state.notifications.insights,
  );
  const { selectedCohort } = useAppSelector((state: RootState): CohortsState => state.cohorts);
  const { userVisitedInsightsPage } = useAppSelector((state: RootState): NotificationState => state.notifications);
  const { isPreview } = useAppSelector((state: RootState): JourneyState => state.journey);
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);
  // const [isFacilitator, setIsFacilitator] = useState<boolean>(false);
  const { id } = useAppSelector((state) => state.user);
  const location = useLocation();
  const isFacLearner = (location.pathname !== '/app/home') && selectedCohort.cohortId !== undefined && selectedCohort.userIds.includes(id);
  
  // useEffect((): (() => void) => {
  //   setIsFacilitator(roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures'));
  //   return (): void => {};
  // }, []);

  useEffect((): (() => void) => {
    setShouldNotify(false);
    const notifyable = !!insightNotifications.find(
      (insight: InsightNotification): boolean => insight.seen == false && insight.cohortId === selectedCohort.cohortId,
    );

    if (notifyable) setShouldNotify(true);

    return (): void => {};
  }, [insightNotifications, selectedCohort]);

  useEffect((): (() => void) => {
    if (userVisitedInsightsPage) {
      setShouldNotify(false);
    }
    return (): void => {};
  }, [userVisitedInsightsPage]);

  const { width: deviceWidth } = useViewport();

  const onClickLogoutButton = () => {
    logger('info', 'LOG_OUT');
    logout();

    const redirectUrl = `/${isNativeWebView ? window.location.search : ''}`;
    history.push(redirectUrl);
  };

  const renderListItems: () => JSX.Element = (): JSX.Element => {
    return (
      <>
        <li>
          <ModalListItem
            type="menu"
            title="Journey homepage"
            iconName="journey"
            route="/app/journey"
            onClick={() => closeMenu()}
          />
        </li>
        <li>
          <ModalListItem
            type="menu"
            title="Insights Wall"
            iconName="experiment-info"
            route={isPreview ? '' : isFacilitator?`/app/insightswall?cohortId=${selectedCohort.cohortId}` :'/app/insightswall'}
            onClick={(): void => {
              closeMenu();
              dispatch(insightNotificationsDismissAll(null));
              dispatch(setVisitedInsightsPage());
              isPreview && setShowInsightsWallFilterModal(true);
              localStorage.setItem("previousPage",window.location.pathname)
            }}
          />
        </li>
        {/* {process.env.REACT_APP_SHOW_ASSESSMENTS === 'true' && (
          <li>
            <ModalListItem
              type="menu"
              title="Assessments"
              iconName="assessment"
              route="/app/assessments"
              onClick={() => closeMenu()}
            />
          </li>
        )} */}
        <li>
          <ModalListItem
            type="menu"
            title="Content library"
            iconName="content"
            route="/app/content"
            onClick={() => closeMenu()}
          />
        </li>
      </>
    );
  };

  return (
    <Header isFacilitator={isFacilitator} isFacLearner={isFacLearner} style={{ paddingTop: type == 'mobile' ? `${21 + +safeAreaTop}px` : '0px' }}>
      <HeaderContentWrapper isMobile={deviceWidth < theme.devices.tablet}>
        <Link to="/app/journey">
          <Logo src="/images/strata_logo.svg" alt="Strata" />
        </Link>       
        <div>
        <MenuWrapper shouldNotify={process.env.REACT_APP_NEW_HOMEPAGE === 'true' && shouldNotify}>
          <MenuButton onClick={(): void => setShowMenu(!showMenu)} tabIndex={0}>
            <Icon name="menu" size="medium" fill={theme.colours.white} />
            <span>Menu</span>
          </MenuButton>
        
          <DesktopMenu
            isOpen={showMenu && deviceWidth > theme.devices.tablet}
            onMouseLeave={(): void => setShowMenu(false)}>
            {!isFacilitatorHomePage &&
            <List shouldNotify={process.env.REACT_APP_NEW_HOMEPAGE === 'true' && shouldNotify}>            
              {renderListItems()}         
            </List>
            }
            <ContentWrapper>              
              <Button
                style={{
                  width: '140px',
                }}
                onClick={onClickLogoutButton}
                onBlur={closeMenu}>
                Log out
              </Button>
              {!isFacilitator &&
              <span style={{ alignSelf: 'center', fontSize: theme.typography.font.size.xSmall }}>
                <strong>Environment:</strong> {process.env.REACT_APP_ENVIRONMENT} <br />
                <strong>Build No:</strong> {process.env.REACT_APP_VERSION}
              </span>
              }
            </ContentWrapper>
          </DesktopMenu>
        
        </MenuWrapper>

        {/* Mobiel menu */}
        <Modal isOpen={showMenu && deviceWidth < theme.devices.tablet} onClose={(): void => setShowMenu(false)}>
          <ExperimentModalHeader iconName="menu" title="Menu" />
          
          <List shouldNotify={shouldNotify} style={{}}>
          {!isFacilitator &&
            renderListItems()
          }
            <ContentWrapper>
              <Button
                style={{
                  width: '140px',
                }}
                onClick={onClickLogoutButton}
                onBlur={closeMenu}>
                Log out
              </Button>
          {!isFacilitator &&
              <span style={{ alignSelf: 'center', fontSize: theme.typography.font.size.xSmall }}>
                <strong>Environment:</strong> {process.env.REACT_APP_ENVIRONMENT} <br />
                <strong>Build No:</strong> {process.env.REACT_APP_VERSION}
              </span>
          }
            </ContentWrapper>
          </List>
        </Modal>
        </div>

      </HeaderContentWrapper>
      {!isFacilitator &&
      <div> 
        <Modal isOpen={showInsightsWallFilterModal} onClose={() => setShowInsightsWallFilterModal(false)}>
        <FilterModal
          isOpen={showInsightsWallFilterModal}
          filter={(filters: FilterDetails): void => localStorage.setItem('insightFilters', JSON.stringify(filters))}
          onClose={(): void => {
            setShowInsightsWallFilterModal(false);
            history.push('/app/insightswall');
          }}
          isFacilitator={false}
        />
        </Modal>{' '} 
      </div>}
    </Header>
  );
};

export default HeaderWithLogo;
