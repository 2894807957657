import React, { FC, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';

import GoalHeader from '../../components/GoalHeader';
import HabitCard from '../../components/HabitCard';
import OptionsModal, { OptionsModalOption } from '../../components/OptionsModal';
import Button from '../../components/Button';

import { FormattedGoal } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import styles from './styles.module.scss';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';

type Props = FormattedGoal & {
  deleteGoal: any;
};

const Goal: FC<Props> = ({
  title = '',
  id = '',
  habits,
  tasks,
  reflections,
  completedPerc = 0,
  deleteGoal,
}: Props): ReactElement | null => {
  const history = useHistory();
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const deleteGoalCallback = async () => {
    const huh = window.confirm('Are you sure? Deleting this goal will erase all saved data');
    if (huh) {
      history.replace(`/app/goals`);
      logger('info', 'GOAL_DELETE_CONFIRM', { goalId: id });
      await deleteGoal(id);
    }
  };

  return (
    <>
      <GoalHeader
        title={title}
        id={id}
        completedPerc={completedPerc}
        onClickEdit={() => {
          logger('info', 'GOAL_OPTIONS', { goalId: id });
          setShowOptions(true);
        }}
        taskCount={!tasks ? 0 : tasks.length}
        reflectionCount={!reflections ? 0 : reflections.length}
      />
      {(habits || []).map((habit, index) => (
        <HabitCard key={index} {...habit} />
      ))}
      {(!habits || habits.length < 3) && (
        <div className={styles.buttonContainer}>
          <Button
            variant="filled"
            onClick={() => {
              logger('info', 'GOAL_DASHBOARD_ADD_A_HABIT', { goalId: id });
              history.push(`/app/goals/goal/${id}/createhabit`);
            }}
            testId="addHabitToGoalButton">
            Add a new habit
          </Button>
        </div>
      )}

      <Modal isOpen={showOptions} onClose={() => setShowOptions(false)} width="small">
        <OptionsModal title="Edit goal">
          <OptionsModalOption
            onClick={() => {
              logger('info', 'GOAL_EDIT', { goalId: id });
              history.push(`/app/goals/goal/${id}/edit/name`);
            }}>
            <span>Edit name</span> <Icon name="edit" />
          </OptionsModalOption>
          <OptionsModalOption
            onClick={() => {
              logger('info', 'GOAL_DELETE', { goalId: id });
              deleteGoalCallback();
            }}>
            <span>Delete goal</span>
            <Icon name="delete" color="orange" />
          </OptionsModalOption>
        </OptionsModal>
      </Modal>
    </>
  );
};

export default Goal;
