import React, { FC, useRef, useState } from 'react';
import { resizeImage } from '../../utils/helpers';
import Modal from '../Modal';
import ContentItem from '../../screens/ContentItem/';
import styles from './styles.module.scss';
import { useWindowDimensions } from 'react-native';
import theme from '../../utils/theme';

type Props = {
  title: string;
  imageUrl: string;
  id: string;
  type: string;
  url?: string;
  index: number;
  carouselIndex?: number;
};

type SwitchLinkProps = {
  url: string;
  mediaId?: string;
  index: number;
  carouselIndex?: number;
};

const SwitchLink: FC<SwitchLinkProps> = ({ url, mediaId, index, carouselIndex, ...rest }: SwitchLinkProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const contentId = `${mediaId}-${carouselIndex}-${index}`;

  const contentParams = {
    params: {
      id: mediaId,
    },
  };

  if (url.indexOf('http') === 0) {
    return <a href={url} target="_blank" rel="noopener noreferrer" {...rest} />;
  }

  return (
    <>
      <button id={contentId} className={styles.contentButton} {...rest} onClick={openModal} />

      <Modal isOpen={modalOpen} onClose={closeModal} previousFocusedElementId={contentId}>
        <ContentItem showBreadcrumb={false} match={contentParams} />
      </Modal>
    </>
  );
};

const ContentCarouselItem: FC<Props> = ({ title, imageUrl, id, type, url, index, carouselIndex }: Props) => {
  const link = url || `/app/content/${id}`;
  const { width: deviceWidth } = useWindowDimensions();

  return (
    <div
      className={styles.container}
      style={{ width: deviceWidth > theme.devices.tablet ? '93%' : '95%' }}
      id={'carouselItemContainer'}>
      <SwitchLink
        url={link}
        data-test-id={`contentCarouselItemType-${type}`}
        mediaId={id}
        index={index}
        carouselIndex={carouselIndex}>
        <div className={styles.image} style={{ backgroundImage: `url(${resizeImage(imageUrl, 263, 137)})` }} />
        <div className={styles.content}>
          <div className={styles.tag}>{type}</div>
          <h3 className={styles.title}>{title}</h3>
        </div>
      </SwitchLink>
    </div>
  );
};

export default ContentCarouselItem;
