import styled from 'styled-components';

export const IconContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${(p) => p.theme.spacing(1)};
  margin-bottom: ${(p) => p.theme.spacing(3)};
  flex-wrap: wrap;

  > * {
    margin: ${(p) => p.theme.spacing(0.5)};
  }
`;

export const NextActivityText = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.small};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.darkGrey};
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

export const ExperimentParticipantContainer = styled.div`
  border-radius: ${(p) => p.theme.borders.radius};
  background-color: rgba(81, 26, 188, 0.05);
  padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(2)};
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

export const ParticipantsInfo = styled.span`
  text-align: left;
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.small};
`;
