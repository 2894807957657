export const habitEncouragingMessage = (completed: number, target: number): { header: string; body: string } => {
  if (completed > Math.ceil(target * 0.75)) {
    return {
      header: 'Fantastic job!',
      body: 'Your practice is paying off, just a few more repetitions to go.',
    };
  }
  if (completed === Math.ceil(target * 0.75)) {
    return {
      header: 'Milestone reached!',
      body: 'Congratulations on your amazing work so far.',
    };
  }
  if (completed > Math.ceil(target * 0.5)) {
    return {
      header: 'Practice makes perfect!',
      body: 'Today is another chance to get better.',
    };
  }
  if (completed === Math.ceil(target * 0.5)) {
    return {
      header: 'Milestone reached!',
      body: 'Good job, you’re half way to creating a lasting habit.',
    };
  }
  if (completed > Math.ceil(target * 0.25)) {
    return {
      header: 'Keep up the great work!',
      body: 'The more you practice the easier it gets.',
    };
  }
  if (completed === Math.ceil(target * 0.25)) {
    return {
      header: 'Milestone reached!',
      body: 'This is the beginning of even more great things.',
    };
  }
  if (completed > 1) {
    return {
      header: 'Amazing!',
      body: 'Don’t give up. The beginning is always the hardest part.',
    };
  }
  return {
    header: 'Great start!',
    body: 'For the best success, try to practice your habit once a day.',
  };
};
