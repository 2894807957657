import React from 'react';
import { FC } from 'react';
import { BlockType, Question } from '../../utils/types';
import JournalEntry from '../JournalEntry';
import Icon, { IconName } from '../Icon';
import styles from './styles.module.scss';
import theme from '../../utils/theme';
import { IconButton } from '../IconButton/styles';

interface Props {
  icon: IconName;
  questions: Question[];
  introText: string;
  view: BlockType;
  question?: string;
  journalId?: string;
}

const JournalPlaybackBlock: FC<Props> = ({ icon, questions, introText, view }) => {
  return (
    <div className={styles.journalBlock}>
      <div>
        {questions &&
          questions.map((question) => (
            <div key={question._id} className={styles.playbackQuestion} data-test-id="playbackQuestion">
              {view=='journalPlayback'? 
                 question.response && question.response.length>0 && <IconButton as="div" className={styles.playbackIcon} color={theme.colours.moduleFushia}>
                <Icon name="playback-block" color="white" />
                </IconButton>:
                <IconButton as="div" className={styles.playbackIcon} color={theme.colours.moduleFushia}>
                <Icon name="playback-block" color="white" />
                </IconButton>
              }
              <JournalEntry
                questionId={''}
                question={question.question}
                response={question.response}
                updateDate={question.responseUpdatedAt}
                view={view}
                key={question.question}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default JournalPlaybackBlock;
