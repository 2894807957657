import styled, { css } from 'styled-components';

const Container = styled.button<{ isSelectable: boolean }>`
  ${({ theme }) => ({
    border: `1px solid ${theme.colours.lightGrey}`,
    borderRadius: theme.borders.radius,
    backgroundColor: theme.colours.lightGrey,
    fontSize: theme.typography.font.size.xSmall,
    padding: `${theme.spacing(1)}`,
  })};

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92px;
  flex: 1;
  text-align: center;
  position: relative;

  ${(p) =>
    p.isSelectable &&
    css`
      cursor: pointer;
      background-color: ${(p) => p.theme.colours.white};
      border: 2px solid ${(p) => p.theme.colours.primary};
    `};
`;

const IconContainer = styled.div`
  ${({ theme }) => ({
    paddingBottom: `${theme.spacing(1)}`,
  })};
`;

const Label = styled.span`
  ${({ theme }) => ({
    color: `${theme.colours.darkGrey}`,
  })};
`;

export { Container, IconContainer, Label };
