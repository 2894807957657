import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { CardTypeStyleProps, CardInlineWrapperStyleProps, InlineWrapperStyleProps } from './cardTypes';

export const IntroText = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  text-align: left;
`;

export const ContentCard = styled.div`
  width: 100%;
  height: 375px;
  height: 100%;
  padding: ${(p) => p.theme.spacing(2)};
  background: ${(p) => p.theme.colours.white};
  border-radius: 10px;
  box-shadow: ${(p) => p.theme.shadows};
`;

export const CardType = styled.p<CardTypeStyleProps>`
  color: ${(p) => p.color};
  margin-left: ${(p) => p.theme.spacing(1)};
`;

export const CardTitle = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.large};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 22px;
`;

export const CardOption = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  margin-right: ${(p) => p.theme.spacing(1)};
`;

export const RequestInfo = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  margin-top: ${(p) => p.theme.spacing(1)};
  line-height: 17px;
`;

export const CardDescription = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  text-align: center;
  margin-top: ${(p) => p.theme.spacing(2)};
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const InlineWrapper = styled.div<InlineWrapperStyleProps>`
  flex-direction: row;
  align-items: center;
  ${(p) => p.marginStyle === 'vertical' && `margin-top: ${p.theme.spacing(2)}; margin-bottom: ${p.theme.spacing(2)};`}
  ${(p) => p.marginStyle === 'top' && `margin-top: ${p.theme.spacing(2)};`}
  ${(p) => p.marginStyle === 'bottom' && `margin-bottom: ${p.theme.spacing(2)};`}
  ${(p) => (p.spaced ? 'justify-content: space-between' : null)};
`;

const BlockWrapper = styled.div<InlineWrapperStyleProps>`
  flex-direction: column;
  align-items: flex-start;
  ${(p) => p.marginStyle === 'vertical' && `margin-top: ${p.theme.spacing(2)}; margin-bottom: ${p.theme.spacing(2)};`}
  ${(p) => p.marginStyle === 'top' && `margin-top: ${p.theme.spacing(2)};`}
  ${(p) => p.marginStyle === 'bottom' && `margin-bottom: ${p.theme.spacing(2)};`}
  ${(p) => (p.spaced ? 'justify-content: space-between' : null)};
`;

const Divider = styled.div`
  margin: auto 0;
  height: 1px;
  background: ${(p) => p.theme.colours.mediumGrey};
`;

export const CardInlineWrapper: React.FC<CardInlineWrapperStyleProps> = ({
  children,
  spaced = false,
  divider = false,
  marginStyle = 'vertical',
}: PropsWithChildren<CardInlineWrapperStyleProps>): JSX.Element => (
  <>
    <InlineWrapper spaced={spaced} marginStyle={marginStyle}>
      {children}
    </InlineWrapper>
    {divider && <Divider />}
  </>
);

export const CardBlockWrapper: React.FC<CardInlineWrapperStyleProps> = ({
  children,
  spaced = false,
  divider = false,
  marginStyle = 'vertical',
}: PropsWithChildren<CardInlineWrapperStyleProps>): JSX.Element => (
  <>
    <BlockWrapper spaced={spaced} marginStyle={marginStyle}>
      {children}
    </BlockWrapper>
    {divider && <Divider />}
  </>
);
