import styled, { css } from 'styled-components';

export const EmptyText = styled.p`
  align-self: center;
  text-align: center;
  margin-top: ${(p) => p.theme.spacing(2)};
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  line-height: 23px;
  max-width: 400px;
`;
