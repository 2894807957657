import { Settings } from 'react-slick';
import theme from '../../utils/theme';
import React from 'react';

import ContentCarouselArrow from '../../components/ContentCarouselArrow';

const settings: Settings = {
  arrows: true,
  infinite: false,
  dots: false,
  speed: 500,
  swipe: false,
  slidesToShow: 3,
  // slidesToScroll: 3,

  nextArrow: <ContentCarouselArrow next />,
  prevArrow: <ContentCarouselArrow />,

  responsive: [
    {
      breakpoint: theme.devices.mobile,
      settings: {
        arrows: false,
        slidesToShow: 1.3,
        slidesToScroll: 1,
        swipe: true,
      },
    },
    {
      breakpoint: theme.devices.tablet,
      settings: {
        arrows: false,
        slidesToShow: 2.3,
        slidesToScroll: 1,
        swipe: true,
      },
    },
  ],
};

export default settings;
