import styled from 'styled-components';
//import {} from './types';

export const QuitModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direciotn: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacing(2)};
  text-align: center;

  > * {
    margin: ${(p) => p.theme.spacing(1)} 0;
  }
`;
export const QuitModalOptions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalTitle = styled.h1`
  font-size: ${(p) => p.theme.typography.font.size.large};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
`;

export const ModalMessage = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
`;
