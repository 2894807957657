import React, { Fragment, useEffect, useState } from 'react';
import AssessmentCard from '../AssessmentCard';
import { AdaptedAssessment, Cohort, FullAssessment, UserMeta } from '../../../../utils/types';
import { fetchUserMeta, generateOTP } from '../../../../utils/api/assessments';
import { adaptAssessments } from '../../../../utils/adapters/assessments';
// import { ActivityAssessmentConstructor } from '../../../../screens/Assessments/helpers';
import Modal from '../../../../components/Modal';
import { LoaderForIframe } from '../../../../screens/Assessments/styles';
import { isSafari } from '../../../../utils/getUserAgent';
import { useAppSelector } from '../../../../utils/hooks';
import { RootState } from '../../../../redux';
import { UserState } from '../../../../redux/user.slice';

interface AssessmentCardWrapperProps {
  key: any;
  projectId: string;
}

const AssessmentCardWrapper: React.FC<AssessmentCardWrapperProps> = ({
  projectId,
  key,
}: AssessmentCardWrapperProps): JSX.Element => {
  const { email } = useAppSelector((state: RootState): UserState => state.user);
  const { accessToken } = useAppSelector((state: RootState) => state.auth);
  const [userAssessments, setUserAssessment] = useState<AdaptedAssessment[]>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalUrl, setModalUrl] = useState<string>('');
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);
  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);
  const [isLoadingRaters, setIsLoadingRaters] = useState<boolean>(false);

  useEffect((): void => {
    (async function (): Promise<void> {
      // const fullAssessments: FullAssessment[] = await ActivityAssessmentConstructor(accessToken, projectId, email);
      const userMetaData: UserMeta[] = await fetchUserMeta(accessToken);
      // setUserAssessment(adaptAssessments(fullAssessments, userMetaData[0]));
    })();
  }, []);

  const toggleModal: (type: string, index: number) => void = async (type: string, index: number): Promise<void> => {
    if (!userAssessments?.length) return;
    if (modalIsOpen) {
      // const fullAssessments: FullAssessment[] = await ActivityAssessmentConstructor(accessToken, projectId, email);
      const userMetaData: UserMeta[] = await fetchUserMeta(accessToken);
      // setUserAssessment(adaptAssessments(fullAssessments, userMetaData[0]));
      setModalIsOpen(false);
      return;
    }

    if (type === 'start') setIsLoadingStart(true);
    if (type === 'raters') setIsLoadingRaters(true);

    const { otp } = await generateOTP(accessToken, email);
    const startUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${userAssessments[index].questionnaireLink}`;
    const raterUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${userAssessments[index].nominationLink}`;
    const downloadUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${userAssessments[index].reportLink}`;
    const url: string = type !== 'start' ? (type === 'download' ? downloadUrl : raterUrl) : startUrl;

    if (type === 'start') setIsLoadingStart(false);
    if (type === 'raters') setIsLoadingRaters(false);

    if (isNativeWebView) {
      const qs = `?envisiaTestUrl=${process.env.REACT_APP_ENVISIA_BASE_URL_TEST}`; // TODO: Needs to be removed once Envisia URL config fixed on native app
      window.location.href = `${qs}#${url}`;
      return;
    }

    if (isSafari) {
      window.location.href = url;
      return;
    }

    setModalUrl(url);
    setModalIsOpen(true);
  };

  const renderAssessmentCards: () => React.ReactNode = (): React.ReactNode => {
    return userAssessments?.map((assessment: AdaptedAssessment, i: number): React.ReactNode => {
      return (
        <AssessmentCard
          title={assessment.title}
          campaignName={assessment.campaignName}
          totalRaters={assessment.totalRaters}
          deadline={assessment.deadline}
          status={assessment.status}
          key={i}
          cardIndex={i}
          startButtonOnPress={toggleModal}
          ratersButtonOnPress={toggleModal}
          downloadButtonOnPress={toggleModal}
          isLoadingStart={isLoadingStart}
          isLoadingRaters={isLoadingRaters}
        />
      );
    });
  };

  return (
    <Fragment key={key}>
      {renderAssessmentCards()}
      <Modal isOpen={modalIsOpen} onClose={(): void => setModalIsOpen(false)}>
        <LoaderForIframe />
        <iframe src={modalUrl} frameBorder="0" style={{ minHeight: `800px` }}></iframe>
      </Modal>
    </Fragment>
  );
};

export default AssessmentCardWrapper;
