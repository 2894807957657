import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AI_INST_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.addTelemetryInitializer((envelope) => {
  if (envelope.tags != null) {
    envelope.tags['ai.cloud.role'] = 'Web App';
    envelope.tags['ai.cloud.roleInstance'] = 'Web App instance';
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export { reactPlugin };
