import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import styles from './styles.module.scss';
import Icon, { IconName } from '../Icon';
import { ViewInsightsWall } from './styles';

interface Props {
  variant: 'journey' | 'module' | 'experiment';
  iconName?: IconName;
  buttonAction?: (() => void) | null;
  resetModalState: any;
}

const CongratulationsModal: FC<Props> = ({ variant, iconName, buttonAction, resetModalState }) => {
  const history = useHistory();

  return (
    <>
      <div className={styles.content}>
        <div className={styles.icon}>
          {variant === 'experiment' && (
            <Icon name={(iconName as IconName) || 'rosette-module'} size="large" color="secondary" />
          )}
          {variant === 'journey' && <Icon name="rosette-journey" size="large" color="secondary" />}
          {variant === 'module' && <Icon name="rosette-module" size="large" />}
        </div>

        <div className={styles.title}>Congratulations!</div>
        <div className={styles.subtitle}>You&apos;ve completed this {variant}</div>

        {variant === 'journey' && (
          <div className={styles.body}>
            Setting new goals and habits can help you apply what you&apos;ve learned on the programme.
          </div>
        )}

        {buttonAction === undefined && (
          <Button
            onClick={() => {
              history.replace('/app/journey');
              resetModalState();
            }}>
            Return to journey overview
          </Button>
        )}

        {buttonAction !== undefined && (
          <Button
            onClick={() => {
              resetModalState();
            }}>
            Done
          </Button>
        )}

        {buttonAction && (
          <ViewInsightsWall
            onClick={() => {
              resetModalState();
              buttonAction && buttonAction();
            }}>
            View Insights Wall
          </ViewInsightsWall>
        )}
      </div>
    </>
  );
};

export default CongratulationsModal;
