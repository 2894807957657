import { APIAssessment, APIProject, ProjectStatus, UserMeta, APIRequest } from '../types';

export const getData: (method: string, url: string) => Promise<any> = async (method: string, url: string) => {
  const data: Response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const response: any = await data.json();
  return response;
};

export const generateOTP: (accessToken: string, email: string) => Promise<any> = async (
  accessToken: string,
  email: string,
) => {
  const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/envisia-gateway/users/generate_otp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  const response: any = await data.json();
  return response;
};

export const fetchProjectsByJourney: (id: string) => Promise<ProjectStatus[]> = async (id: string) =>
  await getData('GET', `${process.env.REACT_APP_API_ROUTE}/journey/project/all/${id}`);

export const fetchProjectById: (id: string) => Promise<ProjectStatus> = async (id: string) =>
  await getData('GET', `${process.env.REACT_APP_API_ROUTE}/journey/project/${id}`);

export const fetchProjectUrlConstructor: (projectIds: string[]) => string = (projectIds: string[]) => {
  return projectIds.map((id: string) => `&project_ids[]=${id}`).join('');
};

// export const fetchAssessments: (accessToken: string, email: string, projectId: string) => Promise<APIAssessment> =
//   async (accessToken: string, email: string, projectId: string) => {
//     const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/envisia-gateway/assessments/status`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       },
//       body: JSON.stringify({
//         emails: [email],
//         projectId: projectId,
//         includeRaterDetails: true,
//       }),
//     });
//     const response: any = await data.json();
//     return response;
//   };

// export const fetchProjects: (accessToken: string, projectIds: string[]) => Promise<APIProject> = async (
//   accessToken: string,
//   projectIds: string[],
// ) => {
//   const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/envisia-gateway/projects`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify({
//       projectIds: projectIds,
//     }),
//   });
//   const response: any = await data.json();
//   return response;
// };

// export const fetchRequests: (accessToken: string, email: string, projectId: string[]) => Promise<APIRequest> = async (
//   accessToken: string,
//   email: string,
//   projectId: string[],
// ) => {
//   const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/envisia-gateway/raters/status`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//       Authorization: `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify({
//       email: email,
//       projectIds: projectId,
//       includeRaterDetails: true,
//     }),
//   });
//   const response: any = await data.json();
//   return response;
// };

export const fetchUserMeta: (accessToken: string) => Promise<UserMeta[]> = async (accessToken: string) => {
  const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/user-meta/user-meta/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const response: any = await data.json();
  return response;
};
