import { connect } from 'react-redux';
import Assessments from './AssessmentCardWrapper';
import { RootState } from '../../../../redux';

const mapState = (state: RootState, props: any) => {
  const { key, projectId } = props;

  return {
    key: key,
    projectId: projectId,
  };
};

export default connect(mapState, null)(Assessments);
