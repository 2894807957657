import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';

import Content from './Content';

const mapState = (state: RootState) => ({
  collections: get(state, 'content.collections', []),
});

export default connect(mapState, null)(Content);
