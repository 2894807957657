import styled, { css } from 'styled-components';
import { PositionalContainerStyleProps } from './types';

export const ModuleOverlayLayoutContainer = styled.div<{ isOpen: boolean }>`
  ${(p) =>
    p.isOpen
      ? css`
          pointer-events: default;
          opacity: 1;
        `
      : css`
          pointer-events: none;
          opacity: 0;
        `}
  position: absolute;
  left: 50%;
  bottom: 0;
`;

export const RelativeContainer = styled.div`
  position: relative;
  min-height: 200px;
`;

export const PositionalContainer = styled.div<PositionalContainerStyleProps>`
  position: absolute;
  right: 0;
  left: ${(p) => p.axisXOffset || 0}px;
  width: ${(p) => (p.isMobileView ? p.mobileWidth : '364')}px;
  border: 4px solid ${(p) => p.theme.colours.primary};
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(2)};
  padding-bottom: ${(p) => p.theme.spacing(3)};
  background: ${(p) => p.theme.colours.white};
  z-index: 3;

  ${(p) =>
    p.displayOnTop
      ? css`
          transform: translate(-50%, -100%);
          top: 41px;
        `
      : css`
          transform: translate(-50%, 100%);
          bottom: -15px;
        `}
`;

export const OverlayPointer = styled.div<{ displayOnTop: boolean }>`
  position: absolute;
  display: flex;
  transform: translate(-50%, -50%) rotateZ(-45deg);
  left: 50%;
  bottom: -40px;
  height: 24px;
  width: 24px;
  border-top: 4px solid ${(p) => p.theme.colours.primary};
  border-right: 4px solid ${(p) => p.theme.colours.primary};
  background: ${(p) => p.theme.colours.white};
  z-index: 5;

  ${(p) =>
    p.displayOnTop
      ? css`
          transform: translate(-50%, -50%) rotateZ(135deg);
          top: 40px;
        `
      : css`
          transform: translate(-50%, -50%) rotateZ(-45deg);
          bottom: -40px;
        `}
`;
