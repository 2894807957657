import styled, { css } from 'styled-components';

export const ContentCarousel = styled.div`
  width: 100%;
`;

export const SliderWrapper = styled.div`
  width: 100vw;

  ${({ theme }) =>
    css`
      @media (min-width: ${theme.devices.tablet}px) {
        width: 100%;
        margin: 0 auto;
        margin-bottom: ${({ theme }) => theme.spacing(2)};
      } ;
    `};
`;

export const Heading = styled.div`
  align-items: center;
  justify-content: left;
  flex-direction: row;
  margin-bottom: 5px;

  ${({ theme }) =>
    css`
      @media (min-width: ${theme.devices.tablet}px) {
        justify-content: space-between;
      } ;
    `};
`;

export const Title = styled.h2`
  margin-right: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.typography.font.color};
  font-size: ${({ theme }) => theme.typography.font.size.large};
  font-weight: ${({ theme }) => theme.typography.font.weight.bold};
  letter-spacing: 0;
  line-height: ${({ theme }) => theme.spacing(4)};
  text-align: left;
`;

export const ItemCount = styled.span`
  color: ${({ theme }) => theme.colours.primary};
  font-size: ${({ theme }) => theme.typography.font.size.xSmall};
  letter-spacing: 0;
  line-height: ${({ theme }) => theme.spacing(2)};
  text-transform: uppercase;
`;
