import React from 'react';
import * as Styled from './styles';

interface Props {
  className?: string;
}

interface FC extends React.FC<Props> {
  Item: typeof Styled.ListItem;
}

const List: FC = ({ children, className }) => {
  return <Styled.List className={className}>{children}</Styled.List>;
};

List.Item = Styled.ListItem;

export default List;
