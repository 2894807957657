import React, { FC, useEffect, useRef } from 'react';
import * as Styled from './styles';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Confetti, { ConfettiConfig } from 'react-dom-confetti';
import confettiConfig from './confettiConfig';

const rootElement = document.getElementById('root') as HTMLElement;

ReactModal.setAppElement(rootElement);

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  previousFocusedElement?: HTMLElement | null;
  previousFocusedElementId?: string;
  width?: 'small' | 'default';
  showConfetti?: boolean;
  containsDropdown?: boolean;
}

const Modal: FC<Props> = ({
  isOpen,
  onClose,
  previousFocusedElement,
  previousFocusedElementId,
  width = 'default',
  children,
  showConfetti = false,
  containsDropdown = false,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleRequestClose = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    onClose && onClose();

    if (previousFocusedElement) {
      previousFocusedElement.focus();
    }
  };

  const handleAfterOpen = () => {
    document.body.style.overflow = 'hidden';

    if (buttonRef && buttonRef.current) {
      buttonRef.current.focus({ preventScroll: true });
    }
  };

  const handleAfterClose = () => {
    document.body.style.overflow = 'unset';
    if (previousFocusedElementId) {
      const el: HTMLElement | null = document.getElementById(previousFocusedElementId);
      // if previous element, scroll to center the element on screen
      if (el)
        window.scrollTo({
          top: el.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 3,
        });
    }
  };

  return (
    <>
      <ReactModal
        portalClassName="modal"
        className={classNames('modal__content', {
          ['modal__content--small']: width === 'small',
          ['modal__content--confetti']: showConfetti,
          ['modal__with__dropdown']: containsDropdown,
        })}
        overlayClassName="modal__overlay"
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        preventScroll={true}
        parentSelector={() => rootElement}
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        ariaHideApp={false}>
        <Styled.ConfettiWrapper>
          <Confetti active={showConfetti} config={confettiConfig} />
        </Styled.ConfettiWrapper>

        <IconButton
          className="closeModalButton"
          aria-label="Close modal"
          onClick={onClose && onClose}
          data-test-id="modal-close"
          ref={buttonRef}>
          <Icon name="close" />
        </IconButton>
        {children}
      </ReactModal>
    </>
  );
};

export default Modal;
