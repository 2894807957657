import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import ActivityNavigation from '../../components/ActivityNavigation';
import PageHeader from '../../components/PageHeader';
import { isDesktop } from '../../utils/breakpoints';
import { useViewport } from '../../utils/hooks';
import { ModuleDisplayData } from './adapters';

type Props = ModuleDisplayData & {
  activityId?: string;
  test: boolean;
  journeyTitle: string;
};

const Module: FC<Props> = ({ id, title, activities }: Props): JSX.Element => {
  const { width } = useViewport();

  if (isDesktop(width)) {
    const firstIncomplete = activities.find((activity) => !activity.completed);
    const activityToOpen: string | undefined = firstIncomplete?.id || activities[0].id;
    const pathname = activityToOpen ? `/app/activity/${activityToOpen}` : '/app/journey';

    return (
      <Redirect
        to={{
          pathname: pathname,
          search: `?moduleId=${id}`,
        }}
      />
    );
  }

  return (
    <>
      <PageHeader title={title} />
      <ActivityNavigation />
    </>
  );
};

export default Module;
