import styled, { css } from 'styled-components';
import { ModalListItemContainerStyleProps, ModalListItemType } from './types';
import { Link } from 'react-router-dom';

export const ModalListItemContainer = styled.button<ModalListItemContainerStyleProps>`
  outline: none;
  border: none;
  background-color: none;
  cursor: ${(p) => (p.isCurrent ? 'default' : 'pointer')};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  background-color: ${(p) => (p.isCurrent ? p.theme.colours.lightGrey : p.theme.colours.white)};
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(2)};
  box-shadow: ${(p) => (p.isCurrent ? 'none' : p.theme.shadows)};
  pointer-events: ${(p) => (p.isCurrent ? 'none' : 'default')};
`;

export const ModalListItemLinkContainer = ModalListItemContainer.withComponent(Link);

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justfiy-content: center;
  flex: 1;
`;

export const Title = styled.h2<{ type?: ModalListItemType; isSelected?: boolean }>`
  text-align: start;
  font-size: ${(p) => (p.type === 'menu' ? p.theme.typography.font.size.regular : p.theme.typography.font.size.small)};
  font-weight: ${(p) =>
    p.isSelected && p.type === 'menu' ? p.theme.typography.font.weight.bold : p.theme.typography.font.weight.regular};
  color: ${(p) => (p.isSelected && p.type === 'menu' ? p.theme.colours.primary : p.theme.colours.darkGrey)};
  margin: 0;

  ${(p) =>
    p.type == 'menu' &&
    css`
      flex: 1;
      align-self: start;
    `}
`;

export const Progress = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  color: ${(p) => p.theme.colours.darkGrey};
`;
