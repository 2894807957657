import React from 'react';
import * as Styled from './styles';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import theme from '../../utils/theme';
import { colourExistsInTheme } from '../../utils/theme/helpers';

interface Props {
  moduleName?: string;
  id?: string;
  description: string;
  completed: boolean;
  imageUrl?: any;
  moduleId?: string;
  title?: string;
  type?: string;
  moduleBackground: string;
  currentActivityId?: string;
  isPreivew?: boolean;
}

const ActivityCard = ({
  moduleName,
  id,
  completed,
  description,
  imageUrl,
  moduleId,
  title,
  type,
  moduleBackground,
  currentActivityId,
  isPreivew = false,
}: Props): JSX.Element => {
  if (!colourExistsInTheme(moduleBackground, 'module')) moduleBackground = theme.colours.moduleFushia;

  if (isPreivew) {
    return (
      <Styled.Container completed={completed} selected={id === currentActivityId}>
        <Styled.Meta>
          <Styled.TypeLabel>{type}</Styled.TypeLabel>
          <Styled.Title>{title}</Styled.Title>
          {!completed && <Styled.Description>{description}</Styled.Description>}
        </Styled.Meta>
      </Styled.Container>
    );
  }

  return (
    <Styled.ActivityLink
      aria-label={`module ${moduleName}, activity(${completed ? 'completed' : 'incomplete'}) ${title}`}
      to={`/app/activity/${id}?moduleId=${moduleId}`}
      aria-current={id === currentActivityId}>
      <Styled.Container completed={completed} selected={id === currentActivityId}>
        <Styled.Meta>
          <Styled.TypeLabel>{type}</Styled.TypeLabel>
          <Styled.Title>{title}</Styled.Title>
          {!completed && <Styled.Description>{description}</Styled.Description>}
        </Styled.Meta>

        <Styled.ActivityIcon color={completed ? theme.colours.secondary : moduleBackground}>
          <Icon name={completed ? 'check' : imageUrl} type="activity" color="white" />
        </Styled.ActivityIcon>
      </Styled.Container>
    </Styled.ActivityLink>
  );
};

export default ActivityCard;
