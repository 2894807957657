import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';

import Loader from '../Loader';

import { HabitCollection } from '../../utils/types';

interface Props {
  getHabitCollection: any;
  onSelectHabit: any;
}

const HabitBankModal: FC<Props> = ({ getHabitCollection, onSelectHabit }) => {
  const [habitBank, setHabitBank] = useState<Array<HabitCollection> | null>(null);

  useEffect(() => {
    (async () => {
      if (habitBank) return;
      const response = await getHabitCollection();
      setHabitBank(response.payload);
    })();
  }, []);

  return (
    <>
      <div className={styles.content}>
        {!habitBank && (
          <div className={styles.loading}>
            <Loader color="purple" size="large" />
          </div>
        )}
        {habitBank && (
          <>
            <div className={styles.title}>Select a habit</div>
            <div className={styles.modalContent}>
              {habitBank.map(({ group, _id, habits, icon }, bankIndex) => {
                if (!habits.length) return null;
                return (
                  <div key={_id} className={styles.habitGroup}>
                    <div className={styles.habitGroupTitle}>
                      <svg>
                        <image xlinkHref={icon} />
                      </svg>
                      {group}
                    </div>
                    {habits.map((habit, habitIndex) => {
                      if (!habit.title || habit.title.length === 0) return null;
                      return (
                        <button
                          key={habit._id}
                          className={styles.habitButton}
                          onClick={() => onSelectHabit(habit)}
                          data-test-id={`habitBankButton-${bankIndex}-${habitIndex}`}>
                          {habit.title}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HabitBankModal;
