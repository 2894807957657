import React, { FC, PropsWithChildren } from 'react';
import { WidgetProps } from './types';
import WidgetHeader from './WidgetHeader';
import WidgetContainer from './WidgetContainer';

const Widget: FC<WidgetProps> = ({
  title,
  iconName,
  RouterLink,
  isComponentView = false,
  children,
  isFacilitator = false,
}: PropsWithChildren<WidgetProps>): JSX.Element => {
  return (
    <>
      <WidgetContainer>
        <WidgetHeader title={title} iconName={iconName} RouterLink={RouterLink} isComponentView={isComponentView} isFacilitator={isFacilitator} />
        {children}
      </WidgetContainer>
    </>
  );
};

export default Widget;
