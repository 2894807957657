import styled from 'styled-components';
import theme from '../../utils/theme';
import ModuleProgress from '../ModuleProgress';
import Typography from '../Typography/Typography';

export const ModuleHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  display: none;

  @media (min-width: ${theme.devices.tablet}px) {
    display: block;
  }
`;

export const Description = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(2)};
  flex: 1;
`;

export const Meta = styled.div`
  flex-direction: row;
`;

export const Progress = styled(ModuleProgress)``;
