import styled from 'styled-components';
import { TileBackgroundProps } from './types';

export const TileBackground = styled.div<TileBackgroundProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(p) =>
    p.completed ? `background-color: ${p.theme.colours.lightGrey};` : `border: 2px solid ${p.theme.colours.lightGrey};`}
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(2)};
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

export const Name = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.small};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  line-height: 18px;
`;

export const InfoWrapper = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  line-height: 18px;
`;

export const ProgressInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
