import React, { FC } from 'react';
import * as Styled from './styles';
import { Color } from '../../utils/types/theme';

interface Props {
  value: number;
  max: number;
  color: Color;
  segments?: number;
}

const ProgressBarNotched: FC<Props> = ({ color, value, max, segments }) => {
  const notchCount = segments || max;

  return (
    <Styled.ProgressBarNotched>
      {[...new Array(notchCount - 1)].map((v, i) => (
        <Styled.Notch key={i} style={{ left: `${Math.round(((i + 1) / notchCount) * 100)}%` }} />
      ))}
      <Styled.Fill width={Math.round((value / max) * 100)} background={color} />
    </Styled.ProgressBarNotched>
  );
};

export default ProgressBarNotched;
