import React, { FC, useEffect, useState } from 'react';
import * as Styled from './styles';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { SetTokensPayload } from '../../redux/auth.slice';

import Button from '../../components/Button';

import { verifyCode } from '../../utils/api/auth';

import OtpInput from 'react-otp-input';
import Typography from '../../components/Typography';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { actions } from '../../redux/app.slice';
import { useAppDispatch } from '../../utils/hooks';

type CodeFormPayload = {
  code: string;
};

type Props = {
  email: string;
  loginRedirect: string;
  setTokens: ActionCreatorWithPayload<SetTokensPayload, string>;
};

const LoginCode: FC<Props> = ({ email, loginRedirect, setTokens }: Props) => {
  const dispatch = useAppDispatch();
  const { tracking } = actions;
  const appInsights = useAppInsightsContext();

  const history = useHistory();

  const [code, setCode] = useState<string>('');
  const [sending, setSending] = useState<boolean>(false);

  const { handleSubmit, control, errors, setError } = useForm();

  useEffect(() => {
    if (!email || email.length === 0) {
      history.replace('/auth/email');
    }
  }, [email]);

  const onSubmit = async ({ code }: CodeFormPayload) => {
    dispatch(
      tracking({
        appInsights,
        name: 'debug-user',
        payload: { type: 'submit' },
      }),
    );

    if (!code || code.length < 6) {
      setError('code', {
        type: 'manual',
        message: 'Must be at least 6 characters',
      });
      return;
    }
    setSending(true);
    try {
      const result = await verifyCode(code, email);
      if (result === false) {
        history.replace('/auth/email', { invalidCode: true });
      } else {
        const { accessToken, refreshToken } = result;
        setTokens({ accessToken, refreshToken });
        dispatch(
          tracking({
            appInsights,
            name: 'debug-user',
            payload: { type: 'verifyCode', data: { email } },
          }),
        );
        history.push(loginRedirect, { from: 'auth' });
      }
    } catch (err) {
      dispatch(
        tracking({
          appInsights,
          name: 'debug-user',
          payload: { type: 'failed verifyCode', data: { email } },
        }),
      );
      setSending(false);
    }
  };

  const handleChange = (value: string) => setCode(value);

  return (
    <Styled.LoginCode>
      <Styled.Image src="/images/password_email_NEEDSVG.png" alt="" />

      <Styled.LoginHeading>Check your email & spam folder</Styled.LoginHeading>

      <Styled.CodeInstructions tabIndex={0}>
      If{' '}
        <Typography color="primary" level="span">
          <strong>{email}</strong>
        </Typography>
        {' '}exists in our system, you will be sent a code. Please allow up to five minutes for the email to arrive.
      </Styled.CodeInstructions>

      <Typography className="sr-only" tabIndex={0}>
        Enter the 6 digit code from your email
      </Typography>

      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={OtpInput}
          value={code}
          onChange={handleChange}
          name="code"
          control={control}
          numInputs={6}
          containerStyle={{ flexDirection: 'row', marginBottom: '20px' }}
          isInputNum={true}
          inputStyle={Styled.codeInput}
        />

        <Button testId="login" loading={sending}>
          Log in.
        </Button>

        <Typography color="primary" mt={20}>
          Not received a code? <Styled.Link to="/auth/email">Change email address</Styled.Link>
        </Typography>
      </Styled.Form>
    </Styled.LoginCode>
  );
};

export default LoginCode;
