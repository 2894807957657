import { connect } from 'react-redux';
import SwitchFacJourneyModal from './SwitchFacJourneyModal';
import { RootState } from '../../../../redux';
import { actions as modalActions } from '../../../../redux/modals.slice';

const mapState = (state: RootState) => {
  return {};
};

const { resetModalState } = modalActions;

export default connect(mapState, { resetModalState })(SwitchFacJourneyModal);
