import styled from 'styled-components';
import theme from '../../utils/theme';

export const container = styled.div`
  flex-direction: row;
  align-items: center;
  color: ${theme.colours.primary};
  font-size: ${theme.typography.font.size.large};
  letter-spacing: 0;

  margin-bottom: ${theme.spacing(1)};
  flex-direction: row;

  svg {
    flex-shrink: 0;
  }

  span {
    margin-left: ${theme.spacing(1)};
    line-height: 20px;
  }
`;
