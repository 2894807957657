import classNames from 'classnames';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import React, { FC, FormEvent, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import {
  createUserJournalEntry,
  NewJournalEntry,
  UpdatedJournalEntry,
  updateUserJournalEntry,
} from '../../redux/userJournal.slice';

import { actions as activityActions } from '../../redux/experiment.slice';

import { isDesktop } from '../../utils/breakpoints';
import { isHTML } from '../../utils/helpers';

import { useAppDispatch, useAppSelector, useViewport } from '../../utils/hooks';
import { BlockType, JournalEntry, UserRecord } from '../../utils/types';
import Button from '../Button';
import Icon from '../Icon';
import IconButton from '../IconButton';
import RichTextEditor from '../RichTextEditor';

import styles from './styles.module.scss';
import { tracking } from '../../redux/app.slice';

interface Props {
  blockId?: string;
  questionId: string;
  questionText?: string;
  response?: string;
  openModal?: () => void;
  isExperiment?: boolean;
  debounce?: any;
  view?: BlockType;
  activityId?: string;
  isInsightWallEditorView?: boolean;
}

const JournalCaptureEntry: FC<Props> = ({
  blockId,
  view,
  questionText,
  questionId,
  response,
  openModal,
  isExperiment = false,
  debounce,
  activityId,
  isInsightWallEditorView,
}) => {
  const appInsights = useAppInsightsContext();

  const history = useHistory<any>();
  const dispatch = useAppDispatch();
  const scrollToRef = useRef<any>();

  const { id } = useAppSelector((state) => state.user);
  const journalEntries = useAppSelector((state) => state.userJournal.journalEntries);
  const { id: journeyId } = useAppSelector((state) => state.journey);
  const { programmes } = useAppSelector((state) => state.programmes);
  const userRecord: UserRecord = useAppSelector((state) => state.experiment.activityRecord);
  const { currentEntryResponse } = useAppSelector((state) => state.experiment);

  const [journalEntryResponse, setjournalEntryResponse] = useState('');
  const [journalEntry, setJournalEntry] = useState<JournalEntry | null>(null);
  const [isEditEntry, setIsEditEntry] = useState(false);
  const [parentId, setParentId] = useState('');

  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  const { width: deviceWidth } = useViewport();
  debounce && debounce(() => handleExperimentSubmit(), 600, [journalEntryResponse]);

  const locationIsAddEntry = history.location.pathname.startsWith('/app/add-journal');

  useEffect(() => {
    if(programmes.length) {
      const currentProgramme = programmes.find((programme) => programme.journeys.includes(journeyId ? journeyId : ''));
      currentProgramme && setParentId(currentProgramme._id);
    }
  }, []);

  useEffect(() => {
    if (journalEntries) {
// @ts-ignore
      const foundEntry = journalEntries.find((journal) => journal.sourceId === questionId);
      if (foundEntry) {
        if (isExperiment) {
          setJournalEntry(foundEntry);
          setjournalEntryResponse(currentEntryResponse.response || foundEntry.response || '');
          return;
        } else {
          setJournalEntry(foundEntry);
          setjournalEntryResponse(foundEntry.response || '');
          return;
        }
      }

      if (isExperiment) {
        setjournalEntryResponse(currentEntryResponse.response || '');
      }
    }
  }, [journalEntries]);

  useEffect(() => {
    if (!isDesktop(deviceWidth)) {
      if (history.location.state?.questionId && scrollToRef?.current?.offsetTop) {
        const y = scrollToRef?.current.getBoundingClientRect().top + window.pageYOffset - 200;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [deviceWidth]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const newJournalEntry: NewJournalEntry = {
      sourceId: questionId,
      response: journalEntryResponse,
      question: questionText,
      parentId,
    };

    const updatedJournalEntry: UpdatedJournalEntry = {
      ...newJournalEntry,
      journalEntryId: journalEntry?._id,
    };

    if (!journalEntry) {
      dispatch(createUserJournalEntry(newJournalEntry));
    } else {
      dispatch(updateUserJournalEntry(updatedJournalEntry));
    }

    setIsEditEntry(false);
    if (!isDesktop(deviceWidth) && locationIsAddEntry) {
      history.push(history.location?.state?.fromPath, { questionId });
    }

    dispatch(
      tracking({
        appInsights,
        name: 'Activity',
        payload: { type: 'PAGE_ACTIVITY_JOURNAL_ENTRY_SAVE', activityId, blockId, questionId },
      }),
    );
  };

  const handleExperimentSubmit: () => void = (): void => {
    let meta;
    if (userRecord.experimentId && activityId) {
      meta = {
        journeyId: userRecord.journeyId,
        // cohortId: userRecord.cohortId,
        cohortId: selectedCohort.cohortId,
        experimentId: userRecord.experimentId,
        moduleId: userRecord.moduleId,
        activityId: activityId,
      };
    }
    const newJournalEntry: NewJournalEntry = {
      sourceId: questionId,
      response: journalEntryResponse,
      question: questionText,
      parentId,
      meta,
    };
    const updatedJournalEntry: UpdatedJournalEntry = {
      ...newJournalEntry,
      // meta,
      journalEntryId: journalEntry?._id,
    };
// @ts-ignore
  if (!journalEntry) {
    const newExperimentEntry = { ...newJournalEntry, userId: id };
    dispatch(activityActions.setJournalEntries(newExperimentEntry));
  } else {
    const updatedExperimentEntry = { ...updatedJournalEntry, userId: id };
    dispatch(activityActions.setJournalEntries(updatedExperimentEntry));
  }
  }

  const onEditLink = () => {
    if (journalEntryResponse !== response) {
      setjournalEntryResponse(response ? response : '');
    }

    isDesktop(deviceWidth)
      ? setIsEditEntry(!isEditEntry)
      : history.push(`/app/add-journal/${questionId}`, {
          isEditEntry,
          questionText,
          fromPath: window.location.pathname + window.location.search,
          activityId: activityId,
          blockId: blockId,
        });
  };

  const renderRichTextEditor = () => {
    const hideSubmitButton = !isDesktop(deviceWidth) && locationIsAddEntry;

    return (
      <RichTextEditor
        placeholder="Insert answer here"
        value={journalEntryResponse}
        onChange={(value) => setjournalEntryResponse(value)}
        toolbar={{ hideSubmitButton }}
        autoFocus={locationIsAddEntry}
        isExperiment={isExperiment}
        journalId={questionId}
        question={questionText}
      />
    );
  };

  return (
    <form className={styles.journalEntry} onSubmit={handleSubmit}>
      <div className={classNames(styles.journalEntryHeader, locationIsAddEntry && styles.addJournalEntryHeaderMobile)}>
        <label htmlFor="question" className={styles.entryQuestion}>
          {view === 'insightJournalText' ? parse(questionText!) : questionText}
        </label>

        {locationIsAddEntry && !isDesktop(deviceWidth) && (
          <IconButton
            aria-label="Open learner journal modal"
            className={styles.helpIcon}
            onClick={openModal && openModal}>
            <Icon name="info" />
          </IconButton>
        )}
      </div>

      <div className={styles.entryResponseBlock}>
        {response && (
          <div className={styles.entryWithResponse}>
            {isExperiment && !isInsightWallEditorView && (
              <div className={styles.entryResponseHTML}>{parse(response)}</div>
            )}

            {/* insightWallEditView show response editor desktop */}
            {isExperiment && isInsightWallEditorView && isDesktop(deviceWidth) && renderRichTextEditor()}

            {!isExperiment && isEditEntry && isDesktop(deviceWidth) && renderRichTextEditor()}

            {!isExperiment && !isEditEntry && isHTML(response) && (
              <div className={styles.entryResponseHTML}>{parse(response)}</div>
            )}

            {!isExperiment && !isEditEntry && !isHTML(response) && (
              <p className={styles.entryResponseText}>{response}</p>
            )}

          </div>
        )}

        {!response && (
          <div className={styles.entryWithoutResponse}>
            {isDesktop(deviceWidth) && !locationIsAddEntry && renderRichTextEditor()}

            {!isDesktop(deviceWidth) && locationIsAddEntry && (
              <>
                {renderRichTextEditor()}

                <div className={styles.saveBtnWrapper}>
                  <Button type="submit" color="primary" size="medium">
                    Save
                  </Button>
                </div>
              </>
            )}

            {!isExperiment && !isDesktop(deviceWidth) && !locationIsAddEntry && (
              <button type="button" className={styles.inputEntryButton} onClick={onEditLink}>
                Insert answer here
              </button>
            )}

            {isExperiment && !isDesktop(deviceWidth) && renderRichTextEditor()}
          </div>
        )}
      </div>
    </form>
  );
};

export default JournalCaptureEntry;
