import React, { FC, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../utils/hooks';
import { actions } from '../../redux/auth.slice';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import * as Styled from './styles';

const Callback: FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { setTokens } = actions;
  const dispatch = useDispatch();
  const history = useHistory();
  const { loginRedirect } = useAppSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(setTokens({ accessToken: token, refreshToken: '' }));
        history.push(loginRedirect, { from: 'auth' });
      } catch (error) {
        console.log(error);
      }
    })();
  }, [getAccessTokenSilently]);
  return (
    <Styled.Container>
      <Loader size="large" />
    </Styled.Container>
  );
};

export default Callback;
