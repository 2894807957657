import React, { FC, useState, PropsWithChildren } from 'react';
import { ActivityContentBlock, Experiment } from '../../utils/types';
import ExperimentCard from '../ExperimentCard';
import { useAppSelector } from '../../utils/hooks';

interface Props {
  block: ActivityContentBlock;
  updateActivityProgress: any;
  activityId: string;
  getCohortUserRecords: any;
}

const ActivityExperimentCard: FC<Props> = ({
  block,
  updateActivityProgress,
  activityId,
  getCohortUserRecords,
}: PropsWithChildren<Props>) => {
  const { text } = block;
  const experiment: Experiment | undefined = useAppSelector((state) =>
    state.experiment.experiments.find((experiment: Experiment) => experiment.id === text),
  );
  const records: any = useAppSelector((state) =>
    state.cohorts.cohortUserRecords.filter((userRecord: any) => userRecord.experimentId === experiment?.id),
  );
  const filteredRecordsLength = records.filter((userRecord: any) => userRecord.activityId === activityId).length;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!experiment) return <></>;

  const handleToggleStatus: () => void = () => {
    setTimeout(() => {
      (async () => {
        setIsLoading(true);
        await updateActivityProgress({
          activityId: activityId,
          completed: true,
          experimentId: experiment.id,
          isExperimentCompleted: false,
        });
        await getCohortUserRecords();
        setIsLoading(false);
      })();
    }, 1000);
  };

  return (
    <ExperimentCard
      experiment={experiment}
      personCount={filteredRecordsLength}
      onClick={() => handleToggleStatus()}
      isLoading={isLoading}
    />
  );
};

export default ActivityExperimentCard;
