import React, { FC, PropsWithChildren } from 'react';
import { WidgetContainerContainer } from './styles';
import { WidgetContainerProps } from './types';

const WidgetContainer: FC<WidgetContainerProps> = ({
  children,
}: PropsWithChildren<WidgetContainerProps>): JSX.Element => {
  return <WidgetContainerContainer>{children}</WidgetContainerContainer>;
};
export default WidgetContainer;
