import React, { FC } from 'react';
import * as Styled from './styles';
import ModuleListItem from '../../../ModuleListItem';
import { ModuleDisplayData } from '../../../../screens/LearningJourney/adapters';

interface Props {
  modules: ModuleDisplayData[];
  allModulesCompleted: boolean;
  previewFeatures: boolean;
}
const StandardJourney: FC<Props> = ({ modules, allModulesCompleted, previewFeatures }) => {
  return (
    <Styled.StandardJourney>
      <Styled.DottedPath />

      <Styled.List>
        {modules.map((module: any, index: number) => (
          <Styled.ListItem key={index}>
            <ModuleListItem {...module} previewFeatures={previewFeatures} index={index} testID={'ModuleListItem'} />
          </Styled.ListItem>
        ))}
      </Styled.List>
    </Styled.StandardJourney>
  );
};

export default StandardJourney;
