const onboarding = {
  learnerJournal: [
    {
      title: 'The benefits of journaling',
      image: '/images/onboarding/learnerJournal/learnerJournal_01@2x.png',
      description:
        'Self-reflection is a critical habit for optimizing performance in our daily lives, it helps to remind us of our progression and what it is we want to accomplish.',
    },
    {
      title: 'Who has access to my journal?',
      image: '/images/onboarding/learnerJournal/learnerJournal_02@2x.png',
      description:
        'Responses to questions are saved and played back at key points throughout your learning journey. These playback reminders will only be visible to you.',
    },
  ],

  goals: [
    {
      title: 'Turn aspirations in to action by setting goals',
      image: '/images/onboarding/goals/goals_01@2x.png',
      description: 'Trying new things takes planning, practice and perserverance. Defining success is crucial.',
    },
    {
      title: 'Add up to 3 habits to each of your goals',
      image: '/images/onboarding/goals/goals_02@2x.png',
      description: 'Choose from the habit bank or create your own.',
    },
    {
      title: 'Log your progress in the habit tracker',
      image: '/images/onboarding/goals/goals_03@2x.png',
      description: 'Tap an empty habit disc to log each practice. Collect badges for streaks and milestones',
    },
  ],

  learningJourney: [
    {
      title: 'Set goals, build habits, change the way you lead!',
      image: '/images/onboarding/journey/journey_01@2x.png',
      description: 'Follow the simple step by step journey. Click on the first title in your journey to get started',
    },
    {
      title: 'Bite size content, events and self guided activities',
      image: '/images/onboarding/journey/journey_02@2x.png',
      description: 'Access learning content, self guided challenges and information about upcoming events',
    },
    {
      title: 'Stay on track of your progress',
      image: '/images/onboarding/journey/journey_03@2x.png',
      description: `Click 'mark as complete' on each activity page as you go.`,
    },
  ],

  content: [
    {
      title: 'Top tips, videos and articles to help you develop',
      image: '/images/onboarding/content/content_01@2x.png',
      description: 'Quick access to just-in-time resources',
    },
  ],
};

export default onboarding;
