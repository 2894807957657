import React, { ButtonHTMLAttributes, FC, MouseEventHandler } from 'react';
import Loader from '../Loader';
import * as Styled from './styles';
import { Variant, Color, Size, Shape } from '../../utils/types/theme';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  color?: Color;
  size?: Size;
  loading?: boolean;
  testId?: string;
  icon?: any;
  hasIcon?: boolean;
  shape?: Shape;
}

const Button: FC<Props> = ({
  children,
  color = 'primary',
  size = 'medium',
  type = 'submit',
  disabled = false,
  loading = false,
  variant,
  testId,
  icon: Icon,
  onClick,
  shape = 'pill',
  ...restProps
}) => {
  return (
    <Styled.Button
      type={type}
      disabled={disabled}
      onClick={onClick}
      color={color}
      size={size}
      variant={variant}
      data-test-id={testId}
      hasIcon={!!Icon && !loading}
      loading={loading}
      shape={shape}
      {...restProps}>
      {Icon && !loading ? <Icon /> : null}
      {loading ? <Loader size="small" /> : children}
    </Styled.Button>
  );
};

export default Button;
