import styled from 'styled-components';
import { NotificationHeaderContainerStyleProps } from './types';

export const NotificationHeaderContainer = styled.div<NotificationHeaderContainerStyleProps>`
  position: relative;
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: center;
  background-color: rgba(81, 26, 188, 0.05);
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(3)};
  margin-top: ${(p) => p.theme.spacing(2)};
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: ${(p) => p.theme.spacing(1)};
  top: ${(p) => p.theme.spacing(1)};
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
`;

export const InfoWrapper = styled.div<{ isMobile: boolean }>`
  text-align: left;
  padding-right: ${(p) => p.theme.spacing(1)};
  margin-top: ${(p) => (p.isMobile ? p.theme.spacing(1) : '0px')};
  color: ${(p) => p.theme.colours.darkGrey};
  flex: 1;
`;
