import React, { FC, SVGProps } from 'react';
import { IconName } from './iconNames';

import { ReactComponent as DefaultIcon } from '../../icons/default.svg';
import { ReactComponent as WebinarIcon } from '../../icons/webinar.svg';

import theme from '../../utils/theme';
import { Pallette, Size } from '../../utils/types/theme';
import { useDynamicSVGImport } from '../../utils/hooks';
import { isUrl } from '../../utils/helpers';

interface Props extends SVGProps<SVGSVGElement> {
  name: IconName;
  size?: Size;
  color?: Pallette;
  type?: 'module' | 'activity';
}

const Icon: FC<Props> = ({ name, size = 'small', color, type, ...rest }) => {
  const { error, SvgIcon } = useDynamicSVGImport(name);

  if (isUrl(name)) {
    if (type === 'module' || type === 'activity') {
      return (
        <WebinarIcon
          width={theme.icon.sizes[size].icon}
          height={theme.icon.sizes[size].icon}
          fill={color ? theme.colours[color] : theme.typography.font.color}
          {...rest}
        />
      );
    }

    return (
      <DefaultIcon
        width={theme.icon.sizes[size].icon}
        height={theme.icon.sizes[size].icon}
        fill={color ? theme.colours[color] : theme.typography.font.color}
        {...rest}
      />
    );
  }

  if (SvgIcon) {
    return (
      <SvgIcon
        width={theme.icon.sizes[size].icon}
        height={theme.icon.sizes[size].icon}
        fill={color ? theme.colours[color] : theme.typography.font.color}
        {...rest}
      />
    );
  }

  if (error) {
    if (type === 'module' || type === 'activity') {
      return (
        <WebinarIcon
          width={theme.icon.sizes[size].icon}
          height={theme.icon.sizes[size].icon}
          fill={color ? theme.colours[color] : theme.typography.font.color}
          {...rest}
        />
      );
    }

    return (
      <DefaultIcon
        width={theme.icon.sizes[size].icon}
        height={theme.icon.sizes[size].icon}
        fill={color ? theme.colours[color] : theme.typography.font.color}
        {...rest}
      />
    );
  }

  return null;
};

export default Icon;
export type { IconName };
