import React, { FC } from 'react';
import ProgressBarNotched from '../ProgressBarNotched';
import styles from './styles.module.scss';

type Props = {
  completedActivities: number;
  totalActivities: number;
};

const ProgressBarDetail: FC<Props> = ({ completedActivities, totalActivities }: Props) => {
  return (
    <div className={styles.progressMain}>
      <h4 className={styles.progressTitle}>
        {completedActivities}&nbsp;/&nbsp;{totalActivities}
      </h4>
      <div className={styles.progressWrap}>
        <ProgressBarNotched color="secondary" value={completedActivities} max={totalActivities} />
      </div>
    </div>
  );
};

export default ProgressBarDetail;
