import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconButton } from '../IconButton/styles';

export const Container = styled.div<{ completed: boolean; selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
  pointer-events: none;
  width: 100%;

  ${({ theme, completed, selected }) => ({
    backgroundColor: completed ? theme.colours.lightGrey : 'transparent',
    border: selected ? `1px solid ${theme.colours.darkGrey}` : '1px solid transparent',
    boxShadow: completed ? 'none' : theme.shadows[0],
    borderRadius: theme.borders.radius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  })};
`;

export const Meta = styled.div`
  flex: 1;
  padding-right: ${({ theme }) => theme.spacing(1)};
  text-align: start;
`;

export const Description = styled.span`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.small,
  })};
`;

export const TypeLabel = styled.span`
  ${({ theme }) => ({
    color: theme.colours.primary,
    fontSize: theme.typography.font.size.small,
    textTransform: 'uppercase',
  })};
`;

export const Title = styled.h3`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.regular,
    fontWeight: theme.typography.font.weight.bold,
  })};
`;

export const ActivityIcon = styled(IconButton).attrs({
  as: 'div',
})``;

export const ActivityLink = styled(Link)`
  display: block;
`;
