import { css } from 'styled-components';
import theme from '../../utils/theme';
import * as Button from '../../utils/types/theme';

const smallButtonSize = '27px';
const mediumButtonSize = '45px';

export const handleHasIcon = (hasIcon?: boolean) => {
  if (hasIcon) {
    return css`
      padding-left: ${theme.spacing(7)};

      svg {
        position: absolute;
        left: ${theme.spacing(2)};
      }
    `;
  }
};

export const handleLoading = (loading?: boolean) => {
  if (loading) {
    return css`
      min-width: 200px;
    `;
  }
};

export const handleVariant = (variant?: Button.Variant, color?: Button.Color) => {
  if (variant === 'outline') {
    return css`
      background-color: transparent;

      border: 2px solid ${theme.colours[color || 'primary']};
      color: ${theme.colours[color || 'primary']};
    `;
  }
};

export const handleSize = (size?: Button.Size) => {
  if (size === 'small') {
    return css`
      height: ${smallButtonSize};
      line-height: ${smallButtonSize};
      padding: 0 ${theme.spacing(2)};
      font-size: ${theme.typography.font.size.small};
    `;
  }

  return css`
    height: ${mediumButtonSize};
    line-height: ${mediumButtonSize};
    padding: 0 ${theme.spacing(4)};
    font-size: ${theme.typography.font.size.regular};
  `;
};

export const handleDisabled = (color?: Button.Color, disabled?: boolean) => {
  if (disabled)
    return css`
      background-color: ${theme.colours.mediumGrey};
      cursor: not-allowed;
    `;

  return css`
    background-color: ${theme.colours[color || 'primary']};
  `;
};

export const handleShape = (shape?: Button.Shape, size?: Button.Size) => {
  if (shape === 'circle') {
    return css`
      padding: 0;
      width: ${size === 'small' ? smallButtonSize : mediumButtonSize};
      border-radius: 50%;
    `;
  }
};

export const handleColor = (color?: Button.Color) => {
  if (color === 'primary') {
    return css`
      color: ${theme.colours.white};
    `;
  }
  if (color === 'secondary') {
    return css`
      color: ${theme.colours.black};
      font-weight: bold;
    `;
  }

  return css`
    color: ${theme.colours.white};
  `;
};
