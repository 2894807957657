// key: FEATURE_PLACE_MODALNAME

export type ModalKeyType =
  | 'EXPERIMENTS_EXPERIMENTCONTENT_REFLECTION_CONGRATULATIONSMODAL'
  | 'EXPERIMENTS_EXPERIMENTCONTENT_INSIGHT_CONGRATULATIONSMODAL'
  | 'HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL';

export enum ModalKey {
  EXPERIMENTS_EXPERIMENTCONTENT_REFLECTION_CONGRATULATIONSMODAL = 'EXPERIMENTS_EXPERIMENTCONTENT_REFLECTION_CONGRATULATIONSMODAL',
  EXPERIMENTS_EXPERIMENTCONTENT_INSIGHT_CONGRATULATIONSMODAL = 'EXPERIMENTS_EXPERIMENTCONTENT_INSIGHT_CONGRATULATIONSMODAL',
  HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL = 'HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL',
}
