import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../../../redux';
import { actions } from '../../../../redux/auth.slice';
import { actions as cohortActions } from '../../../../redux/cohorts.slice';
import FilterModal from './FilterModal';

const { setTokens } = actions;

const mapState = (state: RootState) => ({
  tokens: {
    accessToken: get(state, 'auth.accessToken', ''),
    refreshToken: get(state, 'auth.refreshToken', ''),
  },
});
const { setSelectedFacCohort } = cohortActions;

export default connect(mapState, { setTokens, setSelectedFacCohort })(FilterModal);
