import React, { FC, PropsWithChildren } from 'react';
import { useAppSelector, useViewport } from '../../../utils/hooks';
import { ModalKey } from '../../../utils/modals';
import theme from '../../../utils/theme';
import Icon from '../../Icon';
import IconButton from '../../IconButton';
import { HomepageHeaderContainer, InfoWrapper, Type, Title, IconButtonWrapper, Divider } from './styles';
import { HomepageHeaderProps } from './types';

import { RootState } from '../../../redux';
import { UserState } from '../../../redux/user.slice';
import { JourneyState } from '../../../redux/journey.slice';

const HomepageHeader: FC<HomepageHeaderProps> = ({
  setModalState,
  isFacilitatorHomePage = false,
}: PropsWithChildren<HomepageHeaderProps>): JSX.Element => {
  const journeyTitle: string | undefined = useAppSelector((state) => state.journey.title);
  const { width: deviceWidth } = useViewport();
  const isMobile: boolean = deviceWidth < theme.devices.tablet;
  const { firstName, lastName } = useAppSelector((state) => state.user);
  const userName=`${firstName} ${lastName}`
  const { roles } = useAppSelector((state: RootState): UserState => state.user);
  const { cohortsOfFacilitator } = useAppSelector((state) => state.cohorts);
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const isFacilitatorAssignedToCohort: boolean = cohortsOfFacilitator && cohortsOfFacilitator.length>0

  return (
    <>
      <HomepageHeaderContainer>
        <InfoWrapper>
          {isFacilitatorHomePage ? (
            <div>
              <Type>{userName.toUpperCase()}</Type>
              <Title>{'Facilitator homepage'}</Title>
            </div>
          )
            : (
              <div>
                <Type>{'learning journey'.toUpperCase()}</Type>
                <Title>{journeyTitle || 'Journey'}</Title>
              </div>
            )
          }
        </InfoWrapper>
        {isFacilitatorHomePage ? (
          <></>
        )
        :(<IconButtonWrapper>
            <IconButton
              onClick={(): void => setModalState({ key: ModalKey.HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL, isOpen: true })}
              elevation={1}
              style={{
                borderRadius: theme.borders.radius,
                width: isMobile ? '40px' : '160px',
                height: '40px',
                marginLeft: theme.spacing(2),
              }}>
              {/* {isMobile ? (
                <Icon name="journey" size="small" fill={theme.colours.primary} />
              ) : (              
                <>
                  Switch journeyss&nbsp;&nbsp;
                  <Icon name="journey" size="small" fill={theme.colours.primary} />
                </>
              )} */}
              <>
                  Switch journey&nbsp;&nbsp;
                  <Icon name="journey" size="small" fill={theme.colours.primary} />
              </>
            </IconButton>
          </IconButtonWrapper>)
        }
      </HomepageHeaderContainer>
      <Divider />
    </>
  );
};
export default HomepageHeader;
