import { connect } from 'react-redux';
import ActivityExperimentCard from './ActivityExperimentCard';
import { RootState } from '../../redux';
import { actions as journeyActions } from '../../redux/journey.slice';
import { actions as cohortActions } from '../../redux/cohorts.slice';

const mapState = (state: RootState) => {
  try {
    return {};
  } catch (err) {}
  return {};
};

const { getCohortUserRecords } = cohortActions;
const { updateActivityProgress } = journeyActions;

export default connect(mapState, { updateActivityProgress, getCohortUserRecords })(ActivityExperimentCard);
