import React, { ReactElement, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { FormattedGoal, FormattedTask } from '../../utils/adapters/goals';

import Button from '../../components/Button';
import EditableTaskRow from '../../components/EditableTaskRow';

import styles from './styles.module.scss';
import PageHeader from '../../components/PageHeader';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import TextWithIcon from '../../components/TextWithIcon';

type Props = FormattedGoal & {
  updateGoal: any;
};

type EditNameFormPayload = {
  tasks: Array<FormattedTask>;
};

const GoalOneOffTasks = ({ tasks, title, id, updateGoal }: Props): ReactElement => {
  const history = useHistory();
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const { width: deviceWidth } = useViewport();

  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      tasks,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'tasks',
  });

  useEffect(() => {
    append({ title: null, completed: false });
  }, []);

  const onSubmit = ({ tasks }: EditNameFormPayload) => {
    (async () => {
      setSending(true);
      try {
        await updateGoal({ id, tasks });
        history.goBack();
      } catch (err) {
        setSending(false);
      }
    })();
  };

  const onBlur = (e: any) => {
    if (e.target.value) {
      append({ title: null });
    }
  };

  const onChange = () => {
    if (!hasEdited) setHasEdited(true);
  };

  return (
    <>
      {isDesktop(deviceWidth) && <PageHeader title="One off tasks" leftButton={{ iconName: 'chevron-left' }} />}

      <div className={styles.container}>
        <TextWithIcon text="Tasks" icon="one-off-tasks" />

        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          <p>You can add one off tasks that may help you to complete or stay on track with your goal.</p>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {fields.map((task, index) => (
              <EditableTaskRow
                key={task.id}
                task={task}
                name={`tasks[${index}]`}
                register={register}
                onRequestRemove={() => {
                  if (index < fields.length - 1) {
                    onChange();
                    remove(index);
                  }
                }}
                onChange={onChange}
                onBlur={index === fields.length - 1 ? onBlur : null}
              />
            ))}
            <div className={styles.buttonContainer}>
              {/* stops the default enter action from submitting form as first submit button in a form is default action (disabled) */}
              <button type="submit" disabled style={{ display: 'none' }} aria-hidden="true" />
              <Button loading={sending} disabled={!hasEdited}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GoalOneOffTasks;
