import React, { ChangeEvent } from 'react';
import { UserFilterOption } from '../../InsightWallFilter.styles';

export const renderPersonalFilterOption: (
  personalInsightsChecked: boolean,
  setPersonalInsightsChecked: Function,
) => JSX.Element = (personalInsightsChecked: boolean, setPersonalInsightsChecked: Function): JSX.Element => {
  return (
    <UserFilterOption>
      <span>Show only my insights</span>
      <input
        type="checkbox"
        style={{ width: '24px', height: '24px' }}
        checked={personalInsightsChecked}
        onChange={(e: ChangeEvent<HTMLInputElement>): void => {
          setPersonalInsightsChecked(!personalInsightsChecked);
        }}
      />
    </UserFilterOption>
  );
};
