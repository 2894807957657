import React, { FC, useState, useEffect } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Button from '../../components/Button';
import CmsContent from '../../components/CmsContent';
import CongratulationsModal from '../../components/CongratulationsModal';
import { ActivityDisplayData, ModuleDisplayData } from './adapters';
import { useAppSelector, useViewport } from '../../utils/hooks';
import { hasSeenModal, setSeenModal } from '../../utils/checkModalLocalStorage';
import logger from '../../utils/logger';
import styles from './styles.module.scss';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader, { HeaderButton } from '../../components/PageHeader/PageHeader';
import { IconName } from '../../components/Icon';
import theme from '../../utils/theme';
import { colourExistsInTheme } from '../../utils/theme/helpers';
import Modal from '../../components/Modal';
import OnboardingCarousel from '../../containers/OnboardingCarousel/OnboardingCarousel';
import onboarding from '../../containers/OnboardingCarousel/OnboardingCarousel.content';
import { ModalContext } from '../../utils/contexts';
import ExperimentContent from '../../components/ExperimentContent';
import { Experiment, ExperimentType, Media, MediaCollection, UserRecord, Cohort } from '../../utils/types';
import { ContentState } from '../../redux/content.slice';
import dayjs from 'dayjs';
import { GetCohortsResponse } from '../../utils/api/cohorts';
import { CohortsState } from '../../redux/cohorts.slice';
import { useHistory } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/app.slice';

type Props = ActivityDisplayData & {
  updateActivityProgress: any;
  isModuleComplete: boolean;
  isJourneyComplete: boolean;
  journeyTitle: string;
  module: ModuleDisplayData;
  experimentId: string;
  experimentType: ExperimentType;
  experiments: Experiment[];
  cohortMeta: GetCohortsResponse;
  getActivityRecord: any;
  userRecord: any;
  getJourneyProgressAndUpdateActivityProgress: any;
};

type CongratulationsPopupType = {
  variant: 'module' | 'journey';
  show: boolean;
};

const Activity: FC<Props> = ({
  content,
  title,
  type,
  id,
  imageUrl,
  completed,
  updateActivityProgress,
  isModuleComplete,
  isJourneyComplete,
  module,
  getActivityRecord,
  experimentId,
  experiments,
  experimentType,
  getJourneyProgressAndUpdateActivityProgress,
}: Props) => {
  const { width: deviceWidth } = useViewport();
  const history = useHistory();
  const appInsights = useAppInsightsContext();
  const [popup, setPopup] = useState<CongratulationsPopupType>({
    show: false,
    variant: 'module',
  });

  const { cohortMeta, cohortUserRecords, cohorts, selectedCohort, cohortsOfFacilitator } = useAppSelector((state): CohortsState => state.cohorts);
  const [blastOff, setBlastOff] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [articleCollection, setArticleCollection] = useState<MediaCollection[]>([]);
  const { id: userId, roles } = useAppSelector((state) => state.user);
  const { allContents } = useAppSelector((state): ContentState => state.content);
  const [experiment, setExperiment] = useState<Experiment>();
  const [modalOpen, setModalOpen] = useState<boolean>(!hasSeenModal('journalDesktop') || false);
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const openModal: () => void = () => setModalOpen(true);
  const closeModal: () => void = () => setModalOpen(false);

  const dispatch: Dispatch<any> = useDispatch();
  const { tracking } = actions;
  const handleToggleStatus = async () => {
    setIsLoading(true);
    setIsLoading(false);
    sessionStorage.setItem('activityMarkedAsComplete', 'true');
    let journeyIds: string[] = [];
    let journeyIdsFac: string[] = [];
    let journeyIdsLer: string[] = [];
    if(isFacilitator) {      
      if(cohortsOfFacilitator !== undefined && cohortsOfFacilitator.length){
        journeyIdsFac = cohortsOfFacilitator.map((cohort: Cohort): string => cohort.learningJourneyId);
      }
    }
    if(cohorts !== undefined && cohorts.length){
      journeyIdsLer = cohorts.map((cohort: Cohort): string => cohort.learningJourneyId);
    }
    journeyIds = journeyIdsFac.concat(journeyIdsLer);
    // const journeyIds: string[] = cohorts.map((cohort: any): string => cohort.learningJourneyId);
    getJourneyProgressAndUpdateActivityProgress({ activityId: id, completed: !completed, journeyIds: journeyIds });

    if (!completed) {
      dispatch(
        tracking({
          appInsights,
          name: 'Activity',
          payload: { type: 'PAGE_ACTIVITY_COMPLETED', activityId: id },
        }),
      );
    }
  };

  const handleExperimentQuit: () => void = (): void => {
    setTimeout(() => {
      (async () => {
        setIsLoading(true);
        await updateActivityProgress({
          activityId: id,
          completed: false,
          experimentId: null,
          isExperimentCompleted: false,
        });
        setIsLoading(false);
      })();
    }, 1000);
  };

  const onModalClose = () => {
    setPopup({ ...popup, show: false });
    sessionStorage.removeItem('activityMarkedAsComplete');
  };

  if (module && !colourExistsInTheme(module.backgroundColor, 'module')) {
    module.backgroundColor = theme.colours.moduleFushia;
  }
 
  const isExperiment: boolean = type == 'experiment';
  let experimentDeadline = '';

  if(cohorts && cohorts.length>0){
    cohorts.forEach((cohort: any) => {
      if (cohort.cohortId == selectedCohort.cohortId) {
        if(cohort.experiments && cohort.experiments.length>0){
        cohort.experiments.forEach((experiment: any) => {
          if(experiment.experimentId == id) {
            experimentDeadline = (experiment.deadlineDate == '') ? '' : `| Complete By: ${dayjs(experiment.deadlineDate).format('DD/MM/YYYY')}`;
          }
        });
      }   
    }   
    });
  }
  const subtitle: string = isExperiment
    ? `Experiment ${experimentDeadline}`
    : type;

  const getTitle: () => string = (): string => {
    return experiment ? experiment.title : title;
  };

  const getIconName: () => IconName | undefined = () => {
    if (experiment) return completed ? 'check' : 'in-progress';
    return completed ? 'check' : (imageUrl as IconName);
  };

  const getIconColor: () => string = () => {
    if (completed || experiment) return theme.colours.secondary;
    return module?.backgroundColor;
  };

  const rightPageHeaderButton: HeaderButton = {
    iconName: getIconName(),
    background: getIconColor(),
    ariaLabel: `activity ${getTitle()}(${completed ? 'completed' : 'incomplete'})`,
  };

  useEffect(() => {
    if (!experimentId) return;
    const currentExp: Experiment | undefined = experiments.find(
      (experiment: Experiment): boolean => experiment.id === experimentId,
    );
    setExperiment(currentExp);
    return () => {};
  }, [experiments, experimentId]);

  useEffect(() => {
    logger('info', 'LEARNING_JOURNEY_ACTIVITY_NAVIGATE', {
      activityId: id,
      moduleId: module?.id,
    });

    if (!localStorage.getItem('page_activity_viewed')) {
      dispatch(
        tracking({
          appInsights,
          name: 'Activity',
          payload: { type: 'PAGE_ACTIVITY_VIEW', activityId: id },
        }),
      );
      localStorage.setItem('page_activity_viewed', 'true');
    }

    if (modalOpen) {
      setSeenModal('journalDesktop');
    }

    const timeOnMount = dayjs();

    let wordCount = 0;
    const TextBlocks = content?.body?.filter((block) => block.blockType === 'text');
    TextBlocks.forEach((textBlock) => {
      const wordsInBlock = textBlock.text.split(' ').length;
      wordCount = wordCount + wordsInBlock;
    });

    const readTimeInSecs = Math.round((wordCount / 200) * 60);

    const trackPageExit = () => {
      const timeOnCleanUp = dayjs();
      const stayDurationSecs = timeOnCleanUp.diff(timeOnMount, 'seconds');

      dispatch(
        tracking({
          appInsights,
          name: 'Activity',
          payload: { type: 'PAGE_ACTIVITY_EXIT', activityId: id, stayDurationSecs, readTimeInSecs },
        }),
      );

      localStorage.removeItem('page_activity_viewed');
    };

    window.addEventListener('beforeunload', trackPageExit);

    return () => {
      if (!history.location.pathname.startsWith(`/app/activity/${id}`)) {
        trackPageExit();
      }
      window.removeEventListener('beforeunload', trackPageExit);
    };
  }, []);

  useEffect(() => {
    const getArticlesData = async () => {
      const filteredItems: Media[] = allContents.filter((item: Media) => experiment?.body?.articles.includes(item._id));

      if (allContents.length > 0) {
        const mediaCollection: MediaCollection[] = [
          {
            _id: 'experiment',
            title: 'experiment',
            description: 'experiment',
            imageUrl: '',
            items: filteredItems,
          },
        ];
        setArticleCollection(mediaCollection);
      }
    };

    getArticlesData();
  }, [experiment, allContents]);

  useEffect(() => {
    if (!id || !module.id) return;

    if (experiment) {
      (async () => {
        await getActivityRecord({ activityId: id, moduleId: module.id });
      })();
    }

    if (!sessionStorage.getItem('activityMarkedAsComplete')) {
      return;
    }

    window.scrollTo(0, 0);

    if (isModuleComplete && isJourneyComplete) {
      setPopup({ show: true, variant: 'journey' });
      setBlastOff(true);
      return;
    }

    if (isModuleComplete && !isJourneyComplete) {
      setPopup({ show: true, variant: 'module' });
      setBlastOff(true);
      return;
    }

    if (!isDesktop(deviceWidth) && completed) {
      history.push(`/app/module/${module.id}`);
    }
  }, [experiment]);

  const ActivityPageHeader = React.memo(
    (): JSX.Element => (
      <PageHeader title={getTitle()} subTitle={subtitle} rightButton={rightPageHeaderButton} type="activity" />
    ),
  );

  return (
    <>
      <ActivityPageHeader />

      <div className={styles.activityContainer}>
        <ModalContext.Provider value={{ openModal, closeModal }}>
          {!experiment ? (
            <CmsContent content={content} isExperiment={isExperiment} activityId={id} />
          ) : (
            <ExperimentContent
              isCompleted={completed}
              activityId={id}
              activityTitle={title}
              activityContent={content}
              activityStartDate={
                cohortUserRecords.find(
                  (record: UserRecord): boolean => record.activityId === id && record.userId === userId,
                )?.startDate
              }
              experiment={experiment}
              experimentType={experimentType}
              cohortExperimentInfo={cohortMeta?.activities && cohortMeta?.activities[id]}
              articles={articleCollection}
              loading={isLoading}
              onExperimentQuit={handleExperimentQuit}
              iconName={imageUrl as IconName}
            />
          )}
        </ModalContext.Provider>
      </div>

      {!isExperiment && (
        <div className={styles.buttonContainer}>
          <Button
            onClick={handleToggleStatus}
            loading={isLoading}
            variant="filled"
            color={completed ? 'secondary' : 'primary'}>
            {completed ? 'Completed' : 'Mark as completed'}
          </Button>
        </div>
      )}

      <Modal isOpen={popup.show} width="small" onClose={onModalClose} showConfetti={blastOff}>
        <CongratulationsModal variant={popup.variant} />
      </Modal>

      {isDesktop(deviceWidth) && (
        <Modal isOpen={modalOpen} onClose={closeModal}>
          <OnboardingCarousel items={onboarding.learnerJournal} closeOnboardingModal={closeModal} />
        </Modal>
      )}
    </>
  );
};

export default Activity;
