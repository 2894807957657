// Theme colours
export const primary = '#481DB4';
export const secondary = '#22D1B0';
export const orange = '#FF4500';

// Common colours
export const white = '#FFFFFF';
export const black = '#000000';
export const red = '#FF0000';

// Colours used for modules
export const moduleCyan = '#1DB7D4';
export const moduleBlue = '#0071CE';
export const moduleFushia = '#E80C79';
export const moduleAmber = '#FF9900';
export const moduleOrange = '#FF4400';

// Grey Colours
export const lightGrey = '#EFEFEF';
export const mediumGrey = '#CECECE';
export const darkGrey = '#485155';

// Objects of pallettes used for typescript interfaces/types;
export const main = { primary, secondary, orange };
export const grey = { lightGrey, mediumGrey, darkGrey };
export const common = { white, black, red };
export const moduleColours = { moduleCyan, moduleBlue, moduleFushia, moduleAmber, moduleOrange };

// pastel colours
export const lilac = '#F6F4FB';
export const pastelColours = { lilac };

export const all = { ...main, ...common, ...grey, ...moduleColours, ...pastelColours };
