import React, { FC, Fragment } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

type Crumb = {
  path?: string;
  text: string;
};

type Props = {
  crumbs: Array<Crumb>;
  hideOnMobile?: boolean;
};

const BreadCrumbs: FC<Props> = ({ crumbs, hideOnMobile }: Props) => {
  return (
    <div
      className={classnames(styles.breadcrumbs, {
        [styles.hideOnMobile]: hideOnMobile,
      })}>
      {crumbs.map(({ path, text }, index) => {
        if (path) {
          return (
            <Fragment key={index}>
              {index !== 0 && <div className={styles.divider}>&gt;</div>}
              <Link to={path} className={styles.crumb}>
                {text}
              </Link>
            </Fragment>
          );
        } else {
          return (
            <Fragment key={index}>
              {index !== 0 && <div className={styles.divider}>&gt;</div>}
              <span className={styles.crumb}>{text}</span>
            </Fragment>
          );
        }
      })}
      <div style={{ flex: 1 }}></div>
    </div>
  );
};

export default BreadCrumbs;
