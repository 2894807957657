import React, { PropsWithChildren } from 'react';
import { ExperimentHintsProps } from './types';
import { HintsContainer, HeaderWrapper, Title, ContentWrapper } from './styles';
import Icon from '../Icon';
import MarkDown from 'react-markdown';

const ExperimentHints: React.FC<ExperimentHintsProps> = ({
  title,
  content,
}: PropsWithChildren<ExperimentHintsProps>): JSX.Element => {
  return (
    <HintsContainer>
      {/* <HeaderWrapper>
        <Icon name="teaching" size="medium" color="primary" />
        {title && <Title>{title}</Title>}
      </HeaderWrapper> */}
      <ContentWrapper>
        <MarkDown className={'markdown'} source={content} />
      </ContentWrapper>
    </HintsContainer>
  );
};

export default ExperimentHints;
