import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { RootState } from '.';
import * as learnerJournal from '../utils/api/learnerJournal';
import { JournalEntry, Tokens } from '../utils/types';
import { JournalEntryPayload } from './experiment.slice';

interface InitialState {
  journalEntries: JournalEntry[];
}

export interface NewJournalEntry {
  sourceId: string;
  parentId: string;
  response: string;
  question?: string;
  meta?: { [key: string]: string | number };
}

export interface UpdatedJournalEntry extends NewJournalEntry {
  journalEntryId?: string;
}

const initialState: InitialState = { journalEntries: [] };

export const getUserJournal = createAsyncThunk('userJournal/getUserJournal', async (userID: string, thunkAPI) => {
  const {
    auth: { accessToken, refreshToken },
  } = thunkAPI.getState() as RootState;

  const tokens: Tokens = { accessToken, refreshToken };
  const userJournal = await learnerJournal.getUserJournal(tokens, userID);

  return userJournal;
});

export const createUserJournalEntry = createAsyncThunk(
  'userJournal/createUserJournalEntry',
  async (payload: NewJournalEntry, thunkAPI) => {
    const {
      auth: { accessToken, refreshToken },
      user,
    } = thunkAPI.getState() as RootState;

    const tokens: Tokens = { accessToken, refreshToken };
    const data = await learnerJournal.createUserJournalEntry(tokens, user.id, payload);

    const journalEntry = data.journalEntries
      ? data.journalEntries.find((entry: any) => entry.sourceId === payload.sourceId)
      : null;

    return journalEntry;
  },
);

export const updateUserJournalEntry = createAsyncThunk(
  'userJournal/updateUserJournalEntry',
  async (payload: NewJournalEntry, thunkAPI) => {
    const {
      auth: { accessToken, refreshToken },
      user,
    } = thunkAPI.getState() as RootState;

    const tokens: Tokens = { accessToken, refreshToken };
    const data = await learnerJournal.updateUserJournalEntry(tokens, user.id, payload);

    return data;
  },
);

// This is triggered when insight is edited from the insight wall
export const editUserJournalEntry = createAsyncThunk(
  'userJournal/editUserJournalEntry',
  async (payload: JournalEntryPayload, thunkAPI) => {
    const {
      auth: { accessToken, refreshToken },
    } = thunkAPI.getState() as RootState;

    const tokens: Tokens = { accessToken, refreshToken };
    const data: JournalEntry = await learnerJournal.updateUserJournalEntryByUserId(tokens, payload);
    return data;
  },
);

const userJournal = createSlice({
  name: 'userJournal',
  initialState,
  reducers: {
    removeUserJournalEntry(state, { payload }): void {
      const foundEntry = state.journalEntries.find((entry: JournalEntry): boolean => entry._id === payload);
      if (foundEntry) {
        foundEntry.response = '';
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserJournal.fulfilled, (state, { payload }) => {
      state.journalEntries = payload.journalEntries;
    });

    builder.addCase(updateUserJournalEntry.fulfilled, (state, { payload }) => {
      const journalEntries = current(state.journalEntries);
      const newEntry = payload;
      state.journalEntries = journalEntries.map((entry) => (entry._id === newEntry._id ? newEntry : entry));
    });

    builder.addCase(createUserJournalEntry.fulfilled, (state, { payload }) => {
      if (payload) {
        state.journalEntries = [...state.journalEntries, payload];
      }
    });
    builder.addCase(editUserJournalEntry.fulfilled, (state, { payload }) => {
      if (payload) {
        state.journalEntries = [...state.journalEntries, payload];
      }
    });
  },
});

export default userJournal.reducer;

export const actions = {
  ...userJournal.actions,
};
