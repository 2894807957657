import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions as contentActions } from '../../redux/content.slice';

import ContentTab from './ContentTab';

const { getContent } = contentActions;

const mapState = (state: RootState) => ({
  journeyId: get(state, 'journey.id', null),
  collections: get(state, 'content.collections', []),
});

export default connect(mapState, { getContent })(ContentTab);
