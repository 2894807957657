import React from 'react';
import { TileBackground, PersonalInfoContainer, ProgressInfo, Name, InfoWrapper } from './styles';
import Icon from '../Icon';
import IconButton from '../IconButton';
import theme from '../../utils/theme';
import { ParticipantInfoTileProps } from './types';
import dayjs from 'dayjs';

const ParticipantInfoTile: React.FC<ParticipantInfoTileProps> = ({
  name,
  startDate,
  endDate,
}: ParticipantInfoTileProps): JSX.Element => {
  const completed = !!endDate;

  const termFeeback: (endDate: Date) => string = (endDate: Date) => {
    switch (dayjs(new Date(Date.now())).diff(dayjs(endDate), 'day')) {
      case 0:
        return 'Today';
      case 1:
        return 'Yesterday';
      default:
        return dayjs(endDate).format('DD/MM/YYYY');
    }
  };

  return (
    <TileBackground completed={completed}>
      <PersonalInfoContainer>
        <Name>{name}</Name>
        <InfoWrapper>
          <strong style={{ marginRight: theme.spacing(1) }}>Began experiment:</strong>
          {dayjs(startDate).format('MM/DD/YYYY')}
        </InfoWrapper>
      </PersonalInfoContainer>

      {completed && (
        <ProgressInfo>
          <InfoWrapper>
            <strong style={{ marginRight: theme.spacing(2) }}>Completed:</strong>
            <br />
            <span style={{ color: theme.colours.primary }}>{termFeeback(endDate!)}</span>
          </InfoWrapper>
          <IconButton size="small" color={theme.colours.secondary}>
            <Icon name="check" size="small" color="white" />
          </IconButton>
        </ProgressInfo>
      )}

      {!completed && (
        <ProgressInfo>
          <InfoWrapper>
            <strong style={{ marginRight: theme.spacing(2) }}>In progress:</strong>
            <br />
          </InfoWrapper>
          <IconButton size="small" color={theme.colours.secondary}>
            <Icon name="in-progress" size="small" color="black" />
          </IconButton>
        </ProgressInfo>
      )}
    </TileBackground>
  );
};

export default ParticipantInfoTile;
