import { connect } from 'react-redux';
import ExperimentContent from './ExperimentContent';
import { RootState } from '../../redux';
import get from 'lodash.get';

const mapState = (state: RootState) => {
  return {
    tokens: {
      accessToken: get(state, 'auth.accessToken', ''),
      refreshToken: get(state, 'auth.refreshToken', ''),
    },
  };
};

export default connect(mapState, {})(ExperimentContent);
