import styled from 'styled-components';
import { ListItemContainerStyleProps } from './types';

export const ListItemContainer = styled.li<ListItemContainerStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const InsightTitle = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.small};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 17px;
`;

export const Publisher = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 17px;
`;

export const PublishedAt = Publisher;
