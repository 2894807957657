import React, { FC } from 'react';
import * as Styled from './styles';
import { useHistory } from 'react-router-dom';
import { getSafeAreaTop } from '../../utils/safeArea';
import { isDesktop } from '../../utils/breakpoints';
import { useViewport } from '../../utils/hooks';

interface Props {
  isPreview: boolean;
  clearPreview: any;
}

const JourneyPreviewReset: FC<Props> = ({ isPreview, clearPreview }) => {
  const history = useHistory();
  const { width: deviceWidth } = useViewport();
  if (!isPreview) return null;

  const returnBackToJourney = () => {
    const { pathname } = window.location;
    if (pathname.indexOf('/module/') > -1 || pathname.indexOf('/activity/')) {
      history.push('/app/journey');
    }
    if (pathname.indexOf('/content/') > -1) {
      history.push('/app/content');
    }
    clearPreview();
  };

  let safeAreaTop = +getSafeAreaTop() + 21 + 100;
  if (isDesktop(deviceWidth)) {
    safeAreaTop = 0;
  }

  return (
    <Styled.Banner onClick={returnBackToJourney} style={{ marginTop: `${safeAreaTop}px` }}>
      <Styled.JourneyIcon name="journey" size="medium" color="white" />
      <span>
        You are previewing another organisation&apos;s journey. &nbsp; Any activities marked as complete will not be
        saved.
        <strong> Click here to exit preview mode.</strong>
      </span>
    </Styled.Banner>
  );
};

export default JourneyPreviewReset;
