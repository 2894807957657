import { connect } from 'react-redux';
import get from 'lodash.get';
import Module from './Module';
import { RootState } from '../../redux';
import { adaptModuleById } from './adapters';

const mapState = (state: RootState, props: any) => {
  try {
    const { id, activityId } = props.match.params;
    return {
      activityId: activityId,
      journeyTitle: get(state, 'journey.title', null),
      ...adaptModuleById(get(state, 'journey.modules', []), id),
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, null)(Module);
