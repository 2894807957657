import React, { FC, useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import get from 'lodash.get';

import { SetUserEmailPayload } from '../../redux/auth.slice';

import Button from '../../components/Button';
import Input from '../../components/Input';

import styles from './styles.module.scss';
import { sendCodeToEmail } from '../../utils/api/auth';

type LoginFormPayload = {
  email: string;
};

type Props = {
  email: string;
  setEmail: ActionCreatorWithPayload<SetUserEmailPayload, string>;
  location: {
    state: {
      isInvalid?: boolean;
    };
  };
};

const LoginEmail: FC<Props> = ({ email, setEmail, location }: Props) => {
  const history = useHistory();
  const [sending, setSending] = useState<boolean>(false);

  const isCodeInvalid = get(location, 'state.invalidCode', false);

  const { handleSubmit, register, errors, setError } = useForm({
    defaultValues: {
      email,
    },
  });

  const onSubmit = async ({ email }: LoginFormPayload) => {
    setSending(true);

    try {
      const sendEmailCode = await sendCodeToEmail(email);

      if (sendEmailCode) {
        setEmail({ email });
        history.push('/auth/code');
      }
    } catch (err) {
      setSending(false);
    }
  };

  return (
    <div className={styles.container}>
      <img className={styles.img} src="/images/password_code.svg" />
      {isCodeInvalid ? (
        <>
          <h3 className={styles.h3}>Oops!</h3>
          <p className={styles.p}>
            This code is incorrect or has expired. Enter your email again to get sent a new one.
          </p>
        </>
      ) : (
        <>
          <h3 className={styles.h3}>No more passwords!</h3>
          <p className={styles.p}>Get a code sent to your email that&apos;ll sign you in instantly.</p>
        </>
      )}
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="email"
          type="text"
          error={get(errors, 'email', null)}
          register={register}
          placeholder="Please enter your email address"
        />
        <Button testId="send" loading={sending}>
          Send Code
        </Button>
      </form>
    </div>
  );
};

export default LoginEmail;
