import { connect } from 'react-redux';
import get from 'lodash.get';
import Activity from './Activity';
import { RootState } from '../../redux';
import { actions as journeyActions } from '../../redux/journey.slice';
import { actions as experimentActions } from '../../redux/experiment.slice';
import { adaptActivity, adaptModule } from './adapters';

import { isActivityParentModuleComplete, isJourneyCompleted } from '../../utils/adapters/journey';
const mapState = (state: RootState, props: any) => {
  try {
    const { id } = props.match.params;
    return {
      auth: get(state, 'auth'),
      journeyId: get(state, 'journey.id'),
      journeyTitle: get(state, 'journey.title'),
      module: adaptModule(get(state, 'journey.modules', []), id),
      ...adaptActivity(get(state, 'journey.modules', []), id),
      isModuleComplete: isActivityParentModuleComplete(state, id),
      isJourneyComplete: isJourneyCompleted(state),
      userRecord: get(state, 'activity.activityRecord'),
      collections: get(state, 'content.collections', []),
      experiments: get(state, 'experiment.experiments'),
    };
  } catch (err) {}
  return {};
};

const { getActivityRecord } = experimentActions;
const { updateActivityProgress, getJourneyProgress, getJourneyProgressAndUpdateActivityProgress } = journeyActions;

export default connect(mapState, {
  updateActivityProgress,
  getActivityRecord,
  getJourneyProgress,
  getJourneyProgressAndUpdateActivityProgress,
})(Activity);
