import { MediaCollection, Media } from '../../utils/types';

export type GetContentResponse = { journeyId: string; collections: Array<MediaCollection> };

export const getContent = async (accessToken: string, journeyId: string): Promise<Array<MediaCollection>> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cms/media-item/by-journey/${journeyId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  // const res = await fetch(`http://localhost:61104/cms/media-item/by-journey/${journeyId}`, {
  //   headers: {
  //     Authorization: `uia {"isAnonymous":false,"id":"3a9739bf-7378-463e-9386-0522b2719bb6","authenticationType":"AuthenticationTypes.Federation","claims":{"auth0-id":"auth0|5c8fc43002d7ad0170ba766f","iat":"1575448895","exp":"1575535295","client-id":"95BtKEY6eWhetvvSDAYD5xplQtozhBup","first-name":"David","last-name":"Clare","display-name":"DC Universe","email":"david.clare@cirrus-connect.com","role":"system:administrator bottle-washer"}}`,
  //   },
  // });
  if (res.status === 404) {
    throw new Error('No Journey');
  }
  if (res.status === 204) {
    throw new Error('No Content');
  }
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Array<MediaCollection> = await res.json();
  return json;
};

export const getAllContents = async (accessToken: string): Promise<Array<Media>> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cms/article/list-all`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 404) {
    throw new Error('No Journey');
  }
  if (res.status === 204) {
    throw new Error('No Content');
  }
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Array<Media> = await res.json();
  return json;
};

export const getContentById = async (accessToken: string, mediaId: string): Promise<Media> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cms/media-item/${mediaId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Media = await res.json();
  return json;
};
