import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import AnimatedPage from '../../components/AnimatedPage';

import GoalNavigation from '../../containers/GoalNavigation';
import GoalList from '../../containers/GoalList';

import { FormattedGoals } from '../../utils/adapters/goals';

import styles from './styles.module.scss';
import PageHeader from '../../components/PageHeader';
import Modal from '../../components/Modal';
import OnboardingCarousel from '../../containers/OnboardingCarousel/OnboardingCarousel';
import { hasSeenModal, setSeenModal } from '../../utils/checkModalLocalStorage';
import TabNavigation from '../../components/TabNavigation';
import onboarding from '../../containers/OnboardingCarousel/OnboardingCarousel.content';

type Props = {
  goals: FormattedGoals;
};

const AnimatedGoalPage = AnimatedPage(GoalList, 'SlideIn');

const Goals = ({ goals }: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(!hasSeenModal('goals') || false);
  const [previousFocusedElement, setPreviousFocusedElement] = useState<HTMLElement | null>(null);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const location = useLocation();

  useEffect(() => {
    if (modalOpen) {
      setSeenModal('goals');
    }
  }, []);

  useEffect(() => {
    if (previousFocusedElement) {
      previousFocusedElement.focus();
    }
  }, [previousFocusedElement]);

  return (
    <>
      <PageHeader
        title="Your Goals"
        rightButton={{ iconName: 'info', onClick: openModal, ariaLabel: 'Open goals help modal' }}
        setPreviousFocusedElement={setPreviousFocusedElement}
      />

      <TabNavigation
        color="primary"
        activeTab={location.pathname.includes('completed') ? 'completed' : 'active'}
        tabs={[
          { text: 'active', href: '/app/goals/active', status: goals.active.length },
          { text: 'completed', href: '/app/goals/completed', status: goals.completed.length },
        ]}
      />

      <div className={styles.listContainer}>
        <Switch>
          <Route path="/app/goals/completed" component={() => <AnimatedGoalPage goals={goals.completed} completed />} />
          <Route path="/app/goals" component={() => <AnimatedGoalPage goals={goals.active} />} />
        </Switch>
      </div>

      <Modal isOpen={modalOpen} onClose={closeModal} previousFocusedElement={previousFocusedElement}>
        <OnboardingCarousel items={onboarding.goals} closeOnboardingModal={closeModal} />
      </Modal>
    </>
  );
};

export default Goals;
