import styled from 'styled-components';
import { CardDescriptionAlertStyleProps } from './types';
import { CardDescription } from '../../../shared/cardStyles';

export const CardDescriptionAlert = styled.span<CardDescriptionAlertStyleProps>`
  ${CardDescription};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  ${(p) => p.isAlert && `color: ${p.theme.colours.red};`}
`;
export const Alert = styled.div`
  width: 100%;
`;

export const DescriptionText = styled.p``;
