import styled from 'styled-components';

interface ContainerProps {
  isPreview: boolean;
}

const Container = styled.div<ContainerProps>`
  ${({ theme, isPreview }) => ({
    border: isPreview ? 'none' : `1px solid ${theme.colours.lightGrey}`,
    boxShadow: isPreview ? 'none' : theme.shadows[0],
    borderRadius: theme.borders.radius,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    width: '100%',
  })};
  text-align: center;
`;

const MainLabel = styled.div`
  margin: 0;
  ${({ theme }) => ({
    color: theme.colours.primary,
    fontSize: theme.typography.font.size.xSmall,
    marginBottom: theme.spacing(1),
  })};
  text-transform: uppercase;
`;

const MainTitle = styled.h2`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.large,
    marginBottom: theme.spacing(2),
  })};
`;

const Section = styled.div`
  ${({ theme }) => ({
    marginBottom: theme.spacing(4),
  })};

  &:nth-of-type(3) {
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }
`;

const SectionTitle = styled.h3`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.small,
  })};
`;

const SectionText = styled.p`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.small,
  })};
`;

const PersonCount = styled.span`
  ${({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colours.primary,
    marginBottom: theme.spacing(2),
  })};

  > p {
    font-size: ${(p) => p.theme.typography.font.size.small};
  }
`;
const ButtonContainer = styled.span`
  ${({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  })};
`;

export { Container, MainTitle, MainLabel, Section, SectionTitle, SectionText, PersonCount, ButtonContainer };
