import styled from 'styled-components';
import { SvgBackgroundStyleProps } from '../InsightCard/types';
//import {} from './types';

export const InsightsWallContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;

  &:after,
  :before {
    box-sizing: border-box;
  }
`;

export const InsightColumn = styled.div<{ isMobile: boolean }>`
  position: relative;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: ${(p) => (p.isMobile ? '0' : `0px ${p.theme.spacing(1)}`)};

  > div {
    margin-top: ${(p) => p.theme.spacing(2)};
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

export const SvgBackground = styled.div<SvgBackgroundStyleProps>`
  width: 284px;
  height: 295px;
  background: url('${(p) => p.imageUrl}') no-repeat;
  background-position-x: 0%;
  background-position-y: 101%;
  background-size: contain;
`;
