import styled from 'styled-components';

export const WidgetContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flrex-direction: column;
  margin-left: ${(p) => (p.isMobile ? '0px' : p.theme.spacing(3))};
  > div {
    &:not(:first-child, :last-child) {
      margin: ${(p) => p.theme.spacing(2)} 0;
    }
  }
`;

export const HomepageHeaderContainer = styled.div<{ isMobile: boolean; isPreview: boolean; isWebView: boolean }>`
  max-width: 1024px;
  width: 100%;
  background: ${(p) => p.theme.colours.white};
  margin: 0 auto;
  margin-top: ${(p) => (p.isMobile ? (p.isPreview ? '0px' : '0px') : '0px')};
  padding-top: ${(p) => (p.isWebView && p.isPreview ? '16px' : '0px')};
  padding: 0 ${(p) => (p.isMobile ? '21px' : p.theme.spacing(5))};
`;

export const GrayWrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 10px 30px;
  color: #FFFFFF;
  background: #4D4D4D;
  flex: 1;
`;