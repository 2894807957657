import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { FormattedHabit, formatHabitPracticeDate, FormattedGoal } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import Button from '../../components/Button';
import OptionsModal, { OptionsModalOption } from '../../components/OptionsModal';

import HabitTrackerCarousel from '../../containers/HabitTrackerCarousel';
import HabitProgressSynopsis from '../../containers/HabitProgressSynopsis';
import HabitSuccessPlan from '../../containers/HabitSuccessPlan';
import HabitCompleteSynopsis from '../../containers/HabitCompleteSynopsis';

import styles from './styles.module.scss';
import PageHeader from '../../components/PageHeader';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import Icon from '../../components/Icon';
import ReactDOM from 'react-dom';
import Modal from '../../components/Modal';

interface Props extends FormattedHabit {
  updateHabit: any;
  deleteHabit: any;
  goal: FormattedGoal;
}

const Habit: FC<Props> = ({
  title,
  id,
  goal,
  target,
  completed,
  practiceDates,
  successPlan,
  updateHabit,
  deleteHabit,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [edited, setEdited] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [habitPracticeDates, setHabitPracticeDates] = useState([...practiceDates]);

  const history = useHistory();
  const { width: deviceWidth } = useViewport();

  const isHabitCompleted = completed === target;

  const onHabitClick = (shouldRemoveOne = false, index: number) => {
    setEdited(true);
    const practiceDates = [...habitPracticeDates];

    logger('info', shouldRemoveOne ? 'HABIT_TRACKER_HABIT_UN_LOG' : 'HABIT_TRACKER_HABIT_LOG', {
      habitId: id,
      goalId: goal.id,
    });

    if (shouldRemoveOne) {
      practiceDates.splice(index, 1);
    } else {
      practiceDates.push(formatHabitPracticeDate(new Date().toString(), true));
    }
    setHabitPracticeDates(practiceDates);
  };

  const save = async () => {
    setLoading(true);
    await updateHabit({ id, practiceDates: habitPracticeDates, goalId: goal.id });
    history.push(`/app/goals/goal/${goal.id}`);
  };

  const deleteHabitCallback = async () => {
    logger('info', 'HABIT_TRACKER_HABIT_DELETE', {
      habitId: id,
      goalId: goal.id,
    });
    const huh = window.confirm('Are you sure? Deleting this habit will erase all saved data');
    if (huh) {
      logger('info', 'HABIT_TRACKER_HABIT_DELETE_CONFIRM', {
        habitId: id,
        goalId: goal.id,
      });
      history.replace(`/app/goals/goal/${goal.id}`);
      await deleteHabit(id);
    }
  };

  return (
    <>
      <div className={classnames(styles.container, { [styles.containerCompleted]: isHabitCompleted })}>
        {document.getElementById('#headerEditRoot')
          ? ReactDOM.createPortal(
              <a data-test-id="editHabit" className={styles.editButton} onClick={() => setShowOptions(true)}>
                <Icon name="edit" color="white" />
              </a>,
              document.getElementById('#headerEditRoot')!,
            )
          : null}

        <PageHeader
          title={title}
          rightButton={{ iconName: isDesktop(deviceWidth) && 'edit', onClick: () => setShowOptions(true) }}
          leftButton={{ iconName: isDesktop(deviceWidth) && 'chevron-left', ariaLabel: `Back to goal(${goal.title})` }}
        />

        <div className={styles.content}>
          {!isHabitCompleted ? (
            <>
              <HabitTrackerCarousel
                habitPracticeDates={habitPracticeDates}
                target={target}
                onHabitClick={onHabitClick}
              />
              <HabitProgressSynopsis target={target} completed={habitPracticeDates.length} />
            </>
          ) : (
            <HabitCompleteSynopsis habitPracticeDates={habitPracticeDates} />
          )}

          <HabitSuccessPlan {...successPlan} isHabitCompleted={isHabitCompleted} />

          {!isHabitCompleted && (
            <div className={styles.buttonContainer}>
              <Button testId="save" loading={loading} disabled={!edited} onClick={() => save()}>
                Save
              </Button>
            </div>
          )}
        </div>
      </div>

      <Modal isOpen={showOptions} onClose={() => setShowOptions(false)} width="small">
        <OptionsModal title="Edit habit">
          <OptionsModalOption onClick={() => history.push(`/app/goals/habit/${id}/edit/name`)}>
            <span>Edit name</span> <Icon name="edit" />
          </OptionsModalOption>
          <OptionsModalOption onClick={() => history.push(`/app/goals/habit/${id}/edit/repetitions`)}>
            <span>Edit repetitions</span> <Icon name="edit" />
          </OptionsModalOption>
          <OptionsModalOption onClick={() => history.push(`/app/goals/habit/${id}/edit/success`)}>
            <span>Edit success plan</span> <Icon name="edit" />
          </OptionsModalOption>
          <OptionsModalOption onClick={() => deleteHabitCallback()}>
            <span>Delete habit</span> <Icon name="delete" color="orange" />
          </OptionsModalOption>
        </OptionsModal>
      </Modal>
    </>
  );
};

export default Habit;
