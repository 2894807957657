import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';
import { AdaptedAssessment } from '../utils/types';
import { InsightResponse } from './experiment.slice';

export type NotificationType = 'assessments' | 'insights';

export interface DismissNotificationPayload {
  id: string;
  type: NotificationType;
}

export interface DismissNotificationResponse {
  // assessmentNotifications: AssessmentNotification[];
  insightNotifications: InsightNotification[];
}

// export interface AssessmentNotification {
//   id: string;
//   seen: boolean;
// }

export interface InsightNotification {
  insightId: string;
  cohortId: string;
  seen: boolean;
}

export interface NotificationState {
  userVisitedInsightsPage: boolean;
  // assessments: AssessmentNotification[];
  insights: InsightNotification[];
}

const initialState: NotificationState = {
  userVisitedInsightsPage: false,
  // assessments: [],
  insights: [],
};

// const getAssessmentNotifications = createAsyncThunk<
//   AssessmentNotification[],
//   null,
//   {
//     dispatch: AppDispatch;
//     state: RootState;
//   }
// >(
//   'notifications/getAssessmentNotifications',
//   generateReauthenticatingThunkApiAction(async (state: RootState): Promise<any> => {
//     // const { assessments } = state.assessments;
//     const { cohortId } = state.cohorts.selectedCohort;
//     const storageItems: { [k: string]: AssessmentNotification[] } =
//       JSON.parse(localStorage.getItem('assessmentNotifications')!) || {};
//     let purifiedStorage: AssessmentNotification[] = [];
//     let purifiedStorageIdList: string[] = [];

    // get assessments id list
    // const assessmentIdList: string[] = assessments.map((assessment: AdaptedAssessment): string => assessment.id);

    // if (cohortId) {
    //   if (!storageItems[cohortId]) {
    //     storageItems[cohortId] = [];
    //   } else if (storageItems[cohortId].length) {
        // purify (in case an assessment gets deleted) only purifies storage at cohortId when visited
        // purifiedStorage = storageItems[cohortId].filter((assessmentNotification: AssessmentNotification): boolean =>
          // assessmentIdList.includes(assessmentNotification.id),
        // );
        // get assessment notifications id list from purified list
      //   purifiedStorageIdList = purifiedStorage.map(
      //     (notificationAssessment: AssessmentNotification): string => notificationAssessment.id,
      //   );
      // } else {
      //   purifiedStorage = [];
      // }

      // if assessment has no notification created => adapt one, else skip
      // assessments.forEach((assessment: AdaptedAssessment): void => {
      //   if (!purifiedStorageIdList.includes(assessment.id)) {
      //     purifiedStorage.push({ id: assessment.id, seen: false });
      //   }
      // });

      // update storage at cohortID
    //   storageItems[cohortId] = purifiedStorage;
    // }

    // save back to storage
    // localStorage.setItem('assessmentNotifications', JSON.stringify(storageItems));

    // get the updated storage at cohortId
//     return purifiedStorage || [];
//   }),
// );

// insight notification by cohortId & removed user created insights & filtered by selected cohortId
const getInsightNotifications = createAsyncThunk<
  InsightNotification[],
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'notifications/getInsightNotifications',
  generateReauthenticatingThunkApiAction(async (state: RootState): Promise<any> => {
    const { insights } = state.experiment;
    const { id: userId } = state.user;
    const allInsights = insights;

    const insightsWithSeenStatus =
      allInsights &&
      allInsights.map((insight) => {
        const insightsInStorage = JSON.parse(localStorage.getItem('insightNotifications')!) || [];

        const isSeen = insightsInStorage.find((insightInStorage: any) => {
          if (insight.userId === userId) return true;
          return insightInStorage.insightId === insight.id && insightInStorage.seen;
        });

        return {
          insightId: insight.id,
          cohortId: insight.cohortId,
          seen: !!isSeen,
        };
      });

    if (insightsWithSeenStatus && insightsWithSeenStatus.length) {
      localStorage.setItem('insightNotifications', JSON.stringify(insightsWithSeenStatus));
    }

    return insightsWithSeenStatus;
  }),
);

const dismissNotification = createAsyncThunk<
  DismissNotificationResponse,
  DismissNotificationPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'notifications/dismissNotification',
  generateReauthenticatingThunkApiAction(
    async (state: RootState, payload: DismissNotificationPayload): Promise<any> => {
      const { cohortId } = state.cohorts.selectedCohort;
      // const assessmentNotifications: { [k: string]: AssessmentNotification[] } =
      //   JSON.parse(localStorage.getItem('assessmentNotifications')!) || [];
      const insightNotifications: InsightNotification[] =
        JSON.parse(localStorage.getItem('insightNotifications')!) || [];

      if (cohortId) {
        // if (payload.type == 'assessments' && cohortId) {
        //   const foundNotification = assessmentNotifications[cohortId].find(
        //     (item: any): boolean => item.id === payload.id,
        //   );
        //   if (foundNotification) {
        //     foundNotification.seen = true;
        //     localStorage.setItem('assessmentNotifications', JSON.stringify(assessmentNotifications));
        //   }
        // }

        if (payload.type == 'insights' && cohortId) {
          const foundNotification = insightNotifications.find((item: any): boolean => item.insightId === payload.id);
          if (foundNotification) {
            foundNotification.seen = true;
            localStorage.setItem('insightNotifications', JSON.stringify(insightNotifications));
          }
        }
      }

      return {
        // assessmentNotifications: assessmentNotifications[cohortId],
        insightNotifications: insightNotifications,
      };
    },
  ),
);

const insightNotificationsDismissAll = createAsyncThunk<
  InsightNotification[],
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'notifications/insightNotificationsDismissAll',
  generateReauthenticatingThunkApiAction(async (state: RootState): Promise<any> => {
    const { cohortId } = state.cohorts.selectedCohort;
    const insightsInStorage = JSON.parse(localStorage.getItem('insightNotifications')!) || [];
    const seenInsights = insightsInStorage.map((insight: any) => {
      return {
        ...insight,
        seen: cohortId === insight.cohortId,
      };
    });
    localStorage.setItem('insightNotifications', JSON.stringify(seenInsights));
    return seenInsights;
  }),
);

// const assessmentNotificationsDismissAll = createAsyncThunk<
//   AssessmentNotification[],
//   null,
//   {
//     dispatch: AppDispatch;
//     state: RootState;
//   }
// >(
//   'notifications/assessmentNotificationsDismissAll',
//   generateReauthenticatingThunkApiAction(async (state: RootState): Promise<any> => {
//     const { cohortId } = state.cohorts.selectedCohort;
//     const assessmentNotifications: { [k: string]: AssessmentNotification[] } =
//       JSON.parse(localStorage.getItem('assessmentNotifications')!) || [];
//     let updatedNotifications: AssessmentNotification[] = [];

//     if (cohortId) {
//       updatedNotifications = assessmentNotifications[cohortId].map(
//         (assessment: AssessmentNotification): AssessmentNotification => ({ ...assessment, seen: true }),
//       );
//       assessmentNotifications[cohortId] = updatedNotifications;
//     }

//     localStorage.setItem('assessmentNotifications', JSON.stringify(assessmentNotifications));

//     return updatedNotifications;
//   }),
// );

const notifications = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setVisitedInsightsPage(state) {
      state.userVisitedInsightsPage = true;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getAssessmentNotifications.fulfilled, (state, { payload }) => {
    //   if (!payload) {
    //     return;
    //   }
    //   state.assessments = payload;
    // });
    builder.addCase(getInsightNotifications.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.insights = payload;
    });
    builder.addCase(dismissNotification.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.insights = payload.insightNotifications;
      // state.assessments = payload.assessmentNotifications;
    });
    builder.addCase(insightNotificationsDismissAll.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.insights = payload;
    });
    // builder.addCase(assessmentNotificationsDismissAll.fulfilled, (state, { payload }) => {
    //   if (!payload) {
    //     return;
    //   }
    //   state.assessments = payload;
    // });
  },
});

export default notifications.reducer;

export const actions = {
  ...notifications.actions,
  // getAssessmentNotifications,
  getInsightNotifications,
  dismissNotification,
  insightNotificationsDismissAll,
  // assessmentNotificationsDismissAll,
};
