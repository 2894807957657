import React, { PropsWithChildren } from 'react';
import { ExperimentModalHeaderProps } from './types';
import { HeaderContainer, Title, LeftButton, ButtonText } from './styles';
import Icon, { IconName } from '../Icon';
import theme from '../../utils/theme';
import { useHistory } from 'react-router-dom';
import { useViewport } from '../../utils/hooks';

const ExperimentModalHeader: React.FC<ExperimentModalHeaderProps> = ({
  iconName,
  title,
  backButton,
}: PropsWithChildren<ExperimentModalHeaderProps>): JSX.Element => {
  const history = useHistory();
  const { width: deviceWidth } = useViewport();

  const leftButton: boolean = deviceWidth > theme.devices.tablet;

  return (
    <HeaderContainer>
      {leftButton && backButton && (
        <LeftButton onClick={(): void => history.goBack()}>
          <Icon
            name={(backButton?.iconName as IconName) || 'chevron-left'}
            size="xSmall"
            fill={theme.colours.primary}
          />
          <ButtonText aria-label={backButton?.label}>{backButton?.label}</ButtonText>
        </LeftButton>
      )}
      {iconName && <Icon name={iconName} size="medium" color="primary" />}
      <Title>{title}</Title>
    </HeaderContainer>
  );
};

export default ExperimentModalHeader;
