import React, { PropsWithChildren, useEffect, useState } from 'react';
import { EmptyText, IconWrapper, IntroText, LoaderForIframe, CardsContainer } from './styles';
import { generateOTP } from '../../utils/api/assessments';
import { AdaptedAssessment, AdaptedRequest, AssessmentsProps } from '../../utils/types';
import Modal from '../../components/Modal';
import PageHeader from '../../components/PageHeader';
import AssessmentCard from '../../components/Assessments/components/AssessmentCard';
import RequestCard from '../../components/Assessments/components/RequestCard';
import TabNavigation from '../../components/Assessments/components/TabNavigation';
import { tabType } from '../../components/Assessments/components/TabNavigation/types';
import Icon from '../../components/Icon';
import theme from '../../utils/theme';
import { isSafari } from '../../utils/getUserAgent';
import { useAppSelector, useViewport } from '../../utils/hooks';
import { RootState } from '../../redux';
// import { AssessmentsState } from '../../redux/assessments.slice';
import { UserState } from '../../redux/user.slice';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const Assessments: React.FC<AssessmentsProps> = ({
  getAssessments,
  getRequests,
}: PropsWithChildren<AssessmentsProps>): JSX.Element => {
  const { width: deviceWidth } = useViewport();
  const isMobile: boolean = deviceWidth < theme.devices.mobile;
  const isTablet: boolean = deviceWidth < theme.devices.tablet && deviceWidth > theme.devices.mobile;
  // const { assessments, requests } = useAppSelector((state: RootState): AssessmentsState => state.assessments);
  const { email } = useAppSelector((state: RootState): UserState => state.user);
  const { accessToken } = useAppSelector((state: RootState) => state.auth);
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalUrl, setModalUrl] = useState<string>('');
  const [tabs, setTabs] = useState<tabType[]>([
    { title: 'Assesments', numberOfItems: 0 },
    { title: 'Requests', numberOfItems: 0 },
  ]);
  const [debugText, setDebugText] = useState('');
  const [isLoadingStart, setIsLoadingStart] = useState<boolean>(false);
  const [isLoadingRaters, setIsLoadingRaters] = useState<boolean>(false);
  const appInsights = useAppInsightsContext();

  // const onCloseModal = () => {
  //   appInsights.trackEvent({
  //     name: 'Envisia',
  //     properties: { type: 'ON_CLOSE_ENVISIA_MODAL', isNativeWebView, email },
  //   });
  //   setModalIsOpen(false);
  //   getAssessments();
  //   getRequests();
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     window.addEventListener('message', (event) => {
  //       console.log("event.data aman",event)
  //       const data = JSON.parse(event.data);

  //       if (data?.type === 'ON_CLOSE_NATIVE_MODAL') {
  //         onCloseModal();
  //       }

  //       if (data?.type === 'ON_GET_ENVISIA_WEBVIEW') {
  //         //setDebugText('POSTMSG: ON_GET_ENVISIA_WEBVIEW');

  //         appInsights.trackEvent({
  //           name: 'Envisia',
  //           properties: { type: 'ON_GET_ENVISIA_WEBVIEW', payload: data.payload, isNativeWebView, email },
  //         });
  //       }
  //     });
  //   }, 0);
  // }, []);

  // useEffect((): void => {
  //   const tabsArray: tabType[] = [
  //     { title: 'Assesments', numberOfItems: assessments?.length || 0 },
  //     { title: 'Requests', numberOfItems: requests?.length || 0 },
  //   ];
  //   setTabs(tabsArray);
  // }, [assessments, requests]);

  // const toggleModal: (type: string, index: number) => void = async (type: string, index: number): Promise<void> => {
  //   if (!assessments?.length) return;

  //   appInsights.trackEvent({
  //     name: 'Envisia',
  //     properties: { type: 'ON_OPEN_ENVISIA_MODAL', isNativeWebView, email },
  //   });

  //   if (modalIsOpen) {
  //     setModalIsOpen(false);
  //     return;
  //   }

  //   if (type === 'start') setIsLoadingStart(true);
  //   if (type === 'raters') setIsLoadingRaters(true);

  //   const { otp } = await generateOTP(accessToken, email);
  //   const startUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${assessments[index].questionnaireLink}`;
  //   const raterUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${assessments[index].nominationLink}`;
  //   const downloadUrl = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${assessments[index].reportLink}`;
  //   const url: string = type !== 'start' ? (type === 'download' ? downloadUrl : raterUrl) : startUrl;

  //   if (type === 'start') setIsLoadingStart(false);
  //   if (type === 'raters') setIsLoadingRaters(false);

  //   appInsights.trackEvent({
  //     name: 'Envisia',
  //     properties: { type: 'ON_GET_ENVISIA_URL', payload: url, isNativeWebView, email },
  //   });

  //   if (isNativeWebView) {
  //     const qs = `?envisiaTestUrl=${process.env.REACT_APP_ENVISIA_BASE_URL_TEST}`; // TODO: Needs to be removed once Envisia URL config fixed on native app
  //     window.location.href = `${qs}#${url}`;
  //     return;
  //   }

  //   if (isSafari) {
  //     window.location.href = url;
  //     //open new tab
  //     return;
  //   }

  //   setModalUrl(url);
  //   setModalIsOpen(true);
  // };

  // const toggleRequestModal: (type: string, index: number) => void = async (
  //   type: string,
  //   index: number,
  // ): Promise<void> => {
  //   if (!requests?.length) return;

  //   appInsights.trackEvent({
  //     name: 'Envisia',
  //     properties: { type: 'ON_OPEN_ENVISIA_MODAL', isNativeWebView, email },
  //   });

  //   if (modalIsOpen) {
  //     setModalIsOpen(false);
  //     return;
  //   }

  //   if (type === 'feedbackStart') setIsLoadingStart(true);

  //   const { otp } = await generateOTP(accessToken, email);
  //   const url = `${process.env.REACT_APP_ENVISIA_BASE_URL}/jwt/auth?email=${email}&otp=${otp}&redirect_url=${requests[index].questionnaireLink}`;

  //   if (type === 'feedbackStart') setIsLoadingStart(false);

  //   appInsights.trackEvent({
  //     name: 'Envisia',
  //     properties: { type: 'ON_GET_ENVISIA_URL', payload: url, isNativeWebView, email },
  //   });

  //   if (isNativeWebView) {
  //     const qs = `?envisiaTestUrl=${process.env.REACT_APP_ENVISIA_BASE_URL_TEST}`; // TODO: Needs to be removed once Envisia URL config fixed on native app
  //     window.location.href = `${qs}#${url}`;
  //     return;
  //   }

  //   if (isSafari) {
  //     window.location.href = url;
  //     return;
  //   }

  //   setModalUrl(url);
  //   setModalIsOpen(true);
  // };

  // const renderAssessmentCards: () => React.ReactNode = (): React.ReactNode => {
  //   return assessments?.map((assessment: AdaptedAssessment, i: number): React.ReactNode => {
  //     return (
  //       <div>
  //         <AssessmentCard
  //           title={assessment.title}
  //           campaignName={assessment.campaignName}
  //           totalRaters={assessment.totalRaters}
  //           deadline={assessment.deadline}
  //           status={assessment.status}
  //           key={i}
  //           cardIndex={i}
  //           startButtonOnPress={toggleModal}
  //           ratersButtonOnPress={toggleModal}
  //           downloadButtonOnPress={toggleModal}
  //           isLoadingStart={isLoadingStart}
  //           isLoadingRaters={isLoadingRaters}
  //         />
  //       </div>
  //     );
  //   });
  // };

  // const renderRequestCards: () => React.ReactNode = (): React.ReactNode => {
  //   return requests?.map((request: AdaptedRequest, i: number): React.ReactNode => {
  //     return (
  //       <RequestCard
  //         title={request.name}
  //         deadline={request.deadline}
  //         status={request.status}
  //         startButtonOnPress={toggleRequestModal}
  //         key={i}
  //         cardIndex={i}
  //         isLoadingStart={isLoadingStart}
  //       />
  //     );
  //   });
  // };

  // if (activeIndex === 0 && assessments?.length) {
  //   return (
  //     <>
  //       <div>{debugText}</div>
  //       <PageHeader title="Assessments" />
  //       <TabNavigation tabs={tabs} onPress={setActiveIndex} activeIndex={activeIndex} />
  //       <IntroText>
  //         Assessments help to provide you with information to support your future growth as a Leader. You will also need
  //         to select raters to give feedback.
  //       </IntroText>
  //       <CardsContainer isMobile={isMobile} isTablet={isTablet}>
  //         {renderAssessmentCards()}
  //       </CardsContainer>
  //       <Modal isOpen={modalIsOpen} onClose={onCloseModal}>
  //         <LoaderForIframe />
  //         <iframe src={modalUrl} frameBorder="0" style={{ minHeight: `800px` }}></iframe>
  //       </Modal>
  //     </>
  //   );
  // }

  // if (activeIndex === 1 && requests?.length) {
  //   return (
  //     <>
  //       <PageHeader title="Assessments" />
  //       <TabNavigation tabs={tabs} onPress={setActiveIndex} activeIndex={activeIndex} />
  //       <CardsContainer isMobile={isMobile} isTablet={isTablet}>
  //         {renderRequestCards()}
  //       </CardsContainer>
  //       <Modal isOpen={modalIsOpen} onClose={onCloseModal}>
  //         <LoaderForIframe />
  //         <iframe src={modalUrl} frameBorder="0" style={{ minHeight: `800px` }}></iframe>
  //       </Modal>
  //     </>
  //   );
  // }

  return (
    <>
      {/* <PageHeader title="Assessments" /> */}
      {/* <TabNavigation tabs={tabs} onPress={setActiveIndex} activeIndex={activeIndex} /> */}
      <IconWrapper>
        <Icon name="assessment" size="large" fill={theme.colours.lightGrey} />
      </IconWrapper>
      <EmptyText>This feature is no more available.</EmptyText>
    </>
  );
};

export default Assessments;
