import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  getGoals as getGoalsFromApi,
  GetGoalsResponse,
  updateHabit as updateHabitFromApi,
  deleteHabit as deleteHabitFromApi,
  updateGoal as updateGoalFromApi,
  deleteGoal as deleteGoalFromApi,
  createGoal as createGoalFromApi,
  createHabit as createHabitFromApi,
  getHabitCollection as getHabitCollectionFromApi,
} from '../utils/api/goals';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';
import { Goal, Habit, HabitCollection } from '../utils/types';
import {
  getHabitById,
  FormattedPracticeDate,
  FormattedReflection,
  HabitSuccessPlan,
  getGoalById,
} from '../utils/adapters/goals';

export type GoalState = {
  goals: Array<Goal> | null;
};

const initialState: GoalState = { goals: null };

const getGoals = createAsyncThunk<
  GetGoalsResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/getGoals',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const goals = await getGoalsFromApi(accessToken);
    return goals;
  }),
);

const deleteHabit = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/deleteHabit',
  generateReauthenticatingThunkApiAction(async (state: RootState, id: string) => {
    const { accessToken } = state.auth;
    // dont await, need to be instant on app.
    deleteHabitFromApi(accessToken, id);
    return id;
  }),
);

const deleteGoal = createAsyncThunk<
  string,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/deleteGoal',
  generateReauthenticatingThunkApiAction(async (state: RootState, id: string) => {
    const { accessToken } = state.auth;
    // dont await, need to be instant on app.
    deleteGoalFromApi(accessToken, id);
    return id;
  }),
);

export type UpdateHabitPayload = {
  id: string;
  goalId: string;
  practiceDates?: Array<FormattedPracticeDate>;
  title?: string;
  successPlan?: HabitSuccessPlan;
  target?: number;
};

type UpdateHabitResponse = {
  habit: Habit;
};

const updateHabit = createAsyncThunk<
  UpdateHabitResponse,
  UpdateHabitPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/updateHabit',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: UpdateHabitPayload) => {
    const { accessToken } = state.auth;
    const { id, practiceDates, title, successPlan, target } = payload;
    const habit: any = { ...getHabitById(state, id) };
    if (!habit._id) return null;
    if (practiceDates) {
      habit.practiceDates = practiceDates.map(({ dateString }) => dateString);
    }
    if (title) {
      habit.title = title;
    }
    if (successPlan) {
      habit.barriers = successPlan.barriers;
      habit.enablers = successPlan.enablers;
      habit.reward = successPlan.reward;
      habit.shareWith = successPlan.shareWith;
      habit.trigger = successPlan.trigger;
    }
    if (target) {
      habit.practice = target;
    }
    await updateHabitFromApi(accessToken, habit, '');
    return { habit };
  }),
);

export type UpdateGoalPayload = {
  id: string;
  practiceDates?: Array<FormattedPracticeDate>;
  title?: string;
  successPlan?: HabitSuccessPlan;
  tasks?: any;
  reflections?: Array<FormattedReflection>;
};
type UpdateGoalResponse = {
  goal: Goal;
};

const updateGoal = createAsyncThunk<
  UpdateGoalResponse,
  UpdateGoalPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/updateGoal',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: UpdateGoalPayload) => {
    const { accessToken } = state.auth;
    const { id, title, tasks, reflections } = payload;
    const goal: any = { ...getGoalById(state, id) };
    if (!goal) return;
    if (title) {
      goal.ambition = title;
    }
    if (tasks) {
      goal.tasks = tasks
        .map((task: any) => {
          if (task.title.length === 0) {
            return null;
          }
          return {
            completedDate: task.completed ? dayjs().format() : null,
            title: task.title,
          };
        })
        .filter(Boolean);
    }
    if (reflections) {
      goal.reflections = reflections
        .map((reflection: any) => {
          const originalReflection = goal.reflections.find(({ _id }: any) => _id === reflection.id);
          return { ...originalReflection, ...reflection };
        })
        .filter(Boolean);
    }
    const newGoal = await updateGoalFromApi(accessToken, goal);
    return { goal: { ...goal, ...newGoal } };
  }),
);

export type CreateGoalPayload = {
  title: string;
};

const createGoal = createAsyncThunk<
  UpdateGoalResponse,
  UpdateGoalPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/createGoal',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: UpdateGoalPayload) => {
    const { accessToken } = state.auth;
    const { title } = payload;
    const goal = await createGoalFromApi(accessToken, { ambition: title || '' });
    return { goal: { ...goal, tasks: [], habit: [], reflections: [] } };
  }),
);

export type CreateHabitPayload = {
  goalId: string;
} & Habit;

export type CreateHabitResponse = {
  goalId: string;
  habit: Habit;
};

const createHabit = createAsyncThunk<
  CreateHabitResponse,
  CreateHabitPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/createHabit',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: CreateHabitPayload) => {
    const { accessToken } = state.auth;
    const habitRaw = { ...payload };

    const habit = await createHabitFromApi(accessToken, habitRaw);
    return { goalId: habitRaw.goalId, habit };
  }),
);

const getHabitCollection = createAsyncThunk<
  Array<HabitCollection>,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'goals/getHabitCollections',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const collection = await getHabitCollectionFromApi(accessToken);
    return collection;
  }),
);

const goals = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGoals.fulfilled, (state, { payload }) => {
      state.goals = payload;
    });
    builder.addCase(updateHabit.fulfilled, (state, { payload }) => {
      const { habit } = payload;
      if (!state.goals) return;
      for (let i = 0; i < state.goals.length; i++) {
        for (let j = 0; j < state.goals[i].habits.length; j++) {
          if (state.goals[i].habits[j]._id === habit._id) {
            state.goals[i].habits[j] = habit;
          }
        }
      }
    });
    builder.addCase(updateGoal.fulfilled, (state, { payload }) => {
      const { goal } = payload;
      if (!state.goals) return;
      for (let i = 0; i < state.goals.length; i++) {
        if (state.goals[i]._id === goal._id) {
          state.goals[i] = goal;
        }
      }
    });
    builder.addCase(deleteHabit.fulfilled, (state, { payload }) => {
      if (!state.goals) return;
      state.goals.forEach((goal) => {
        let index = -1;
        goal.habits.forEach((habit, _index) => {
          if (habit._id === payload) index = _index;
        });
        goal.habits.splice(index, 1);
      });
    });
    builder.addCase(deleteGoal.fulfilled, (state, { payload }) => {
      if (!state.goals) return;
      let index = -1;
      state.goals.forEach((goal, _index) => {
        if (goal._id === payload) index = _index;
      });
      state.goals.splice(index, 1);
    });
    builder.addCase(createGoal.fulfilled, (state, { payload }) => {
      if (!state.goals) return;
      const { goal } = payload;
      state.goals.push(goal);
    });
    builder.addCase(createHabit.fulfilled, (state, { payload }) => {
      if (!state.goals) return;
      const { goalId, habit } = payload;
      state.goals.forEach((goal) => {
        if (goal._id === goalId) {
          if (!goal.habits) goal.habits = [];
          goal.habits.push(habit);
        }
      });
    });
  },
});

export default goals.reducer;

export const actions = {
  ...goals.actions,
  getGoals,
  updateHabit,
  deleteHabit,
  deleteGoal,
  updateGoal,
  createGoal,
  createHabit,
  getHabitCollection,
};
