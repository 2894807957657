import styled from 'styled-components';
import theme from '../../utils/theme';
//import {} from './types';

export const InsightModalContainer = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;
export const LoadingContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;
