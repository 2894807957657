import { connect } from 'react-redux';
import get from 'lodash.get';
import { RootState } from '../../../../redux';
import { actions } from '../../../../redux/auth.slice';
import LearningJourneys from './LearningJourneys';

const { setTokens } = actions;

const mapState = (state: RootState) => ({
  tokens: {
    accessToken: get(state, 'auth.accessToken', ''),
    refreshToken: get(state, 'auth.refreshToken', ''),
  },
  loginRedirect: get(state, 'auth.loginRedirect', '/app/home'),
});

export default connect(mapState, { setTokens })(LearningJourneys);
