import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import ProgressBarNotched from '../../components/ProgressBarNotched';

import { FormattedGoalHabit } from '../../utils/adapters/goals';

import styles from './styles.module.scss';
import TextWithIcon from '../TextWithIcon';
import Icon from '../Icon';

type Props = FormattedGoalHabit;

const HabitCard: FC<Props> = ({ title, id, completed, target }: Props) => {
  const isHabitCompleted = completed >= target;
  return (
    <Link to={`/app/goals/habit/${id}`} className={styles.link}>
      <div className={classnames(styles.container, { [styles.containerCompleted]: isHabitCompleted })}>
        <TextWithIcon text="Habit" icon="habit" />

        <div className={styles.title}>{title}</div>
        <div className={styles.footer}>
          {!isHabitCompleted && (
            <div className={styles.progressBar}>
              <div className={styles.progressTitle}>
                {completed} / {target} completed
              </div>
              <ProgressBarNotched color="secondary" value={completed} max={target} segments={4} />
            </div>
          )}

          <div className={styles.button}>
            {isHabitCompleted && <Icon className={styles.icon} name="rosette-habit" size="large" />}
            {isHabitCompleted ? 'Review details' : 'View and track your habit activity'}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default HabitCard;
