import React, { PropsWithChildren, useState, useEffect } from 'react';
import { FinalContent, InsightEditOptionsProps, UIType } from './types';
import {
  EditOptionsContainer,
  HeaderContainer,
  HeaderOptions,
  RadioLabel,
  Separator,
  BodyContainer,
  BodyContent,
  ColorBox,
  IconBox,
  ImageBox,
} from './styles';
import Icon, { IconName } from '../Icon';
import { useViewport } from '../../utils/hooks';
import theme from '../../utils/theme';

const InsightEditOptions: React.FC<InsightEditOptionsProps> = ({
  colorSet,
  iconSet,
  imageSet,
  getContent,
  isInsightWallEditorView = false,
  insightWallEditorMetadata,
}: PropsWithChildren<InsightEditOptionsProps>): JSX.Element => {
  const [colorIndex, setColorIndex] = useState<number>(0);
  const [iconIndex, setIconIndex] = useState<number>(0);
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [activeRadioButton, setActiveRadioButton] = useState<number>(1);
  const [finalContent, setFinalContent] = useState<any>(null);
  const [uiType, setUIType] = useState<UIType>('icon');
  const { width: deviceWidth } = useViewport();
  const [isInsightWallEditorInitialised, setIsInsightWallEditorInitialised] = useState<boolean>(false);

  const assetUrl = process.env.REACT_APP_SHARED_EXPERIMENT_CDN_ASSETS;
  const constructColudinaryUrl = (fileName: string): string => `${assetUrl + fileName}`;

  useEffect(() => {
    if (!isInsightWallEditorView) {
      setColorIndex(0);
      setIconIndex(0);
      setImageIndex(0);
    }

    activeRadioButton == 1 ? setUIType('icon') : setUIType('image');
  }, [activeRadioButton]);

  // This is needed for items having default Indexes (0)
  // If an item has indexes equal to 0 (defaults) UI wont update when it loads in existing metadata
  useEffect((): (() => void) => {
    if (insightWallEditorMetadata && insightWallEditorMetadata.type === 'image' && isInsightWallEditorView) {
      setIconIndex(2);
    }
    return (): void => {};
  }, []);

  // if used from insight wall to edit a post
  // get metadata of that insight and set properties
  useEffect((): (() => void) | undefined => {
    if (isInsightWallEditorView && insightWallEditorMetadata && !isInsightWallEditorInitialised) {
      if (insightWallEditorMetadata.icon && insightWallEditorMetadata.type === 'icon') {
        setActiveRadioButton(1);
        setUIType('icon');
        setColorIndex(colorSet.indexOf(insightWallEditorMetadata.color));
        setIconIndex(iconSet.indexOf(insightWallEditorMetadata.icon));
      }
      if (insightWallEditorMetadata.image && insightWallEditorMetadata.type === 'image') {
        setActiveRadioButton(2);
        const urlArr: string[] = insightWallEditorMetadata.image.split('/');
        const imgTitle: string = urlArr[urlArr.length - 1];
        setUIType('image');
        setImageIndex(imageSet.indexOf(imgTitle));
      }
      setIsInsightWallEditorInitialised(true);
    }
    return (): void => {};
  }, [insightWallEditorMetadata]);

  useEffect((): void => {
    const content: FinalContent = {
      type: uiType,
      icon: uiType == 'icon' ? (iconSet[iconIndex] as IconName) : undefined,
      image: uiType == 'image' ? constructColudinaryUrl(imageSet[imageIndex]) : undefined,
      color: colorSet[colorIndex],
    };

    setFinalContent(content);
  }, [colorIndex, imageIndex, iconIndex]);

  useEffect((): void => {
    getContent(finalContent);
  }, [finalContent]);

  return (
    <EditOptionsContainer>
      <HeaderContainer>
        <p>
          <strong>Customise card:</strong>
        </p>
        <HeaderOptions>
          <input
            style={{ width: '1.4em', height: '1.4em' }}
            type="radio"
            id="icon"
            name="icon_or_image"
            value="icon"
            checked={activeRadioButton == 1}
            onChange={() => setActiveRadioButton(1)}
            tabIndex={0}
            aria-label={'Icon and Colour selection button'}
          />
          <RadioLabel htmlFor="icon">Colour &amp; Icon</RadioLabel>
          <input
            style={{ width: '1.4em', height: '1.4em' }}
            type="radio"
            id="image"
            name="icon_or_image"
            value="image"
            checked={activeRadioButton == 2}
            onChange={() => setActiveRadioButton(2)}
            tabIndex={0}
            aria-label={'Image selection button'}
          />
          <RadioLabel htmlFor="image">Image</RadioLabel>
        </HeaderOptions>
      </HeaderContainer>
      <BodyContainer>
        {uiType == 'icon' && (
          <>
            <BodyContent contentType="color">
              {colorSet.map((color: string, index: number) => (
                <ColorBox
                  background={color}
                  key={index}
                  selected={colorIndex == index}
                  onClick={() => {
                    setColorIndex(index);
                    getContent(finalContent);
                  }}
                  tabIndex={0}
                  aria-label={`Colour - ${color}, ${colorIndex === index ? 'selected' : ''}`}
                />
              ))}
            </BodyContent>
            <Separator />
            <BodyContent contentType="icon">
              {iconSet.map((icon: string, index: number) => (
                <IconBox
                  key={index}
                  selected={iconIndex == index}
                  onClick={() => setIconIndex(index)}
                  tabIndex={0}
                  aria-label={`Icon - ${icon}, ${iconIndex === index ? 'selected' : ''}`}>
                  <Icon
                    name={icon as IconName}
                    size={deviceWidth > theme.devices.tablet ? 'large' : 'medium'}
                    color={iconIndex == index ? 'white' : 'black'}
                  />
                </IconBox>
              ))}
            </BodyContent>
          </>
        )}
        {uiType == 'image' && (
          <BodyContent contentType="image">
            {imageSet.map((image: string, index: number) => (
              <ImageBox
                background={constructColudinaryUrl(image)}
                selected={imageIndex == index}
                key={index}
                onClick={() => setImageIndex(index)}
                tabIndex={0}
                aria-label={`Icon - ${image}, ${imageIndex === index ? 'selected' : ''}`}
              />
            ))}
          </BodyContent>
        )}
      </BodyContainer>
    </EditOptionsContainer>
  );
};

export default InsightEditOptions;
