import { connect } from 'react-redux';
import get from 'lodash.get';
import LearningJourney from './LearningJourney';
import { RootState } from '../../redux';
import { adaptJourneyModules, adaptModulesCompleted } from './adapters';

const mapState = (state: RootState) => {
  return {
    journey: get(state, 'journey', []),
    modules: adaptJourneyModules(get(state, 'journey.modules', [])),
    allModulesCompleted: adaptModulesCompleted(get(state, 'journey.modules', [])),
  };
};

export default connect(mapState, null)(LearningJourney);
