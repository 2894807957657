import styled from 'styled-components';
import { InsightWallActionNotificationContainerStyleProps } from './InsightWallActionNotification.types';

export const InsightWallActionNotificationContainer = styled.div<InsightWallActionNotificationContainerStyleProps>`
  ${({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  })}
`;

export const InsightWallNotificationContentWrapper = styled.div`
  ${({ theme }) => ({
    width: '190px',
  })}
`;

export const InsightWallActionNotificationHeader = styled.span`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.large,
    color: theme.colours.darkGrey,
  })}
`;

export const InsightWallActionNotificationMessage = styled.span`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.small,
    color: theme.colours.darkGrey,
  })}
`;
