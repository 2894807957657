import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Organisation } from '../utils/types';
import { getOrganisations as getOrganisationsFromApi} from '../components/JourneySelectModal/helpers';

interface initialState {
  organisations: Organisation[] | [];
}

const initialState: initialState = {
  organisations:[],
};

export const getOrganisations = createAsyncThunk('organisation/getOrganisations', async (_, thunkAPI) => {
  const { auth } = thunkAPI.getState() as RootState;
  const data = await getOrganisationsFromApi(auth);
  return data;
});
const organisations = createSlice({
  name: 'organisations',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getOrganisations.fulfilled, (state, { payload }) => {
      state.organisations = payload;
    });
  },
});

export default organisations.reducer;
export const actions = {
  ...organisations.actions,
  getOrganisations,
};
