import React, { ReactElement, useState } from 'react';
import get from 'lodash.get';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Button from '../../components/Button';
import Input from '../../components/Input';

import styles from './styles.module.scss';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader from '../../components/PageHeader';
import TextWithIcon from '../../components/TextWithIcon';

type Props = {
  createGoal: any;
};

type CreateFormPayload = {
  title: string;
};

const GoalEditName = ({ createGoal }: Props): ReactElement => {
  const history = useHistory();
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const { width: deviceWidth } = useViewport();

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ title }: CreateFormPayload) => {
    (async () => {
      setSending(true);
      try {
        await createGoal({ title });
        history.goBack();
      } catch (err) {
        setSending(false);
      }
    })();
  };

  const onChange = () => {
    if (!hasEdited) setHasEdited(true);
  };

  return (
    <>
      {isDesktop(deviceWidth) && (
        <PageHeader title="New Goal" leftButton={{ iconName: 'chevron-left', ariaLabel: 'Back to your goals' }} />
      )}

      <div className={styles.container}>
        <TextWithIcon text="Goal" icon="goal" />

        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <Input
              id="title"
              name="title"
              type="text"
              onChange={onChange}
              error={get(errors, 'title', null)}
              register={register}
              placeholder="e.g. How to become a better manager"
              label="Create a goal"
            />
            <div className={styles.buttonContainer}>
              <Button loading={sending} disabled={!hasEdited} testId="createGoalSubmitButton">
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GoalEditName;
