import { auth0domain, teamsWebHook } from '../../utils/model';
import logger from '../../utils/logger';
import { Tokens } from '../../utils/types';
import { bootstrap } from 'teams-web-send';


export const sendCodeToEmail = async (email: string): Promise<number> => {
  try {
    logger('info', 'SEND_CODE');
    const response = await fetch(`${auth0domain}/passwordless/start`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_id: process.env.REACT_APP_AUTHOH_USER_CLIENT_ID,
        connection: 'email',
        email,
        send: 'code',
        authParams: {
          scope: 'openid offline_access profile email',
        },
      }),
    });
    const { status } = response;
    return status;
  } catch (err) {
    logger('info', 'SEND_CODE_FAIL');
    return 500;
  }
};

type VerifyCodeResponse = {
  error?: boolean; // not really but satisfies ts
  access_token: string;
  refresh_token: string;
};

export const verifyCode = async (code: string, email: string): Promise<false | Tokens> => {
  logger('info', 'VALIDATE_CODE');

  const response = await fetch(`${auth0domain}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      client_id: process.env.REACT_APP_AUTHOH_USER_CLIENT_ID,
      realm: 'email',
      grant_type: 'http://auth0.com/oauth/grant-type/passwordless/otp',
      username: email,
      otp: code,
      audience: process.env.REACT_APP_AUDIENCE,
      scope: 'openid offline_access profile email',
    }),
  });

  const json: VerifyCodeResponse = await response.json();

  if (json.error) {
    logger('info', 'VALIDATE_CODE_INVALID');
    return false;
  }

  const { access_token: accessToken, refresh_token: refreshToken } = json;
  return { accessToken, refreshToken };
};

type RefreshTokenResponse = {
  access_token: string;
};

export const refreshToken = async (refreshToken: string): Promise<string> => {
  const response = await fetch(`${auth0domain}/oauth/token`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      client_id: process.env.REACT_APP_AUTHOH_USER_CLIENT_ID,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  });
  const json: RefreshTokenResponse = await response.json();
  return json.access_token;
};

export const sendMessageToTeams = async (cause: string): Promise<any> => {
  try {
    logger('info', 'SEND_TEAMS_MESSAGE');
    const mail = localStorage.getItem("email");
    console.log(mail)
    let msg = cause ==='unauthorized'? `User is not present in DB. \nAPI url : ${process.env.REACT_APP_API_ROUTE}`:`User has not been assigned to a learning journey yet. \nAPI url : ${process.env.REACT_APP_API_ROUTE}`
    const sendMessage = bootstrap();
    const message = `{"text": "<pre>Hello Team,\n${mail} just tried to login and failed. \nReason : ${msg}"}</pre>`;
    const status = sendMessage(`${teamsWebHook}`, message);
    return status;
  } catch (err) {
    logger('info', 'SEND_TEAMS_MESSAGE_FAIL');
    return 500;
  }
};