import React, { FC } from 'react';
import Select from 'react-select';
import * as Styled from './styles';

import Loader from '../Loader';

type Props = {
  id?: string;
  placeholder: string;
  label: string;
  onValueChange: any;
  value?: string;
  items: Array<string>;
  loading?: boolean;
  loadingText?: string;
  emptyLabel?: string;
  disabled?: boolean;
};

const Dropdown: FC<Props> = ({
  id,
  onValueChange,
  loading,
  items,
  value,
  label,
  loadingText,
  emptyLabel,
  placeholder,
  disabled,
}: Props) => {
  const error = !loading && items.length === 0;

  return (
    <Styled.Wrapper>
      <Styled.Label htmlFor="">{label}</Styled.Label>
      <Styled.DropdownWrapper>
        {loading ? (
          <Styled.LoaderWrapper>
            <Loader size="small" color="purple" />
            <p>{loadingText ? loadingText : 'Loading...'}</p>
          </Styled.LoaderWrapper>
        ) : (
          <Styled.SelectorWrapper>
            <Select
              placeholder={error ? emptyLabel || 'Nothing to select' : placeholder}
              onChange={onValueChange}
              options={items}
              value={value}
              isDisabled= {disabled}
            />
          </Styled.SelectorWrapper>
        )}
      </Styled.DropdownWrapper>
    </Styled.Wrapper>
  );
};

export default Dropdown;
