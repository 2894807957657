import React, { FC, ReactElement } from 'react';
import TextWithIcon from '../../components/TextWithIcon';
import styles from './styles.module.scss';

type Props = {
  title?: string | undefined;
  children?: ReactElement | ReactElement[];
};

const CreateHabitWrapper: FC<Props> = ({ children, title }: Props) => {
  return (
    <div className={styles.container}>
      {title && (
        <>
          <TextWithIcon text="Habit" icon="habit" />
          <div className={styles.title}>{title}</div>
        </>
      )}
      {children}
    </div>
  );
};

export default CreateHabitWrapper;
