import React, { FC } from 'react';
import { WidgetText, ImageConatiner, ImageWrapper, Image } from './styles';
import Widget from '../../components/HomepageWidget';
import { Link } from 'react-router-dom';

const ContentLibraryWidget: FC = ({}): JSX.Element => {
  return (
    <Widget title="Content library" iconName="content" RouterLink="/app/content" isComponentView={false}>
      <Link to="/app/content">
        <WidgetText>
          Use this library to easily access all supporting materials featured within each activity of your learning
          journey.
        </WidgetText>
        <ImageConatiner>
          <ImageWrapper>
            <Image src={'/images/homepage/content_library_image.png'} alt="content-library-image" />
          </ImageWrapper>
        </ImageConatiner>
      </Link>
    </Widget>
  );
};

export default ContentLibraryWidget;
