import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import get from 'lodash.get';
import {
  getContent as getContentFromApi,
  getContentById as getContentByIdFromApi,
  getAllContents as getAllContent,
  GetContentResponse,
} from '../utils/api/content';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';
import { MediaCollection, Media } from '../utils/types';

export type ContentState = {
  allContents: Media[];
  journeyId: string;
  collections: Array<MediaCollection>;
};

const initialState: ContentState = { allContents: [], collections: [], journeyId: '' };

const getContent = createAsyncThunk<
  GetContentResponse,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'conent/getContent',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: string) => {
    const { accessToken } = state.auth;
    if (state.content.journeyId === payload) {
      return { journeyId: payload, collections: state.content.collections };
    }
    const content = await getContentFromApi(accessToken, payload);
    return { journeyId: payload, collections: content };
  }),
);

const getAllContents = createAsyncThunk<
  Media[],
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'conent/getAllContents',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const content: Media[] = await getAllContent(accessToken);
    return content || [];
  }),
);

const getContentById = createAsyncThunk<
  Media,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'conent/getContentById',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: string) => {
    const { accessToken } = state.auth;

    if (!payload || !payload.length) {
      return;
    }

    const item: Media = get(state, 'content.collections', [])
      .reduce((accum: Array<Media>, collection: MediaCollection) => {
        accum.push(...collection.items);
        return accum;
      }, [])
      .find((item: Media) => item._id === payload);

    if (item) {
      return item;
    }

    const content = await getContentByIdFromApi(accessToken, payload);
    return content;
  }),
);

const content = createSlice({
  name: 'content',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContent.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.journeyId = payload.journeyId;
      state.collections = payload.collections;
    });
    builder.addCase(getAllContents.fulfilled, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.allContents = payload;
    });
  },
});

export default content.reducer;

export const actions = {
  ...content.actions,
  getContent,
  getAllContents,
  getContentById,
};
