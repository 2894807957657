import React, { FC, PropsWithChildren } from 'react';
import { Divider, Title } from './index.styles';

export const renderFilterModalHeader: FC<{ isFacilitator: boolean, location: any }> = ({
  isFacilitator,
  location,
}: PropsWithChildren<{ isFacilitator: boolean , location:any}>): JSX.Element => { 
  return (
    <>
      <Title>Filter options</Title>

      <Divider />

      {isFacilitator && location.pathname == '/app/home'? (
        <p>
          You can view any insights posted for cohorts that you are facilitating.
          After selecting the organisation, programme and cohort below you will be able to view and filter insights by individual activities.
        </p> 
      ) : (
        <p>Filter the insights wall posts by activity or experiments.</p>
      )}
    </>
  );
};
