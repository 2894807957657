import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useViewport } from '../../../../utils/hooks';
import { ModuleOverlayLayoutContainer, OverlayPointer, PositionalContainer, RelativeContainer } from './styles';
import { ModuleOverlayLayoutProps } from './types';

const ModuleOverlayLayout: FC<ModuleOverlayLayoutProps> = ({
  isOpen,
  onClose,
  moduleId,
  index,
  children,
}: PropsWithChildren<ModuleOverlayLayoutProps>): JSX.Element => {
  const [displayOnTop, setDisplayOnTop] = useState<boolean>(false);
  const [offsetAmountX, setOffsetAmountX] = useState<number>(0);
  const { width } = useViewport();

  // Set positioners that keeps overlay in journey column
  useEffect((): (() => void) => {
    const container: HTMLElement | null = document.querySelector('#flexible_journey_wrapper');
    const module: HTMLElement | null = document.getElementById(`${moduleId}`);

    const parentElement: HTMLElement | null = container;
    const overlay: DOMRect | undefined = document.querySelector(`#overlay-${index}`)?.getBoundingClientRect();

    if (parentElement && overlay) {
      const containerLeftBorder: number = parentElement.offsetLeft;
      const containerRightBorder: number = parentElement.offsetWidth + parentElement.offsetLeft;

      const overlayLeftBorder: number = overlay.left;
      const overlayRightBorder: number = overlay.right;

      if (overlayLeftBorder < containerLeftBorder) {
        setOffsetAmountX(containerLeftBorder - overlayLeftBorder);
      } else if (overlayRightBorder > containerRightBorder) {
        setOffsetAmountX(containerRightBorder - overlayRightBorder);
      }
    }

    if (module && parentElement) {
      const moduleBottomBorder: number = module.offsetTop + module.offsetHeight + 400;
      const containerBottomBorder: number = parentElement?.offsetTop + parentElement?.offsetHeight;
      if (moduleBottomBorder > containerBottomBorder) {
        setDisplayOnTop(true);
      }
    }

    return (): void => {};
  }, []);

  // This will close all other overlays when ones selected
  useEffect((): (() => void) => {
    const leaveWindowChecker: (e: any) => void = (e: any): void => {
      const isOutsideOfOverlay: boolean =
        e.target.id !== `overlay-${index}` || e.target.parentElement?.id !== `overlay-${index}`;
      const isAnOpenableModuleButton: boolean = e.target.id === `${moduleId}` && !isOpen;
      if (isAnOpenableModuleButton) {
        return;
      }
      if (isOutsideOfOverlay) {
        onClose();
      } else {
        return;
      }
    };

    window.addEventListener('click', (e: any): void => leaveWindowChecker(e));
    return (): void => {
      window.removeEventListener('click', (e: any): void => leaveWindowChecker(e));
    };
  }, []);

  return (
    <ModuleOverlayLayoutContainer isOpen={isOpen}>
      <OverlayPointer displayOnTop={displayOnTop} />
      <RelativeContainer>
        <PositionalContainer
          displayOnTop={displayOnTop}
          mobileWidth={width - 42}
          isMobileView={width < 500}
          id={`overlay-${index}`}
          axisXOffset={offsetAmountX}>
          {children}
        </PositionalContainer>
      </RelativeContainer>
    </ModuleOverlayLayoutContainer>
  );
};

export default ModuleOverlayLayout;
