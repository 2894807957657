import { Activity, ActivityContentBlock, Journey, Module } from '../../../../utils/types';

export const getActivities: (journey: any) => Activity[] = (journey: any): Activity[] => {
  const result: Activity[] = [];

  journey.modules.forEach((module: Module): void => {
    module.activities.forEach((activity: Activity): void => {
      result.push(activity);
    });
  });

  return result;
};

export const getExperimentIdsFromContent: (contents: ActivityContentBlock[]) => string[] = (
  contents: ActivityContentBlock[],
): string[] => {
  const result: string[] = [];

  contents.forEach((contentBlock: ActivityContentBlock): void => {
    if (contentBlock.blockType === 'experiment') {
      result.push(contentBlock.text);
    }
  });

  return result;
};

export const getExperimentIdsFromModules: (journey: Journey) => string[] = (journey: Journey): string[] => {
  const result: string[] = [];

  journey.modules.forEach((module: Module): void => {
    module.activities.forEach((activity: Activity): void => {
      const contents: ActivityContentBlock[] = activity.content.body;
      contents.forEach((contentBlock: ActivityContentBlock): void => {
        if (contentBlock.blockType === 'experiment') {
          result.push(contentBlock.text);
        }
      });
    });
  });

  return result;
};
