import React, { ReactElement, useState } from 'react';
import get from 'lodash.get';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { FormattedGoal, FormattedHabit, HabitSuccessPlan } from '../../utils/adapters/goals';

import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

import styles from './styles.module.scss';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader from '../../components/PageHeader';
import TextWithIcon from '../../components/TextWithIcon';

type Props = FormattedHabit & {
  updateHabit: any;
  goal: FormattedGoal;
};

type EditSuccessPlanFormPayload = HabitSuccessPlan;

const HabitEditSuccessPlan = ({ successPlan, id, updateHabit, title, goal }: Props): ReactElement => {
  const history = useHistory();
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const { width: deviceWidth } = useViewport();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      ...successPlan,
    },
  });

  const onSubmit = (successPlan: EditSuccessPlanFormPayload) => {
    (async () => {
      setSending(true);
      try {
        await updateHabit({ id, successPlan, goalId: goal.id });
        history.goBack();
      } catch (err) {
        setSending(false);
      }
    })();
  };

  const onChange = () => {
    if (!hasEdited) setHasEdited(true);
  };

  return (
    <>
      {isDesktop(deviceWidth) && (
        <PageHeader
          title="Edit success plan"
          leftButton={{ iconName: 'chevron-left', ariaLabel: `Back to habit name(${title})` }}
        />
      )}
      <div className={styles.container}>
        <TextWithIcon text="Habit" icon="habit" />

        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <TextArea
              id="trigger"
              name="trigger"
              error={get(errors, 'trigger', null)}
              register={register}
              onChange={onChange}
              placeholder="e.g. whenever I give formal or informal feedback"
              label="What will trigger your new habit?"
            />

            <TextArea
              id="reward"
              name="reward"
              error={get(errors, 'reward', null)}
              register={register}
              onChange={onChange}
              placeholder="e.g. more helpful feedback to my team which will lead to higher performance."
              label="What's your reward?"
            />

            <TextArea
              name="barriers"
              error={get(errors, 'barriers', null)}
              register={register}
              onChange={onChange}
              placeholder="e.g. some people in my team see constructive feedback as a personal attack"
              label="What barriers might get in the way?"
            />

            <TextArea
              id="enablers"
              name="enablers"
              error={get(errors, 'enablers', null)}
              register={register}
              onChange={onChange}
              placeholder="e.g. each time I need to give feedback to someone"
              label="What enablers could help you succeed?"
            />

            <TextArea
              id="shareWith"
              name="shareWith"
              error={get(errors, 'shareWith', null)}
              register={register}
              onChange={onChange}
              placeholder="Sharing with others, such as a trusted colleague, is good for motivation, accountability and reminders."
              label="Who will you share your plan with?"
            />

            <div className={styles.buttonContainer}>
              <Button testId="save" loading={sending} disabled={!hasEdited}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default HabitEditSuccessPlan;
