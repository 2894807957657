import styled from 'styled-components';
import { FilterModalContainerStyleProps } from './types';

export const FilterModalContainer = styled.div<FilterModalContainerStyleProps>`
  align-items: center;
  min-height: 50vh;

  > * {
    width: 100%;
  }
`;
export const LoadingContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;