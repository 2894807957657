import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import AnimatedPage from '../../components/AnimatedPage';
import LoginSplash from '../../containers/LoginSplash';
import Callback from '../../screens/Callback';

import AuthModal from '../AuthModal';
import styles from './styles.module.scss';

const Login: FC = () => {
  return (
    <div className={styles.container}>
      <Switch>
        <Route path="/auth" component={AnimatedPage(AuthModal)} />
        <Route path="/callback" component={AnimatedPage(Callback)} />
        <Route path="/" component={AnimatedPage(LoginSplash)} />
      </Switch>
    </div>
  );
};

export default Login;
