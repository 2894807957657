import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ExperimentReflectionModalProps } from './types';
import { ReflectionModalContainer } from './styles';
import PageHeader from '../PageHeader';
import { HeaderButton } from '../PageHeader/PageHeader';
import { IconName } from '../Icon';
import theme from '../../utils/theme';
import ActivityJournals from '../ActivityJournals';
import { ActivityContentBlock } from '../../utils/types';
import Button from '../Button';
import { JournalEntryPayload, JournalEntryResponse } from '../../redux/experiment.slice';
import { actions as activityActions } from '../../redux/experiment.slice';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import { ModalKey } from '../../utils/modals';
import dayjs from 'dayjs';

const ExperimentReflectionModal: React.FC<ExperimentReflectionModalProps> = ({
  isCompleted,
  activityId,
  activityContent,
  experiment,
  updateUserJournalEntry,
  createUserJournalEntry,
  updateActivityProgress,
  onCancel,
  onCompletion,
  setModalState,
  iconName,
  deadline,
}: PropsWithChildren<ExperimentReflectionModalProps>): JSX.Element => {
  const dispatch = useAppDispatch();
  const cachedEntries: JournalEntryPayload[] = useAppSelector((state) => state.experiment.entryResponses);
  const currentEntryQuestionIds: string[] = useAppSelector(
    (state): string[] => state.experiment.currentEntryQuestionIds,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const displayDeadline: string = deadline ? `| Deadline: ${dayjs(deadline).format('DD/MM/YYYY')}` : '';
  const clearText = (text: string | undefined): string | undefined => text?.replace(/<\/?[^>]+(>|$)/g, '');

  const responseChecker = (responses: JournalEntryPayload[], questionIds: string[]): boolean => {
    if (responses.length !== questionIds.length) return false;

    const hasClearedResponse: boolean[] = responses.map((reponse: JournalEntryPayload): boolean => {
      const result: string | undefined = clearText(reponse.response);
      if (result) return result.length > 0;
      return false;
    });

    return hasClearedResponse.includes(false) ? false : true;
  };

  const isDisabled = !responseChecker(cachedEntries, currentEntryQuestionIds);

  useEffect(() => {
    return () => {
      dispatch(activityActions.setJournalEntries({}));
      dispatch(activityActions.resetCachedJournalEntries());
    };
  }, []);

  if (!experiment) return <></>;

  const saveJournalEntries: () => void = (): void => {
    setIsLoading(true);
    cachedEntries.map((entry: JournalEntryResponse) => {
      if (!entry.journalEntryId) {
        dispatch(createUserJournalEntry(entry));
      } else {
        dispatch(updateUserJournalEntry(entry));
      }
    });
    (async () => {
      await updateActivityProgress({
        activityId: activityId,
        completed: true,
        experimentId: experiment.id,
        isExperimentCompleted: true,
      });
    })();
    setIsLoading(false);
    setModalState({ key: ModalKey.EXPERIMENTS_EXPERIMENTCONTENT_REFLECTION_CONGRATULATIONSMODAL, isOpen: true });
    onCompletion({ variant: 'experiment', show: true, iconName: (iconName as IconName) || 'rosette-module' });
    onCancel();
  };

  const rightButton: HeaderButton = {
    iconName: isCompleted ? 'check' : ('in-progress' as IconName),
    background: theme.colours.secondary,
    ariaLabel: `Experiment - Incomplete`,
  };

  const { body } = activityContent;
  const JournalCaptureCMSBlock: ActivityContentBlock = body.filter(
    (cms: ActivityContentBlock) => cms.blockType == 'journalCapture',
  )[0];

  return (
    <ReflectionModalContainer>
      <PageHeader
        title={experiment.title}
        subTitle={`Experiment ${displayDeadline}`}
        rightButton={rightButton}
        type="activity"
      />
      <p style={{ marginBottom: '32px' }}>
        <strong>Take some time to reflect on this experiment.</strong> Journal reflections are a record of your thoughts
        and not shared publicly with others.
      </p>
      <ActivityJournals block={JournalCaptureCMSBlock} isExperiment={true} activityId={activityId} />

      {!isCompleted && (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Button variant="outline" onClick={() => onCancel()}>
            Cancel
          </Button>
          <Button disabled={isDisabled} loading={isLoading} onClick={() => saveJournalEntries()}>
            Save
          </Button>
        </div>
      )}
    </ReflectionModalContainer>
  );
};

export default ExperimentReflectionModal;
