import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';
import { detect } from 'detect-browser';

// info
const device: any = {};
const session: any = {
  userId: '',
};
let logs: Array<any> = [];

// send log stream to server
const updateServerHandler = () => {
  const logsToSend = [...logs];
  logs = [];
  fetch(`${process.env.REACT_APP_API_ROUTE}/.dc/collector/batch`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logsToSend),
  }).catch((e) => {
    // put em back in the queue
    logsToSend.forEach((log) => logs.push(log));
  });
};

window.onbeforeunload = () => {
  updateServerHandler();
};

// update server every 10 seconds
const updateServer = debounce(updateServerHandler, 5000, { maxWait: 5000 });

// called when we know about a users Id
export const setSessionLogin = (userId: string) => (session.userId = userId);

// log a message
const log = (level: 'info' | 'warn' | 'error', eventName: string, payload: any = {}) => {
  logs.push({
    eventDate: new Date(),
    eventName,
    payload: { level, userId: session.userId || '', ...payload },
    session: { ...session },
    sourceName: 'strata2',
    sourceType: 'web',
  });
  updateServer();
};

// start a session
export const startSession = () => {
  session.id = uuid();
  session.startTime = new Date();
  log('info', 'SESSION_START', { ...device });
};

// generate device info
(async () => {
  const browser = detect();
  if (browser) {
    device.browser = browser.name;
    device.version = browser.version;
    device.os = browser.os;
  }
  device.deviceID = localStorage.getItem('did') || uuid();
  localStorage.setItem('did', device.deviceID);
  startSession();
})();

export default log;
