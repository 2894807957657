import { IconName } from '../../Icon';

export const getIcon: (field: string) => string = (field: string): IconName => {
  switch (field) {
    case 'modules':
      return 'module_progress';

    case 'activities':
      return 'activity_progress';

    case 'experiments':
      return 'experiment_progress';

    default:
      return 'default';
  }
};

export const journeyComplete: (moduleStats: { all: number; completed: number }) => number = (moduleStats: {
  all: number;
  completed: number;
}): number => {
  if (!moduleStats) return 0;
  return Math.ceil((moduleStats.completed / moduleStats.all) * 100) || 0;
};
