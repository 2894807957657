import styled from 'styled-components';

export const ViewInsightsWall = styled.button`
  border: none;
  background-color: transparent;
  background: ${(p) => p.theme.colours.white};
  border-radius: 5px;
  margin-bottom: 3px;
  color: ${(p) => p.theme.colours.primary};
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  text-decoration: underline;
  margin: auto;
  margin-top: ${(p) => p.theme.spacing(3)};
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;
