import { connect } from 'react-redux';

import { actions as userActions } from '../../redux/user.slice';
import { actions as cohortActions } from '../../redux/cohorts.slice';
import { actions as journeyActions } from '../../redux/journey.slice';
import { actions as experimentActions } from '../../redux/experiment.slice';
import { actions as assessmentActions } from '../../redux/assessments.slice';
import { actions as notificationsActions } from '../../redux/notification.slice';
import { actions as contentActions } from '../../redux/content.slice';
import { actions as modalActions } from '../../redux/modals.slice';
import { actions as programmesActions } from '../../redux/programmes.slice';
import { actions as organisationsActions } from '../../redux/organisations.slice';

import App from './App';
const { setModalState } = modalActions;
const { getAllContents } = contentActions;
const { getUser } = userActions;
const { getCohorts, getCohortMeta, getCohortUserRecords, getCohortsOfFacilitator, setSelectedFacCohort } = cohortActions;
const { getJourney, getJourneyProgress, restoreJourneyPreviewFromStorage } = journeyActions;
const { getExperiments, getExperimentInsights, getUserRecords } = experimentActions;
// const { getAssessments, getRequests } = assessmentActions;
// const { getRequests } = assessmentActions;
// const { getAssessmentNotifications, getInsightNotifications } = notificationsActions;
const { getInsightNotifications } = notificationsActions;
const { getAllProgrammes } = programmesActions;
const { getOrganisations } = organisationsActions;

export default connect(null, {
  getUser,
  getCohorts,
  getJourney,
  getAllContents,
  getJourneyProgress,
  getExperiments,
  getCohortMeta,
  getCohortUserRecords,
  getExperimentInsights,
  // getAssessments,
  // getRequests,
  // getAssessmentNotifications,
  getInsightNotifications,
  setModalState,
  restoreJourneyPreviewFromStorage,
  getCohortsOfFacilitator,
  getAllProgrammes,
  getUserRecords,
  getOrganisations,
  setSelectedFacCohort,
})(App);
