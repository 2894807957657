import React, { FC, HTMLAttributes } from 'react';
import { Color } from '../../utils/types/theme';
import * as Styled from './styles';

type TypograhyElement = HTMLAttributes<HTMLHeadingElement | HTMLParagraphElement | HTMLSpanElement>;

export interface Props extends TypograhyElement {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  color?: Color;
  mb?: number;
  mt?: number;
}

const Typography: FC<Props> = ({ level = 'p', children, ...restProps }) => {
  return (
    <Styled.Typography as={level} {...restProps}>
      {children}
    </Styled.Typography>
  );
};

export default Typography;
