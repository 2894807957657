import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions as journeyActions } from '../../redux/journey.slice';

import JourneyPreviewReset from './JourneyPreviewReset';

const { clearPreview } = journeyActions;

const mapState = (state: RootState) => ({
  isPreview: get(state, 'journey.isPreview', false),
});

export default connect(mapState, { clearPreview })(JourneyPreviewReset);
