import React, { FC } from 'react';
import { ExperimentLink, CardContainer, CardHeader, CardBody, CardText, CardTitle, CardType, CardIcon } from './styles';
import Icon from '../Icon';
import dayjs from 'dayjs';
import { ExperimentActivityCardProps } from './types';
import theme from '../../utils/theme';
import { IconName } from '../Icon';

const ExperimentActivityCard: FC<ExperimentActivityCardProps> = ({
  id,
  moduleId,
  moduleName,
  experiment,
  completed,
  isStarted = false,
  deadline,
  description,
  title,
  imageUrl,
  moduleBackground,
  currentActivityId,
  storyView,
  isPreview = false,
}: ExperimentActivityCardProps) => {
  const isWithExperiment: boolean = experiment !== undefined && experiment.id !== undefined;
  const inProgress: boolean = experiment?.id !== undefined && !completed;
  const isWithDescription: boolean = !!description && !completed;
  const icon: string | undefined = inProgress ? 'in-progress' : imageUrl;
  const iconBackgroundColor: string = inProgress ? theme.colours.secondary : moduleBackground;
  const displayDeadline: string = !!deadline
    ? `Experiment | Deadline: ${dayjs(deadline).format('DD/MM/YYYY')}`
    : 'Experiment';

  const getDescription = (isWithDescription: boolean, description: string) => {
    return isWithDescription ? (
      <CardBody>
        <CardText>
          {isStarted && <strong>{experiment?.title}</strong>}
          {!isStarted && description}
        </CardText>
        {isStarted && (
          <CardText>
            {experiment?.type == 'reflection'
              ? '\n Complete this activity by adding your reflections'
              : '\n Complete this activity by sharing your insights'}
          </CardText>
        )}
      </CardBody>
    ) : null;
  };

  if (storyView) {
    return (
      <CardContainer isExperiment={isWithExperiment} isComplete={completed} selected={id === currentActivityId}>
        <CardHeader description={isWithDescription}>
          <CardType>{`Experiment | Deadline: ${dayjs(new Date()).format('DD/MM/YYYY')}`.toUpperCase()}</CardType>
          <CardTitle isComplete={completed}>{title}</CardTitle>
          {getDescription(isWithDescription, description)}
          <CardIcon color={completed ? theme.colours.secondary : iconBackgroundColor}>
            <Icon name={completed ? 'check' : (icon as IconName)} type="activity" color="white" size="medium" />
          </CardIcon>
        </CardHeader>
      </CardContainer>
    );
  }

  if (isPreview) {
    return (
      <CardContainer isExperiment={isWithExperiment} isComplete={completed} selected={id === currentActivityId}>
        <CardHeader description={isWithDescription}>
          <CardType>{displayDeadline.toUpperCase()}</CardType>
          <CardTitle isComplete={completed}>{title}</CardTitle>
          {getDescription(isWithDescription, description)}
        </CardHeader>
      </CardContainer>
    );
  }

  return (
    <ExperimentLink
      aria-label={`module ${moduleName}, activity(${completed ? 'completed' : 'incomplete'}) ${title}`}
      to={`/app/activity/${id}?moduleId=${moduleId}`}
      aria-current={id === currentActivityId}>
      <CardContainer isExperiment={isWithExperiment} isComplete={completed} selected={id === currentActivityId}>
        <CardHeader description={isWithDescription}>
          <CardType>{displayDeadline.toUpperCase()}</CardType>
          <CardTitle isComplete={completed}>{title}</CardTitle>
          {getDescription(isWithDescription, description)}
          <CardIcon color={completed ? theme.colours.secondary : iconBackgroundColor}>
            <Icon name={completed ? 'check' : (icon as IconName)} type="activity" color="white" size="medium" />
          </CardIcon>
        </CardHeader>
      </CardContainer>
    </ExperimentLink>
  );
};

export default ExperimentActivityCard;
