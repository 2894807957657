import styled, { css } from 'styled-components';
import Typography from '../Typography/Typography';
import { IconButton } from '../IconButton/styles';
import theme from '../../utils/theme';

interface PageTitleProps {
  isActivityPageTitle: boolean;
}

const handleType = (type?: 'main' | 'activity' | 'home') => {
  if (type === 'activity') {
    return css`
      align-items: center;
      justify-content: space-between;

      ${HeaderButton} {
        position: relative;
      }

      ${RightHeaderButton} {
        align-items: center;
        justify-content: center;

        width: 40px;
        height: 40px;

        svg {
          fill: ${theme.colours.white};
        }
      }

      ${Title} {
        text-align: left;
        padding: 0;
      }
    `;
  }

  if (type === 'home') {
    return css`
      padding-top: ${(p) => p.theme.spacing(4)};
      &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 300px;
        background: linear-gradient(rgba(236, 236, 236, 0.6), white);
        border-radius: ${(p) => p.theme.borders.radius} ${(p) => p.theme.borders.radius} 0 0;
      }
    `;
  }
};

export const PageHeader = styled.div<{ type?: 'main' | 'activity' | 'home' }>`
  width: 100%;
  position: relative;
  margin-top: 0px;
  flex-direction: row;
  min-height: ${({ theme }) => theme.icon.sizes.small.icon};

  ${({ type }) => handleType(type)};
`;

export const HeaderButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
`;

export const LeftHeaderButton = styled(HeaderButton)<{ isBackButton?: boolean }>`
  left: 0px;

  ${({ isBackButton }) =>
    isBackButton &&
    css`
      color: ${theme.typography.font.color};
      flex-direction: row;
      width: 78px;
      justify-content: flex-start;

      span {
        flex: 1;
      }
    `};
`;

export const RightHeaderButton = styled(HeaderButton)<{ isHomeHeader?: boolean }>`
  right: ${(p) => (p.isHomeHeader ? p.theme.spacing(3) : p.theme.spacing(0))};
`;

export const Meta = styled.div`
  padding-right: 40px;
`;

export const PageTitle = styled.h1<PageTitleProps>`
  width: 100%;
  text-align: ${(p) => (p.isActivityPageTitle ? 'start' : 'center')};
  font-size: ${theme.typography.font.size.large};
  color: ${(p) => p.theme.colours.darkGrey};
  z-index: 1;
  padding: 0px;

  @media (min-width: ${theme.devices.tablet}px) {
    max-width: 100%;
  }
`;

export const Title = styled(Typography)`
  width: 100%;
  text-align: center;

  padding: 0px ${theme.spacing(4)};

  @media (min-width: ${theme.devices.tablet}px) {
    padding: 0px 78px;
    max-width: 610px;
  }
`;

export const SubTitle = styled(Typography)`
  font-weight: normal;
  text-transform: uppercase;
  font-size: ${theme.typography.font.size.xSmall};
  color: ${theme.colours.primary};
`;

export const ActivityIcon = styled(IconButton).attrs({ as: 'div' })`
  position: absolute;
  right: 0px;
`;
