import React, { FC } from 'react';
import classnames from 'classnames';
import { getLongestStreak } from '../../utils/adapters/goals';

import styles from './styles.module.scss';
import Icon from '../../components/Icon';

type Props = {
  habitPracticeDates: Array<any>;
};

const HabitSuccessPlan: FC<Props> = ({ habitPracticeDates }: Props) => {
  const longStreak = getLongestStreak(habitPracticeDates) + 1;
  const daysToComplete =
    habitPracticeDates[habitPracticeDates.length - 1].date.diff(habitPracticeDates[0].date, 'd') + 1;

  return (
    <div className={classnames(styles.container)}>
      <div className={styles.badge}>
        <Icon name="rosette-habit" size="large" />
        <div className={styles.title}>Completed</div>
        <div className={styles.date}>
          {habitPracticeDates[habitPracticeDates.length - 1].date.format('ddd - DD MMM - YYYY')}
        </div>
      </div>
      <strong>Practice statistics</strong>
      <div className={styles.infoWrapper}>
        <div className={styles.infoCard}>
          <span>Total repetitions</span> <strong>{habitPracticeDates.length}</strong>
        </div>
        <div className={styles.infoCard}>
          <span>Days to complete</span> <strong>{`${daysToComplete} day${daysToComplete > 1 ? 's' : ''}`}</strong>
        </div>
        <div className={styles.infoCard}>
          <span>Best practice streak</span> <strong>{`${longStreak} day${longStreak > 1 ? 's' : ''}`}</strong>
        </div>
      </div>
    </div>
  );
};

export default HabitSuccessPlan;
