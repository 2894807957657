import React, { FC } from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';
import Icon from '../Icon';
import IconButton from '../IconButton';

type Props = {
  step: number;
};

const WizardHeader: FC<Props> = ({ step }: Props) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.breadcrumbs}>
        <div className={styles.track}>
          <div className={styles.trackLine} />
          {[0, 1, 2].map((number) => {
            const isActive = step === number;
            return (
              <div key={number} className={styles.trackItemWrapper}>
                <span className={classnames(styles.trackItem, { [styles.trackItemActive]: isActive })}>
                  {number + 1}
                </span>
              </div>
            );
          })}
        </div>
        <div className={styles.titles}>
          <span>Habit</span>
          <span>Target</span>
          <span>Plan</span>
        </div>
      </div>
      <button className={styles.quit} onClick={() => history.goBack()}>
        <Icon name="close" />
        <span>Quit habit setup</span>
      </button>
    </div>
  );
};

export default WizardHeader;
