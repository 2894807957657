import { configureStore } from '@reduxjs/toolkit';

import auth from './auth.slice';
import user from './user.slice';
import cohorts from './cohorts.slice';
import content from './content.slice';
import goals from './goals.slice';
import journey from './journey.slice';
import experiment from './experiment.slice';
import userJournal from './userJournal.slice';
import programmes from './programmes.slice';
import app from './app.slice';
import modals from './modals.slice';
// import assessments from './assessments.slice';
import notifications from './notification.slice';
import organisations from './organisations.slice';

const store = configureStore({
  reducer: {
    auth,
    user,
    content,
    cohorts,
    goals,
    journey,
    experiment,
    userJournal,
    programmes,
    app,
    modals,
    notifications,
    organisations
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
