import styled from 'styled-components';
import { ExperimentCTAContainerStyleProps, TitleStyleProps } from './types';

export const ExperimentCTAContainer = styled.div<ExperimentCTAContainerStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(p) => (p.isPreview ? p.theme.colours.lightGrey : 'rgba(81, 26, 188, 0.05)')};
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(3)} ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(3)}
    ${(p) => p.theme.spacing(2)};

  > :nth-child(2) {
    margin-top: ${(p) => p.theme.spacing(1)};
    margin-bottom: ${(p) => p.theme.spacing(3)};
  }

  > :nth-child(3) {
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

export const Title = styled.h2<TitleStyleProps>`
  color: ${(p) => (p.isPreview ? p.theme.colours.darkGrey : p.theme.colours.primary)};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  line-height: 18px;
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
`;

export const Description = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  line-height: 18px;
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
`;

export const ParticipantCount = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  line-height: 18px;
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
`;
