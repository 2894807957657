import React, { ReactElement, useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import PageHeader from '../../components/PageHeader';
import ContentCarousel from '../../containers/ContentCarousel';
import OnboardingCarousel from '../../containers/OnboardingCarousel/OnboardingCarousel';
import onboarding from '../../containers/OnboardingCarousel/OnboardingCarousel.content';
import { hasSeenModal, setSeenModal } from '../../utils/checkModalLocalStorage';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { flowRight } from 'lodash';

import { Media, MediaCollection } from '../../utils/types';

import styles from './styles.module.scss';

type Props = {
  collections: Array<MediaCollection>;
};

const Content = ({ collections }: Props): ReactElement => {
  const [modalOpen, setModalOpen] = useState<boolean>(!hasSeenModal('content') || false);
  const [previousFocusedElement, setPreviousFocusedElement] = useState<HTMLElement | null>(null);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const orderedCollection = (collections: MediaCollection[]) => {
    const orderMediaItems = flowRight([sortBy((x: Media) => x.title)]);

    const orderMediaCollection = flowRight([
      sortBy((x: MediaCollection) => x.title),
      map((x: MediaCollection) => ({
        ...x,
        items: orderMediaItems(x.items),
      })),
    ]);

    return orderMediaCollection(collections);
  };

  useEffect(() => {
    if (modalOpen) {
      setSeenModal('content');
    }
  }, []);

  return (
    <>
      <PageHeader
        title="Content Library"
        rightButton={{ iconName: 'info', onClick: openModal, ariaLabel: 'Open content help modal' }}
        setPreviousFocusedElement={setPreviousFocusedElement}
      />

      <p className={styles.body}>
        This collection of topic based tools is designed to support your development as you progress through the
        programme. There are simple tips and techniques that you can put into practice straight away.
      </p>
      {collections.length === 0 && (
        <p>
          <br />
          There is no content associated with this journey.
        </p>
      )}
      {orderedCollection(collections).map((collection: MediaCollection, index: number) => (
        <ContentCarousel key={index} carouselIndex={index} {...collection} />
      ))}

      <Modal isOpen={modalOpen} onClose={closeModal} previousFocusedElement={previousFocusedElement}>
        <OnboardingCarousel items={onboarding.content} closeOnboardingModal={closeModal} />
      </Modal>
    </>
  );
};

export default Content;
