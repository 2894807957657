import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ExperimentContentProps } from './types';
import Tile from '../Tile';
import ExperimentCallToAction from '../ExperimentCallToAction';
import ExperimentHints from '../ExperimentHints';
import ContentCarousel from '../../containers/ContentCarousel';
import {
  ExperimentContentContainer,
  ExperimentTilesContainer,
  ExperimentInstructions,
  SectionHeader,
  SectionTitle,
} from './styles';
import parse from 'html-react-parser';
import MarkDown from 'react-markdown';
import { ActivityContent, Cohort, Media, MediaCollection, UserRecord } from '../../utils/types';
import dayjs from 'dayjs';
import Modal from '../../components/Modal';
import ExperimentReflectionModal from '../ExperimentReflectionModal';
import OtherParticipantsModal from '../OtherParticipantsModal';
import ExperimentQuitModal from '../ExperimentQuitModal';
import theme from '../../utils/theme';
import Loader from '../Loader';
import CongratulationsModal from '../CongratulationsModal';
import { IconName } from '../Icon';
import PageHeader from '../PageHeader';
import { HeaderButton } from '../PageHeader/PageHeader';
import { Link, useHistory } from 'react-router-dom';
import ExperimentInsightModal from '../ExperimentInsightModal';
import { ExperimentType } from '../../utils/types';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { flowRight } from 'lodash';
import { useAppSelector, useViewport } from '../../utils/hooks';
import { actions } from '../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { FilterDetails } from '../InsightWallFilter/types';
import { RootState } from '../../redux';
import { UserState } from '../../redux/user.slice';
import { getOrganization, getProgramme, insightsWallFilterPreset } from './helpers';
import FilterModal from '../InsightWallFilter/components/FilterModal';

const { insightNotificationsDismissAll, setVisitedInsightsPage } = actions;

type CongratulationsPopupType = {
  variant: 'module' | 'journey' | 'experiment';
  show: boolean;
  iconName?: IconName;
};

const ExperimentContent: React.FC<ExperimentContentProps> = ({
  isCompleted = false,
  activityId,
  activityTitle,
  activityContent,
  experiment,
  experimentType = 'reflection',
  cohortExperimentInfo,
  articles,
  onExperimentQuit,
  loading,
  iconName,
  isPreview = false,
  activityStartDate,
  tokens,
}: PropsWithChildren<ExperimentContentProps>): JSX.Element => {
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const endDate: Date | undefined = cohortExperimentInfo && cohortExperimentInfo.endDate;
  const { id: userId, organizationId, roles } = useAppSelector((state: RootState): UserState => state.user);

  const {
    cohortId,
    title: cohortTitle,
    learningJourneyId,
  } = useAppSelector((state: RootState): Cohort => state.cohorts.selectedCohort);
  const { currentModal } = useAppSelector((state) => state.modals);
  const previewMode = useAppSelector((state) => state.journey.isPreview);
  const [org, setOrg] = useState<{ id: string; name: string } | null>(null);
  const [programme, setProgramme] = useState<{
    id: string | undefined;
    title: string | undefined;
    journeys: string[];
  } | null>(null);
  const [isFacilitator, setIsFacilitator] = useState<boolean>(false);
  const records: any = useAppSelector((state) =>
    state.cohorts.cohortUserRecords.filter((userRecord: UserRecord) => userRecord.experimentId === experiment?.id),
  );
  const [showInsightsWallFilterModal, setShowInsightsWallFilterModal] = useState<boolean>(false);
  const filteredRecords = records.filter((userRecord: UserRecord) => userRecord.activityId === activityId);
  const userRecordsLength = filteredRecords.filter(
    (userRecord: UserRecord): boolean => userRecord.activityId === activityId && userRecord.userId !== userId,
  ).length;
  const completedRecordsLength = filteredRecords.filter((userRecord: UserRecord) => !!userRecord.completedDate).length;
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [otherParticipantsModalIsOpen, setOtherParticipantsModalIsOpen] = useState<boolean>(false);
  const [quitModalIsOpen, setQuitModalOpen] = useState<boolean>(false);
  const [insightModalIsOpen, setInsightModalIsOpen] = useState<boolean>(false);
  const { width: deviceWidth } = useViewport();
  const [popup, setPopup] = useState<CongratulationsPopupType>({
    show: false,
    variant: 'experiment',
    iconName: iconName as IconName,
  });

  useEffect((): (() => void) => {
    window.scrollTo(0, 0);
    return (): void => {};
  }, []);

  useEffect((): (() => void) => {
    (async () => {
      const org = await getOrganization(tokens, organizationId);
      const programme = await getProgramme(tokens, organizationId, learningJourneyId);

      if (org) {
        setOrg(org);
      }

      if (programme) {
        setProgramme(programme);
      }
    })();

    return (): void => {};
  }, []);

  useEffect((): (() => void) => {
    setIsFacilitator(roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures'))
    return (): void => {};
  }, [roles]);

  useEffect(() => {
    if (
      currentModal?.key == 'EXPERIMENTS_EXPERIMENTCONTENT_INSIGHT_CONGRATULATIONSMODAL' ||
      currentModal?.key == 'EXPERIMENTS_EXPERIMENTCONTENT_REFLECTION_CONGRATULATIONSMODAL'
    ) {
      setPopup({
        ...popup,
        show: true,
      });
    } else {
      setPopup({
        ...popup,
        show: false,
      });
    }
    return () => {};
  }, [currentModal]);

  const toggleReflectionModal: () => void = async () => {
    setModalIsOpen(!modalIsOpen);
  };

  const toggleInsightModal: () => void = async () => {
    setInsightModalIsOpen(!insightModalIsOpen);
  };

  const orderedCollection = (collections: MediaCollection[]) => {
    const orderMediaItems = flowRight([sortBy((x: Media) => x.title)]);

    const orderMediaCollection = flowRight([
      sortBy((x: MediaCollection) => x.title),
      map((x: MediaCollection) => ({
        ...x,
        items: orderMediaItems(x.items),
      })),
    ]);

    return orderMediaCollection(collections);
  };

  const congratulationsModalActions: (experimentType: ExperimentType, experimentId: string) => (() => void) | null = (
    experimentType: ExperimentType,
    experimentId: string,
  ): (() => void) | null => {
    if (experimentType == 'insight') {
      return () => (isFacilitator) ? history.replace(`/app/insightswall?cohortId=${cohortId}&experimentId=${experimentId}`) : history.replace(`/app/insightswall?experimentId=${experimentId}`);
    } else {
      return null;
    }
  };

  const renderContentCarousel = (articles: MediaCollection[]): JSX.Element | null => {
    if (articles[0]?.items && articles[0]?.items?.length > 0) {
      return orderedCollection(articles).map((collection: MediaCollection, index: number) => (
        <ContentCarousel isExperimentCarousel={true} key={index} {...collection} />
      ));
    }
    return null;
  };

  const renderContentCarouselMemo = useMemo((): JSX.Element | null => renderContentCarousel(articles), [articles]);

  if (!experiment) return <></>;

  const quitBtn = (isCompleted: boolean) => {
    if (isCompleted) return;

    return (
      <button
        style={{
          border: 'none',
          background: 'transparent',
          color: theme.colours.primary,
          textDecoration: 'underline',
          cursor: 'pointer',
          fontSize: theme.typography.font.size.regular,
        }}
        onClick={() => !isPreview && setQuitModalOpen(true)}>
        Quit
      </button>
    );
  };

  const loader: JSX.Element = <Loader size="small" color="purple" />;
  const rightPageHeaderButton: HeaderButton = {
    iconName: 'in-progress',
    background: theme.colours.secondary,
    ariaLabel: `activity ${experiment.title}(${isCompleted ? 'completed' : 'incomplete'})`,
  };
  const displayPageHeader: string = endDate
    ? `Experiment | Deadline: ${dayjs(endDate).format('DD/MM/YYYY')}`
    : 'Experiment';

  const displayStartDate = (startDate: string | undefined) =>
    parse(`<span><strong>Start Date: </strong>${dayjs(startDate ?? new Date()).format('DD/MM/YYYY')}</span>`);

  const displayDaysRemaining = (endDate: Date | undefined) => {
    if (!endDate) return '';
    const daysRemaining: number = dayjs(endDate).diff(dayjs(new Date()), 'day');
    return parse(`<span><strong>${daysRemaining > 0 ? daysRemaining : 0}</strong> days remaining</span>`);
  };
  const displayInstructions = (value: string) => <MarkDown className={'markdown'} source={value} />;
  const callToActionAction = (experimentType: ExperimentType) =>
    experimentType === 'insight' ? toggleInsightModal() : toggleReflectionModal();
  return (
    <>
      {isPreview && (
        <PageHeader
          title={experiment.title}
          subTitle={displayPageHeader}
          rightButton={rightPageHeaderButton}
          type="activity"
        />
      )}

      <ExperimentContentContainer isDesktop={deviceWidth > theme.devices.tablet}>
        {isPreview ? (
          <div
            style={{
              width: '100%',
              backgroundColor: theme.colours.lightGrey,
              padding: theme.spacing(2),
              textAlign: 'center',
              borderRadius: theme.borders.radius,
            }}>
            <p>
              This experiment overview area will be automatically created when you add an experiment activity to a
              learning journey.
            </p>
          </div>
        ) : (
          <ExperimentTilesContainer isMobile={deviceWidth <= theme.devices.mobile}>
            <Tile iconName="calendar" label={displayStartDate(activityStartDate)} />
            {endDate && <Tile iconName="hourglass" label={displayDaysRemaining(endDate)} />}
            <Tile
              isSelectable={!previewMode && userRecordsLength > 0}
              iconName="user"
              onClick={() => !previewMode && userRecordsLength > 0 && setOtherParticipantsModalIsOpen(true)}
              label="Other Participants"
            />
            {experimentType === 'insight' && !previewMode && (
              <Link
                to={`/app/insightswall?cohortId=${cohortId}&origin=activity`}
                onClick={(): void => {
                  insightsWallFilterPreset(
                    org,
                    programme,
                    cohortId,
                    learningJourneyId,
                    activityId,
                    experiment.id,
                    cohortTitle,
                    activityTitle,
                    experiment.title,
                    isFacilitator,
                  );
                  dispatch(insightNotificationsDismissAll(null));
                  dispatch(setVisitedInsightsPage());
                  localStorage.setItem("previousPage",window.location.pathname)
                }}>
                <Tile isSelectable={true} iconName="experiment-info" label="Insights wall" />
              </Link>
            )}
            {experimentType === 'insight' && previewMode && (
              <Tile
                isSelectable={true}
                iconName="experiment-info"
                onClick={() => {setShowInsightsWallFilterModal(true);localStorage.setItem("previousPage",window.location.pathname)}}
                label="Insights wall"
              />
            )}
          </ExperimentTilesContainer>
        )}
        <div>
          <SectionHeader>
            <SectionTitle>What to do</SectionTitle>
            {loading ? loader : quitBtn(isCompleted)}
          </SectionHeader>
          <ExperimentInstructions>{displayInstructions(experiment.body.instructions)}</ExperimentInstructions>
        </div>
        <ExperimentCallToAction 
          type={experimentType}
          experimentId={experiment.id}
          activityId={activityId}
          numberOfTimesCompleted={completedRecordsLength}
          onClick={() => callToActionAction(experimentType)}
          isCompleted={isCompleted}
          isPreview={isPreview}
        />
        {experiment.body.hints && experiment.body.hints.length && (
          <div>
            <SectionTitle>Useful hints</SectionTitle>
            <ExperimentHints content={experiment.body.hints} />
          </div>
        )}

        {renderContentCarouselMemo}
      </ExperimentContentContainer>

      <Modal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <ExperimentReflectionModal
          deadline={endDate}
          isCompleted={isCompleted}
          activityId={activityId}
          activityContent={activityContent || ({} as ActivityContent)}
          experiment={experiment}
          onCancel={() => setModalIsOpen(false)}
          onCompletion={setPopup}
          iconName={iconName}
        />
      </Modal>

      <Modal isOpen={insightModalIsOpen} onClose={() => setInsightModalIsOpen(false)}>
        <ExperimentInsightModal
          deadline={endDate}
          isCompleted={isCompleted}
          activityId={activityId}
          activityContent={activityContent || ({} as ActivityContent)}
          experiment={experiment}
          onCancel={() => setInsightModalIsOpen(false)}
          onCompletion={setPopup}
          iconName={iconName}
        />
      </Modal>

      <Modal isOpen={otherParticipantsModalIsOpen} onClose={() => setOtherParticipantsModalIsOpen(false)}>
        <OtherParticipantsModal
          experimentId={experiment.id}
          activityId={activityId}
          onClose={() => setOtherParticipantsModalIsOpen(false)}
        />
      </Modal>

      <Modal isOpen={quitModalIsOpen} onClose={() => setQuitModalOpen(false)} width="small">
        <ExperimentQuitModal onCancel={() => setQuitModalOpen(false)} onQuit={onExperimentQuit} />
      </Modal>

      <Modal
        isOpen={popup.show}
        width="small"
        onClose={() => {
          setPopup({ ...popup, show: false });
        }}
        showConfetti={true}>
        <CongratulationsModal
          variant={popup.variant}
          buttonAction={congratulationsModalActions(experimentType, experiment.id)}
        />
      </Modal>

      <Modal isOpen={showInsightsWallFilterModal} onClose={() => setShowInsightsWallFilterModal(false)}>
        <FilterModal
          isOpen={showInsightsWallFilterModal}
          filter={(filters: FilterDetails): void => localStorage.setItem('insightFilters', JSON.stringify(filters))}
          onClose={(): void => {
            setShowInsightsWallFilterModal(false);
            history.push('/app/insightswall');
          }}
          isFacilitator={isFacilitator}
        />
      </Modal>
    </>
  );
};

export default ExperimentContent;
