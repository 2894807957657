import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { Programme } from '../utils/types';
import { getProgrammes as getProgrammesFromApi } from '../utils/api/journey';
import { getAllProgrammes as getAllProgrammesFromApi } from '../utils/api/journey';

interface initialState {
  programmes: Programme[] | [];
}

const initialState: initialState = {
  programmes: [],
};

export const getProgrammes = createAsyncThunk('journey/getProgrammes', async (_, thunkAPI) => {
  const { user } = thunkAPI.getState() as RootState;
  const data = await getProgrammesFromApi(user.organizationId);

  return data;
});

export const getAllProgrammes = createAsyncThunk('journey/getAllProgrammes', async (orgIds:string[], thunkAPI) => {  
  const { auth } = thunkAPI.getState() as RootState;
  const data = await getAllProgrammesFromApi(auth.accessToken, orgIds);
  return data;
});

const programmes = createSlice({
  name: 'programmes',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getProgrammes.fulfilled, (state, { payload }) => {
      state.programmes = payload;
    });
    builder.addCase(getAllProgrammes.fulfilled, (state, { payload }) => {
      state.programmes = payload;
    });
  },
});

export default programmes.reducer;
export const actions = {
  ...programmes.actions,
  getAllProgrammes,
  getProgrammes,
};
