import * as palette from './palette';
import { createBorder } from './helpers';

const theme = {
  devices: {
    tablet: 991.98,
    mobile: 767.98,
  },

  typography: {
    font: {
      size: {
        xSmall: '12px',
        small: '14px',
        regular: '16px',
        large: '20px',
        Xlarge: '24px',
      },

      weight: {
        regular: 400,
        bold: 700,
      },

      family: ['proxima-nova', 'sans-serif'],

      color: '#485155',
    },

    h1: {},

    h2: {
      fontSize: '20px',
      lineHeight: '23px',
    },

    h3: {
      fontSize: '18px',
      lineHeight: '21px',
    },

    h4: {},
    h5: {},
    h6: {},
  },

  colours: {
    ...palette.all,
  },

  icon: {
    sizes: {
      xxSmall: { background: '24px', icon: '16px' },
      xSmall: { background: '30px', icon: '18px' },
      small: { background: '40px', icon: '24px' },
      medium: { background: '56px', icon: '32px' },
      large: { background: '72px', icon: '48px' },
    },
  },

  borders: {
    radius: '10px',
    createBorder,
  },

  shadows: [' 0px 2px 8px 0px rgba(0, 0, 0, 0.2)'],

  spacing: (value: number) => `${value * 8}px`,
};

export type Theme = typeof theme;

export default theme;
