import React, { FC } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type Props = {
  size?: 'small' | 'medium' | 'large';
  color?: 'white' | 'purple';
};

const Loader: FC<Props> = ({ size, color }: Props) => {
  return (
    <div
      className={classnames(styles.loader, {
        [styles.loaderSmall]: size === 'small',
        [styles.loaderLarge]: size === 'large',
        [styles.loaderPurple]: color === 'purple',
      })}>
      Loading...
    </div>
  );
};

export default Loader;
