import React, { FC, PropsWithChildren, useState, useEffect } from 'react';
import { SwitchJourneyModalContainer, JourneyList } from './styles';
import { SwitchJourneyModalProps } from './types';
import ExperimentModalHeader from '../../../ExperimentModalHeader';
import Modal from '../../../Modal';
import { Modal as ModalType } from '../../../../redux/modals.slice';
import { useAppSelector } from '../../../../utils/hooks';
import { RootState } from '../../../../redux';
import { CohortsState } from '../../../../redux/cohorts.slice';
import { Cohort, Organisation } from '../../../../utils/types';
import ModalListItem from '../../../ModalListItem';
import theme from '../../../../utils/theme';
import Button from '../../../Button';
import { JourneyState } from '../../../../redux/journey.slice';
import styles from './styles.module.scss';

const SwitchFacJourneyModal: FC<SwitchJourneyModalProps> = ({
  resetModalState,
}: PropsWithChildren<SwitchJourneyModalProps>): JSX.Element => {
  const { key, isOpen } = useAppSelector((state: RootState): ModalType => state.modals.currentModal);
  const { cohorts, cohortsOfFacilitator } = useAppSelector((state: RootState): CohortsState => state.cohorts);
  const { progress } = useAppSelector((state: RootState): JourneyState => state.journey);

  const modalIsOpen: boolean = isOpen && key == 'HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL';
  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  const { id } = useAppSelector((state) => state.user);
  const [isActive, setIsActive] = useState(true);
  const [finalList, setFinalList] = useState<Array<any>>([]);
  const { organisations } = useAppSelector((state) => state.organisations);

  const handleClick = () => {
    setIsActive(current => !current);
  };
  useEffect((): (() => void) | undefined => {
    if(selectedCohort.cohortId !== undefined){
      if(selectedCohort.userIds.includes(id))
        setIsActive(false)
    }
    return (): void => {};
  },[]);
  useEffect((): (() => void) | undefined => {
      let finalArr:any = []
      let orgs:string[] = []

      cohortsOfFacilitator.map((item1:Cohort) => {
        const orgPrg = `${item1.organisationId}-${item1.learningJourney.programmeName}`
        if(!orgs.includes(orgPrg)){
          let filteredSameorgs = cohortsOfFacilitator.filter((item2:Cohort, index2:number) => {
            if(orgs.includes(orgPrg))
              return false
            else
              return (item2.organisationId === item1.organisationId 
              && item2.learningJourney.programmeName === item1.learningJourney.programmeName 
              );
          });
          orgs.push(orgPrg);
          if(filteredSameorgs.length){
            let filteredSameorgs2 = filteredSameorgs;
            let lids:string[]=[]
            filteredSameorgs.map((item:Cohort, index:number) => {
              if(lids.includes(item.learningJourneyId))
                filteredSameorgs2.splice(index, 1);
              else
                lids.push(item.learningJourneyId)
            })
            if(filteredSameorgs2.length)
              finalArr.push(filteredSameorgs2)
          }       
               
        }
      });
      setFinalList(finalArr)

      return (): void => {};
    }, []);
   
  return (
    <Modal isOpen={modalIsOpen} width="default" onClose={(): void => resetModalState()}>
      <SwitchJourneyModalContainer>
        <ExperimentModalHeader title="Switch journeys" iconName="journey" />
        <p style={{ margin: `${theme.spacing(2)} 0` }}>Please select which learning journey you wish to switch to.</p>
        <div className={styles.container}>
          <div className={styles.mainRow}>
            <div className={isActive ? styles.selectedBox : styles.custBox} onClick={handleClick}>Journeys you facilitate</div>
            <div className={isActive ? styles.custBox : styles.selectedBox} onClick={handleClick}>Your learning journeys</div>
          </div>
          <div className={styles.grayDevider}></div>
        </div>
        {isActive ?
          <div className={styles.detailsContainer}>
            {cohortsOfFacilitator.length === 0 ?
              <>You are not a facilitator of any journey.</>
            :
            <JourneyList>
              {finalList.map((cohortList: Cohort[], ): JSX.Element => {
                const curOgId = cohortList[0].organisationId;
                const foundOrganisation: any = organisations.find((organisation: Organisation): boolean =>
                  organisation.id === curOgId
                );
                let organisationName = (foundOrganisation) ? foundOrganisation.name : '';
                return(<>
                  {cohortList.map((cohort: Cohort): JSX.Element => {
                    if(cohort.learningJourneyId === selectedCohort.learningJourneyId){
                      return (
                        <>
                        <b style={{ margin: `${theme.spacing(2)} 0` }}>{organisationName} - {cohort.learningJourney.programmeName} </b>
                        <li style={{ marginBottom: theme.spacing(2) }} key={cohort.cohortId}>
                          <ModalListItem
                            cohortId={cohort.cohortId}
                            journeyId={cohort.learningJourneyId}
                            title={cohort.learningJourney.title}
                            cohortTitle = {cohort.title}
                          />
                        </li>
                        </>
                      );
                    } else {
                      return(<></>);
                    }
                  })}
                  </>)              
              })}
              {finalList.map((cohortList: Cohort[], ): JSX.Element => {
                const curOgId = cohortList[0].organisationId;
                const foundOrganisation: any = organisations.find((organisation: Organisation): boolean =>
                  organisation.id === curOgId
                );
                let organisationName = (foundOrganisation) ? foundOrganisation.name : '';
                let cohortListLength=0
                cohortList.map((cohort: Cohort) => {
                  if(cohort.learningJourneyId !== selectedCohort.learningJourneyId){
                    cohortListLength+=1
                  } 
                })
                return(<>
                {cohortListLength>0 && <b style={{ margin: `${theme.spacing(2)} 0` }}>{organisationName} - {cohortList[0].learningJourney.programmeName} </b>}
                {cohortList.map((cohort: Cohort): JSX.Element => {
                  if(cohort.learningJourneyId === selectedCohort.learningJourneyId){
                    return(<></>);
                  } else {
                  return (
                    <li style={{ marginBottom: theme.spacing(2) }} key={cohort.cohortId}>
                      <ModalListItem
                        cohortId={cohort.cohortId}
                        journeyId={cohort.learningJourneyId}
                        title={cohort.learningJourney.title}
                        cohortTitle = {cohort.title}
                      />
                    </li>
                  );
                  }
                })}
                </>)
                
              })}            
            </JourneyList>
            }
          </div>
          :
          <div className={styles.detailsContainer}>
            {cohorts.length === 0 ?
              <>You are not a learner of any journey.</>
            :
              <JourneyList>
              {cohorts.map((cohort: Cohort): JSX.Element => {
                const currentJourneyModules: { all: number; completed: number } =
                  progress.records[cohort.learningJourneyId].modules;
                const currentJourneyCompletion: number =
                  Math.ceil((currentJourneyModules.completed / currentJourneyModules.all) * 100) || 0;

                return (
                  <li style={{ marginBottom: theme.spacing(2) }} key={cohort.cohortId}>
                    <ModalListItem
                      progress={currentJourneyCompletion}
                      cohortId={cohort.cohortId}
                      journeyId={cohort.learningJourneyId}
                      title={cohort.learningJourney.title}
                    />
                  </li>
                );
              })}
              </JourneyList> 
            }
          </div>} 
        
        <Button
          variant="outline"
          style={{ alignSelf: 'center', margin: `${theme.spacing(3)} 0 ${theme.spacing(1)} 0` }}
          onClick={(): void => resetModalState()}>
          Close
        </Button>
      </SwitchJourneyModalContainer>
    </Modal>
  );
};
export default SwitchFacJourneyModal;
