import React, { FC, HTMLAttributes, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: any;
  register: any;
  validate?: any;
  label?: string;
}

const Input: FC<Props> = ({ id, label, name, type, error, register, validate, placeholder, value, onChange }) => {
  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={id}>
          {label}
        </label>
      )}

      <input
        id={id}
        type={type}
        className={classnames(styles.input, { [styles.error]: !!error })}
        name={name}
        onChange={onChange}
        ref={validate ? register(validate) : register}
        placeholder={placeholder}
        value={value}
      />

      {!!error ? <p className={styles.errorMessage}>{error.message || 'Required'}</p> : null}
    </>
  );
};

export default Input;
