import { CSSProperties } from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '../../components/Typography';

export const LoginCode = styled.div`
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  margin-bottom: 25px;
  width: 188px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Link = styled(RouterLink)`
  text-decoration: underline;
`;

export const codeInput: CSSProperties = {
  boxSizing: 'border-box',
  height: '40px',
  width: '40px',
  margin: '0px 3px',
  border: '2px solid #d7d7d7',
  borderRadius: '5px',
  backgroundColor: '#ffffff',
  color: '#444444',
  fontSize: '24px',
  fontWeight: 'bold',
  letterSpacing: 0,
  lineHeight: '36px',
  textAlign: 'center',
};

export const CodeInstructions = styled(Typography)`
  margin-bottom: 20px;
  padding: 0px 20px;
  text-align: center;
`;

export const LoginHeading = styled.h2`
  font-size: 23px;
  margin-bottom: 10px;
  font-weight: normal;
`;
