import React, { FC } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { resizeImage } from '../../utils/helpers';

import styles from './styles.module.scss';

export type Props = {
  _id: string;
  title: string;
  type: string;
  url: string;
  imageUrl: string;
};

const WeblinkCard: FC<Props> = ({ _id, title, type, url, imageUrl }: Props) => {
  const appInsights = useAppInsightsContext();
  const onClick = () => {
    console.log('app insights', 'CLICK_WEBLINK_CARD');
    appInsights.trackEvent({
      name: 'Activity',
      properties: { type: 'CLICK_WEBLINK_CARD' },
    });
  };
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.container}
      data-test-id={'weblink-card'}
      onClick={onClick}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${resizeImage(imageUrl, 83, 43)})` }}
        data-test-id={'image'}></div>
      <div className={styles.content}>
        <div className={styles.type} data-test-id={'type'}>
          {type}
        </div>
        <div className={styles.title} data-test-id={'title'}>
          {title}
        </div>
      </div>
    </a>
  );
};

export default WeblinkCard;
