import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Activity, ActivityContent, Module } from '../../utils/types';
import { toast } from 'react-toastify';
import InsightWallActionNotification from '../InsightWallActionNotification';

export const getIsManualUpdateDateNewerThanCardUpdateDate = (
  manualUpdateDate: string | null,
  updatedAt: string | undefined,
): boolean => {
  if (!manualUpdateDate) return false;
  if (!updatedAt) return true;

  const manualDate: Dayjs = dayjs(ukDateToAnActualDateFormat(manualUpdateDate));
  const updateDate: Dayjs = dayjs(updatedAt);

  return manualDate.diff(updateDate, 'hours') > 0;
};

const ukDateToAnActualDateFormat = (date: string): string => {
  const pieces: string[] = date.split('/');
  return `${pieces[2]}/${pieces[1]}/${pieces[0]}`;
};

export const acitivtyContent = (modules: Module[], activityId: string): ActivityContent => {
  const activities: Activity[] = [];
  modules.forEach((module: Module): void =>
    module.activities.forEach((activity: Activity): number => activities.push(activity)),
  );
  const foundActivity: Activity | undefined = activities.find(
    (activity: Activity): boolean => activity._id === activityId,
  );
  if (foundActivity) {
    return foundActivity.content;
  }

  return {} as ActivityContent;
};

export const fireToastMessage = (type: 'edit' | 'delete'): void => {
  toast(<InsightWallActionNotification type={type} />, {
    closeButton: false,
    icon: false,
    hideProgressBar: true,
    autoClose: 3000,
  });
};
