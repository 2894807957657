import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './styles.module.scss';
import PageHeader from '../PageHeader/PageHeader';
import ProgressBarCircle from '../ProgressBarCircle';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import Icon from '../Icon';

interface Props {
  title: string;
  id: string;
  completedPerc: number;
  onClickEdit: any;
  taskCount: number;
  reflectionCount: number;
}

const GoalHeader: FC<Props> = ({ title, id, completedPerc, onClickEdit: editGoal, taskCount, reflectionCount }) => {
  const formattedPerc = Math.round(completedPerc * 100);
  const completed = formattedPerc === 100;
  const [progressBarPerc, setProgressBarPerc] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setProgressBarPerc(Math.max(1, formattedPerc));
    }, 500);
  }, []);

  const { width: deviceWidth } = useViewport();

  return (
    <>
      <div className={classnames(styles.container, { [styles.completed]: completed })}>
        {document.getElementById('#headerEditRoot')
          ? ReactDOM.createPortal(
              <a data-test-id="editGoal" className={styles.editButton} onClick={editGoal}>
                <Icon name="edit" color="white" />
              </a>,
              document.getElementById('#headerEditRoot')!,
            )
          : null}

        <PageHeader
          title={title}
          leftButton={{ iconName: isDesktop(deviceWidth) && 'chevron-left', ariaLabel: 'Back to your goals' }}
          rightButton={{
            iconName: isDesktop(deviceWidth) && 'edit',
            onClick: editGoal,
            ariaLabel: `Edit goal`,
          }}
        />

        <div className={styles.content}>
          <div className={styles.circleProgress}>
            <ProgressBarCircle value={formattedPerc} strokeWidth={10} percentageFontSize="large" children={true} />
          </div>
          <div className={styles.actions}>
            <Link className={styles.button} to={`/app/goals/goal/${id}/tasks`}>
              <Icon name="one-off-tasks" color="primary" />
              {completedPerc < 1 ? 'Add o' : 'O'}ne off tasks
              {taskCount > 0 && <span>{taskCount}</span>}
            </Link>
            <Link className={styles.button} to={`/app/goals/goal/${id}/reflections`}>
              <Icon name="reflection" color="primary" />
              {completedPerc < 1 ? 'Add r' : 'R'}eflections
              {reflectionCount > 0 && <span>{reflectionCount}</span>}
            </Link>
          </div>
          <div className={styles.message}>
            {completedPerc === 1 ? (
              <>
                <div className={styles.messageTitle}>All habits completed</div>
                <div className={styles.messageBody}>
                  Review your completed individual habit information by tapping a card below.
                </div>
              </>
            ) : (
              <>
                <div className={styles.messageTitle}>Forming habits can be hard!</div>
                <div className={styles.messageBody}>To increase your chance of success try to practice every day.</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GoalHeader;
