import { connect } from 'react-redux';
import get from 'lodash.get';
import ExperimentInsightEditor from './ExperimentInsightEditor';
import { RootState } from '../../redux';
import { actions as journeyActions } from '../../redux/journey.slice';
import { createUserJournalEntry, updateUserJournalEntry } from '../../redux/userJournal.slice';

const mapState = (state: RootState) => {
  try {
    return {
      auth: get(state, 'auth'),
      createUserJournalEntry,
      updateUserJournalEntry,
    };
  } catch (err) {}
  return {};
};

const { updateActivityProgress, getJourney } = journeyActions;

export default connect(mapState, { updateActivityProgress, getJourney })(ExperimentInsightEditor);
