import React, { FC, PropsWithChildren } from 'react';
import Icon from '../../../Icon';
import { NotificationHeaderContainer, CloseBtn, InfoWrapper } from './styles';
import { NotificationHeaderProps } from './types';
import theme from '../../../../utils/theme';
import Button from '../../../Button';
import { useViewport } from '../../../../utils/hooks';
import parse from 'html-react-parser';

const NotificationHeader: FC<NotificationHeaderProps> = ({
  iconName,
  title,
  description,
  buttonText,
  onClick,
  onClose,
}: PropsWithChildren<NotificationHeaderProps>): JSX.Element => {
  const { width: deviceWidth } = useViewport();

  const isMobile: boolean = deviceWidth < theme.devices.mobile;

  return (
    <NotificationHeaderContainer isMobile={isMobile}>
      <Icon
        name={iconName}
        fill={theme.colours.primary}
        size="medium"
        style={{ marginRight: theme.spacing(3), alignSelf: isMobile ? 'start' : 'center' }}
      />

      <InfoWrapper isMobile={isMobile}>
        <h3 style={{ fontSize: theme.typography.font.size.regular }}>{title}</h3>
        <p style={{ lineHeight: '17px', fontSize: theme.typography.font.size.small }}>{parse(description)}</p>
      </InfoWrapper>

      <Button
        variant="outline"
        style={{
          backgroundColor: theme.colours.white,
          minWidth: '210px',
          marginRight: isMobile ? '0px' : theme.spacing(2),
          marginTop: isMobile ? `${theme.spacing(2)}` : '0px',
          marginBottom: isMobile ? `${theme.spacing(1)}` : '0px',
        }}
        onClick={(): void => onClick()}>
        {buttonText}
      </Button>

      <CloseBtn onClick={(): void => onClose()}>
        <Icon name="close" fill={theme.colours.primary} size="xSmall" />
      </CloseBtn>
    </NotificationHeaderContainer>
  );
};
export default NotificationHeader;
