import HomepageHeader from './HomepageHeader';
import { connect } from 'react-redux';
import { RootState } from '../../../redux';
import { actions as modalActions } from '../../../redux/modals.slice';

const mapState = (state: RootState) => {
  return {};
};

const { setModalState, resetModalState } = modalActions;

export default connect(mapState, { setModalState, resetModalState })(HomepageHeader);
