import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedGoal } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import Button from '../../components/Button';
import ReflectionListItem from '../../components/ReflectionListItem';
import OptionsModal, { OptionsModalOption } from '../../components/OptionsModal';

import styles from './styles.module.scss';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader from '../../components/PageHeader';
import TextWithIcon from '../../components/TextWithIcon';
import Icon from '../../components/Icon';
import Modal from '../../components/Modal';
import List from '../../components/List';

type Props = FormattedGoal & {
  updateGoal: any;
};

const GoalReflections = ({ reflections, id, title, updateGoal }: Props): ReactElement => {
  const [optionsModalReflectionId, setOptionsModalReflectionId] = useState<string | null>(null);
  const history = useHistory();

  const { width: deviceWidth } = useViewport();

  const handleClickEdit = (reflectionId: string) => {
    logger('info', 'REFLECTION_EDIT', { goalId: id, reflectionId });
    setOptionsModalReflectionId(reflectionId);
  };

  const deleteReflectionCallback = () => {
    (async () => {
      try {
        logger('info', 'REFLECTION_DELETE_CONFIRM', { goalId: id, reflectionId: optionsModalReflectionId });
        await updateGoal({ id, reflections: (reflections || []).filter(({ id }) => id !== optionsModalReflectionId) });
        setOptionsModalReflectionId(null);
      } catch (err) {}
    })();
  };

  return (
    <>
      {isDesktop(deviceWidth) && (
        <PageHeader title="Reflections" leftButton={{ iconName: 'chevron-left', ariaLabel: 'Back to your goals' }} />
      )}

      <div className={styles.container}>
        <TextWithIcon text="Reflections" icon="reflection" />
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          {(!reflections || reflections.length === 0) && (
            <div className={styles.empty}>
              <Icon name="reflection" size="large" />
              <div className={styles.emptyTitle}>You have not created any reflections yet</div>

              <div className={styles.emptyBody}>
                We recommend taking a few minutes at least once a week to add any comments or reflections on the
                progress of each goal.
              </div>
            </div>
          )}

          {reflections && reflections.length > 0 && (
            <>
              <div className={styles.body}>
                We recommend taking a few minutes at least once a week to add any comments or reflections on the
                progress of each goal.
              </div>

              <div className={styles.relfectionsList}>
                <List>
                  {reflections.map((reflection) => (
                    <List.Item key={reflection.id || reflection.comment}>
                      <ReflectionListItem {...reflection} onClickEdit={() => handleClickEdit(reflection.id)} />
                    </List.Item>
                  ))}
                </List>
              </div>
            </>
          )}

          <div className={styles.buttonContainer}>
            <Button
              onClick={() => {
                logger('info', 'REFLECTIONS_ADD_A_REFLECTION', {
                  goalId: id,
                });
                history.push(`/app/goals/goal/${id}/reflections/add`);
              }}>
              Add reflection
            </Button>
          </div>
        </div>
      </div>

      <Modal isOpen={optionsModalReflectionId !== null} onClose={() => setOptionsModalReflectionId(null)} width="small">
        <OptionsModal title="Reflection options">
          <OptionsModalOption
            onClick={() => history.push(`/app/goals/goal/${id}/reflections/${optionsModalReflectionId}`)}>
            <span>Edit reflection</span> <Icon name="edit" />
          </OptionsModalOption>
          <OptionsModalOption onClick={() => deleteReflectionCallback()}>
            <span>Delete reflection</span> <Icon name="delete" color="orange" />
          </OptionsModalOption>
        </OptionsModal>
      </Modal>
    </>
  );
};

export default GoalReflections;
