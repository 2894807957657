import styled from 'styled-components';
import { CardOption } from './cardStyles';

export const CompletedButtonWrapper = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const CompletedButtonText = CardOption;

export const IncompletedButtonText = CardOption;
