import { connect } from 'react-redux';
import ExperimentNotificationHeader from './ExperimentNotificationHeader';
import { RootState } from '../../../redux';
import { actions as notificationActions } from '../../../redux/notification.slice';

const mapState = (state: RootState) => {
  return {};
};

const { dismissNotification, setVisitedInsightsPage } = notificationActions;

export default connect(mapState, { dismissNotification, setVisitedInsightsPage })(ExperimentNotificationHeader);
