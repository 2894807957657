import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import logger from '../../utils/logger';
import styles from './styles.module.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../utils/hooks';

const LoginSplash: FC = () => {
  const { loginWithRedirect } = useAuth0();
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);

  return (
    <>
      <div className={styles.container}>
        <img className={styles.logo} src="/images/strata_logo_accenture_new.svg" />
        <Link to="/auth/email" onClick={() => logger('info', 'CLICK_LOGIN')}>
          <Button color="orange">Log in</Button>
        </Link>
        <br />
        {process.env.REACT_APP_ENVIRONMENT !== 'PRODUCTION' && !isNativeWebView && (
          <button className={styles.loginWithPasswordBtn} onClick={() => loginWithRedirect()}>
            Login with password
          </button>
        )}
      </div>
    </>
  );
};

export default LoginSplash;
