import React, { FC } from 'react';
import Icon from '../Icon';
import * as Styled from './styles';
import theme from '../../utils/theme';

interface Props {
  index: number;
  date?: any;
  dateString?: string;
  dateFormattedString?: string;
  dayFormattedString?: string;
  isClearable?: boolean;
  onClick?: any;
}

const HabitToken: FC<Props> = ({
  index,
  date,
  dateString,
  dateFormattedString,
  dayFormattedString,
  isClearable,
  onClick,
}) => {
  const isCompletedHabit = !!date;

  const handleClick = () => {
    if (isCompletedHabit && !isClearable) return;
    onClick(isCompletedHabit, index);
  };

  return (
    <Styled.HabitToken data-test-id={!isCompletedHabit && 'habitRepToken'}>
      <Styled.Token
        color={isCompletedHabit ? theme.colours.secondary : theme.colours.white}
        size="medium"
        elevation={1}
        onClick={handleClick}
        aria-label={
          isCompletedHabit ? `habit completed ${dayFormattedString} ${dateFormattedString}` : 'incomplete habit'
        }>
        {!isCompletedHabit && (
          <>
            <Icon name="habit" />
            <Styled.Index>{index + 1}</Styled.Index>
          </>
        )}

        {isCompletedHabit && (
          <>
            <Icon name="check" color="white" />
            <Styled.Day>{dayFormattedString}</Styled.Day>
          </>
        )}
      </Styled.Token>

      {isCompletedHabit && <Styled.DateCompleted>{dateFormattedString}</Styled.DateCompleted>}
    </Styled.HabitToken>
  );
};

export default HabitToken;
