import styled, { css } from 'styled-components';
import { Color } from '../../utils/types/theme';
import IconButton from '../IconButton';

export const HabitToken = styled.div`
  transition: all 0.2s;
  align-items: center;
  margin-top: 5px;
  height: 80px;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
`;

export const Token = styled(IconButton)`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  flex-direction: column;
  border-radius: 50%;
`;

export const Day = styled.span`
  color: ${({ theme }) => theme.colours.white};
  line-height: ${({ theme }) => theme.spacing(2)};
  font-size: ${({ theme }) => theme.typography.font.size.xSmall};
  letter-spacing: 0;
  text-align: center;
  margin-top: -3px;
`;

export const Index = styled(Day)`
  color: ${({ theme }) => theme.colours.darkGrey};
`;

export const DateCompleted = styled.span`
  color: ${({ theme }) => theme.typography.font.color};
  font-size: ${({ theme }) => theme.typography.font.size.xSmall};
  line-height: ${({ theme }) => theme.spacing(2)};
  letter-spacing: 0;
  text-align: center;
`;
