import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUser as getUserFromApi, GetUserResponse } from '../utils/api/user';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';

export type UserState = {
  email: string;
  externalId: string;
  firstName: string;
  lastName: string;
  id: string;
  organizationId: string;
  roles: Array<string>;
};

const initialState: UserState = {
  email: '',
  externalId: '',
  firstName: '',
  lastName: '',
  id: '',
  organizationId: '',
  roles: [],
};

const getUser = createAsyncThunk<
  GetUserResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'user/getUser',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const user: GetUserResponse = await getUserFromApi(accessToken);
    return user;
  }),
);

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.email = payload.email;
      state.externalId = payload.externalId;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.id = payload.id;
      state.organizationId = payload.organizationId;
      state.roles = payload.roles;
    });
  },  
});

export default user.reducer;

export const actions = {
  ...user.actions,
  getUser,
};
