import React, { FC, useState } from 'react';
import * as Styled from './styles';

import capitalize from 'lodash.capitalize';
import { Color } from '../../utils/types/theme';
import { Link } from 'react-router-dom';

interface Tab {
  text: string;
  href: string;
  status?: number | string;
}

interface Props {
  color: Color;
  tabs: Tab[];
  activeTab: string;
}

const TabNavigation: FC<Props> = ({ tabs, activeTab, color }) => {
  return (
    <Styled.TabNavigation>
      <Styled.Tabs>
        {tabs.map((currentTab, index) => (
          <Styled.Tab color={color} active={currentTab.text === activeTab} key={index}>
            <Link to={currentTab.href} aria-current={currentTab.text === activeTab}>
              {capitalize(currentTab.text)}&nbsp;({currentTab?.status || '0'})
            </Link>
          </Styled.Tab>
        ))}
      </Styled.Tabs>
    </Styled.TabNavigation>
  );
};

export default TabNavigation;
