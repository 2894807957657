import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedGoalOverview } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import styles from './styles.module.scss';
import ProgressBarCircle from '../ProgressBarCircle';
import TextWithIcon from '../TextWithIcon';
import Icon from '../Icon';

type Props = FormattedGoalOverview;
const GoalCard: FC<Props> = ({ title, habitStatus, id, completedPerc }: Props) => {
  const formattedPerc = Math.round(completedPerc * 100);
  const completed = formattedPerc === 100;
  const hasHabits = habitStatus.filter(Boolean).length > 0; // if array is [0,0,0] .length is 0

  const message = hasHabits
    ? 'You can assign a maximum of 3 habits to a goal.'
    : 'Practice your habits regularly to achieve your goal.';
  const buttonText = hasHabits ? (completed ? 'Review details' : 'View full details') : 'Add a habit';

  return (
    <Link
      to={`/app/goals/goal/${id}`}
      className={classnames(styles.link, { [styles.completed]: completed })}
      onClick={() => logger('info', 'GOAL_NAVIGATE', { goalId: id })}>
      <div className={styles.container}>
        <div className={styles.header}>
          <TextWithIcon text="Goal" icon="goal" />
          <h2 className={styles.title}>{title}</h2>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.circleProgress}>
            <ProgressBarCircle value={formattedPerc} strokeWidth={13} children={true} percentageFontSize="Xlarge" />
          </div>
          <div className={styles.content}>
            <div className={styles.footer}>
              <div className={styles.habitSynopsis}>
                <div className={styles.habitMarkers}>
                  <strong>Habits: {}</strong>
                  {habitStatus.map((state, index) => (
                    <div
                      key={index}
                      className={classnames(styles.habitIcon, {
                        [styles.habitIconActive]: state === 1,
                        [styles.habitIconCompleted]: state === 2,
                      })}>
                      <Icon name="habit" color={state === 1 ? 'primary' : 'secondary'} />
                    </div>
                  ))}
                </div>
                <p className={styles.tipMessage}>{message}</p>
              </div>
              <div className={styles.button}>{buttonText}</div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default GoalCard;
