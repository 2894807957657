import styled from 'styled-components';

export const WidgetText = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.small};
  color: ${(p) => p.theme.colours.darkGrey};
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

export const ImageConatiner = styled.div`
  position: relative;
  width: 100%;
  height: 123px;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  width: 297px;
  height: 123px;
  transform: translateX(-50%);
  left: 50%;
  bottom: 0;
`;

export const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;
