import React, { ButtonHTMLAttributes, FC, forwardRef } from 'react';
import * as Styled from './styles';

import { Size } from '../../utils/types/theme';
import { colourExistsInTheme } from '../../utils/theme/helpers';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement | HTMLDivElement> {
  color?: string;
  size?: Size;
  elevation?: number;
}

const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, elevation = 0, color, size = 'small', onClick, children, ...restProps } = props;

  const handleBackgroundColor = () => {
    if (color && colourExistsInTheme(color)) return color;
    return;
  };

  return (
    <Styled.IconButton
      ref={ref}
      type="button"
      elevation={elevation}
      className={className}
      onClick={onClick}
      color={handleBackgroundColor()}
      size={size}
      {...restProps}>
      {children}
    </Styled.IconButton>
  );
});

export default IconButton;
