import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getJourney as getJourneyFromApi,
  updateActivityProgress as updateActivityProgressFromApi,
  updateReporting as updateReportingFromApi,
  GetJourneyResponse,
  getJourneyUserProgress,
} from '../utils/api/journey';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';
import { Module, UserRecord, ReportingUserData, Journey, Activity } from '../utils/types';
import { adaptUserRecordForActivity } from '../utils/adapters/journey';

interface UpdateActivityProgressContentProps {
  id: string;
  firstName: string;
  lastName: string;
  organizationId: string;
  state: any;
  activityId: string;
  experimentId?: string;
  isPreview: boolean;
  completed: boolean;
  accessToken: string;
  isExperimentCompleted?: boolean;
}

const updateActivityProgressContent = async ({
  id,
  firstName,
  lastName,
  organizationId,
  state,
  activityId,
  experimentId,
  isPreview,
  completed,
  accessToken,
  isExperimentCompleted,
}: UpdateActivityProgressContentProps) => {
  const userData: ReportingUserData = {
    userId: id,
    firstName,
    lastName,
    organisationId: organizationId,
  };

  const record: UserRecord | null = adaptUserRecordForActivity(state, activityId, experimentId);

  // fake that shit yo.
  if (isPreview) {
    return {
      record: completed ? record : null,
      activityId: activityId,
    };
  }

  if (!record) return;

  const recordfromAPI = await updateActivityProgressFromApi(accessToken, record, completed, isExperimentCompleted);
  await updateReportingFromApi(accessToken, record, userData, completed);

  return {
    record: recordfromAPI,
    activityId: activityId,
  };
};

export type JourneyState = {
  isPreview: boolean;
  id?: string;
  title?: string;
  subtitle?: string;
  modules: Array<Module>;
  projects: string[];
  layoutType: string;
  progress: GetJourneyProgressResponse;
  cachedJourney?: {
    id?: string;
    title?: string;
    subtitle?: string;
    modules: Array<Module>;
    projects: string[];
    layoutType: string;
  };
};

export interface GetJourneyPayload {
  learningJourneyId: string;
  cohortId: string;
}

const previewData = sessionStorage.getItem('journey/setPreviewJourney');

const initialState: JourneyState = previewData
  ? JSON.parse(previewData)
  : {
      isPreview: false,
      modules: [],
      projects: [],
      layoutType: '',
      progress: {},
    };

const getJourney = createAsyncThunk<
  GetJourneyResponse,
  GetJourneyPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'journey/getJourney',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: GetJourneyPayload) => {
    const { accessToken } = state.auth;
    
    const journey = await getJourneyFromApi(accessToken, payload);
    return journey;
  }),
);

type UpdateHabitProgressPayload = {
  activityId: string;
  completed: boolean;
  experimentId?: string;
  isExperimentCompleted?: boolean;
};
type UpdateHabitProgressResponse = {
  record?: UserRecord | null;
  activityId: string;
};
const updateActivityProgress = createAsyncThunk<
  UpdateHabitProgressResponse | null,
  UpdateHabitProgressPayload,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'journey/updateActivity',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: UpdateHabitProgressPayload) => {
    const { id, firstName, lastName, organizationId } = state.user;
    const { accessToken } = state.auth;
    const { isPreview } = state.journey;

    return {
      ...(await updateActivityProgressContent({
        id,
        firstName,
        lastName,
        organizationId,
        state,
        activityId: payload.activityId,
        experimentId: payload.experimentId,
        isPreview,
        completed: payload.completed,
        accessToken,
        isExperimentCompleted: payload.isExperimentCompleted,
      })),
    };
  }),
);

export interface GetJourneyProgressResponse {
  records: {
    [id: string]: {
      journeyId: string;
      title: string;
      modules: {
        all: number;
        completed: number;
      };
      activities: {
        all: number;
        completed: number;
      };
      experiments: {
        all: number;
        completed: number;
      };
    };
  };
}

const getJourneyProgress = createAsyncThunk<
  GetJourneyProgressResponse,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'journey/getJourneyProgress',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: string[]) => {
    const { accessToken } = state.auth;
    const userId: string = state.user.id;
    const progress: any = await getJourneyUserProgress(accessToken, payload, userId);
    return progress;
  }),
);

interface CompleteActivity extends UpdateHabitProgressPayload {
  journeyIds: string[];
}

interface CompleteActivityResponse {
  journeyProgress: GetJourneyProgressResponse;
  record: UserRecord | null;
  activityId: string;
}

const getJourneyProgressAndUpdateActivityProgress = createAsyncThunk<
  CompleteActivityResponse,
  CompleteActivity,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'journey/getJourneyProgressAndUpdateActivityProgress',
  generateReauthenticatingThunkApiAction(async (state: RootState, payload: CompleteActivity) => {
    const { accessToken } = state.auth;
    const { id, firstName, lastName, organizationId } = state.user;
    const { isPreview } = state.journey;
    const userId: string = state.user.id;

    return {
      ...(await updateActivityProgressContent({
        id,
        firstName,
        lastName,
        organizationId,
        state,
        activityId: payload.activityId,
        experimentId: payload.experimentId,
        isPreview,
        completed: payload.completed,
        accessToken,
        isExperimentCompleted: payload.isExperimentCompleted,
      })),
      journeyProgress: await getJourneyUserProgress(accessToken, payload.journeyIds, userId),
    };
  }),
);

type RestoreJourneyPreviewFromStorageResponse = {
  cachedJourney: Journey;
  isPreview: boolean;
  layoutType: string;
  modules: Module[];
  projects: any;
  subtitle: string;
  title: string;
  id: string;
} | null;

const restoreJourneyPreviewFromStorage = createAsyncThunk<
  RestoreJourneyPreviewFromStorageResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'journey/restoreJourneyPreviewFromStorage',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const { cohortId } = state.cohorts.selectedCohort;

    // check if preview exist
    const storagePreivewData = JSON.parse(localStorage.getItem('journey/setPreivewJourney') || '{}');
    if (!storagePreivewData.previewedJourneyId || !storagePreivewData.cachedJourneyId) return null;

    // get updated journeys
    const previewedJourney: Journey = await getJourneyFromApi(accessToken, {
      learningJourneyId: storagePreivewData.previewedJourneyId,
      cohortId,
    });

    const cachedJourney: Journey = await getJourneyFromApi(accessToken, {
      learningJourneyId: storagePreivewData.cachedJourneyId,
      cohortId,
    });

    if (previewedJourney && cachedJourney) {
      return {
        cachedJourney: cachedJourney,
        isPreview: true,
        layoutType: previewedJourney.layoutType,
        modules: previewedJourney.modules,
        projects: previewedJourney.projects,
        subtitle: previewedJourney.subtitle,
        title: previewedJourney.title,
        id: previewedJourney._id,
      };
    }

    return null;
  }),
);

const journey = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    setJourneyPreview(state, { payload }) {
      localStorage.setItem(
        'journey/setPreivewJourney',
        JSON.stringify({ previewedJourneyId: payload._id, cachedJourneyId: state.id }),
      );

      if (!state.isPreview) {
        state.cachedJourney = {
          layoutType: state.layoutType,
          modules: state.modules,
          projects: state.projects,
          subtitle: state.subtitle,
          title: state.title,
          id: state.id,
        };
      }
      state.isPreview = true;
      state.layoutType = payload.layoutType;
      state.modules = payload.modules;
      state.projects = payload.projects;
      state.subtitle = payload.subtitle;
      state.title = payload.title;
      state.id = payload._id;

      sessionStorage.setItem('journey/setPreviewJourney', JSON.stringify(state));
    },
    clearPreview(state) {
      state.isPreview = false;
      state.layoutType = state.cachedJourney?.layoutType || 'flexible';
      state.modules = state.cachedJourney?.modules || [];
      state.projects = state.cachedJourney?.projects || [];
      state.subtitle = state.cachedJourney?.subtitle;
      state.title = state.cachedJourney?.title;
      state.id = state.cachedJourney?.id;

      state.cachedJourney = undefined;

      localStorage.removeItem('journey/setPreivewJourney');
      sessionStorage.removeItem('journey/setPreviewJourney');
    },
    // positive update to uncomplete an activity
    removeActivityRecord(state, { payload: id }): void {
      state.modules.forEach((module: Module) => {
        const foundAcitvity: Activity | undefined = module.activities.find(
          (activity: Activity): boolean => activity._id === id,
        );

        if (foundAcitvity) {
          foundAcitvity.record = undefined;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJourney.fulfilled, (state, { payload }) => {
      state.modules = payload.modules;
      state.projects = payload.projects;
      state.subtitle = payload.subtitle;
      state.title = payload.title;
      state.id = payload._id;
      state.layoutType = payload.layoutType;
      
    });
    builder.addCase(updateActivityProgress.fulfilled, (state, { payload }) => {
      if (!payload) return;
      for (let i = 0; i < state.modules.length; i++) {
        const module = state.modules[i];
        for (let i = 0; i < module.activities.length; i++) {
          if (module.activities[i]._id === payload.activityId) {
            module.activities[i].record = payload.record as any;
          }
        }
      }
    });
    builder.addCase(getJourneyProgress.fulfilled, (state, { payload }) => {
      state.progress = payload;
    });
    builder.addCase(getJourneyProgressAndUpdateActivityProgress.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.progress = payload.journeyProgress;
      for (let i = 0; i < state.modules.length; i++) {
        const module = state.modules[i];
        for (let i = 0; i < module.activities.length; i++) {
          if (module.activities[i]._id === payload.activityId) {
            module.activities[i].record = payload.record as any;
          }
        }
      }
    });
    builder.addCase(restoreJourneyPreviewFromStorage.fulfilled, (state, { payload }): void => {
      if (payload) {
        state.isPreview = true;
        state.cachedJourney = payload.cachedJourney;
        state.layoutType = payload.layoutType;
        state.modules = payload.modules;
        state.projects = payload.projects;
        state.subtitle = payload.subtitle;
        state.title = payload.title;
        state.id = payload.id;
      }
    });
  },
});

export default journey.reducer;

export const actions = {
  ...journey.actions,
  getJourney,
  getJourneyProgress,
  updateActivityProgress,
  getJourneyProgressAndUpdateActivityProgress,
  restoreJourneyPreviewFromStorage,
};
