import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash.get';
import Button from '../../components/Button';
import HabitBankModal from '../../components/HabitBankModal';
import Input from '../../components/Input';

import styles from './styles.module.scss';
import TextWithIcon from '../../components/TextWithIcon';
import Icon from '../../components/Icon';
import IconButton from '../../components/IconButton';
import Modal from '../../components/Modal';

type Props = {
  update: (habit: any) => void;
  habit: any;
  goalId: string;
  setStep: (step: number) => void;
  createHabit: any;
};

type CreateHabitAmbitionFormPayload = {
  title: string;
};

type FormStateType = 'select' | 'input' | 'bank';

const CreateHabitAmbition: FC<Props> = ({ setStep, habit, update }: Props) => {
  const [canProgress, setCanProgress] = useState<boolean>(habit.title && habit.title.length > 0);

  let defaultFormState: FormStateType = 'select';
  if (habit.title) {
    defaultFormState = habit.habitBankItemId ? 'bank' : 'input';
  }

  const [formState, setFormState] = useState<FormStateType>(defaultFormState);
  const [showHabitBank, setShowHabitBank] = useState<boolean>(false);

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      title: habit.title,
    },
  });

  const onSubmit = ({ title }: CreateHabitAmbitionFormPayload) => {
    if (formState === 'input') {
      update({ title });
    }
    setStep(1);
  };

  const handleCreateCustomHabit = () => {
    setFormState('input');
  };

  const handleHabitBankOpen = () => {
    setShowHabitBank(true);
  };

  const onChange = () => {
    setCanProgress(true);
  };

  const selectHabitFromBank = (habit: any) => {
    update({ title: habit.title, habitBankItemId: habit._id });
    setFormState('bank');
    setCanProgress(true);
    setShowHabitBank(false);
  };

  const handleBackToSelect = () => {
    update({ title: null, habitBankItemId: null });
    setFormState('select');
    setCanProgress(false);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.body}>
        Making your habit a natural activity that you do regularly, will help you to achieve success.
      </div>

      <div className={styles.body}>
        Get started by either selecting a habit from our list of pre-defined options or create your own.
      </div>

      {formState === 'input' && (
        <div className={styles.habitCard}>
          <TextWithIcon text="Habit" icon="habit" />

          <IconButton className={styles.close} onClick={handleBackToSelect} aria-label="close">
            <Icon name="close" />
          </IconButton>

          <Input
            id="title"
            name="title"
            type="text"
            error={get(errors, 'title', null)}
            register={register}
            validate={{ required: true }}
            onChange={onChange}
            placeholder="e.g. Review my conversations with my team everyday"
            label="Give your habit a title"
          />
        </div>
      )}

      {formState === 'bank' && (
        <div className={styles.habitCard}>
          <TextWithIcon text="Habit" icon="habit" />
          <a
            data-test-id="habitCancelSelection"
            className={styles.close}
            onClick={handleBackToSelect}
            aria-label="close">
            <Icon name="close" />
          </a>
          <div className={styles.habitInputMask}>{habit.title}</div>
        </div>
      )}

      {formState === 'select' && (
        <div className={styles.habitTypeWrapper}>
          <button type="button" onClick={handleHabitBankOpen} className={styles.habitTypeButton}>
            <Icon name="habit-list" color="primary" size="large" />
            <div className={styles.habitTypeButtonTitle}>Choose from our habit bank</div>
          </button>

          <button type="button" onClick={handleCreateCustomHabit} className={styles.habitTypeButton}>
            <Icon name="habit-create" color="primary" size="large" />
            <div className={styles.habitTypeButtonTitle}>Choose your own habit title</div>
          </button>
        </div>
      )}

      <Modal isOpen={showHabitBank} onClose={() => setShowHabitBank(false)}>
        <HabitBankModal onSelectHabit={selectHabitFromBank} />
      </Modal>

      <div className={styles.buttonContainer}>
        <Button testId="continue" disabled={!canProgress}>
          Next
        </Button>
      </div>
    </form>
  );
};

export default CreateHabitAmbition;
