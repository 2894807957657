import React, { ReactElement } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import styles from './styles.module.scss';
const config = {
  SlideIn: {
    transitionAppearTimeout: 170,
    transitionEnterTimeout: 170,
    transitionLeaveTimeout: 170,
  },
  FadeIn: {
    transitionAppearTimeout: 120,
    transitionEnterTimeout: 120,
    transitionLeaveTimeout: 120,
  },
};
const PageShell = (Page: any, transitionName: 'FadeIn' | 'SlideIn' = 'FadeIn') => {
  return function WrappedPage(props: any): ReactElement {
    return (
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={config[transitionName].transitionAppearTimeout}
        transitionEnterTimeout={config[transitionName].transitionEnterTimeout}
        transitionLeaveTimeout={config[transitionName].transitionLeaveTimeout}
        transitionName={transitionName}
        component="div"
        className={styles.page}>
        <Page {...props} />
      </ReactCSSTransitionGroup>
    );
  };
};
export default PageShell;
