import React, { FC, PropsWithChildren } from 'react';
import {
  InsightWallActionNotificationContainer,
  InsightWallNotificationContentWrapper,
  InsightWallActionNotificationHeader,
  InsightWallActionNotificationMessage,
} from './InsightWallActionNotification.styles';
import { InsightWallActionNotificationProps } from './InsightWallActionNotification.types';
import Icon from '../Icon';
import IconButton from '../IconButton';
import theme from '../../utils/theme/theme';

const InsightWallActionNotification: FC<InsightWallActionNotificationProps> = ({
  type,
}: PropsWithChildren<InsightWallActionNotificationProps>): JSX.Element => {
  const notificationType: 'edited' | 'deleted' = type === 'edit' ? 'edited' : 'deleted';

  return (
    <InsightWallActionNotificationContainer>
      <IconButton color={theme.colours.secondary} style={{ marginRight: theme.spacing(1) }}>
        <Icon name="check" size="small" color="white" />
      </IconButton>
      <InsightWallNotificationContentWrapper>
        <InsightWallActionNotificationHeader>Post {notificationType}</InsightWallActionNotificationHeader>
        <InsightWallActionNotificationMessage>
          This post has been {notificationType}.
        </InsightWallActionNotificationMessage>
      </InsightWallNotificationContentWrapper>
      <Icon name="close" size="small" style={{ alignSelf: 'flex-start' }} />
    </InsightWallActionNotificationContainer>
  );
};
export default InsightWallActionNotification;
