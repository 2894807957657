import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import Icon from '../Icon';

type Props = {
  className?: string;
  style?: any;
  onClick?: any;
  next?: boolean;
};

const ContentCarouselArrow: FC<Props> = ({ className, style, onClick, next }: Props) => {
  return (
    <div className={classnames(className, styles.container)} style={style} onClick={onClick}>
      {next ? <Icon name="chevron-right" /> : <Icon name="chevron-left" />}
    </div>
  );
};

export default ContentCarouselArrow;
