import React, { PropsWithChildren } from 'react';
import { ExperimentCallToActionProps } from './types';
import { ExperimentCTAContainer, Title, Description, ParticipantCount } from './styles';
import Button from '../Button';
import theme from '../../utils/theme';
import { Link } from 'react-router-dom';
import { actions } from '../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';

const { insightNotificationsDismissAll, setVisitedInsightsPage } = actions;

const ExperimentCallToAction: React.FC<ExperimentCallToActionProps> = ({
  type,
  numberOfTimesCompleted = 0,
  onClick,
  isCompleted,
  isPreview = false,
}: PropsWithChildren<ExperimentCallToActionProps>): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  if (isPreview)
    return (
      <ExperimentCTAContainer isPreview={isPreview}>
        <Title isPreview={isPreview}>Self reflection or share insights</Title>
        <Description>
          This area will be automatically created when you add an experiment activity to a learning journey.
        </Description>

        <Button color={!isCompleted ? 'primary' : 'secondary'} disabled={true} onClick={() => {}}>
          {!isCompleted ? 'Complete this experiment' : 'View your reflections'}
        </Button>
      </ExperimentCTAContainer>
    );

  return (
    <ExperimentCTAContainer>
      {!isCompleted ? (
        <>
          <Title>{type == 'reflection' ? 'Add your reflections' : 'Share your insights'}</Title>

          <Description>
            {' '}
            {type == 'reflection'
              ? 'Complete the experiment by adding your reflections on how things went overall.'
              : 'Complete this experiment by sharing your experience and lessons learnt with others.'}{' '}
          </Description>
        </>
      ) : (
        <Title> You have completed this experiment </Title>
      )}

      {type == 'reflection' ? (
        <Button
          color={!isCompleted ? 'primary' : 'secondary'}
          style={{
            marginBottom: isCompleted ? theme.spacing(0) : theme.spacing(2),
            marginTop: isCompleted ? theme.spacing(3) : theme.spacing(0),
          }}
          onClick={() => onClick()}>
          {!isCompleted ? 'Complete this experiment' : 'View your reflections'}
        </Button>
      ) : isCompleted ? (
        <Link to={`/app/insightswall`}>
          <Button
            color={'secondary'}
            style={{ marginBottom: theme.spacing(0), marginTop: theme.spacing(3) }}
            onClick={() => {
              onClick();
              dispatch(insightNotificationsDismissAll(null));
              dispatch(setVisitedInsightsPage());
            }}>
            {'View the insights wall'}
          </Button>
        </Link>
      ) : (
        <Button
          color={'primary'}
          style={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(0) }}
          onClick={() => onClick()}>
          {'Complete this experiment'}
        </Button>
      )}

      {!isCompleted && numberOfTimesCompleted > 0 && (
        <ParticipantCount>
          <strong>
            {numberOfTimesCompleted} {numberOfTimesCompleted > 1 ? 'people' : 'person'}
          </strong>
          {type == 'reflection'
            ? ` ${numberOfTimesCompleted > 1 ? 'have' : 'has'} completed this experiment`
            : ` ${numberOfTimesCompleted > 1 ? 'have' : 'has'} completed this experiment and shared their insights`}
        </ParticipantCount>
      )}
    </ExperimentCTAContainer>
  );
};

export default ExperimentCallToAction;
