import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconButton } from '../IconButton/styles';
import { CardContainerStyleProps, CardHeaderStyleProps, CardTitleStyleProps } from './types';

export const ExperimentLink = styled(Link)`
  display: block;
`;

export const CardContainer = styled.div<CardContainerStyleProps>`
  position: relative;
  background-color: ${(p) => (p.isComplete ? p.theme.colours.lightGrey : p.theme.colours.white)};
  box-shadow: ${(p) => (p.isComplete ? 'none' : p.theme.shadows[0])};
  padding: ${(p) => p.theme.spacing(2)};
  margin-bottom: ${(p) => p.theme.spacing(2)};
  border: ${(p) =>
    p.selected
      ? `1px solid ${p.isExperiment ? p.theme.colours.secondary : p.theme.colours.darkGrey}`
      : '1px solid transparent'};
  min-height: 72px;
  border-radius: 10px;
  width: 100%;
`;
export const CardHeader = styled.div<CardHeaderStyleProps>`
  flex: 1;
  margin-bottom: ${(p) => (p.description ? p.theme.spacing(1) : 0)};
  padding-right: ${(p) => p.theme.spacing(9)};
  text-align: start;
`;
export const CardBody = styled.div`
  flex: 1;
  // padding-right:  ${(p) => p.theme.spacing(9)};
`;

export const CardType = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
  color: ${(p) => p.theme.colours.primary};
  margin-bottom: 4px;
  line-height: 14px;
`;
export const CardTitle = styled.h3<CardTitleStyleProps>`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 21px;
  margin-bottom: ${(p) => (p.isComplete ? '0' : p.theme.spacing(1))};
`;

export const CardText = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.small};
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 16px;
`;

export const CardIcon = styled(IconButton)`
  position: absolute;
  right: 16px;
  top: 16px;
`;
