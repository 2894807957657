import { connect } from 'react-redux';

import { RootState } from '../../redux';

import { adaptGoalById } from '../../utils/adapters/goals';
import { actions as goalActions } from '../../redux/goals.slice';

import GoalOneOffTasks from './GoalOneOffTasks';

const { updateGoal } = goalActions;

const mapState = (state: RootState, props: any) => {
  try {
    const { id } = props.match.params;
    return {
      ...adaptGoalById(state, id),
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, { updateGoal })(GoalOneOffTasks);
