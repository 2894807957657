import styled from 'styled-components';
import { InsightWallFilterContainerStyleProps } from './types';

export const InsightWallFilterContainer = styled.div<InsightWallFilterContainerStyleProps>`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

export const SelectionBackground = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colours.lightGrey};
  border-radius: ${(p) => p.theme.borders.radius};
  min-height: 72px;
`;

export const SelectionDisplay = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${(p) => p.theme.spacing(2)} 0px ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(4)};
  flex: 1;
  flex-wrap: wrap;
`;

export const SelectionSection = styled.div`
  padding: ${(p) => p.theme.spacing(1)} ${(p) => p.theme.spacing(3)} ${(p) => p.theme.spacing(1)}
    ${(p) => p.theme.spacing(3)};
  border-left: 1px solid ${(p) => p.theme.colours.mediumGrey};
  colo: ${(p) => p.theme.colours.darkGrey};

  &:first-child {
    padding-left: 0px;
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  > span:first-child {
    font-weight: ${(p) => p.theme.typography.font.weight.bold};
    font-size: 14px;
  }

  > span {
    line-height: 16px;
    font-size: 14px;
  }
`;

export const UserFilterOption = styled.div`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: ${(p) => p.theme.spacing(2)} 0px;

  > * {
    font-size: 14px;
    margin: 0px ${(p) => p.theme.spacing(1)};
  }
`;
