import React, { FC, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { useHistory, useParams } from 'react-router-dom';
import JournalCaptureEntry from '../../components/JournalCaptureEntry';
import { isDesktop } from '../../utils/breakpoints';
import { useAppSelector, useViewport } from '../../utils/hooks';
import { JournalEntry } from '../../utils/types';
import { hasSeenModal, setSeenModal } from '../../utils/checkModalLocalStorage';
import Modal from '../../components/Modal';
import OnboardingCarousel from '../../containers/OnboardingCarousel/OnboardingCarousel';
import onboarding from '../../containers/OnboardingCarousel/OnboardingCarousel.content';

const Error = () => {
  const history = useHistory();
  return (
    <>
      <h1>No Entry found with that ID</h1>
      <button onClick={() => history.goBack()}>Go back to activity</button>
    </>
  );
};

const AddJournal: FC = () => {
  const [foundEntry, setFoundEntry] = useState<JournalEntry | undefined>(undefined);

  const [modalOpen, setModalOpen] = useState<boolean>(!hasSeenModal('journalMobile') || false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const { questionId }: any = useParams();

  const { journalEntries } = useAppSelector((state) => state.userJournal);
  const { width: deviceWidth } = useViewport();
  const history = useHistory<{ isEditEntry: boolean; questionText: string; activityId: string; blockId: string }>();

  useEffect(() => {
    if (modalOpen) {
      setSeenModal('journalMobile');
    }
  }, []);

  useEffect(() => {
    isDesktop(deviceWidth) && history.goBack();
  }, [deviceWidth]);

  useEffect(() => {
    if (!questionId) return;

    const journalEntry = journalEntries.find((entry) => entry.sourceId === questionId);

    setFoundEntry(journalEntry);
  }, [journalEntries]);

  const newEntry = !history.location?.state.isEditEntry;
  const questionText = foundEntry ? foundEntry.question : history.location?.state.questionText;
  const activityId: string = history.location.state.activityId;
  const blockId: string = history.location.state.blockId;

  if (newEntry || foundEntry) {
    return (
      <Styled.AddJournal>
        <JournalCaptureEntry
          questionText={questionText}
          activityId={activityId}
          blockId={blockId}
          questionId={questionId}
          openModal={openModal}
        />

        <Modal isOpen={modalOpen} onClose={closeModal}>
          <OnboardingCarousel items={onboarding.learnerJournal} closeOnboardingModal={closeModal} />
        </Modal>
      </Styled.AddJournal>
    );
  }

  return <Error />;
};

export default AddJournal;
