type OrgListPayload = {
  maxRecords: number;
  continuationToken?: string;
  ids?: string[];
};

export const getOrganisations = async (tokens: any) => {
  const { accessToken } = tokens;
  const organisations: any = [];
  let continuationToken: any = '';
  do {
    const body: OrgListPayload = { maxRecords: 10 };
    if (continuationToken && continuationToken.length) {
      body.continuationToken = continuationToken;
    }
    try {
      const results = await fetch(`${process.env.REACT_APP_API_ROUTE}/organisation/organisations/list`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const { results: orgs, continuationToken: contToken } = await results.json();

      // add orgs
      orgs.forEach((a: any) => organisations.push(a));
      continuationToken = contToken;
    } catch (err) {
      continuationToken = null;
    }
  } while (continuationToken !== null);

  return organisations;
};
export const getOrganisationsByIds = async (tokens: any, ids: string[]) => {
  const { accessToken } = tokens;
  const organisations: any = [];
  let continuationToken: any = '';
  do {
    const body: OrgListPayload = { maxRecords: 10, ids: ids };
    if (continuationToken && continuationToken.length) {
      body.continuationToken = continuationToken;
    }
    
    try {
      const results = await fetch(`${process.env.REACT_APP_API_ROUTE}/organisation/organisations/byids`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const { results: orgs, continuationToken: contToken } = await results.json();

      // add orgs
      orgs.forEach((a: any) => organisations.push(a));
      continuationToken = contToken;
    } catch (err) {
      continuationToken = null;
    }
  } while (continuationToken !== null);

  return organisations;
};
export const getProgrammes = async (tokens: any, orgId: string) => {
  const { accessToken } = tokens;
  try {
    const results = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/programme/org-id/${orgId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json: Array<any> = await results.json();
    return json;
  } catch (err) {
    return [];
  }
};

export const getJourneys = async (tokens: any, programmeId: string) => {
  const { accessToken } = tokens;
  try {
    const results = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/programme/${programmeId}/full-references`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await results.json();
    return json.journeys;
  } catch (err) {
    return [];
  }
};

export const getJourney = async (tokens: any, journeyId: string, cohortId: string) => {
  const { accessToken } = tokens;
  try {
    const results = await fetch(
      `${process.env.REACT_APP_API_ROUTE}/journey/journey/${journeyId}/full?cohortId=${cohortId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    const json = await results.json();
    return json;
  } catch (err) {
    return null;
  }
};
