import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const WidgetHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  color: ${(p) => p.theme.colours.darkGrey};
`;

export const Link = styled(RouterLink)`
  text-decoration: underline;
  color: ${(p) => p.theme.colours.primary};
  font-size: ${(p) => p.theme.typography.font.size.small};
`;

export const StoryBookLink = styled.p`
  text-decoration: underline;
  color: ${(p) => p.theme.colours.primary};
  font-size: ${(p) => p.theme.typography.font.size.small};
`;

export const Divider = styled.hr`
  margin: ${(p) => p.theme.spacing(2)} 0;
  color: ${(p) => p.theme.colours.mediumGrey};
`;
