import styled from 'styled-components';
import theme from '../../utils/theme';
import Button from '../Button';

export const RichTextEditor = styled.div<{ isExperiment: boolean }>`
  width: 100%;
  border: ${(p) => (p.isExperiment ? `2px solid ${p.theme.colours.mediumGrey}` : 'none')};
  border-radius: 4px;
  position: relative;

  .ql-container {
    border: none;
  }

  .ql-editor {
    min-height: 100px;
    padding: 12px 15px;

    ul,
    ol > li {
      font-size: 16px;
      line-height: 24px;
      padding-left: 0px;
    }
    ul > li::before {
      font-size: 24px;
    }
  }

  .ql-toolbar.ql-snow {
    border: 0;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    font-family: ${({ theme }) => theme.typography.font.family.join()};
    color: ${({ theme }) => theme.typography.font.color};
    opacity: 0.5;
    left: ${({ theme }) => theme.spacing(2)};
  }

  @media (min-width: ${theme.devices.tablet}px) {
    border: ${({ theme }) => `2px solid ${theme.colours.mediumGrey}`};

    .ql-editor {
      padding: 12px 15px;
      margin: 0px;
    }

    .ql-editor.ql-blank::before {
      left: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const Editor = { border: 'none', height: '100px' };

export const Box = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  flex-direction: row;
  justify-content: flex-end;
`;

export const SaveButton = styled(Button)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing(1)};
  right: ${({ theme }) => theme.spacing(1)};
`;
