import styled from 'styled-components';
import { InsightConfirmationModalContainerStyleProps } from './types';

export const InsightConfirmationModalContainer = styled.div<InsightConfirmationModalContainerStyleProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InsightConfirmationModalHeader = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.Xlarge};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

export const ConfirmationQuestion = styled.span`
  text-align: center;
  margin: ${(p) => p.theme.spacing(1)} 0 ${(p) => p.theme.spacing(1)} 0;
`;

export const ConfirmationInformations = styled.ul`
  margin-bottom: ${(p) => p.theme.spacing(1)};
  > li {
    margin-left: ${(p) => p.theme.spacing(2)};
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.theme.colours.lightGrey};
  margin: ${(p) => p.theme.spacing(1)} 0 ${(p) => p.theme.spacing(1)} 0;
`;

export const ConsentButtonLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 80%;
  cursor: pointer;
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
  text-align: left;
  align-self: flex-end;
  user-select: none;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: ${(p) => p.theme.spacing(4)};
`;
