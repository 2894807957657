import React, { FC, PropsWithChildren } from 'react';
import { SwitchJourneyModalContainer, JourneyList } from './styles';
import { SwitchJourneyModalProps } from './types';
import ExperimentModalHeader from '../../ExperimentModalHeader';
import Modal from '../../Modal';
import { Modal as ModalType } from '../../../redux/modals.slice';
import { useAppSelector } from '../../../utils/hooks';
import { RootState } from '../../../redux';
import { CohortsState } from '../../../redux/cohorts.slice';
import { Cohort } from '../../../utils/types';
import ModalListItem from '../../ModalListItem';
import theme from '../../../utils/theme';
import Button from '../../Button';
import { JourneyState } from '../../../redux/journey.slice';

const SwitchJourneyModal: FC<SwitchJourneyModalProps> = ({
  resetModalState,
}: PropsWithChildren<SwitchJourneyModalProps>): JSX.Element => {
  const { key, isOpen } = useAppSelector((state: RootState): ModalType => state.modals.currentModal);
  const { cohorts } = useAppSelector((state): CohortsState => state.cohorts);
  const { progress } = useAppSelector((state: RootState): JourneyState => state.journey);

  const modalIsOpen: boolean = isOpen && key == 'HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL';
  return (
    <Modal isOpen={modalIsOpen} width="default" onClose={(): void => resetModalState()}>
      <SwitchJourneyModalContainer>
        <ExperimentModalHeader title="Your learning journeys" iconName="journey" />
        <p style={{ margin: `${theme.spacing(2)} 0` }}>Please select which learning journey wish to view.</p>
        <JourneyList>
          {cohorts.map((cohort: Cohort): JSX.Element => {
            const currentJourneyModules: { all: number; completed: number } =
              progress.records[cohort.learningJourneyId].modules;
            const currentJourneyCompletion: number =
              Math.ceil((currentJourneyModules.completed / currentJourneyModules.all) * 100) || 0;

            return (
              <li style={{ marginBottom: theme.spacing(2) }} key={cohort.cohortId}>
                <ModalListItem
                  progress={currentJourneyCompletion}
                  cohortId={cohort.cohortId}
                  journeyId={cohort.learningJourneyId}
                  title={cohort.learningJourney.title}
                />
              </li>
            );
          })}
        </JourneyList>
        <Button
          variant="outline"
          style={{ alignSelf: 'center', margin: `${theme.spacing(3)} 0 ${theme.spacing(1)} 0` }}
          onClick={(): void => resetModalState()}>
          Close
        </Button>
      </SwitchJourneyModalContainer>
    </Modal>
  );
};
export default SwitchJourneyModal;
