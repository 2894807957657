import * as styled from 'styled-components';
import theme from '../utils/theme';

const GlobalStyles = styled.createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${({ theme }) => theme.typography.font.family.join()};
    font-weight: 400;
    font-style: normal;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    height: 100%;
    width: 100%;
  }

  body {
    min-height: 100vh;
    width: 100%;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  .markdown {
      a, a:-webkit-any-link {
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline;
      }
    
      h1 {
        line-height: 42px;
      }
    
      p {
        margin-bottom: 15px;
      }
      ul,
      ol {
        margin-left: 25px;
        margin-bottom: 15px;
      }
    
      blockquote {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
    
        font-style: italic;
        quotes: '“' '”' '‘' '’';
        border-top: 1px solid #cecece;
        border-bottom: 1px solid #cecece;
        padding: 16px;
        margin: 16px 0px;
        text-align: center;
    
        p {
          width: 100%;
          margin: 0;
          padding: 0px 8px;
        }
      }
    
      blockquote::before {
        content: open-quote;
      }
      blockquote::after {
        content: close-quote;
      }
    }
  }

  #root {
    min-height: 100vh;
    width: 100%;

    .modal {
      .modal__overlay {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        flex-direction: row;
        overflow-x: scroll;
      }

      .modal__content {
        z-index: 12;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 57px;
        background: white;
        overflow: auto;
        border-radius: 4px;
        padding: 50px 15px 15px;
        align-self: flex-start;
        overflow-x: hidden;

        &.modal__content--small {
          margin: auto;
          width: 375px;
          align-self: center;
          max-height: 90%;
          position: static;
        }

        &.modal__content--confetti {
          position: static;
        }

        .closeModalButton {
          position: absolute;
          right: 15px;
          top: 14px;
        }
      }
    }
  }

  body {
    flex: 1;
    background-image: url('/images/purple_clouds.png');
    background-position: center center;
    background-size: cover;
    color: ${({ theme }) => theme.typography.font.color};

    a,
    a:hover {
      color: inherit;
    }

    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }

    div,
    button,
    form {
      display: flex;
      flex-direction: column;
    }

    label {
      color: ${({ theme }) => theme.typography.font.color};
      font-size: ${({ theme }) => theme.typography.font.size.regular};
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      align-self: flex-start;
      margin-bottom: 5px;
      margin-top: 20px;
    }
  }

  .sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  p {
    font-size: ${({ theme }) => theme.typography.font.size.regular};
    line-height: ${({ theme }) => theme.typography.font.size.Xlarge};
  }

  h2 {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    line-height: ${({ theme }) => theme.typography.h2.lineHeight};
  }

  h3 {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
    line-height: ${({ theme }) => theme.typography.h3.lineHeight};
  }

  .SlideIn-appear {
    transform: translateX(15px);
    opacity: 0;
  }

  .SlideIn-appear.SlideIn-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.15s linear;
  }

  .SlideIn-enter {
    opacity: 0;
    transform: translateX(15px);
  }

  .SlideIn-enter.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.05s linear 0.1;
  }

  .SlideIn-leave {
    opacity: 1;
    transform: translateX(0);
  }

  .SlideIn-leave.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-15px);
    transition: all 0.075s linear;
  }

  .FadeIn-appear {
    opacity: 0;
  }

  .FadeIn-appear.FadeIn-appear-active {
    opacity: 1;
    transition: all 0.1s linear;
  }

  .FadeIn-enter {
    opacity: 0;
    transform: translateX(30px);
  }

  .FadeIn-enter.FadeIn-enter-active {
    opacity: 1;
    transition: all 0s linear 0.1s;
  }

  .FadeIn-leave {
    opacity: 1;
  }

  .FadeIn-leave.FadeIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transition: all 0.1s linear;
  }

  a.block {
    display: flex;
    width: 100%;
  }

  a:focus,
  button:focus {
    outline-style: solid;
    outline-width: 2px;
  }

  a[disabled],
  a.disabled {
    pointer-events: none;
    touch-action: none;
  }

  @media (min-width: ${theme.devices.tablet}px) {
    #root {
      .modal {
        .modal__overlay {
          padding: 30px;
        }

        .modal__content {
          z-index: 12;
          position: relative;
          background: white;
          overflow: auto;
          border-radius: 4px;
          padding: 40px 30px 30px;
          width: 600px;
          margin: 0 auto;
          align-self: flex-start;
          overflow-x: hidden;

          &.modal__content--small {
            align-self: center;
            width: 375px;
          }

          .closeModalButton {
            right: 30px;
          }
        }

        .modal__with__dropdown {
          overflow: visible !important;
        }
      }
    }
  }
`;

export default GlobalStyles;
