import { connect } from 'react-redux';

import { RootState } from '../../redux';

import { adaptGoalById, adaptHabitById, getHabitParentGoalId } from '../../utils/adapters/goals';
import { actions as goalActions } from '../../redux/goals.slice';

import HabitEditRepetitions from './HabitEditRepetitions';

const { updateHabit } = goalActions;

const mapState = (state: RootState, props: any) => {
  try {
    const { id } = props.match.params;
    const goalId = getHabitParentGoalId(state, id);
    return {
      ...adaptHabitById(state, id),
      goal: adaptGoalById(state, goalId || ''),
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, { updateHabit })(HabitEditRepetitions);
