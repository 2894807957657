import React, { FC, useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useAppDispatch, useAppSelector } from '../../utils/hooks';
import { JournalEntry } from '../../utils/types';
import {
  createUserJournalEntry,
  NewJournalEntry,
  UpdatedJournalEntry,
  updateUserJournalEntry,
} from '../../redux/userJournal.slice';


export interface Toolbar {
  hideSubmitButton?: boolean;
}

interface Props {
  value?: string;
  placeholder?: string;
  toolbar?: Toolbar;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  isExperiment?: boolean;
  question?: string;
  journalId?: string;
  blockType?: string;
}

const RichTextEditor: FC<Props> = ({
  value = '',
  toolbar,
  placeholder,
  onChange,
  autoFocus = false,
  isExperiment = false,
  question,
  journalId='',
  blockType,
}) => {
  const dispatch = useAppDispatch();
  const [editorValue, setEditorValue] = useState('');
  const [parentId, setParentId] = useState('');
  const editorRef = useRef<any>(null);
  const [journalEntryResponse, setjournalEntryResponse] = useState('');
  const journalEntries = useAppSelector((state) => state.userJournal.journalEntries);
  const { id } = useAppSelector((state) => state.user);
  const [journalEntry, setJournalEntry] = useState<JournalEntry | null>(null);
  const { id: journeyId } = useAppSelector((state) => state.journey);
  const { programmes } = useAppSelector((state) => state.programmes);
  const [characterCount, setCharacterCount] = useState(Number);
   
  useEffect(() => {
    if(programmes.length) {
      const currentProgramme = programmes.find((programme) => programme.journeys.includes(journeyId ? journeyId : ''));
      currentProgramme && setParentId(currentProgramme._id);
    }
  }, []);

  useEffect(() => {
    if (autoFocus && editorRef.current) editorRef.current.focus();
  }, []);

  useEffect(() => {
    setEditorValue(value);
  }, [value]);

  useEffect(() => {
    if(editorValue && blockType === 'journalCapture' && question) {
      localStorage.setItem(`sourceId1_${journalId}`, journalId);
      localStorage.setItem(`response1_${journalId}`, editorValue);
      localStorage.setItem(`question1_${journalId}`, question);
      localStorage.setItem(`parentId1_${journalId}`, parentId);
      localStorage.setItem(`journalEntryId_${journalId}`, journalEntry?._id || '');
      localStorage.setItem(`blocktype_${journalId}`, blockType);
    }
    if (editorValue !== '' && onChange) {
      onChange(editorValue);
    }
  }, [editorValue]);
  useEffect(() => {
    if (journalEntries) {
      // @ts-ignore
      const foundEntry = journalEntries.find((journal) => journal.question === question && journal.userId === id);
      if (foundEntry) {
          setJournalEntry(foundEntry);
          if(value && value.length)setEditorValue(foundEntry.response || '')
          return;
        }
      }
  }, [journalEntries]);

  const saveAnswer = () => { 
    const newJournalEntry: NewJournalEntry = {
     sourceId: journalId,
     response: editorValue,
     question: question,
     parentId,
   };

   const updatedJournalEntry: UpdatedJournalEntry = {
     ...newJournalEntry,
     journalEntryId: journalEntry?._id,
   };

   if (!journalEntry) {
     dispatch(createUserJournalEntry(newJournalEntry));
   } else {
     dispatch(updateUserJournalEntry(updatedJournalEntry));
   }
   localStorage.removeItem(`sourceId1_${journalId}`);
   localStorage.removeItem(`response1_${journalId}`);
   localStorage.removeItem(`question1_${journalId}`);
   localStorage.removeItem(`parentId1_${journalId}`);
   localStorage.removeItem(`journalEntryId_${journalId}`);
   localStorage.removeItem(`blocktype_${journalId}`);   
 };  

  const modules = {
    toolbar: [['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }]],
  };

  return (
    <Styled.RichTextEditor tabIndex={0} isExperiment={isExperiment}>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        value = {editorValue}
        onChange={(value: any) => {
          setEditorValue(value)
          setCharacterCount(editorRef?.current?.state?.selection?.index);}}
        modules={modules}
        ref={editorRef}
        onBlur={(blockType === 'journalCapture' && question) ? saveAnswer : () => {}}
      />
      {!toolbar?.hideSubmitButton && !isExperiment && (
        <Styled.SaveButton disabled={characterCount === 0} type="submit" color="primary" size="small" onClick={saveAnswer}>
          Save
        </Styled.SaveButton>
      )}
    </Styled.RichTextEditor>
  );
};

export default RichTextEditor;
