import styled, { css } from 'styled-components';

interface InsightsWallModalContentContainerProps {}
interface DropdownContainerProps {
  index: number;
}

export const Header = styled.nav<{ isFacilitator: boolean; isFacLearner:boolean}>`
  width: 100%;
  background-color: ${(p) => ((p.isFacilitator && !p.isFacLearner)? p.theme.colours.black : p.theme.colours.primary)};
  z-index: 100;
`;

export const HeaderContentWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  max-width: 1024px;
  margin: 0 auto;
  padding: ${(p) => (p.isMobile ? `0 21px` : `0 ${p.theme.spacing(5)}`)};
  z-index: 100;
`;

export const Logo = styled.img`
  width: 109px;
  height: 30px;
`;

export const MenuWrapper = styled.div<{ shouldNotify: boolean }>`
  position: relative;
  display: block;
  z-index: 100;

  ${(p) =>
    p.shouldNotify &&
    css`
      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 0px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: ${(p) => p.theme.colours.red};
        box-shadow: ${(p) => p.theme.shadows};
      }
    `}
`;

export const MenuButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 42px;
  opacity: 0.5;
  transition: all 0.2s;
  cursor: pointer;
  background: transparent;
  border: none;

  span {
    color: ${(p) => p.theme.colours.white};
    font-size: ${(p) => p.theme.typography.font.size.small};
    letter-spacing: 0;
    line-height: 15px;
    padding-top: ${(p) => p.theme.spacing(1)};
  }

  &:hover,
  :active,
  :focus {
    opacity: 1;
  }
`;

export const DesktopMenu = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  width: 415px;
  position: absolute;
  top: 78px;
  left: -332px;
  border-radius: 0 0 ${(p) => p.theme.borders.radius} ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(3)};
  box-shadow: ${(p) => p.theme.shadows[0]};
  background: ${(p) => p.theme.colours.white};
  z-index: 5;
  z-index: 100;
`;

export const List = styled.ul<{ shouldNotify: boolean }>`
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    position: relative;
    margin-bottom: 16px;
  }

  > li:nth-of-type(2) {
    ${(p) =>
      p.shouldNotify &&
      css`
        &:after {
          content: '';
          position: absolute;
          pointer-events: none;
          top: 40%;
          right: 48px;
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background: ${(p) => p.theme.colours.red};
          box-shadow: ${(p) => p.theme.shadows};
        }
      `}
  }
`;

export const InsightsWallModalContentContainer = styled.div<InsightsWallModalContentContainerProps>`
  ${({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  })}

  > * {
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

export const DropdownContainer = styled.div<DropdownContainerProps>`
  z-index: ${(p) => p.index * (1 / 10)};
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
