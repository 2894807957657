import React, { FC, ReactElement, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import get from 'lodash.get';
import Unauthorized from './screens/Unauthorized';
import LoginScreen from './screens/Login';
import AppScreen from './screens/App';
import Policy from './screens/Policy';
import LearningJourneyError from './screens/LearningJourneyError/LearningJourneyError';

import store, { RootState } from './redux';
import { SetLoginRedirectPayload, actions } from './redux/auth.slice';

import { ThemeProvider } from 'styled-components';
import theme from './utils/theme';
import GlobalStyles from './styles/globalStyles';

import { Auth0Provider } from '@auth0/auth0-react';
import { auth0domain } from './utils/model';

import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/appInsights';
import { updateUserMeta } from './utils/api/user';
import { sendMessageToTeams } from './utils/api/auth';

type Props = {
  email:string;
  accessToken: string;
  refreshToken: string;
  setLoginRedirect: ActionCreatorWithPayload<SetLoginRedirectPayload>;
  logout: Function;
};

const App: FC<Props> = ({ email,accessToken, refreshToken, setLoginRedirect, logout }: Props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const params = new URL(document.location.href).searchParams;
  if (window.location.search.includes('isNativeWebView=true')) localStorage.setItem('isNativeWebView', 'true');
  if (!window.location.search.includes('isNativeWebView=true')) localStorage.setItem('isNativeWebView', 'false');
  if (window.location.search.includes('safeAreaTop'))
    localStorage.setItem('safeAreaTop', params.get('safeAreaTop') || '0');

  // push

  useEffect((): (() => void) | undefined => {
    // do not redirect to login when viewing policy screen
    if (window.location.pathname.indexOf('/policy') === 0) {
      setIsLoading(false);
      return;
    }
    // do not redirect to login when unauthorized
    if (window.location.pathname.indexOf('/unauthorized') === 0) {
      setIsLoading(false);
      sendMessageToTeams('unauthorized')
      return;
    }
    if (window.location.pathname.indexOf('/learning-journey-error') === 0) {
      setIsLoading(false);
      return;
    }
    // if there is no auth tokens do this
    if (accessToken.length + refreshToken.length === 0) {
      // setSeenOnboarding(false);
      // if the user loaded on an app page - but has no auth, redurect ot his page on login
      if (window.location.pathname.indexOf('/app/') === 0) {
        setLoginRedirect({ pathname: window.location.pathname + window.location.search });
      }
      // push to the root / login page
      if (window.location.pathname.indexOf('/callback') === -1) {
        history.push(`/${window.location.search}`);
      }
    } else {
      // setSeenOnboarding(true);
      // if there are auth tokens and if the usr is not on an app page
      if (window.location.pathname.indexOf('/app/') === -1) {
        history.push('/app/home');
      }
      updateUserMeta(accessToken);
    }

    setIsLoading(false);
    return (): void => {};
  }, [accessToken, refreshToken]);

  if (isLoading) return null;

  // return policy screen outside of app
  if (window.location.pathname.indexOf('/policy') === 0) {
    return <Policy />;
  }

  if (window.location.pathname.indexOf('/unauthorized') === 0) {
    sendMessageToTeams('unauthorized')
    return <Unauthorized  logout={logout}/>;
  }

  if (window.location.pathname.indexOf('/learning-journey-error') === 0) {
    sendMessageToTeams('cohort-error')
    return <LearningJourneyError logout={logout} />;
  }

  return (
    <Switch>
      <Route path="/app" component={AppScreen} />
      <Route path="/" component={LoginScreen} />
    </Switch>
  );
};

const mapState = (state: RootState) => ({
  email: get(state,'auth.email',''),
  accessToken: get(state, 'auth.accessToken', ''),
  refreshToken: get(state, 'auth.refreshToken', ''),
});

const { setLoginRedirect, logout } = actions;

const AppConnected = connect(mapState, { setLoginRedirect, logout })(App);

const AppWrapper = (): ReactElement => (
  <Provider store={store}>
    <Router basename={process.env.PUBLIC_URL}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Auth0Provider
          domain={auth0domain}
          audience={`${process.env.REACT_APP_AUDIENCE}`}
          clientId={`${process.env.REACT_APP_AUTHOH_USER_CLIENT_ID}`}
          redirectUri={`${window.location.origin}/callback`}
          useRefreshTokens={true}
          scope={'openid offline_access profile email'}
          cacheLocation={'localstorage'}>
          <AppInsightsContext.Provider value={reactPlugin}>
            <AppConnected />
          </AppInsightsContext.Provider>
        </Auth0Provider>
      </ThemeProvider>
    </Router>
  </Provider>
);

export default AppWrapper;
