import { css } from 'styled-components';
import theme from '.';
import { BorderStyle, CreateBorder, Position } from '../types/theme';
import { moduleColours, main, grey, common } from './palette';

const createBorderStyle = (position: Position, style: BorderStyle) => {
  let top = 'none';
  let right = 'none';
  let bottom = 'none';
  let left = 'none';

  if (position === 'top') top = style;
  if (position === 'right') right = style;
  if (position === 'bottom') bottom = style;
  if (position === 'left') left = style;

  return `${top} ${right} ${bottom} ${left}`;
};

export const createBorder: CreateBorder = (color, size, style, position) => {
  const borderWidth = `${size || 1}px`;
  const borderColor = `${theme.colours[color || 'mediumGrey']}`;

  const borderStyle = `${style || 'solid'}` as BorderStyle;
  const borderPostion = `${position || 'bottom'}` as Position;

  return css`
    border-width: ${borderWidth};
    border-style: ${createBorderStyle(borderPostion, borderStyle)};
    border-color: ${borderColor};
  `;
};

export type colourPalette = 'module' | 'common' | 'main' | 'grey';

const getColourObj = (colours?: colourPalette) => {
  switch (colours) {
    case 'module':
      return moduleColours;
    case 'main':
      return main;
    case 'common':
      return common;
    case 'grey':
      return grey;
    default:
      return theme.colours;
  }
};

export const colourExistsInTheme = (colour: string, colours?: colourPalette) => {
  if (!colour) return false;
  const colourObj = getColourObj(colours);
  const allColours = Object.values(colourObj);
  return allColours.includes(colour);
};
