import React, { PropsWithChildren, useState, useEffect } from 'react';
import InsightCard from '../InsightCard';
import { InsightCardsWrapperProps } from './types';
import { InsightsWallContainer, InsightColumn, SvgBackground } from './styles';
import { InsightCardProps } from '../InsightCard/types';
import { useViewport } from '../../utils/hooks';
import theme from '../../utils/theme';

const InsightCardsWrapper: React.FC<InsightCardsWrapperProps> = ({
  insights,
  cancelLoader,
}: PropsWithChildren<InsightCardsWrapperProps>): JSX.Element => {
  const [column1, setColumn1] = useState<InsightCardProps[]>([]);
  const [column2, setColumn2] = useState<InsightCardProps[]>([]);
  const [column3, setColumn3] = useState<InsightCardProps[]>([]);
  const { width: deviceWidth } = useViewport();

  useEffect(() => {
    if (insights === null || !insights.length) return;
    
    if (deviceWidth > 1211) {
      resetColumnData();
      setColumn1(insights.slice(0, insights.length / 3));
      setColumn2(insights.slice(insights.length / 3, (insights.length * 2) / 3));
      setColumn3(insights.slice((insights.length * 2) / 3, insights.length));
    }

    if (deviceWidth < 1210 && deviceWidth > 767.98) {
      resetColumnData();
      setColumn1(insights.slice(0, insights.length / 2));
      setColumn2(insights.slice(insights.length / 2, insights.length));
    }

    if (deviceWidth < 767.97) {
      resetColumnData();
      setColumn1(insights);
    }

    cancelLoader();
  }, [insights]);

  const resetColumnData: () => void = () => {
    setColumn1([]);
    setColumn2([]);
    setColumn3([]);
  };

  if (insights === null || !insights.length) {
    return (
      <InsightsWallContainer>
        <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
          <SvgBackground imageUrl={'/images/insightBackgrounds/emptyInsight.svg'}></SvgBackground>
        </InsightColumn>

        {deviceWidth > 767 && (
          <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
            <SvgBackground imageUrl={'/images/insightBackgrounds/emptyInsight.svg'}></SvgBackground>
          </InsightColumn>
        )}

        {deviceWidth > 1000 && (
          <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
            <SvgBackground imageUrl={'/images/insightBackgrounds/emptyInsight.svg'}></SvgBackground>
          </InsightColumn>
        )}
      </InsightsWallContainer>
    );
  }

  return (
    <InsightsWallContainer>
      {column1.length > 0 && (
        <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
          {column1.map((element: any, index: number) => (
            <InsightCard
              entryId={element.id}
              userId={element.userId}
              title={element.title}
              publisherName={element.publisherName}
              content={element.content}
              color={element.color}
              iconName={element.icon}
              imageUrl={element.image}
              type={element.type}
              createdAt={element.createdAt}
              updatedAt={element.updatedAt}
              key={element.id}
              activityId={element.activityId}
              experimentId={element.experimentId}
              insightDetails={{
                moduleId: element.moduleId,
                activityId: element.activityId,
                experimentId: element.experimentId,
                cohortId: element.cohortId,
                journeyId: element.journeyId,
              }}
              isWallView
            />
          ))}
        </InsightColumn>
      )}
      {column2.length > 0 && (
        <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
          {column2.map((element: any, index: number) => (
            <InsightCard
              entryId={element.id}
              userId={element.userId}
              title={element.title}
              publisherName={element.publisherName}
              content={element.content}
              color={element.color}
              iconName={element.icon}
              imageUrl={element.image}
              type={element.type}
              createdAt={element.createdAt}
              updatedAt={element.updatedAt}
              key={element.id}
              activityId={element.activityId}
              experimentId={element.experimentId}
              insightDetails={{
                moduleId: element.moduleId,
                activityId: element.activityId,
                experimentId: element.experimentId,
                cohortId: element.cohortId,
                journeyId: element.journeyId,
              }}
              isWallView
            />
          ))}
        </InsightColumn>
      )}
      {column3.length > 0 && (
        <InsightColumn isMobile={deviceWidth < theme.devices.mobile}>
          {column3.map((element: any, index: number) => (
            <InsightCard
              entryId={element.id}
              userId={element.userId}
              title={element.title}
              publisherName={element.publisherName}
              content={element.content}
              color={element.color}
              iconName={element.icon}
              imageUrl={element.image}
              type={element.type}
              createdAt={element.createdAt}
              updatedAt={element.updatedAt}
              key={element.id}
              activityId={element.activityId}
              experimentId={element.experimentId}
              insightDetails={{
                moduleId: element.moduleId,
                activityId: element.activityId,
                experimentId: element.experimentId,
                cohortId: element.cohortId,
                journeyId: element.journeyId,
              }}
              isWallView
            />
          ))}
        </InsightColumn>
      )}
    </InsightsWallContainer>
  );
};

export default InsightCardsWrapper;
