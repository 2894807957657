export const resizeImage = (
  imageUrl: string | null | undefined,
  width: number,
  height: number,
  cropMode: 'fill' | 'fit' = 'fill',
): string => {
  if (!imageUrl) {
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
  }
  if (imageUrl.indexOf('https://res.cloudinary.com/strata-eu/image/upload/') === -1) {
    return imageUrl;
  }

  const imageUrlAppend = imageUrl.replace('https://res.cloudinary.com/strata-eu/image/upload/', '');

  const attributes = [
    `w_${Math.floor(width * window.devicePixelRatio)}`,
    `h_${Math.floor(height * window.devicePixelRatio)}`,
    `c_${cropMode}`,
    'q_auto:good',
    'fl_any_format',
  ];

  return `https://res.cloudinary.com/strata-eu/image/upload/${attributes.join(',')}/${imageUrlAppend}`;
};

export const isUrl = (url: string) => {
  try {
    return Boolean(new URL(url));
  } catch (error) {
    return false;
  }
};

export const isHTML = (text: string) => {
  try {
    const fragment = new DOMParser().parseFromString(text, 'text/html');
    return fragment.body.children.length > 0;
  } catch (error) {}
  return false;
};
