import React, { FC, TextareaHTMLAttributes } from 'react';
import styles from './styles.module.scss';

import classnames from 'classnames';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: any;
  register?: any;
  validate?: any;
  alignChildren?: 'center' | 'right';
  testId?: string;
}

const TextArea: FC<Props> = ({
  label,
  name,
  id,
  error,
  register,
  validate,
  placeholder,
  onChange,
  value,
  children,
  autoFocus = false,
  alignChildren = 'center',
  testId,
}) => {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}

      <textarea
        id={id}
        className={classnames(styles.input, { [styles.error]: !!error })}
        name={name}
        ref={validate ? register(validate) : register}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        autoFocus={autoFocus}
        data-test-id={testId}
      />

      {children && (
        <div
          className={styles.saveBtn}
          style={alignChildren === 'center' ? { justifyContent: 'center' } : { justifyContent: 'flex-end' }}>
          {children}
        </div>
      )}
    </>
  );
};

export default TextArea;
