import { Activity, Experiment, Module } from '../../utils/types';

export type ActivityDisplayData = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  completed: boolean;
  type: string;
  experiment?: Experiment;
  isExperimentStarted?: boolean;
  meta?: { _id?: string; cohortId: string; deadline?: Date; startDate: Date };
  experimentId?: string;
};

export type ModuleDisplayData = {
  title: string;
  active: boolean;
  description: string;
  imageUrl: string;
  iconName: any;
  iconColor: string;
  id?: string;
  totalActivities: number;
  completedActivities: number;
  activities: Array<ActivityDisplayData>;
};

export const adaptModuleById = (modules: Array<Module>, id: string): ModuleDisplayData | null =>
  modules
    .map((module: Module) => {
      if (module._id !== id) return null;
      return {
        title: module.title,
        active: module.active,
        description: module.subtitle,
        imageUrl: module.imageUrl,
        iconName: module.iconName,
        iconColor: module.iconColor,
        id: module._id,
        totalActivities: module.activities.length,
        // checking experiment against record.completionDate as
        // when the user selects an Experiment inside an experiment type activity, it should get compelted until the experiment is completed within that activity
        completedActivities: module.activities.reduce(
          (acc: number, activity: Activity) =>
            acc + ((activity.type === 'experiment' ? activity.record?.completedDate : activity.record) ? 1 : 0),
          0,
        ),
        activities: module.activities.map((activity: Activity) => ({
          title: activity.title,
          imageUrl: activity.imageUrl,
          id: activity._id,
          completed: activity.type === 'experiment' ? !!activity.record?.completedDate : !!activity.record,
          type: activity.type,
          description: activity.description,
          isExperimentStarted: !!activity.record?.experimentId,
          meta: activity.meta,
          experimentId: activity.record?.experimentId,
        })),
      };
    })
    .filter(Boolean)[0];
