import React, { PropsWithChildren, useEffect, useState } from 'react';
import { InsightCardProps } from './types';
import {
  CardContainer,
  HeaderWrapper,
  Title,
  ImageBackground,
  SvgBackground,
  BodyWrapper,
  Type,
  Publisher,
  Content,
  PublishDate,
  OptionsWrapper,
  OptionsDropdown,
  Option,
  OptionButton,
} from './styles';
import Icon, { IconName } from '../Icon';
import parse from 'html-react-parser';
import theme from '../../utils/theme';
import { useWindowDimensions } from 'react-native';
import dayjs from 'dayjs';
import IconButton from '../IconButton';
import Modal from '../Modal';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import ExperimentInsightModal from '../ExperimentInsightModal';
import { Experiment, JournalEntry, Module } from '../../utils/types';
import { getExperiment } from '../../utils/api/experiments';
import { FinalContent } from '../InsightEditOptions/types';
import InsightConfirmationModal from '../InsightConfirmationModal';
import { JournalEntryPayload, actions as activityActions } from '../../redux/experiment.slice';
import { JourneyState, actions as journeyActions } from '../../redux/journey.slice';
import { editUserJournalEntry } from '../../redux/userJournal.slice';
import { deleteJournalEntryByUserId } from '../../utils/api/learnerJournal';
import { actions as journalActions } from '../../redux/userJournal.slice';
import { deleteActivityProgress } from '../../utils/api/journey';
import { RootState } from '../../redux';
import { UserState } from '../../redux/user.slice';
import 'react-toastify/dist/ReactToastify.css';
import { acitivtyContent, fireToastMessage, getIsManualUpdateDateNewerThanCardUpdateDate } from './helpers';

const InsightCard: React.FC<InsightCardProps> = ({
  entryId,
  userId,
  iconName = null,
  imageUrl = null,
  title,
  publisherName,
  content,
  color,
  type,
  createdAt,
  updatedAt,
  isWallView = false,
  activityId,
  experimentId,
  insightDetails,
}: PropsWithChildren<InsightCardProps>): JSX.Element => {
  const dispatch = useAppDispatch();
  const { width: deviceWidth } = useWindowDimensions();
  const { accessToken } = useAppSelector((state) => state.auth);
  const modules: Module[] = useAppSelector((state): Module[] => state.journey.modules);
  const currentEntry: JournalEntryPayload = useAppSelector(
    (state): JournalEntryPayload => state.experiment.currentEntryResponse,
  );
  const currentInisghtJournalEntryId: string = useAppSelector(
    (state): string => state.experiment.currentInsightJournalEntryId,
  );
  const currentInsightJournalEntryUserId: string = useAppSelector(
    (state): string => state.experiment.currentInsightJournalEntryUserId,
  );
  const { roles, id: storeUserId } = useAppSelector((state: RootState): UserState => state.user);
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const { isPreview } = useAppSelector((state: RootState): JourneyState => state.journey);
  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  const isFacilitatorOfSelectedCohort:boolean = isFacilitator && selectedCohort!.facilitatorIds.includes(storeUserId); 
  const shouldShowOptions: boolean = !isPreview && isWallView && (userId === storeUserId || isFacilitatorOfSelectedCohort);

  const [optionsDropdownIsVisible, setOptionsDropdownIsVisible] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState<boolean>(false);
  const [insightModalIsOpen, setInsightModalIsOpen] = useState<boolean>(false);
  const [experiment, setExperiment] = useState<Experiment>({} as Experiment);

  const publicationDate: string = !!createdAt
    ? dayjs(createdAt).format('DD/MM/YYYY')
    : dayjs(Date.now()).format('DD/MM/YYYY');

  const shouldShowUpdatedDate: boolean = updatedAt !== createdAt;
  const updateDate: string = dayjs(updatedAt).format('DD/MM/YYYY');

  useEffect((): (() => void) => {
    (async (): Promise<void> => {
      if (!experimentId) return;
      const experiment = await getExperiment(experimentId, accessToken);

      if (experiment) {
        setExperiment(experiment);
      }
    })();

    return (): void => {};
  }, []);

  const updateJourneyEntry: (currentEntry: JournalEntryPayload) => void = (currentEntry: JournalEntryPayload): void => {
    const objCopy: JournalEntry = {
      ...currentEntry,
      meta: { ...currentEntry.meta, ...insightDetails },
    };

    const insightStyleDetails = {
      journalEntryId: currentEntry.journalEntryId,
      content: currentEntry.response,
      icon: currentEntry?.meta?.icon || null,
      image: currentEntry?.meta?.image || null,
      color: currentEntry?.meta?.color,
      type: currentEntry?.meta?.type,
      updatedAt: Date.now(),
    };

    dispatch(editUserJournalEntry(objCopy));
    dispatch(activityActions.updateInsightContent(insightStyleDetails));
  };

  const deleteJournalEntry: (entryId: string | undefined, userId: string | undefined) => void = async (
    entryId: string | undefined,
    userId: string | undefined,
  ): Promise<void> => {
    deleteJournalEntryByUserId({ accessToken, refreshToken: '' }, { entryId, userId });
    await deleteActivityProgress(accessToken, activityId);
  };

  const onSave = async (): Promise<void> => {
    deleteJournalEntry(entryId, userId);
    dispatch(journeyActions.removeActivityRecord(activityId)); // positive update
    dispatch(journalActions.removeUserJournalEntry(entryId));
    dispatch(activityActions.removeInsightFromStore(entryId)); // positive update
    setOpenDeleteConfirmationModal(false);
    dispatch(activityActions.setJournalEntries({}));
    if (fireToastMessage) fireToastMessage('delete');
  };
  const onUpdate = async (): Promise<void> => {
      updateJourneyEntry({
        ...currentEntry,
        journalEntryId: currentInisghtJournalEntryId,
        userId: currentInsightJournalEntryUserId,
      });
      setOpenConfirmationModal(false);
      dispatch(activityActions.setJournalEntries({}));
      // setManualUpdateDate(dayjs(Date.now()).format('DD/MM/YYYY'));
      if (fireToastMessage) fireToastMessage('edit');    
  };
  return (
    <CardContainer isMobile={deviceWidth < theme.devices.mobile} tabIndex={0} aria-label={`Insight post - ${title}`}>
      <HeaderWrapper color={type == 'image' ? 'white' : color}>
        <Title>{title}</Title>
        {type == 'image' && imageUrl && <ImageBackground imageUrl={imageUrl} key={imageUrl}></ImageBackground>}
        {type == 'icon' && iconName && <Icon name={iconName as IconName} size="large" color="white" key={iconName} />}
        <SvgBackground imageUrl={'/images/insightBackgrounds/image-mask.svg'}></SvgBackground>
      </HeaderWrapper>
      <BodyWrapper>
        <PublishDate tabIndex={0}>{publicationDate}</PublishDate>
        <Type color={color}>{'Experiment Insights'}</Type>
        <Publisher tabIndex={0}>{publisherName}</Publisher>
        <Content tabIndex={0}>{content && parse(content)}</Content>
      </BodyWrapper>

      <>
        <OptionsWrapper>
          <div>
            {shouldShowUpdatedDate && <span>Post edited: {updateDate}</span>}
          </div>
          {shouldShowOptions && (
            <IconButton
              aria-label={`Insight post ${title} options`}
              onClick={(): void => setOptionsDropdownIsVisible(!optionsDropdownIsVisible)}
              style={{ justifySelf: 'right', cursor: 'pointer' }}
              onMouseOver={(e: React.MouseEvent): void => {
                //@ts-ignore
                e.target.style.background = theme.colours.lilac;
              }}
              onMouseLeave={(e: React.MouseEvent): void => {
                //@ts-ignore
                e.target.style.background = theme.colours.white;
              }}>
              <Icon name="options"></Icon>
            </IconButton>
          )}
        </OptionsWrapper>
        {(userId === storeUserId || isFacilitatorOfSelectedCohort) && (
        <OptionsDropdown
          id="insight_card_options_dropdown"
          isOpen={optionsDropdownIsVisible}
          onMouseLeave={(): void => setOptionsDropdownIsVisible(false)}>          
          <Option
            onMouseOver={(e: React.MouseEvent): void => {
              //@ts-ignore
              e.target.style.background = theme.colours.lilac;
            }}
            onMouseLeave={(e: React.MouseEvent): void => {
              //@ts-ignore
              e.target.style.background = theme.colours.white;
            }}
            onClick={(): void => {
              dispatch(activityActions.setCurrentInsightJournalEntryUserId(userId));
              dispatch(activityActions.setCurrentInsightJournalEntryId(entryId));
              dispatch(activityActions.setCurrentEntryResponse(content));
              setInsightModalIsOpen(true);
            }}>
            <OptionButton>Edit post</OptionButton>
          </Option>          
          {isFacilitatorOfSelectedCohort && (
            <Option
              onMouseOver={(e: React.MouseEvent): void => {
                //@ts-ignore
                e.target.style.background = theme.colours.lilac;
              }}
              onMouseLeave={(e: React.MouseEvent): void => {
                //@ts-ignore
                e.target.style.background = theme.colours.white;
              }}
              onClick={(): void => setOpenDeleteConfirmationModal(true)}>
              <OptionButton>Delete post</OptionButton>
            </Option>
          )}
        </OptionsDropdown>
        )}
        <Modal
          isOpen={insightModalIsOpen}
          onClose={(): void => {
            setInsightModalIsOpen(false);
            dispatch(activityActions.setJournalEntries({}));
          }}>
          <ExperimentInsightModal
            isCompleted={false}
            activityId={activityId}
            activityContent={acitivtyContent(modules, activityId)}
            experiment={experiment}
            onCancel={(): void => setInsightModalIsOpen(false)}
            onCompletion={(): void => {}}
            iconName={'activity'}
            isInsightWallEditorView
            insightWallEditorMetadata={{ color, image: imageUrl, type, icon: iconName } as FinalContent}
            openConfirmationModal={(): void => setOpenConfirmationModal(true)}
          />
        </Modal>
        <InsightConfirmationModal
          type="update"
          isOpen={openConfirmationModal}
          onCancel={(): void => {
            setOpenConfirmationModal(false);
            dispatch(activityActions.setJournalEntries({}));
          }}
          onSave={onUpdate}
        />
        <InsightConfirmationModal
          type="delete"
          isOpen={openDeleteConfirmationModal}
          onCancel={(): void => {
            setOpenDeleteConfirmationModal(false);
            dispatch(activityActions.setJournalEntries({}));
          }}
          onSave={onSave}
        />
      </>
    </CardContainer>
  );
};

export default React.memo(InsightCard);
