import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCohorts as getCohortsFromApi,
  GetCohortsResponse,
  getCohortMeta as getCohortMetaFromAPI,
  getCohortsOfFacilitator as getCohortsOfFacilitatorFromApi,
} from '../utils/api/cohorts';
import { generateReauthenticatingThunkApiAction } from './helpers';
import { RootState, AppDispatch } from '.';
import { Cohort, UserRecord } from '../utils/types';
import { getCohortUsers } from '../utils/api/user';

interface CohortMetaResponse {
  activities: {
    [key: string]: {
      activityId: string;
      endDate: Date;
      startDate: Date;
      _id: string;
    };
  };
  cohortId: string;
  id: string;
  oprganisationId: string;
  _id?: string;
}

export interface CohortUserRecordsResponse extends UserRecord {
  userName: string;
}

export type CohortsState = {
  cohorts: Array<Cohort>;
  selectedCohort: Cohort;
  cohortMeta: CohortMetaResponse;
  cohortUserRecords: CohortUserRecordsResponse[];
  cohortsOfFacilitator: Array<Cohort>;
};

const initialState: CohortsState = {
  cohorts: [],
  selectedCohort: {} as Cohort,
  cohortMeta: {} as CohortMetaResponse,
  cohortUserRecords: [],
  cohortsOfFacilitator:[],
};

const getCohorts = createAsyncThunk<
  GetCohortsResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'cohorts/getCohorts',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const cohorts = await getCohortsFromApi(accessToken);
    return cohorts;
  }),
);
const getCohortsOfFacilitator = createAsyncThunk<
  GetCohortsResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'cohorts/getCohortsOfFacilitator',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const cohortsOfFacilitator = await getCohortsOfFacilitatorFromApi(accessToken);
    return cohortsOfFacilitator;
  }),
);
const getCohortMeta = createAsyncThunk<
  CohortMetaResponse,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'cohorts/getCohortMeta',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const { cohortId } = state.cohorts.selectedCohort;
    const meta = await getCohortMetaFromAPI(cohortId, accessToken);
    return meta;
  }),
);

const getCohortUserRecords = createAsyncThunk<
  CohortUserRecordsResponse[],
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'cohorts/getCohortUserRecords',
  generateReauthenticatingThunkApiAction(async (state: RootState) => {
    const { accessToken } = state.auth;
    const { cohortId } = state.cohorts.selectedCohort;
    const cohortUsers = await getCohortUsers(cohortId, accessToken);

    const newUserRecordList: any[] = [];

    if (cohortUsers.users) {
      cohortUsers.userRecords.map((record: any) => {
        const userData = cohortUsers.users[record.userId!];
        if (userData) {
          newUserRecordList.push({
            ...record,
            userName: `${userData.FirstName} ${userData.LastName}`,
          });
        }
      });
    }

    return newUserRecordList;
  }),
);

const cohorts = createSlice({
  name: 'cohorts',
  initialState,
  reducers: {
    setSelectedCohort(state, { payload }): void {
      const foundCohort = state.cohorts.find((cohort: Cohort) => cohort.cohortId == payload);
      if (foundCohort) state.selectedCohort = foundCohort;
    },
    setSelectedFacCohort(state, { payload }): void {
      let foundFacCohort = state.cohortsOfFacilitator.find((cohort: Cohort) => cohort.cohortId == payload); 
      if(!foundFacCohort)
        foundFacCohort = state.cohorts.find((cohort: Cohort) => cohort.cohortId == payload); 
      if (foundFacCohort) state.selectedCohort = foundFacCohort;  
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(getCohorts.fulfilled, (state, { payload }) => {
      state.cohorts = payload;
    });    
    builder.addCase(getCohortsOfFacilitator.fulfilled, (state, { payload }) => {
      state.cohortsOfFacilitator = payload;
    });
    builder.addCase(getCohortMeta.fulfilled, (state, { payload }) => {
      state.cohortMeta = payload;
    });
    builder.addCase(getCohortUserRecords.fulfilled, (state, { payload }) => {
      state.cohortUserRecords = payload;
    });
  },
});

export default cohorts.reducer;

export const actions = {
  ...cohorts.actions,
  getCohorts,
  getCohortMeta,
  getCohortUserRecords,
  getCohortsOfFacilitator,
};
