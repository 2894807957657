import { connect } from 'react-redux';
import get from 'lodash.get';
import OtherParticipantsModal from './OtherParticipantsModal';
import { RootState } from '../../redux';

const mapState = (state: RootState) => {
  try {
    return {
      currentUserId: get(state, 'user.id'),
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, {})(OtherParticipantsModal);
