import React, { FC, useEffect, useState } from 'react';
import { ModuleDisplayData } from './adapters';
import { useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import OnboardingCarousel from '../../containers/OnboardingCarousel/OnboardingCarousel';
import StandardJourney from '../../components/layouts/Journey/StandardJourney';
import FlexibleJourney from '../../components/layouts/Journey/FlexibleJourney';
import { JourneyState } from '../../redux/journey.slice';
import { UserState } from '../../redux/user.slice';
import get from 'lodash.get';
import { RootState } from '../../redux';
import { hasSeenModal, setSeenModal } from '../../utils/checkModalLocalStorage';
import Modal from '../../components/Modal';
import onboarding from '../../containers/OnboardingCarousel/OnboardingCarousel.content';
import JourneyProgress from '../../components/Homepage/JourneyProgress';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

type Props = {
  modules: Array<ModuleDisplayData>;
  allModulesCompleted: boolean;
  journey: JourneyState;
};

const LearningJourney: FC<Props> = ({ modules, allModulesCompleted, journey }) => {
  const [previewFeatures, setPreviewFeatures] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(!hasSeenModal('journey') || false);
  const [previousFocusedElement, setPreviousFocusedElement] = useState<HTMLElement | null>(null);

  const user: UserState = useSelector((state: RootState) => state.user);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const appInsights = useAppInsightsContext();
  //const trackComponent = useTrackMetric(appInsights, 'Screen: LearningJourney');

  useEffect(() => {
    appInsights.trackEvent({
      name: 'Page',
      properties: { customProperty: 'Screen: Learning Journey' },
    });

    if (previousFocusedElement) {
      previousFocusedElement.focus();
    }
  }, [previousFocusedElement]);

  useEffect(() => {
    if (modalOpen) {
      setSeenModal('journey');
    }
  }, []);

  useEffect(() => {
    (async () => {
      setPreviewFeatures(user?.roles.indexOf('previewfeatures') !== -1 ? true : false);
    })();
  }, [user]);

  const { layoutType } = journey;
  return (
    <>
      {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && <JourneyProgress />}

      <PageHeader
        title={process.env.REACT_APP_NEW_HOMEPAGE === 'true' ? 'Your learning modules' : get(journey, 'title', '')}
        type={process.env.REACT_APP_NEW_HOMEPAGE === 'true' ? 'home' : 'main'}
        rightButton={{ iconName: 'info', onClick: openModal, ariaLabel: 'Open journey help modal' }}
        setPreviousFocusedElement={setPreviousFocusedElement}
      />
      {layoutType === 'flexible' ? (
        <FlexibleJourney modules={modules} previewFeatures={previewFeatures} />
      ) : (
        <StandardJourney
          modules={modules}
          previewFeatures={previewFeatures}
          allModulesCompleted={allModulesCompleted}
        />
      )}

      <Modal isOpen={modalOpen} onClose={closeModal} previousFocusedElement={previousFocusedElement}>
        <OnboardingCarousel items={onboarding.learningJourney} closeOnboardingModal={closeModal} />
      </Modal>
    </>
  );
};

export default LearningJourney;
