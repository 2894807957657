import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { IconContainer, NextActivityText, ExperimentParticipantContainer, ParticipantsInfo } from './styles';
import { ModuleOverlayProps } from './types';

import ModuleOverlayLayout from '../components/ModuleOverlayLayout/ModuleOverlayLayout';
import IconButton from '../../IconButton';
import Icon, { IconName } from '../../Icon';
import theme from '../../../utils/theme';
import { Activity, ActivityContentBlock, Experiment } from '../../../utils/types';
import ActivityCard from '../../ActivityCard';
import ExperimentActivityCard from '../../ExperimentActivityCard';
import Button from '../../Button';
import { useAppSelector } from '../../../utils/hooks';
import { RootState } from '../../../redux';
import { useHistory } from 'react-router-dom';
import { colourExistsInTheme } from '../../../utils/theme/helpers';

const ModuleOverlay: FC<ModuleOverlayProps> = ({
  isOpen,
  onClose,
  moduleId,
  module,
  index,
  linkTo,
}: PropsWithChildren<ModuleOverlayProps>): JSX.Element => {
  const { cohortUserRecords } = useAppSelector((state) => state.cohorts);
  const { cohortMeta } = useAppSelector((state: RootState) => state.cohorts);
  const { experiments } = useAppSelector((state) => state.experiment);
  const [numberOfCompletedActivities, setNumberOfCompletedActivities] = useState<number>(0);
  const [nextActivity, setNextActivity] = useState<Activity[]>([]);
  const [experiment, setExperiment] = useState<Experiment>({} as Experiment);
  const [experimentUsers, setExperimentUsers] = useState<number>(0);
  const [userIsInExperiment, setUserIsInExperiment] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const completedActivities: number = module.activities.reduce(
      (acc: number, activity: Activity): number => acc + (activity.record && activity.record.completedDate ? 1 : 0),
      0,
    );

    setNumberOfCompletedActivities(completedActivities);

    // get first uncomplete activity its experiment if theres any
    const nextActivity: Activity | undefined = module.activities.find(
      (activity: Activity): boolean => !activity.record?.completedDate,
    );

    // if found save it
    if (nextActivity) {
      setNextActivity([nextActivity]);

      // search for experiments
      const activityExperimentIds: string[] = [];
      nextActivity.content.body.forEach((content: ActivityContentBlock): void => {
        if (content.blockType === 'experiment') activityExperimentIds.push(content.text);
      });

      const activityExperiments: Experiment[] = experiments.filter((experiment: Experiment): boolean =>
        activityExperimentIds.includes(experiment.id),
      );

      // if there are experiments get the participant number for display
      if (activityExperiments.length) {
        const selectedExperiment: Experiment | undefined = activityExperiments.find((experiment: Experiment): boolean =>
          activityExperimentIds.includes(experiment.id),
        );
        // get all experiment records (with users)
        const experimnetUsers = cohortUserRecords.filter((userRecord: any): boolean =>
          activityExperimentIds.includes(userRecord.experimentId),
        );
        // narrow down by activity
        const activityUsers = experimnetUsers.filter(
          (userRecord: any): boolean => userRecord.activityId === nextActivity._id,
        );
        setExperiment((selectedExperiment && selectedExperiment) || ({} as Experiment));
        setExperimentUsers(activityUsers.length || 0);
        nextActivity.record?.experimentId === undefined && setUserIsInExperiment(true);
      }
    }

    return (): void => {};
  }, [module, experiments]);

  const activitiesLength: number = module.activities.length;
  const nextActivityIndex: number = module.activities.indexOf(nextActivity[0]);
  const buttonAriaLabel: string = nextActivity.length
    ? `Visit current activity: ${nextActivity[0].title}`
    : `Review module`;

  return (
    <ModuleOverlayLayout index={index} moduleId={moduleId} isOpen={isOpen} onClose={(): void => onClose()}>
      <span style={{ color: theme.colours.darkGrey, fontSize: theme.typography.font.size.small }}>
        {numberOfCompletedActivities} of {activitiesLength} activities completed
      </span>

      <IconContainer>
        {module.activities.map((item: Activity, index: number): JSX.Element => {
          const iconDisplay = item.imageUrl.includes('https') || !item.imageUrl ? 'webinar' : item.imageUrl;
          const backgroundColor: string =
            !module.iconColor || !colourExistsInTheme(module.iconColor) ? theme.colours.moduleFushia : module.iconColor;

          if (item.record?.completedDate !== undefined && item.record?.completedDate !== null) {
            return (
              <IconButton
                key={index}
                size="xxSmall"
                color={theme.colours.secondary}
                tabIndex={isOpen ? 0 : -1}
                aria-hidden={isOpen ? false : true}
                aria-label={`Activity: ${item.title} - completed`}>
                <Icon name="check" size="xxSmall" fill={theme.colours.white} />
              </IconButton>
            );
          } else if (!item.record?.completedDate && index > nextActivityIndex) {
            return (
              <IconButton
                key={index}
                size="xxSmall"
                color={theme.colours.lightGrey}
                tabIndex={isOpen ? 0 : -1}
                aria-hidden={isOpen ? false : true}
                aria-label={`Activity: ${item.title} - awaiting`}
              />
            );
          } else if (item.record?.completedDate === null && !!item.record?.experimentId) {
            return (
              <IconButton
                key={index}
                size="xxSmall"
                color={theme.colours.secondary}
                tabIndex={isOpen ? 0 : -1}
                aria-hidden={isOpen ? false : true}
                aria-label={`Activity: ${item.title} - in progress`}>
                <Icon name="in-progress" size="xxSmall" fill={theme.colours.white} />
              </IconButton>
            );
          } else {
            return (
              <IconButton
                key={index}
                size="xxSmall"
                color={backgroundColor}
                tabIndex={isOpen ? 0 : -1}
                aria-hidden={isOpen ? false : true}
                aria-label={`Activity: ${item.title} - current`}>
                <Icon name={iconDisplay as IconName} size="xxSmall" fill={theme.colours.white} />
              </IconButton>
            );
          }
        })}
      </IconContainer>

      {nextActivity.length > 0 ? (
        nextActivity[0].type !== 'experiment' ? (
          <>
            <NextActivityText>
              {' '}
              {module.activities.findIndex((x: Activity): boolean => x._id == nextActivity[0]._id) == 0
                ? 'Your first activity in this module'
                : 'Your next activity in this module'}{' '}
            </NextActivityText>
            <ActivityCard
              title={nextActivity[0].title}
              description={nextActivity[0].description}
              type={nextActivity[0].type}
              completed={false}
              moduleBackground={module.iconColor}
              isPreivew={true}
              currentActivityId=""
            />
          </>
        ) : experiment ? (
          <>
            <NextActivityText>
              {' '}
              {module.activities.findIndex((x: Activity): boolean => x._id == nextActivity[0]._id) == 0
                ? 'Your first activity in this module'
                : 'Your next activity in this module'}{' '}
            </NextActivityText>

            <ExperimentActivityCard
              id={nextActivity[0]._id}
              moduleId={module._id}
              moduleName={module.title}
              experiment={experiment}
              completed={!!nextActivity[0].record?.completedDate}
              isStarted={nextActivity[0].record?.completedDate === null}
              deadline=''
              description={nextActivity[0].description}
              title={nextActivity[0].title}
              moduleBackground={module.iconColor}
              currentActivityId={''}
              isPreview={true}
            />

            {userIsInExperiment && experimentUsers > 0 && (
              <ExperimentParticipantContainer>
                <ParticipantsInfo>
                  {experimentUsers} Members of your learning journey have started this experiment
                </ParticipantsInfo>
              </ExperimentParticipantContainer>
            )}
          </>
        ) : (
          <span>Couldn't find experiment</span>
        )
      ) : (
        <span style={{ marginBottom: theme.spacing(2) }}>
          <strong>Module completed</strong>
        </span>
      )}

      <Button
        aria-label={buttonAriaLabel}
        tabIndex={isOpen ? 0 : -1}
        variant={nextActivity.length > 0 ? 'filled' : 'outline'}
        onClick={() => {
          linkTo && history.push(`${linkTo}`);
        }}
        style={{
          marginTop: theme.spacing(1),
          fontSize: theme.typography.font.size.small,
          width: '130px',
          margin: '0px auto',
        }}>
        {nextActivity.length > 0 ? 'Begin'.toUpperCase() : 'Review'.toUpperCase()}
      </Button>
    </ModuleOverlayLayout>
  );
};
export default ModuleOverlay;
