import React, { FC } from 'react';
import styles from './styles.module.scss';

interface Props {
  title: string;
}

const OptionsModal: FC<Props> = ({ title, children }) => {
  return (
    <>
      <div className={styles.title}>{title}</div>
      {children}
    </>
  );
};

export default OptionsModal;
