import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import theme from '../../utils/theme';
import ExperimentModalHeader from '../../components/ExperimentModalHeader';
import { InsightsWallProps } from './types';
import { EmptyText } from './styles';
import { ExperimentModalHeaderbackButtonProps } from '../../components/ExperimentModalHeader/types';
import InsightCardsWrapper from '../../components/InsightCardsWrapper';
import { useAppSelector } from '../../utils/hooks';
import { InsightResponse } from '../../redux/experiment.slice';
import { getAllInsights } from './helpers';
import InsightWallFilter from '../../components/InsightWallFilter';
import { FilterDetails } from '../../components/InsightWallFilter/types';
import { RootState } from '../../redux';
import { UserState } from '../../redux/user.slice';
import { CohortsState } from '../../redux/cohorts.slice';
import { Activity, ActivityContentBlock, Module } from '../../utils/types';
const InsightsWall: FC<InsightsWallProps> = ({
  getExperimentInsights,
  cohortId,
}: PropsWithChildren<InsightsWallProps>): JSX.Element => {
  const [allInsights, setAllInsights] = useState<InsightResponse[] | null>([] as InsightResponse[]);
  const [filteredInsights, setFilteredInsights] = useState<InsightResponse[] | null>([] as InsightResponse[]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FilterDetails | null>(null);
  const [userFilter, setUserFilter] = useState<boolean>(false);
  const [allActivities, setAllActivities] = useState<Activity[]>([]);

  const modules: Module[] = useAppSelector((state): Module[] => state.journey.modules);
  const { roles, id: userId } = useAppSelector((state: RootState): UserState => state.user);
  const { selectedCohort } = useAppSelector((state: RootState): CohortsState => state.cohorts);
  const storeInsights: InsightResponse[] | null = useAppSelector(
    (state: RootState): InsightResponse[] | null => state.experiment.insights,
  );
  const previouslyAppliedFilters: FilterDetails = JSON.parse(localStorage.getItem('insightFilters')!) || null;
  //const isFacilitator: boolean = roles.includes('system:administrator') || roles.includes('cirrus-facilitator');
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const [learningJourneyId, setLearningJourneyId] = useState<string | null>(null);
  const [fitleredCohortId, setCohortId] = useState<string | null>(null);
  const [experimentId, setExperimentId] = useState<string | null>(null);
  const [activityId, setActivityId] = useState<string | null>(null);

  // reset filters upon leave
  useEffect((): (() => void) => {
    return (): void => {
      if (window.location.search.includes('origin=activity')) return;
      if(!(localStorage.getItem('previousPage') && localStorage.getItem('previousPage')?.includes('app/home'))){
        localStorage.setItem('insightFilters', localStorage.getItem('defaultInsightFilters') || '');
      }      
    };
  }, []);

  // if cohort provided fetch (facilitator), otherwise get from store
  useEffect((): void | (() => void) => {
    (async (): Promise<void> => {
      const insightsResponse: InsightResponse[] | null = await getAllInsights(
        storeInsights,
        filters?.cohort.id || cohortId,
        getExperimentInsights,
      );

      if (!insightsResponse?.length) {
        setIsLoading(false);
      }

      if (insightsResponse === null) {
        setIsLoading(false);
      }
      const queryParameters = new URLSearchParams(window.location.search);
      const expId = queryParameters.get('experimentId') ?  queryParameters.get('experimentId') :(filters?.experiment.id)?filters.experiment.id: experimentId;
      const cId = queryParameters.get('cohortId') ? queryParameters.get('cohortId') : fitleredCohortId;     
      const aId = queryParameters.get('activityId') ?  queryParameters.get('activityId') :(filters?.activity.id)?filters.activity.id: activityId;
      const filteredByAcrtivity: InsightResponse[] | null = filterInsights(insightsResponse, expId, cId, aId);
      setAllInsights(filteredByAcrtivity);
    })();
  }, [learningJourneyId, fitleredCohortId, experimentId, activityId, filters]);

  useEffect((): (() => void) => {
    const queryParameters = new URLSearchParams(window.location.search);
    const experimentId = queryParameters.get('experimentId');
    const filteredByAcrtivity: InsightResponse[] | null = filterInsights(storeInsights, experimentId);
    setAllInsights(filteredByAcrtivity);
    return (): void => {};
  }, [storeInsights]);

  // filter by activities and experiments
  useEffect((): (() => void) | undefined => {
    if (!allInsights || !filters) {
      setFilteredInsights(allInsights);
      return;
    }

    // filter by activity & experiment
    if (filters.activity.id.length && filters.experiment.id.length) {
      const filteredByActivity: InsightResponse[] = allInsights?.filter(
        (insight: InsightResponse): boolean => insight.activityId === filters.activity.id,
      );
      const result: InsightResponse[] = filteredByActivity.filter(
        (insight: InsightResponse): boolean => insight.experimentId === filters.experiment.id,
      );
      setFilteredInsights(result);
      return;
    }

    //  filter by activity
    else if (filters?.activity?.id.length) {
      const result: InsightResponse[] = allInsights?.filter(
        (insight: InsightResponse): boolean => insight.activityId === filters.activity.id,
      );
      setFilteredInsights(result);
      return;
    }

    // filter by experiment
    else if (filters?.experiment.id.length) {
      const result: InsightResponse[] = allInsights.filter(
        (insight: InsightResponse): boolean => insight.experimentId === filters.experiment.id,
      );
      setFilteredInsights(result);
      return;
    }

    setFilteredInsights(allInsights);

    return (): void => {};
  }, [filters, allInsights]);

  // filter on mount if filters are found in the storage
  useEffect((): (() => void) => {
    if (previouslyAppliedFilters && filters?.cohort.id !== previouslyAppliedFilters.cohort.id) {
      setFilters(previouslyAppliedFilters);
      setLearningJourneyId(previouslyAppliedFilters.cohort.ljId);
      setCohortId(previouslyAppliedFilters.cohort.id);
    }
    if (localStorage.getItem('previousPage') && localStorage.getItem('previousPage')?.includes('app/home') && previouslyAppliedFilters && filters?.experiment.id !== previouslyAppliedFilters?.experiment.id || filters?.activity.id !== previouslyAppliedFilters?.activity.id) {
      setExperimentId(previouslyAppliedFilters.experiment.id)
      setActivityId(previouslyAppliedFilters.activity.id)
    }
    return (): void => {};
  }, []);

  const backButton: ExperimentModalHeaderbackButtonProps = {
    label: localStorage.getItem('previousPage') && localStorage.getItem('previousPage')?.includes('app/activity')?'Back to  All Modules':'Back to Home page',
    iconName: 'chevron-left',
  };

  const getAllActivities = (): void => {
    const activities: Activity[] = [];

    modules.forEach((module: Module): void =>
      module.activities.forEach((activity: Activity): number => activities.push(activity)),
    );

    setAllActivities(activities);
  };

  // filter insights by activity CMS Block
  // this will sort out any insights that has no reference to CMS Blocks in activities
  // (e.g. the activity was deleted that answer belonged to)
  const filterInsights = (insights: InsightResponse[] | null, experimentId?:string | null, fitleredCohortId?:string | null, activityId?:string | null) => {
    if (insights === null) return null;

    const filteredItems: InsightResponse[] = [];

    insights.forEach((insight: InsightResponse) => {
      const chkExperimentId = (experimentId) ? experimentId === insight.experimentId : true ;
      const chkCohortId = (fitleredCohortId) ? fitleredCohortId === insight.cohortId : true;
      const chkActivityId = (activityId) ? activityId === insight.activityId: true;
      const isFacilitatorOfCohort:boolean = isFacilitator && selectedCohort!.facilitatorIds.includes(userId);
      if(isFacilitatorOfCohort){
        if (chkCohortId && chkActivityId && chkExperimentId) {
          filteredItems.push(insight);
        }
      } else {
        const foundActivity: Activity | undefined = allActivities.find(
          (activity: Activity): boolean => activity._id === insight.activityId,
        );
  
        const JournalCaptureCMSBlock: ActivityContentBlock | undefined = foundActivity?.content?.body?.filter(
          (cms: ActivityContentBlock): boolean => cms.blockType == 'insightJournalText',
        )[0];
        if (JournalCaptureCMSBlock && chkCohortId && chkActivityId && chkExperimentId) {
          filteredItems.push(insight);
        }
      }
    });
    
    return filteredItems;
  };

  useEffect((): (() => void) => {
    getAllActivities();
    return (): void => {};
  }, []);

  return (
    <>
      <ExperimentModalHeader title="Insights wall" backButton={backButton} />
      <InsightWallFilter
        isFacilitator={isFacilitator}
        numberOfInsights={filteredInsights?.length || 0}
        filter={setFilters}
        filterDetails={filters}
        personalFilter={setUserFilter}
        learningJourneyFilter={setLearningJourneyId}
        cohortIdFilter={setCohortId}
      />
      <InsightCardsWrapper
        insights={filteredInsights || allInsights}
        key={storeInsights?.length}
        cancelLoader={() => setIsLoading(false)}
      />

      {(allInsights === null || !allInsights.length || filteredInsights=== null || !filteredInsights.length) && !isLoading && (
        <>
          <strong color={theme.colours.darkGrey} style={{ alignSelf: 'center' }}>
            {' '}
            No insights have been posted yet. {' \n '}
          </strong>
          <EmptyText>
            Once an experiment has been completed you will be able to share your own experience, as well as view
            insights from other participants on this wall.
          </EmptyText>
        </>
      )}
    </>
  );
};

export default InsightsWall;
