import React, { FC, useEffect, useState } from 'react';
import { ListItem, ScrollView, WidgetText } from './styles';
import Widget from '../../components/HomepageWidget';
import IconButton from '../../../IconButton';
import Icon from '../../../Icon';
import theme from '../../../../utils/theme';
import { User } from '@auth0/auth0-react';
import { getCohortUsers } from '../../../../utils/api/user';
import { useAppSelector } from '../../../../utils/hooks';

const MembersWidget: FC = ({}): JSX.Element => {
  const cohortId: string | undefined = useAppSelector((state) => state.cohorts.selectedCohort?.cohortId);
  const accessToken: string = useAppSelector((state) => state.auth.accessToken);
  const [cohortMembers, setCohortMembers] = useState<User[]>([]);

  useEffect(() => {
    (async () => {
      if (!cohortId || !accessToken) return;
      const cohortUsers = await getCohortUsers(cohortId, accessToken);
      if (cohortUsers && cohortUsers !== undefined && cohortUsers.users !== undefined) {
        const generatedUserList: User[] = [];
        Object.keys(cohortUsers.users).forEach((key: string) => {
          generatedUserList.push(cohortUsers.users[key]);
        });
        setCohortMembers(generatedUserList);
      }
    })();

    return () => {};
  }, [cohortId, accessToken]);

  return (
    <Widget title={`${cohortMembers?.length || 0} Members`} iconName="user">
      <ScrollView>
        {cohortMembers &&
          cohortMembers.map(
            (member: User): JSX.Element => (
              <ListItem key={member.id}>
                <IconButton color={theme.colours.primary}>
                  <Icon name="user" fill="white" size="medium" />
                </IconButton>
                <WidgetText
                  style={{ marginLeft: theme.spacing(2) }}>{`${member.FirstName} ${member.LastName}`}</WidgetText>
              </ListItem>
            ),
          )}
      </ScrollView>
    </Widget>
  );
};
export default MembersWidget;
