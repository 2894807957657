import styled, { css } from 'styled-components';
import { Props } from '.';

export const Divider = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  ${({ theme, color, size, type, orientation }) =>
    orientation === 'vertical' &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      ${theme.borders.createBorder(color, size, type, orientation === 'vertical' && 'left')}
    `};

  ${({ theme, text, size, color, type, hidden }) =>
    !text &&
    !hidden &&
    css`
      ${theme.borders.createBorder(color, size, type, 'bottom')}
    `};

  ${({ theme, text, size, color, type }) =>
    text &&
    css`
      justify-content: center;
      align-items: center;
      text-align: center;

      &::before,
      &::after {
        content: '';
        flex: 1;
        ${theme.borders.createBorder(color, size, type, 'bottom')}
      }

      &:not(:empty)::before {
        margin-right: ${theme.spacing(1)};
      }

      &:not(:empty)::after {
        margin-left: ${theme.spacing(1)};
      }
    `};
`;
