import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  onClick: any;
  children: any;
}

const OptionsModalOption: FC<Props> = ({ onClick, children }) => {
  return (
    <button className={styles.option} onClick={onClick}>
      {children}
    </button>
  );
};

export default OptionsModalOption;
