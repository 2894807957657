import styled from 'styled-components';
import { Props } from '.';
import theme from '../../utils/theme';

import {
  handleColor,
  handleDisabled,
  handleHasIcon,
  handleLoading,
  handleShape,
  handleSize,
  handleVariant,
} from './conditionalStyles';

export const Button = styled.button<Props>`
  box-sizing: border-box;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 33px;
  padding: 0 30px;
  letter-spacing: 0;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s;
  font-weight: ${theme.typography.font.weight.regular};

  ${({ color }) => handleColor(color)};
  ${({ color, disabled }) => handleDisabled(color, disabled)};
  ${({ size }) => handleSize(size)};
  ${({ variant, color }) => handleVariant(variant, color)};
  ${({ loading }) => handleLoading(loading)};
  ${({ hasIcon }) => handleHasIcon(hasIcon)};
  ${({ shape, size }) => handleShape(shape, size)};
`;
