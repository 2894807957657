import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalKeyType } from '../utils/modals';

export interface Modal {
  key: ModalKeyType;
  isOpen: boolean;
}

interface InitialState {
  currentModal: Modal;
}

const initialState: InitialState = {
  currentModal: {} as Modal,
};

type changeStatePayload = Modal;

const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModalState(state, action: PayloadAction<changeStatePayload>) {
      state.currentModal = action.payload;
    },
    resetModalState(state) {
      state.currentModal = {} as Modal;
    },
  },
});

export default modals.reducer;

export const actions = {
  ...modals.actions,
};
