import styled, { css } from 'styled-components';
import { Props } from '.';

export const IconButton = styled.button<Props>`
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme, color, size }) =>
    !color &&
    css`
      width: ${theme.icon.sizes[size || 'small'].icon};
      height: ${theme.icon.sizes[size || 'small'].icon};
    `};

  ${({ theme, color, size }) =>
    color &&
    css`
      width: ${theme.icon.sizes[size || 'small'].background};
      height: ${theme.icon.sizes[size || 'small'].background};
    `};

  background-color: ${({ theme, color }) => (color ? color : 'transparent')};
  border-radius: ${({ color }) => color && '50%'};
  cursor: ${({ onClick }) => onClick && 'pointer'};

  box-shadow: ${({ theme, elevation }) => (elevation && theme.shadows[elevation - 1]) || 'none'};

  svg {
    width: ${({ theme, size }) => theme.icon.sizes[size || 'small'].icon};
    height: ${({ theme, size }) => theme.icon.sizes[size || 'small'].icon};
  }
`;
