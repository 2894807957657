import styled from 'styled-components';

export const ButtonNumber = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.Xlarge};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.white};
  margin-right: ${(p) => p.theme.spacing(2)};
`;

export const ButtonText = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  color: ${(p) => p.theme.colours.white};
`;

export const WidgetText = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.small};
  color: ${(p) => p.theme.colours.darkGrey};
`;

export const Widgetlist = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  width: 100%;

  > li {
    margin: ${(p) => p.theme.spacing(1)} 0px;
  }
`;
