import styled from 'styled-components';
import { Color } from '../../utils/types/theme';
import List from '../List';

export const TabNavigation = styled.div`
  width: 100%;
  flex-direction: row;
`;

export const Tabs = styled(List)`
  display: flex;
`;

export const Tab = styled(List.Item)<{ active: boolean; color: Color }>`
  color: ${({ theme, color, active }) => active && theme.colours[color]};
  border-bottom: ${({ theme, active, color }) => active && `2px solid ${theme.colours[color]}`};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  margin-right: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(1)};

  cursor: pointer;
`;

export const Status = styled.span`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;
