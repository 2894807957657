import styled, { css } from 'styled-components';
import ConditionalLink from '../ConditionalLink';
import { IconButton } from '../IconButton/styles';

export const Link = styled(ConditionalLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OverlayButton = styled.button`
  background: none;
  border: none;
  outline: none;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

export const ModuleProgress = styled.div`
  position: relative;
  width: 88px;
  height: 88px;
  pointer-events: none;

  justify-content: center;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.spacing(2)};

  .CircularProgressbar > * {
    display: block;
  }
`;

export const ModuleIcon = styled(IconButton).attrs({
  as: 'div',
})<{ completed?: boolean }>`
  position: absolute;

  ${({ completed }) =>
    completed &&
    css`
      width: 88px;
      height: 88px;

      svg {
        width: 88px;
        height: 88px;
      }
    `};
`;

export const ComingSoon = styled.div`
  position: absolute;

  span {
    text-align: center;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colours.primary};
    font-size: ${({ theme }) => theme.typography.font.size.xSmall};
    line-height: ${({ theme }) => theme.typography.font.size.xSmall};
  }

  div {
    position: absolute;
    right: -32px;
    bottom: -40px;
  }
`;

export const Title = styled.h2`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  line-height: 23px;
  font-weight: ${(p) => p.theme.typography.font.weight.regular};
  text-align: center;
`;

export const ComingSoonIcon = styled(IconButton).attrs({
  as: 'div',
})`
  position: absolute;
  right: -33px;
  bottom: -33px;
`;
