import React, { FC } from 'react';
import * as Styled from './styles';

import Icon, { IconName } from '../Icon';
interface Props {
  text: string;
  icon: IconName;
}

const TextWithIcon: FC<Props> = ({ text, icon }) => {
  return (
    <Styled.container>
      <Icon name={icon} color="primary" />
      <span>{text}</span>
    </Styled.container>
  );
};

export default TextWithIcon;
