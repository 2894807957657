import { Activity, Module } from '../../utils/types';

export type ModuleDisplayData = {
  title: string;
  imageUrl: string;
  id: string;
  active: boolean;
  totalActivities: number;
  completedActivities: number;
  iconName: string;
  iconColor: string;
  rowTitle: string;
  isNextRow: boolean;
};

export const adaptJourneyModules = (modules: Array<Module>): Array<ModuleDisplayData | null> =>
  modules
    .map((module: Module) => {
      const newModule = {
        title: module.title,
        imageUrl: module.imageUrl,
        id: module._id,
        active: module.active,
        iconName: module.iconName,
        iconColor: module.iconColor,
        isNextRow: module.isNextRow,
        rowTitle: module.rowTitle,
        totalActivities: module.activities.length,
        completedActivities: module.activities.reduce(
          (acc: number, activity: Activity) => acc + (activity.record?.completedDate ? 1 : 0),
          0,
        ),
      };
      return newModule.totalActivities > 0 ? newModule : null;
    })
    .filter(Boolean);

export const adaptModulesCompleted = (modules: Array<Module>): boolean => {
  return (
    modules
      .map((module: Module) => {
        if (
          module.activities.length >
          module.activities.reduce((acc: number, activity: Activity) => acc + (activity.record ? 1 : 0), 0)
        ) {
          return 1;
        }
        return null;
      })
      .filter(Boolean).length === 0
  );
};
