import styled from 'styled-components';

export const WidgetText = styled.p`
  font-size: ${(p) => p.theme.typography.font.size.small};
  color: ${(p) => p.theme.colours.darkGrey};
`;

export const ScrollView = styled.ul`
  overflow: auto;
  max-height: 305px;
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: ${(p) => p.theme.spacing(1)} 0;
`;
