import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import theme from '../../../utils/theme';
import {
  JourneysFacilitateContainer
} from './styles';
import styles from './styles.module.scss';
import { JourneysFacilitateProps } from './types';
import { useViewport } from '../../../utils/hooks';
import Loader from '../../Loader';
import NoCohortHomepage from './NoCohortHomepage';
import FacilitatingJourneys from './FacilitatingJourneys';

const JourneysFacilitate: FC<JourneysFacilitateProps> = ({
  isFacWithNocohortNotLearnerN
}: PropsWithChildren<JourneysFacilitateProps>): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { width: deviceWidth } = useViewport();

  useEffect((): (() => void) | undefined => {
    setIsLoading(false);
    return (): void => { };
  }, []);

  if (isLoading) {
    return (
      <JourneysFacilitateContainer
        isMobile={deviceWidth < theme.devices.mobile}
        style={{ alignItems: 'center', justifyContent: 'center', minHeight: 208 }}>
        <Loader size="large" color="purple" />
      </JourneysFacilitateContainer>
    );
  }

  return (
    <JourneysFacilitateContainer isMobile={deviceWidth < theme.devices.mobile}>      
      {isFacWithNocohortNotLearnerN ?
        <>
          <div className={styles.overviewcontainer}>
            <div className={styles.heading}>
              Sorry, You have no cohorts available to view.
            </div>
            <p className={styles.detail}>
              It looks like you have not been assigned to a cohort on a learning journey yet.<br></br>Please contact your admin to ensure you have been assigned to a cohort ahead of delivery.
            </p>
          </div>
          <NoCohortHomepage />
        </>
        :
        <>
          <div className={styles.overviewcontainer}>
            <div className={styles.heading}>
              Your assigned cohorts
            </div>
            <p className={styles.detail}>
              Welcome to the Strata facilitator page, here you can get access to all of the cohorts that you have been assigned to. Select an option below to view the learning journey.
            </p>
          </div>
          <FacilitatingJourneys />
        </>
      }

    </JourneysFacilitateContainer>
  );
};

export default JourneysFacilitate;
