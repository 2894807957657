import React, { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logger from '../../utils/logger';
import Button from '../../components/Button';
import { useAppSelector, useViewport } from '../../utils/hooks';
import styles from './styles.module.scss';

interface Props {
  logout: Function;
}

const LearningJourneyError: FC<Props> = ({ logout }) => {
  const history = useHistory();
  const [sending, setSending] = useState<boolean>(false);
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);

  const onClickLogoutButton = () => {
    logger('info', 'LOG_OUT');
    logout();

    const redirectUrl = `/${isNativeWebView ? window.location.search : ''}`;
    history.push(redirectUrl);
  };

  return (
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <img src="/images/strata_logo_by_accenture.svg" alt="" />
        </div>
        <div className={styles.modalBody}>
          <div className={styles.container}>
            <img className={styles.img} src="/images/unassigned_icon.svg" />

            <>
              <h3 className={styles.h3}>Something went wrong!</h3>
              <p className={styles.p}>
                We are sorry, it looks like you have not been assigned to a learning journey yet.
              </p>

              <p className={styles.p}>If this problem persists please contact your Learning programme administrator.</p>
            </>
            <Button testId="send" loading={sending} onClick={onClickLogoutButton}>
              Log out
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningJourneyError;
