import React, { FC } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import Icon from '../Icon';

type Props = {
  className?: string;
  style?: any;
  onClick?: any;
  next?: boolean;
  done?: boolean;
  closeModal?: Function;
};

const OnboardingCarouselArrow: FC<Props> = ({ className, style, onClick, next, done = false, closeModal }: Props) => {
  const onClickWrapper = () => {
    if (onClick) {
      onClick();
    }
    if (next && done && closeModal) {
      closeModal();
    }
  };

  return (
    <button
      className={classnames(className, styles.container, done && 'done')}
      style={{ ...style }}
      onClick={onClickWrapper}>
      {next && !done ? <Icon name="chevron-right" /> : <Icon name="chevron-left" />}
      {next && done && <>Done</>}
    </button>
  );
};

export default OnboardingCarouselArrow;
