import { buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';
import theme from '../../utils/theme';
import { Props } from '.';

export const Percentage = styled.div<{ percentageFontSize: 'large' | 'Xlarge' }>`
  ${({ theme, percentageFontSize }) => ({
    fontSize: theme.typography.font.size[percentageFontSize],
    fontWeight: theme.typography.font.weight.bold,
    lineHeight: theme.spacing(4),
  })};
`;

export const Label = styled.div`
  ${({ theme }) => ({
    fontSize: theme.typography.font.size.xSmall,
    fontWeight: theme.typography.font.weight.bold,
    lineHeight: theme.spacing(2),
  })};
`;

export const CircularProgress = buildStyles({
  strokeLinecap: 'butt',
  pathTransitionDuration: 0.5,
  pathColor: theme.colours.secondary,
  trailColor: theme.colours.lightGrey,
});
