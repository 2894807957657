import get from 'lodash.get';
import dayjs from 'dayjs';
import { Goal, Habit } from '../../utils/types';
import { RootState } from '../../redux';

export type FormattedPracticeDate = {
  date: any;
  dateString: string;
  dateFormattedString: string;
  dayFormattedString: string;
  isClearable?: boolean;
};

export type HabitSuccessPlan = {
  barriers: string;
  enablers: string;
  reward: string;
  shareWith: string;
  trigger: string;
};

export type FormattedHabit = {
  title: string;
  id: string;
  completed: number;
  target: number;
  practiceDates: Array<FormattedPracticeDate>;
  successPlan: HabitSuccessPlan;
};

export type FormattedTask = {
  title: string;
  id: string;
  completed?: boolean | null;
};

export type FormattedGoal = {
  title?: string;
  id?: string;
  habits?: Array<FormattedGoalHabit>;
  completedPerc?: number;
  tasks?: Array<FormattedTask>;
  reflections?: Array<FormattedReflection>;
};

export type FormattedGoalHabit = {
  title: string;
  id: string;
  completed: number;
  target: number;
  created: Date;
};

export type FormattedGoalOverview = {
  title: string;
  id: string;
  habitStatus: Array<number>;
  completedPerc: number;
  created: Date;
};

export type FormattedGoals = {
  active: Array<FormattedGoalOverview>;
  completed: Array<FormattedGoalOverview>;
};

export type FormattedReflection = { comment: string; createdOn: string; rating: number; id: string };

export const getGoalById = (state: RootState, id: string): Goal | null => {
  const goals: Array<Goal> = get(state, 'goals.goals', []);
  const goal: Goal | undefined = goals.find(({ _id }) => _id === id);
  if (!goal) return null;
  return goal;
};
export const getHabitParentGoalId = (state: RootState, id: string): string | null => {
  const goals: Array<Goal> = get(state, 'goals.goals', []);
  let goalId = '';
  goals.forEach(({ habits, _id }) => {
    const habit = habits.find(({ _id }) => _id === id);
    if (habit) goalId = _id;
  });
  return goalId;
};

export const getHabitById = (state: RootState, id: string): Habit | null => {
  const goals: Array<Goal> = get(state, 'goals.goals', []);
  const habit: Habit | undefined = goals.map(({ habits }) => habits.find(({ _id }) => _id === id)).filter(Boolean)[0];
  if (!habit) return null;
  return habit;
};

export const formatHabitPracticeDate = (dateString: string, isClearable = false): FormattedPracticeDate => {
  const date = dayjs(dateString);
  return {
    date,
    dateString,
    dayFormattedString: date.format('ddd'),
    dateFormattedString: date.format('D MMM'),
    isClearable,
  };
};

export const adaptHabitById = (state: RootState, id: string): FormattedHabit | null => {
  const habit: Habit | null = getHabitById(state, id);

  if (!habit) return null;

  const { title, _id, practiceDates, practice } = habit;

  return {
    title,
    id: _id,
    completed: Math.min(practice, practiceDates.length),
    target: practice,
    practiceDates: practiceDates
      .map((date) => formatHabitPracticeDate(date))
      .sort((a, b) => (a.date.valueOf() < b.date.valueOf() ? 1 : 0)),
    successPlan: {
      barriers: habit.barriers,
      enablers: habit.enablers,
      reward: habit.reward,
      shareWith: habit.shareWith,
      trigger: habit.trigger,
    },
  };
};

export const adaptGoalById = (state: RootState, id: string): FormattedGoal | null => {
  const goals: Array<Goal> = get(state, 'goals.goals', null);
  if (!goals) return null;

  const goal: Goal | undefined = goals.find(({ _id }) => _id === id);

  if (!goal) return null;

  const habits: Array<FormattedGoalHabit> = [];

  let totalPracticesNeeded = 0;
  let totalPracticesAchieved = 0;

  (goal.habits || []).forEach((habit: Habit) => {
    const habitIsCompleted: boolean = habit.practiceDates.length >= habit.practice;
    totalPracticesNeeded += habit.practice;
    if (habitIsCompleted) {
      totalPracticesAchieved += habit.practice;
    } else {
      totalPracticesAchieved += habit.practiceDates.length;
    }

    habits.push({
      title: habit.title,
      id: habit._id,
      completed: Math.min(habit.practice, habit.practiceDates.length),
      target: habit.practice,
      created: new Date(habit.createdOn),
    });
  });

  habits.sort((a, b) => (a.created.valueOf() > b.created.valueOf() ? -1 : 1));

  const completedPerc = totalPracticesNeeded === 0 ? 0 : totalPracticesAchieved / totalPracticesNeeded;

  const tasks: Array<FormattedTask> = goal.tasks.map(({ completedDate, title, _id }) => ({
    completed: !!completedDate,
    title,
    id: _id,
  }));

  const reflections: Array<FormattedReflection> = goal.reflections.map(({ comment, createdOn, rating, _id }) => ({
    comment,
    createdOn,
    rating,
    id: _id,
  }));

  return {
    title: goal.ambition,
    id: goal._id,
    habits,
    completedPerc,
    tasks,
    reflections,
  };
};

export const adaptGoals = (goals: Array<Goal> | null): FormattedGoals => {
  if (!goals) {
    throw new Error('404');
  }

  const completed: Array<FormattedGoalOverview> = [];
  const active: Array<FormattedGoalOverview> = [];

  goals.forEach((goal: Goal) => {
    const habitStatus: Array<number> = [0, 0, 0];

    let totalPracticesNeeded = 0;
    let totalPracticesAchieved = 0;

    (goal.habits || []).forEach((habit: Habit, index: number) => {
      const habitIsCompleted: boolean = habit.practiceDates.length >= habit.practice;
      habitStatus[index] = habitIsCompleted ? 2 : 1;
      totalPracticesNeeded += habit.practice;
      if (habitIsCompleted) {
        totalPracticesAchieved += habit.practice;
      } else {
        totalPracticesAchieved += habit.practiceDates.length;
      }
    });

    const completedPerc = totalPracticesNeeded === 0 ? 0 : totalPracticesAchieved / totalPracticesNeeded;

    (completedPerc === 1 ? completed : active).push({
      title: goal.ambition,
      id: goal._id,
      habitStatus,
      completedPerc,
      created: new Date(goal.createdOn),
    });
  });

  active.sort((a, b) => (a.created.valueOf() > b.created.valueOf() ? -1 : 1));
  completed.sort((a, b) => (a.created.valueOf() > b.created.valueOf() ? -1 : 1));

  return {
    completed,
    active,
  };
};

export const getLongestStreak = (repetitions: Array<FormattedPracticeDate>): number => {
  const streaks: Array<number> = [];
  const dates: Array<any> = repetitions.map((date: FormattedPracticeDate): any => date.date);
  dates.forEach((date: any, i: number) => {
    streaks.push(0);
    for (let j = i; j < dates.length; j += 1) {
      if (
        dates[j]
          .clone()
          .subtract(1, 'days')
          .isSame(dates[j - 1], 'days')
      ) {
        streaks[i - 1] += 1;
      } else {
        break;
      }
    }
  });
  return Math.max(...streaks);
};
