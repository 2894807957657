import styled from 'styled-components';
//import { WidgetContainerStyleProps } from './types';

export const WidgetContainerContainer = styled.div`
  position: relative;
  padding: ${(p) => p.theme.spacing(3)};
  border: 1px solid ${(p) => p.theme.colours.mediumGrey};
  border-radius: ${(p) => p.theme.borders.radius};
  min-width: 305px;

  > p {
    line-height: 17px;
  }
`;
