import styled from 'styled-components';

export const Header = styled.header`
  width: 100%;
  height: 100px;
  background-color: ${(p) => p.theme.colours.primary};
`;

export const HeaderContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 21px;
`;

export const PageContainer = styled.div`
  background: white;
  height: 100%;
  min-height: 100vh;
  padding: ${(p) => p.theme.spacing(2)};
`;

export const PageContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  height: 100%;
`;

export const Heading = styled.h1`
  font-size: ${(p) => p.theme.typography.font.size.large};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  margin-top: ${(p) => p.theme.spacing(5)};
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

export const DescriptionWrapper = styled.div`
  padding: 21px;
  max-width: 536px;
  background-color: ${(p) => p.theme.colours.lilac};

  > p {
    font-size: 16px;
  }

  > p:first-child {
    margin-bottom: 16px;
  }

  > a {
    display: contents;
    color: #481db4;
  }
`;

export const List = styled.ul`
  margin-left: 30px;
`;

export const CtaContainer = styled.div`
  width: 200px;
  margin: auto;
`;
