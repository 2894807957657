import styled from 'styled-components';
//import {} from './types';

export const HintsContainer = styled.div`
  border: 1px solid ${(p) => p.theme.colours.darkGrey};
  border-radius: 10px;
  padding: ${(p) => p.theme.spacing(1)} 0 ${(p) => p.theme.spacing(1)} 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  font-size: ${(p) => p.theme.typography.font.size.regular};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  line-height: 16px;
  color: ${(p) => p.theme.colours.primary};
`;

export const ContentWrapper = styled.div`
  padding ${(p) => p.theme.spacing(2)};
  font-size: ${(p) => p.theme.typography.font.size.small};
  line-height: 16px;
`;
