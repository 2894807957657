import React, { FC } from 'react';
import * as Styled from './styles';
import Slider from 'react-slick';
import { MediaCollection } from '../../utils/types';
import settings from './sliderSettings';
import ContentCarouselItem from '../../components/ContentCarouselItem';

type Props = MediaCollection & {
  isExperimentCarousel?: boolean;
  carouselIndex?: number;
};

const ContentCarousel: FC<Props> = ({ title, items, isExperimentCarousel = false, carouselIndex }) => {
  const carouseTitle: string = isExperimentCarousel ? 'Useful articles' : title;

  if (isExperimentCarousel) {
    settings.slidesToShow = 2;
  }

  return (
    <Styled.ContentCarousel id={'contentCarousel'}>
      <Styled.SliderWrapper>
        <Styled.Heading>
          <Styled.Title>{carouseTitle}</Styled.Title>
          <Styled.ItemCount>{items.length}&nbsp;items</Styled.ItemCount>
        </Styled.Heading>

        <Slider {...settings}>
          {items.map((item, index) => {
            return (
              <ContentCarouselItem key={index} index={index} carouselIndex={carouselIndex} {...item} id={item._id} />
            );
          })}
        </Slider>
      </Styled.SliderWrapper>
    </Styled.ContentCarousel>
  );
};

export default ContentCarousel;
