import React, { FC } from 'react';
import Icon from '../Icon';
import * as Styled from './styles';

const Tile: FC<any> = ({ iconName, label, onClick, isSelectable = false }): JSX.Element => {
  return (
    <Styled.Container isSelectable={isSelectable} onClick={() => onClick && onClick()}>
      {iconName && (
        <Styled.IconContainer>
          <Icon name={iconName} size="small" color="primary" />
        </Styled.IconContainer>
      )}
      <Styled.Label>{label}</Styled.Label>
    </Styled.Container>
  );
};

export default Tile;
