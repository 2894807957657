import React, { FC, useState } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';
import Icon from '../Icon';
import IconButton from '../IconButton';

type Props = {
  task: any;
  name: string;
  register: any;
  onRequestRemove: any;
  onBlur?: any;
  onChange?: any;
};

const EditableTaskRow: FC<Props> = ({ task, name, register, onRequestRemove, onBlur, onChange }: Props) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(task.completed);

  return (
    <>
      <div className={classnames(styles.wrapper, { [styles.wrapperCompleted]: isCompleted })}>
        <label className={classnames(styles.radioWrapper, { [styles.disabled]: onBlur })} htmlFor={`checkbox-${name}`}>
          <input
            id={`checkbox-${name}`}
            onChange={(e) => {
              onChange && onChange();
              setIsCompleted(e.target.checked);
            }}
            type="checkbox"
            name={`${name}.completed`}
            defaultChecked={task.completed}
            ref={register()}
            aria-label="Mark task as complete"
          />
          <span className={styles.checkmark} />
        </label>

        <input
          type="text"
          className={classnames(styles.input, { [styles.editing]: isEditing })}
          name={`${name}.title`}
          ref={register()}
          placeholder="Enter your task name"
          defaultValue={task.title}
          onFocus={() => {
            setIsEditing(true);
          }}
          onChange={onChange}
          onBlur={(e) => {
            onBlur && onBlur(e);
            setIsEditing(false);
          }}
          aria-label="Enter your task name"
        />
        <IconButton
          className={classnames(styles.trash, { [styles.disabled]: onBlur })}
          onClick={() => onRequestRemove()}
          aria-label="Delete task">
          <Icon name="delete" color="orange" />
        </IconButton>
      </div>
    </>
  );
};

export default EditableTaskRow;
