import { Goal, Habit } from '../../utils/types';
import logger from '../../utils/logger';

export type GetGoalsResponse = Array<Goal>;

export const getGoals = async (accessToken: string): Promise<GetGoalsResponse> => {
  logger('info', 'GOALS_GET_REQUEST');
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/goals/all`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'GOALS_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: Array<Goal> = await res.json();
  logger('info', 'GOALS_GET_SUCCESS', { 'x-request-id': res.headers.get('x-request-id') });
  return json;
};

export const updateHabit = async (accessToken: string, habit: Habit, goalId: string): Promise<void> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/habit/${habit._id}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(habit),
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  logger('info', 'HABIT_UPDATE_SUCCESS', {
    'x-request-id': res.headers.get('x-request-id'),
    goalId,
    habitId: habit._id,
  });
};

export const deleteHabit = async (accessToken: string, id: string): Promise<void> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/habit/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
};
export const updateGoal = async (accessToken: string, goal: Goal): Promise<Goal> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/goals/${goal._id}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(goal),
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Goal = await res.json();
  logger('info', 'GOAL_UPDATE_SUCCESS', {
    goalId: json._id,
    'x-request-id': res.headers.get('x-request-id'),
  });
  return json;
};

export const deleteGoal = async (accessToken: string, id: string): Promise<void> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/goals/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  logger('info', 'GOAL_DELETE_SUCCESS', {
    goalId: id,
    'x-request-id': res.headers.get('x-request-id'),
  });
};

type NewGoal = {
  ambition: string;
};
export const createGoal = async (accessToken: string, goal: NewGoal): Promise<Goal> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/goals/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(goal),
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }

  const json: Goal = await res.json();

  logger('info', 'GOAL_WIZARD_CREATE_SUCCESS', {
    'x-request-id': res.headers.get('x-request-id'),
    goalId: json._id,
  });

  return json;
};

type NewHabit = { goalId?: string } & {
  _id: string;
  createdOn: string;
  updatedOn: string;
  userId: string;
};

export const createHabit = async (accessToken: string, habit: NewHabit): Promise<Goal> => {
  logger('info', 'HABIT_WIZARD_CREATE_REQUEST', { goalId: habit.goalId, habitId: habit._id });
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/habit/create`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(habit),
  });
  if (res.status === 401) {
    logger('error', 'HABIT_WIZARD_CREATE_FAILURE', {
      goalId: habit.goalId,
      habitId: habit._id,
      'x-request-id': res.headers.get('x-request-id'),
    });
    throw new Error('Unauthorized');
  }
  const json: Goal = await res.json();
  logger('info', 'HABIT_WIZARD_CREATE_SUCCESS', {
    goalId: habit.goalId,
    habitId: json._id,
    'x-request-id': res.headers.get('x-request-id'),
  });
  return json;
};

export const getHabitCollection = async (accessToken: string): Promise<Goal> => {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/goal/habit-collection/all/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  });
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Goal = await res.json();
  return json;
};
