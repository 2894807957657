import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
`;

export const Label = styled.label`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`;

export const LoaderWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > div:first-child {
    margin-right: 15px;
  }
`;

export const DropdownWrapper = styled.div`
  border: 1px solid ${(p) => p.theme.colours.mediumGrey};
  border-radius: 5px;
`;

export const SelectorWrapper = styled.div`
  & > div > div:nth-of-type(1) {
    flex-direction: row;
    & > div:nth-of-type(2) {
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }
  }
`;
