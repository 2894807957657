import dayjs from 'dayjs';
import React, { FC, PropsWithChildren } from 'react';
import { ListItemContainer, InsightTitle, Publisher, PublishedAt } from './styles';
import { ListItemProps } from './types';

const ListItem: FC<ListItemProps> = ({ insight }: PropsWithChildren<ListItemProps>): JSX.Element => {
  const publicationDate: string = dayjs(insight.createdAt).format('DD/MM/YYYY');
  return (
    <ListItemContainer>
      <PublishedAt>{publicationDate}</PublishedAt>
      <InsightTitle>{insight.title}</InsightTitle>
      <Publisher>Shared by: {insight.publisherName}</Publisher>
    </ListItemContainer>
  );
};
export default ListItem;
