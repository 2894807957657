import { Activity, Module, ActivityContent, ExperimentType } from '../../utils/types';

export type ActivityDisplayData = {
  content: ActivityContent;
  title: string;
  type: string;
  id: string;
  imageUrl: string;
  completed: boolean;
  experimentId?: string;
  experimentType?: ExperimentType;
};
export type ModuleDisplayData = {
  id: string;
  title: string;
  backgroundColor: string;
};

export const adaptActivity = (modules: Array<Module>, id: string): ActivityDisplayData | null =>
  modules
    .map((module: Module) => {
      const activity = module.activities.find((activity: Activity) => activity._id === id);

      if (activity) {
        return {
          content: activity.content,
          title: activity.title,
          type: activity.type,
          id: activity._id,
          imageUrl: activity.imageUrl,
          completed: activity.type === 'experiment' ? !!activity.record?.completedDate : !!activity.record,
          experimentId: activity.record?.experimentId,
          experimentType: activity.meta?.experimentType,
        };
      }
      return null;
    })
    .filter(Boolean)[0];

export const adaptModule = (modules: Array<Module>, id: string): ModuleDisplayData | null =>
  modules
    .map((module: Module) => {
      const activity = module.activities.find((activity: Activity) => activity._id === id);
      if (activity) {
        return {
          title: module.title,
          id: module._id,
          backgroundColor: module.iconColor,
        };
      }
      return null;
    })
    .filter(Boolean)[0];
