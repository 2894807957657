import { Cohort } from '../../utils/types';
import logger from '../../utils/logger';

export type GetCohortsResponse = Array<Cohort>;
type BodyType = BodyInit | null | undefined;

export const getData: (method: string, url: string, body: BodyType, accessToken: string) => Promise<any> = async (
  method: string,
  url: string,
  body: BodyType,
  accessToken: string,
) =>
  await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body,
  }).then((res) => res.json());

export const getCohorts = async (accessToken: string): Promise<GetCohortsResponse> => {
  logger('info', 'COHORTS_GET_REQUEST');
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cohort/cohorts/list/with-references`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'COHORTS_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: Array<Cohort> = await res.json();
  logger('info', 'COHORTS_GET_SUCCESS', { 'x-request-id': res.headers.get('x-request-id') });
  return json;
};

export const getCohortsOfFacilitator = async (accessToken: string): Promise<GetCohortsResponse> => {
  logger('info', 'COHORTS_GET_REQUEST');
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cohort/cohorts/list/cohortsoffacilitator/with-references`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'COHORTS_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: Array<Cohort> = await res.json();
  logger('info', 'COHORTS_GET_SUCCESS', { 'x-request-id': res.headers.get('x-request-id') });
  return json;
};
export const getAllCohorts = async (accessToken: string): Promise<GetCohortsResponse> => {
  logger('info', 'COHORTS_GET_REQUEST');
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/cohort/cohorts/list/all/with-references`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'COHORTS_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: Array<Cohort> = await res.json();
  logger('info', 'COHORTS_GET_SUCCESS', { 'x-request-id': res.headers.get('x-request-id') });
  return json;
};

export const getCohortsInOrganisation: (id: string, accessToken: string) => Promise<any> = async (
  id: string,
  accessToken: string,
) =>
  await getData(
    'POST',
    `${process.env.REACT_APP_API_ROUTE}/cohort/cohorts/query`,
    JSON.stringify({
      expression: `organisationId EQ '${id}'`,
      maxResults: 100,
      organisationId: `${id}`,
      maxRecords: 100,
      continuationToken: null,
    }),
    accessToken,
  );

export const getCohortMeta: (id: string, accessToken: string) => Promise<any> = async (
  id: string,
  accessToken: string,
) => {
  const data = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/meta/cohort/${id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (data.status == 404) return {};
  const response = await data.json();
  return response;
};

export const getCohortUserIds: (id: string, accessToken: string) => Promise<any> = async (
  id: string,
  accessToken: string,
) =>
  await getData('GET', `${process.env.REACT_APP_API_ROUTE}/reporting/set-query/users/cohort/${id}`, null, accessToken);
