import React, { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormattedHabit, FormattedGoal } from '../../utils/adapters/goals';

import Button from '../../components/Button';
import RepetitionRadio from '../../components/RepetitionRadio';

import styles from './styles.module.scss';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader from '../../components/PageHeader';
import TextWithIcon from '../../components/TextWithIcon';

type Props = FormattedHabit & {
  updateHabit: any;
  goal: FormattedGoal;
};

type EditRepetitionsPlanFormPayload = { target: number };

const HabitEditRepetitions = ({ target, id, title, updateHabit, goal }: Props): ReactElement => {
  const history = useHistory();
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const { width: deviceWidth } = useViewport();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      target: target.toString(),
    },
  });

  const onSubmit = ({ target }: EditRepetitionsPlanFormPayload) => {
    (async () => {
      setSending(true);
      try {
        await updateHabit({ id, target, goalId: goal.id });
        history.goBack();
      } catch (err) {
        setSending(false);
      }
    })();
  };

  const onChange = () => {
    if (!hasEdited) setHasEdited(true);
  };

  return (
    <>
      {isDesktop(deviceWidth) && (
        <PageHeader
          title="Edit repetitions"
          leftButton={{ iconName: 'chevron-left', ariaLabel: `Back to habit name(${title})` }}
        />
      )}

      <div className={styles.container}>
        <TextWithIcon text="Habit" icon="habit" />

        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <legend>How many times do you want to practice this habit?</legend>

              <div className={styles.radioWrap}>
                <RepetitionRadio name="target" value="10" id="10" register={register} onChange={onChange} />
                <RepetitionRadio name="target" value="20" id="20" register={register} onChange={onChange} />
                <RepetitionRadio name="target" value="30" id="30" register={register} onChange={onChange} />
                <RepetitionRadio name="target" value="40" id="40" register={register} onChange={onChange} />
                <RepetitionRadio name="target" value="50" id="50" register={register} onChange={onChange} />
                <RepetitionRadio name="target" value="60" id="60" register={register} onChange={onChange} />
              </div>
            </fieldset>

            <div className={styles.infoCard}>
              <strong>What should i select?&nbsp;&nbsp;</strong> Some habits are easier to form than others, and some
              people may find it easier to develop new behaviors. There&apos;s no right or wrong choice, but 20
              repetitions is a good start.
            </div>

            <div className={styles.buttonContainer}>
              <Button testId="save" loading={sending} disabled={!hasEdited}>
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default HabitEditRepetitions;
