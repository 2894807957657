import React, { FC, useState, useEffect, useRef } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { resizeImage } from '../../utils/helpers';
import { Media } from '../../utils/types';
import Modal from '../Modal';
import ContentItem from '../../screens/ContentItem/';

import styles from './styles.module.scss';

type Props = {
  mediaId: string;
  getContentById: any;
};

type SwitchLinkProps = {
  url: string;
  className?: any;
  mediaId?: string;
};

const SwitchLink: FC<SwitchLinkProps> = ({ url, mediaId, ...rest }: SwitchLinkProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const appInsights = useAppInsightsContext();
  const onClick = () => {
    openModal();
    console.log('app insights', 'CLICK_ARTICLE_CARD');
    appInsights.trackEvent({
      name: 'Activity',
      properties: { type: 'CLICK_ARTICLE_CARD' },
    });
  };

  const contentParams = {
    params: {
      id: mediaId,
    },
  };

  if (url.indexOf('http') === 0) {
    return <a href={url} target="_blank" rel="noopener noreferrer" {...rest} />;
  }

  return (
    <>
      <button id={mediaId} {...rest} onClick={onClick} />
      <Modal isOpen={modalOpen} onClose={closeModal} previousFocusedElementId={mediaId}>
        <ContentItem showBreadcrumb={false} match={contentParams} />
      </Modal>
    </>
  );
};

const MediaContentCard: FC<Props> = ({ mediaId, getContentById }: Props) => {
  const [media, setMedia] = useState<Media | null>(null);
  useEffect(() => {
    (async () => {
      const content = await getContentById(mediaId);
      setMedia(content.payload);
    })();
  }, []);

  if (!media) return null;

  const link = media.url || `/app/content/${media._id}`;

  return (
    <SwitchLink url={link} className={styles.container} mediaId={mediaId}>
      <div className={styles.image} style={{ backgroundImage: `url(${resizeImage(media.imageUrl, 83, 43)})` }}></div>
      <div className={styles.content}>
        <div className={styles.type}>{media.type}</div>
        <div className={styles.title}>{media.title}</div>
      </div>
    </SwitchLink>
  );
};

export default MediaContentCard;
