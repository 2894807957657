import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import get from 'lodash.get';

import { HabitSuccessPlan } from '../../utils/adapters/goals';

import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

import CreateHabitWrapper from '../CreateHabitWrapper';

import styles from './styles.module.scss';
import { useHistory } from 'react-router-dom';

type Props = {
  update: (habit: any) => void;
  habit: any;
  goalId: string;
  setStep: (step: number) => void;
  createHabit: any;
};

const CreateHabitSuccessPlan: FC<Props> = ({ update, setStep, goalId, habit, createHabit }: Props) => {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      ...(habit || {}),
    },
  });
  const [isSending, setIsSending] = useState<boolean>(false);
  const history = useHistory();

  const create = () => {
    (async () => {
      setIsSending(true);
      try {
        await createHabit(habit);
        history.replace(`/app/goals/goal/${goalId}`);
      } catch (err) {}
    })();
  };

  const onSubmit = (successPlan: HabitSuccessPlan) => {
    update({ ...successPlan });
    create();
    //setStep(3);
  };

  return (
    <CreateHabitWrapper title={habit.title}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          id="trigger"
          name="trigger"
          error={get(errors, 'trigger', null)}
          register={register}
          placeholder="e.g. whenever I give formal or informal feedback"
          label="What will trigger your new habit?"
        />

        <TextArea
          id="reward"
          name="reward"
          error={get(errors, 'reward', null)}
          register={register}
          placeholder="e.g. more helpful feedback to my team which will lead to higher performance."
          label="What's your reward?"
        />

        <TextArea
          id="barriers"
          name="barriers"
          error={get(errors, 'barriers', null)}
          register={register}
          placeholder="e.g. some people in my team see constructive feedback as a personal attack"
          label="What barriers might get in the way?"
        />

        <TextArea
          id="enablers"
          name="enablers"
          error={get(errors, 'enablers', null)}
          register={register}
          placeholder="e.g. each time I need to give feedback to someone"
          label="What enablers could help you succeed?"
        />

        <TextArea
          id="shareWith"
          name="shareWith"
          error={get(errors, 'shareWith', null)}
          register={register}
          placeholder="Sharing with others, such as a trusted colleague, is good for motivation, accountability and reminders."
          label="Who will you share your plan with?"
        />

        <div className={styles.buttonContainer}>
          <Button testId="back" type="button" variant="outline" onClick={() => setStep(1)}>
            Back
          </Button>
          <Button testId="continue" type="submit" loading={isSending}>
            Create habit
          </Button>
        </div>
      </form>
    </CreateHabitWrapper>
  );
};

export default CreateHabitSuccessPlan;
