import { Programme } from '../../utils/types';
import { FilterDetails } from '../InsightWallFilter/types';
import { getOrganisations, getProgrammes } from '../JourneySelectModal/helpers';

export const getOrganization = async (
  tokens: any,
  orgId: string,
): Promise<{ id: string; name: string } | undefined> => {
  const result: { id: string; name: string }[] = await getOrganisations(tokens);
  return result.find((org: { id: string; name: string }): boolean => org.id === orgId);
};

export const getProgramme = async (
  tokens: any,
  orgId: string,
  userJourneyId: string,
): Promise<{ id: string | undefined; title: string | undefined; journeys: string[] }> => {
  const programmes: Programme[] = await getProgrammes(tokens, orgId);
  const programme: Programme | undefined = programmes.find((programme: Programme): boolean =>
    programme.journeys.includes(userJourneyId),
  );
  return { id: programme?._id, title: programme?.name, journeys: programme?.journeys || [] };
};

export const insightsWallFilterPreset = async (
  org: { id: string; name: string } | null,
  programme: { id?: string; title?: string; journeys: string[] } | null,
  cohortId: string,
  journeyId: string,
  activityId: string,
  experimentId: string,
  cohortTitle: string,
  activityTitle: string,
  experimentTitle: string,
  isFacilitator: boolean,
) => {
  if (isFacilitator) {
    const filters: FilterDetails = {
      org: { id: org?.id || '', title: org?.name || '' },
      programme: { id: programme?.id || '', title: programme?.title || '', journeys: programme?.journeys || [] },
      cohort: { id: cohortId, title: cohortTitle, ljId: journeyId },
      activity: { id: activityId, title: activityTitle, contentBody: [] },
      experiment: { id: experimentId, title: experimentTitle },
    };

    localStorage.setItem('insightFilters', JSON.stringify(filters));
    return;
  }

  const filters: FilterDetails = {
    org: { id: '', title: '' },
    programme: { id: '', title: '', journeys: [] },
    cohort: { id: cohortId, title: cohortTitle, ljId: '' },
    activity: { id: activityId, title: activityTitle, contentBody: [] },
    experiment: { id: experimentId, title: experimentTitle },
  };
  localStorage.setItem('insightFilters', JSON.stringify(filters));
};
