import styled from 'styled-components';

export const HomepageHeaderContainer = styled.div`
  padding: ${(p) => p.theme.spacing(3)} 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justifi-content: center;
  align-items: flex-start;
  flex: 1;
`;

export const Type = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  color: ${(p) => p.theme.colours.primary};
`;

export const Title = styled.h1`
  font-size: ${(p) => p.theme.typography.font.size.Xlarge};
`;

export const ButtonWrapper = styled.div``;

export const SwitchJourneyText = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  font-size: ${(p) => p.theme.typography.font.size.regular};
  color: ${(p) => p.theme.colours.primary};
  text-decoration: underline;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(2)};
  border-bottom: 1px solid ${(p) => p.theme.colours.mediumGrey};
`;

export const IconButtonWrapper = styled.div`
  button {
    flex-direction: row;
  }
`;
