import React, { FC } from 'react';
import ProgressBarNotched from '../../components/ProgressBarNotched';

import { habitEncouragingMessage } from './adapters';
import styles from './styles.module.scss';

type Props = {
  completed: number;
  target: number;
};

const HabitProgressSynopsis: FC<Props> = ({ completed, target }: Props) => {
  const { header, body } = habitEncouragingMessage(completed, target);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <strong>Overall Progress</strong>
        <span>
          {completed} of {target} completed
        </span>
      </div>
      <ProgressBarNotched value={completed} max={target} segments={4} color="secondary" />
      <div className={styles.infoCard}>
        <strong>{header}&nbsp;&nbsp;</strong> {body}
      </div>
    </div>
  );
};

export default HabitProgressSynopsis;
