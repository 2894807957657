import React, { FC } from 'react';
import Icon from '../Icon';
import styles from './styles.module.scss';

interface Props {
  name: string;
  register: any;
  id?: string;
  value?: any;
  onChange?: any;
}

const RepetitionRadio: FC<Props> = ({ name, register, id, value, onChange }: Props) => {
  return (
    <>
      <label className={styles.radio} htmlFor={id}>
        <input
          id={id}
          type="radio"
          className={styles.input}
          name={name}
          ref={register}
          value={value}
          onChange={onChange}
        />
        <div className={styles.radioMarker}></div>
        <Icon name="repeat" size="medium" />
        <span>{value}</span>
      </label>
    </>
  );
};

export default RepetitionRadio;
