import React, { FC, useContext, useEffect, useState } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { BlockType, Question } from '../../utils/types';
import JournalEntry1 from '../JournalEntry';
import Icon, { IconName } from '../Icon';
import styles from './styles.module.scss';
import theme from '../../utils/theme';
import { IconButton } from '../IconButton/styles';
import { ModalContext } from '../../utils/contexts';
import RichTextEditor from '../RichTextEditor';
import { JournalEntry } from '../../utils/types';
import { useAppSelector } from '../../utils/hooks';
import { isHTML } from '../../utils/helpers';
import parse from 'html-react-parser';
import {
  createUserJournalEntry,
  NewJournalEntry,
  UpdatedJournalEntry,
  updateUserJournalEntry,
} from '../../redux/userJournal.slice';
interface Props {
  icon: IconName;
  title?: string;
  introText?: string;
  view: BlockType;
  questions: Question[];
  type?: string;
  blockId?: string;
  isExperiment?: boolean;
  activityId?: string;
  isInsightWallEditorView?: boolean;
  question?: string;
  journalId?: string;
}

const CaptureBlock: FC<Props> = ({
  blockId,
  icon,
  title,
  introText,
  questions,
  view,
  type,
  isExperiment = false,
  activityId,
  isInsightWallEditorView = false,
  question,
  journalId,
}) => {
  const { openModal } = useContext(ModalContext);
  const [journalEntry, setJournalEntry] = useState<JournalEntry | null>(null);
  const journalEntries = useAppSelector((state) => state.userJournal.journalEntries);
  const { id } = useAppSelector((state) => state.user);
  const [isEditEntry, setIsEditEntry] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (journalEntries) {
      const foundEntry = journalEntries.find((journal) => journal.question === question && journal.userId === id);
      if (foundEntry) {
        setJournalEntry(foundEntry);
        setIsEditEntry(true);
        return;
      }
    }
  }, [journalEntries]);

  useEffect(() => {
    return () => {
      const sourceId1 = localStorage.getItem(`sourceId1_${journalId}`) || '';
      const response1 = localStorage.getItem(`response1_${journalId}`) || '';
      const question1 = localStorage.getItem(`question1_${journalId}`) || '';
      const parentId1 = localStorage.getItem(`parentId1_${journalId}`) || '';
      const journalEntryId = localStorage.getItem(`journalEntryId_${journalId}`) || '';
      const blktype = localStorage.getItem(`blocktype_${journalId}`);
      if (blktype === 'journalCapture' && question === question1 && journalId === sourceId1) {
        if (sourceId1 && response1 && question1 && parentId1) {
          saveAnswerWIthParam(sourceId1, response1, question1, parentId1, journalEntryId);
        }
      }
      localStorage.removeItem(`sourceId1_${journalId}`);
      localStorage.removeItem(`response1_${journalId}`);
      localStorage.removeItem(`question1_${journalId}`);
      localStorage.removeItem(`parentId1_${journalId}`);
      localStorage.removeItem(`journalEntryId_${journalId}`);
      localStorage.removeItem(`blocktype_${journalId}`);
    };
  }, []);

  const saveAnswerWIthParam = (
    sourceId: string,
    response: string,
    question: string | undefined,
    parentId: string,
    journalEntryId: string | undefined,
  ) => {
    const newJournalEntry: NewJournalEntry = {
      sourceId: sourceId,
      response: response,
      question: question,
      parentId: parentId,
    };

    const updatedJournalEntry: UpdatedJournalEntry = {
      ...newJournalEntry,
      journalEntryId: journalEntryId,
    };

    if (!journalEntryId) {
      dispatch(createUserJournalEntry(newJournalEntry));
    } else {
      dispatch(updateUserJournalEntry(updatedJournalEntry));
    }
  };

  const onEditLink = () => {
    setIsEditEntry(!isEditEntry);
  };
  return (
    <>
      {!question ? (
        <div className={styles.journalBlock} data-test-id={`journalBlock-${type}`}>
          <div className={styles.header}>
            <div className={styles.headerContainer}>
              <IconButton as="div" className={styles.headerIcon} color={theme.colours.secondary}>
                <Icon name={icon} color="white" />
              </IconButton>
              <div>
                <p className={styles.headerTitle}>Journal {type === 'Activity' ? 'Activities' : `${type}s`}</p>
                <p className={styles.headerSubtitle}>{title}</p>
              </div>
            </div>
            {!isExperiment && (
              <IconButton className={styles.helpIcon} aria-label="Open learner journal modal" onClick={openModal}>
                <Icon name="info" />
              </IconButton>
            )}
          </div>
          <div className={isExperiment ? '' : styles.journalBody}>
            <p className={styles.introText}>{introText}</p>
            {questions &&
              questions.map((question) => (
                <JournalEntry1
                  blockId={blockId}
                  questionId={question && question._id ? question._id : ''} // Not entry ID but question ID
                  response={question.response}
                  view={view}
                  key={question._id}
                  isExperiment={isExperiment}
                  activityId={activityId}
                  isInsightWallEditorView={isInsightWallEditorView}
                  question={question && question.questionText ? question.questionText : ''}
                />
              ))}
          </div>
        </div>
      ) : (
        <div>
          <div className={styles.journalBlock} data-test-id={`journalBlock-${type}`}>
            <div className={styles.grayBox}>
              <div className={styles.header}>
                <div>
                  <IconButton as="div" className={styles.headerIcon} color={theme.colours.secondary}>
                    <Icon size="small" name={icon} color="white" />
                  </IconButton>
                </div>
                <p className={styles.headerSubtitleQuestion}>{question}</p>
              </div>
              {journalEntry && (
                <>
                  <div className={isEditEntry ? styles.answerBox : styles.answerBoxHidden}>
                    <div
                      className={styles.entryResponseHTML}
                      style={{
                        display: isEditEntry ? '' : 'none',
                      }}>
                      {parse(journalEntry?.response)}
                    </div>

                    <IconButton
                      aria-label={isEditEntry ? 'quit edit journal entry' : 'edit journal entry'}
                      className={styles.editIcon}
                      onClick={onEditLink}>
                      {journalEntry?.response && <Icon name={!isEditEntry ? 'close' : 'edit'} />}
                    </IconButton>
                  </div>
                  {(!isEditEntry || !journalEntry?.response) && (
                    <div className={styles.customTextEditor}>
                      <RichTextEditor
                        placeholder="Insert answer here"
                        journalId={journalId}
                        question={question}
                        blockType={view}
                        value={journalEntry?.response}
                      />
                    </div>
                  )}
                </>
              )}
              {!journalEntry && (
                <div className={styles.customTextEditor}>
                  <RichTextEditor
                    placeholder="Insert answer here"
                    journalId={journalId}
                    question={question}
                    blockType={view}
                  />
                </div>
              )}

              {/* <p className={styles.warnMsg}>Last saved 3 mins ago…</p>
              <p className={styles.errMsg}>error, could not be saved</p> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CaptureBlock;
