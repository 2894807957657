import React, { FC, PropsWithChildren } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

type LinkRendererProps = any;

const LinkRenderer: FC<LinkRendererProps> = (props: PropsWithChildren<LinkRendererProps>): JSX.Element => {
  const appInsights = useAppInsightsContext();
  const onClick = () => {
    console.log('app insights', 'CLICK_CONTENT_LINK');
    appInsights.trackEvent({
      name: 'Activity',
      properties: { type: 'CLICK_CONTENT_LINK' },
    });
  };

  return (
    <a href={props.href} target="_blank" rel="noreferrer" onClick={onClick}>
      {props.children}
    </a>
  );
};
export default LinkRenderer;
