import styled, { css } from 'styled-components';
import theme from '../../../../utils/theme';
import UnorderedList from '../../../List';

export const StandardJourney = styled.div`
  position: relative;
  width: 100%;
  padding: 0 ${(p) => p.theme.spacing(3)};
  z-index: 1;
`;

export const List = styled(UnorderedList)`
  z-index: 2;
`;

export const ListItem = styled(UnorderedList.Item)``;

export const DottedPath = styled.div`
  position: absolute;
  border-left: 2px dotted ${theme.colours.primary};
  top: 10px;
  bottom: ${({ theme }) => theme.spacing(8)};
  left: ${({ theme }) => theme.spacing(7)};
`;
