import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import parse from 'html-react-parser';
import { isHTML } from '../../utils/helpers';

type Props = {
  question: string;
  response?: string;
  updateDate?: string | Date;
};

const JournalPlaybackEntry = ({ question, response, updateDate }: Props) => {
  const [date, setDate] = useState<string>();

  useEffect(() => {
    if (updateDate) {
      const dt = new Date(updateDate);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      setDate(months[dt.getMonth()] + ' ' + dt.getDate() + ' ' + dt.getFullYear());
    }
  }, [updateDate]);

  return (
    <div className={styles.playbackEntry}>
      <p className={styles.playbackEntryQuestion}>Question - {question}</p>
      <div className={styles.playbackResponseBlock}>
        {response && updateDate ? (
          <>
            <span className={styles.entryResponse} key={response}>
              {isHTML(response) && <div className={styles.entryResponseHTML}>{parse(response)}</div>}
              {!isHTML(response) && <p className={styles.entryResponseText}>{response}</p>}
            </span>
            <span className={styles.entryDate}>{date}</span>
          </>
        ) : (
          <div>
            <p className={styles.playbackEntryQuestionNoResponse}>You didn't respond to this question</p>
            <p className={styles.entrySmallNote}>
              Adding to your journal helps the learning process. Your responses are completely secure and private to
              you.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JournalPlaybackEntry;
