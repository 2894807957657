import styled from 'styled-components';
import theme from '../../utils/theme';
import Icon from '../Icon';

export const Banner = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colours.primary};
  color: ${theme.colours.white};
  padding: 15px;
  border: 0;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  opacity: 0.8;
  cursor: pointer;

  @media (min-width: ${theme.devices.tablet}px) {
    padding: 15px 0px;
  }
  @media (max-width: ${theme.devices.tablet}px) {
    margin-top: 57px;
  }
`;

export const JourneyIcon = styled(Icon)`
  margin-right: 20px;
  flex-shrink: 0;
`;
