export const completedAlert = 'Thank you for completing your assessment. \n';
export const completedDescription = 'Please make sure you have selected your raters to provide feedback.';

export const expirationAlert = 'Assessment overdue. \n';
export const expirationDescription =
  'The assessment window is about to close. Complete yours now, before it’s too late!';

export const closeAlert = 'This assessment is now closed. \n';
export const closeDescription = 'Your report will be sent to you via email once it is ready.';

export const assessmentCardDescrition =
  'Please complete the assessment and select which raters you want to provide feedback, before the above deadline.';
