import styled, { css } from 'styled-components';

export const ExperimentContentContainer = styled.div<{ isDesktop: boolean }>`
  > div {
    margin-bottom: ${(p) => p.theme.spacing(5)};
  }

  > div:nth-of-type(2) {
    margin-bottom: ${(p) => p.theme.spacing(3)};
  }

  ${(p) =>
    p.isDesktop &&
    css`

  > #contentCarousel {
    > div {
      > div:last-child {
        width: 500px;
        max-width: 500px;
      }

      #carouselItemContainer {
        width: 226px;
      }

      .slick-slider {
        align-self: center;
      }

      .slick-slide {
        > div {
          justify-content: center;
          align-items: center;
        }
      }
    }
  `}
  }
`;

export const ExperimentTilesContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > * {
    ${(p) =>
      p.isMobile
        ? css`
            margin: 0 ${(p) => p.theme.spacing(0.625)} 0 ${(p) => p.theme.spacing(0.625)};
            flex: 1;
          `
        : css`
            margin: 0 ${(p) => p.theme.spacing(1.5)} 0 ${(p) => p.theme.spacing(1.5)};
            flex: 1;
          `}

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ExperimentInstructions = styled.div`
  background-color: rgba(81, 26, 188, 0.05);
  border-radius: ${(p) => p.theme.borders.radius};
  padding: ${(p) => p.theme.spacing(3)} ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(3)}
    ${(p) => p.theme.spacing(2)};

  > .markdown {
    > h1,
    h2,
    h3,
    h4 {
      color: ${(p) => p.theme.colours.primary};
    }
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SectionTitle = styled.h2`
  margin: 0 0 ${(p) => p.theme.spacing(2)} 0;
`;
