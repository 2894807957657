import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions } from '../../redux/auth.slice';

import NavigationHeader from './NavigationHeader';

const { logout } = actions;

const mapState = (state: RootState) => {
  return {
    journeyTitle: get(state, 'journey.title', ''),
    roles: get(state, 'user.roles', []),
  };
};

export default connect(mapState, { logout })(NavigationHeader);
