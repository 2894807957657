import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import Button from '../../../Button';
import { ButtonNumber, ButtonText, WidgetText, Widgetlist } from './styles';
import Widget from '../../components/HomepageWidget';
import theme from '../../../../utils/theme';
import { useAppSelector } from '../../../../utils/hooks';
import { RootState } from '../../../../redux';
import { useHistory } from 'react-router-dom';
import ListItem from './components/ListItem';
import { actions } from '../../../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import { InsightResponse } from '../../../../redux/experiment.slice';
import Modal from '../../../Modal';
import FilterModal from '../../../InsightWallFilter/components/FilterModal';
import { FilterDetails } from '../../../InsightWallFilter/types';
import { UserState } from '../../../../redux/user.slice';
import { JourneyState } from '../../../../redux/journey.slice';
const { insightNotificationsDismissAll, setVisitedInsightsPage } = actions;

const InsightsWidget: FC = ({}: PropsWithChildren<{}>): JSX.Element => {
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const { insights: insightsToNotify } = useAppSelector((state: RootState) => state.notifications);
  const { insights: allInsightData } = useAppSelector((state: RootState) => state.experiment);
  const { cohortId } = useAppSelector((state: RootState) => state.cohorts.selectedCohort);
  const { isPreview } = useAppSelector((state: RootState): JourneyState => state.journey);
  const { roles } = useAppSelector((state: RootState): UserState => state.user);
  const [unseenInsights, setUnseenInsights] = useState<InsightResponse[]>([]);
  // const isFacilitator: boolean = roles.includes('system:administrator') || roles.includes('cirrus-facilitator');
  // const isFacilitator: boolean = false;
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const [showInsightsWallFilterModal, setShowInsightsWallFilterModal] = useState<boolean>(false);

  useEffect((): (() => void) => {
    if (!insightsToNotify) {
      return (): void => {};
    }

    const foundUnseenInsights = insightsToNotify.filter(
      (insight: any) => !insight.seen && insight.cohortId === cohortId,
    );

    if (!foundUnseenInsights || !allInsightData) {
      return (): void => {};
    }

    const filteredInsights = allInsightData.filter((insightData: any) =>
      foundUnseenInsights.find((unseenInsight: any) => unseenInsight.insightId === insightData.id),
    );

    const orderedInsights: InsightResponse[] = filteredInsights.sort(
      (a: InsightResponse, b: InsightResponse): number =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );

    setUnseenInsights(orderedInsights);

    return (): void => {};
  }, [insightsToNotify, allInsightData]);

  const handleInsightWallAccess = (): void => {
    if (isPreview) {
      setShowInsightsWallFilterModal(true);
    } else {
      if(isFacilitator && cohortId)
        history.push(`insightswall?cohortId=${cohortId}`);
      else
        history.push(`insightswall`);
      dispatch(insightNotificationsDismissAll(null));
      dispatch(setVisitedInsightsPage());
    }
  };

  return (
    <>
      <Widget title="Insights Wall" iconName="experiment-info">
        <Button
          onClick={(): void => handleInsightWallAccess()}
          style={{ borderRadius: 4, alignItems: 'flex-start', marginBottom: theme.spacing(3) }}>
          <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ButtonNumber>{unseenInsights.length}</ButtonNumber>
            <ButtonText>New insights shared</ButtonText>
          </span>
        </Button>
        {(unseenInsights.length && (
          <>
            <span>Most recent:</span>
            <Widgetlist>
              {unseenInsights.map((insight: InsightResponse, index: number): JSX.Element | null => {
                if (index <= 2) {
                  return <ListItem key={insight.id} insight={insight} />;
                } else {
                  return null;
                }
              })}
            </Widgetlist>
          </>
        )) ||
          ''}

        {!unseenInsights.length && (
          <>
            <WidgetText>
              You may be invited to complete certain experiments over the course of this learning journey.
            </WidgetText>
            &nbsp;
            <WidgetText>
              You can use this area to review any insights that you or others have shared about these experiments.
            </WidgetText>
            &nbsp;
          </>
        )}
      </Widget>

      <Modal isOpen={showInsightsWallFilterModal} onClose={(): void => setShowInsightsWallFilterModal(false)}>
        <FilterModal
          isOpen={showInsightsWallFilterModal}
          filter={(filters: FilterDetails): void => localStorage.setItem('insightFilters', JSON.stringify(filters))}
          onClose={(): void => {
            setShowInsightsWallFilterModal(false);
            history.push('/app/insightswall');
          }}
          isFacilitator={isFacilitator}
        />
      </Modal>
    </>
  );
};
export default InsightsWidget;
