import React, { PropsWithChildren } from 'react';
import dayjs from 'dayjs';
import {
  ContentCard,
  CardInlineWrapper,
  CardType,
  CardTitle,
  CardOption,
  CardBlockWrapper,
} from '../shared/cardStyles';
import Icon from '../../../../components/Icon';
import theme from '../../../../utils/theme';
import CardDescription from './components/CardDescription';
import CardRatersButton from './components/CardRatersButton';
import CardStartButton from './components/CardStartButton';
import { AssessmentCardProps } from './types';

const AssessmentCard: React.FC<AssessmentCardProps> = ({
  title,
  type = 'Assessment | 5-15 mins',
  deadline,
  totalRaters,
  status,
  startButtonOnPress,
  ratersButtonOnPress,
  downloadButtonOnPress,
  cardIndex,
  campaignName,
  isLoadingStart,
  isLoadingRaters,
}: PropsWithChildren<AssessmentCardProps>): JSX.Element => {
  return (
    <ContentCard>
      <CardInlineWrapper marginStyle="top">
        <Icon name="assessment" fill={theme.colours.primary} />
        <CardType color={theme.colours.primary} data-test-id={'AssessmentCardType'}>
          {type.toUpperCase()}
        </CardType>
      </CardInlineWrapper>

      <CardBlockWrapper divider>
        <CardTitle data-test-id={'AssessmentCardTitle'}>{title}</CardTitle>
        {campaignName}
      </CardBlockWrapper>

      <CardInlineWrapper spaced divider>
        <div>
          <CardOption>Raters:</CardOption>
          <p>{totalRaters} selected</p>
        </div>
        <CardRatersButton
          status={status}
          cardIndex={cardIndex}
          onClick={startButtonOnPress}
          isLoading={isLoadingRaters}
        />
      </CardInlineWrapper>

      <CardInlineWrapper spaced divider>
        <div>
          <CardOption>Deadline:</CardOption>
          <p>{dayjs(deadline).format('DD MMM YYYY')}</p>
        </div>
        <CardStartButton
          status={status}
          cardIndex={cardIndex}
          onClick={ratersButtonOnPress}
          isLoading={isLoadingStart}
        />
      </CardInlineWrapper>

      <CardDescription status={status} cardIndex={cardIndex} onClick={downloadButtonOnPress} />
    </ContentCard>
  );
};

export default AssessmentCard;
