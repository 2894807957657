import React, { FC } from 'react';
import * as Styled from './styles';

import { useHistory } from 'react-router';
import Icon, { IconName } from '../Icon';
import { Pallette } from '../../utils/types/theme';
import { Divider } from '../Divider/styles';
import theme from '../../utils/theme';

export interface HeaderButton {
  iconName?: IconName | false;
  onClick?: () => void;
  background?: Pallette | string;
  ariaLabel?: string;
}

interface Props {
  title: string;
  subTitle?: string;

  leftButton?: HeaderButton;
  rightButton?: HeaderButton;

  type?: 'main' | 'activity' | 'home';
  setPreviousFocusedElement?: any;
}

const PageHeader: FC<Props> = ({
  title,
  subTitle,
  leftButton,
  rightButton,
  type = 'main',
  setPreviousFocusedElement,
}) => {
  const history = useHistory();

  const showLeftChevron = leftButton && leftButton.iconName === 'chevron-left';

  const handleLeftIconClick = () => {
    if (history.location.pathname.startsWith('/app/goals/goal')) {
      return history.push('/app/goals');
    }

    if (showLeftChevron) history.goBack();

    if (leftButton && leftButton.onClick) leftButton.onClick();
  };

  const handleRightIconClick = () => {
    if (rightButton && rightButton.onClick) rightButton.onClick();
  };

  return (
    <>
      <Styled.PageHeader type={type} style={{marginBottom:'10px'}}>
        {leftButton && (
          <Styled.LeftHeaderButton
            color={leftButton.background}
            onClick={handleLeftIconClick}
            size="small"
            isBackButton={showLeftChevron}
            aria-label={leftButton.ariaLabel}>
            {leftButton.iconName && <Icon name={leftButton.iconName} />}

            {showLeftChevron && <span>Back</span>}
          </Styled.LeftHeaderButton>
        )}

        {type === 'activity' && (
          <Styled.Meta>
            <Styled.SubTitle>{subTitle}</Styled.SubTitle>
            <Styled.PageTitle isActivityPageTitle={type === 'activity'}>{title}</Styled.PageTitle>
          </Styled.Meta>
        )}

        {type !== 'activity' && <Styled.PageTitle isActivityPageTitle={false}>{title}</Styled.PageTitle>}

        {type === 'activity' && rightButton && (
          <Styled.ActivityIcon
            color={rightButton.background}
            size="small"
            tabIndex={0}
            aria-label={`activity ${title}(${rightButton.iconName === 'check' ? 'completed' : 'incomplete'})`}>
            <Icon name={rightButton.iconName || 'webinar'} type="activity" color="white" />
          </Styled.ActivityIcon>
        )}

        {type === 'main' && rightButton && (
          <Styled.RightHeaderButton
            color={rightButton.background}
            onClick={handleRightIconClick}
            size="small"
            onMouseDown={() =>
              setPreviousFocusedElement && (setPreviousFocusedElement(document.activeElement) as HTMLElement)
            }
            aria-label={rightButton.ariaLabel}>
            {rightButton.iconName && <Icon name={rightButton.iconName} />}
          </Styled.RightHeaderButton>
        )}
        {type === 'home' && rightButton && (
          <Styled.RightHeaderButton
            isHomeHeader={true}
            color={rightButton.background}
            onClick={handleRightIconClick}
            size="small"
            onMouseDown={() =>
              setPreviousFocusedElement && (setPreviousFocusedElement(document.activeElement) as HTMLElement)
            }
            aria-label={rightButton.ariaLabel}>
            {rightButton.iconName && <Icon name={rightButton.iconName} />}
          </Styled.RightHeaderButton>
        )}
      </Styled.PageHeader>

      <div style={{ width: '100%', padding: type == 'home' ? `0 ${theme.spacing(3)}` : '0px' }}>

      </div>
    </>
  );
};

export default PageHeader;
