import React, { FC } from 'react';

import ContentCarouselArrow from '../../components/ContentCarouselArrow';
import HabitToken from '../../components/HabitToken';
import List from '../../components/List';

import { FormattedPracticeDate } from '../../utils/adapters/goals';

import styles from './styles.module.scss';

type Props = {
  habitPracticeDates: Array<FormattedPracticeDate>;
  target: number;
  onHabitClick: any;
};

const settings = {
  arrows: true,
  infinite: false,
  dots: false,
  speed: 500,
  swipe: false,
  slidesToShow: 8,
  slidesToScroll: 7,
  nextArrow: <ContentCarouselArrow next />,
  prevArrow: <ContentCarouselArrow />,
  initialSlide: 0,
};

const HabitTrackerCarousel: FC<Props> = ({ habitPracticeDates, target, onHabitClick }: Props) => {
  const habitTokenList = [...habitPracticeDates, ...new Array(target - habitPracticeDates.length).fill(null)];
  settings.initialSlide = Math.max(0, habitPracticeDates.length - 1);
  return (
    <div className={styles.container}>
      <div className={styles.heading}>Track your habit</div>
      <div className={styles.body}>Tap a habit circle below every time you practice.</div>
      <div className={styles.sliderWrapper}>
        <div className={styles.scrollable}>
          <ul className={styles.list}>
            {habitTokenList.map((habitTokenDetails, index) => (
              <List.Item key={index}>
                <HabitToken {...habitTokenDetails} index={index} onClick={onHabitClick} />
              </List.Item>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HabitTrackerCarousel;
