import React, { ReactElement, useState } from 'react';
import get from 'lodash.get';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { FormattedGoal } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import Button from '../../components/Button';
import TextArea from '../../components/TextArea';

import styles from './styles.module.scss';
import dayjs from 'dayjs';
import { useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import PageHeader from '../../components/PageHeader';
import TextWithIcon from '../../components/TextWithIcon';

type Props = FormattedGoal & {
  updateGoal: any;
  reflectionId: string;
};

type ReflectionFormPayload = {
  comment: string;
  id: string;
  rating: string;
};

const GoalReflection = ({ id: goalId, reflections, reflectionId, updateGoal, title }: Props): ReactElement => {
  const history = useHistory();
  const [sending, setSending] = useState<boolean>(false);
  const emojis: string[] = ['angry', 'sad', 'neutral', 'smile', 'happy'];

  const baseReflection = (reflections || []).find(({ id }) => id === reflectionId) || {
    id: reflectionId,
    rating: '',
  };
  baseReflection.rating = `${baseReflection.rating}`;

  const { width: deviceWidth } = useViewport();

  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      ...baseReflection,
    },
  });

  const onSubmit = ({ comment, rating }: ReflectionFormPayload) => {
    (async () => {
      setSending(true);

      const newReflections = [...(reflections || [])];
      if (reflectionId === 'add') {
        newReflections.push({ comment, rating: parseInt(rating), id: '', createdOn: dayjs().format() });
      } else {
        newReflections.forEach((reflection, index) => {
          if (reflection.id === reflectionId) {
            newReflections[index] = { ...reflection, comment, rating: parseInt(rating) };
          }
        });
      }

      try {
        logger('info', 'REFLECTION_SAVE', { goalId });
        await updateGoal({ id: goalId, reflections: newReflections });
        history.goBack();
      } catch (err) {
        setSending(false);
      }
    })();
  };

  return (
    <>
      {isDesktop(deviceWidth) && (
        <PageHeader
          title="Reflections"
          leftButton={{ iconName: 'chevron-left', ariaLabel: 'Back to your goal reflections' }}
        />
      )}

      <div className={styles.container}>
        <TextWithIcon text="Reflections" icon="reflection" />

        <div className={styles.content}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" name="id" ref={register} />
            <label>How do you feel about your progress?</label>
            <div className={styles.radioGroup}>
              {[1, 2, 3, 4, 5].map((index) => (
                <label key={index} className={styles.radio} htmlFor={`rating-${index}`}>
                  <input
                    id={`rating-${index}`}
                    type="radio"
                    className={styles.input}
                    name="rating"
                    aria-label={emojis[index - 1]}
                    value={index}
                    ref={register({ required: true })}
                  />
                  <img src={`/images/emojis/emoji-${index}.png`} />
                </label>
              ))}
            </div>
            {errors.rating && <p className={styles.errorMessage}>Required</p>}

            <TextArea
              id="comment"
              name="comment"
              error={get(errors, 'comment', null)}
              register={register}
              validate={{
                required: true,
              }}
              placeholder="We recommend taking a few minutes at least once a week to help you enjoy
                learning the habit and to reinforce your determination to make it stick."
              label="Add any reflections you have on your goal"
            />
            <div style={{ height: '35px' }}></div>
            <Button loading={sending}>Save</Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default GoalReflection;
