import React, { FC, useEffect, useState } from 'react';
import { getJournalBlock } from '../../utils/api/learnerJournal';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import { BlockType, JournalBlock as JournalBlockType, Question, Tokens } from '../../utils/types';
import CaptureBlock from '../CaptureBlock';
import JournalPlaybackBlock from '../JournalPlaybackBlock';
import { actions as ExperimentAction } from '../../redux/experiment.slice';
import {checkCaptureByQuestion} from '../../utils/api/activity'

interface Props {
  blockId: string;
  blockType?: BlockType;
  questionId?: string;
  isExperiment?: boolean;
  activityId?: string;
  isInsightWallEditorView?: boolean;
  question?: string;
  journalId?: string;
}

const JournalBlock: FC<Props> = ({
  blockId,
  blockType,
  questionId,
  isExperiment = false,
  activityId,
  isInsightWallEditorView = false,
  question,
  journalId
}) => {
  const dispatch = useAppDispatch();

  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);
  const { journalEntries } = useAppSelector((state) => state.userJournal);

  const { id } = useAppSelector((state) => state.user);
  const tokens: Tokens = { accessToken, refreshToken };

  const [journalBlock, setJournalBlock] = useState<JournalBlockType | null>(null);
  const [questionsWithResponse, setQuestionsWithResponse] = useState<any>([]);

  useEffect(() => {
    if (blockId && blockId.length>0) {
      (async () => {
        const data = await getJournalBlock(blockId, tokens);
        if (blockType === 'journalPlayback' && data.questions) {
          data.questions = data.questions.filter((question) => question._id === questionId);
        }

        setJournalBlock(data);
      })();
    }
  }, [blockId]);

  useEffect(() => {
    if (journalBlock && journalBlock.questions) {
      const questions = journalBlock.questions.map((question) => {
        const foundEntry = journalEntries.find((entry) => entry.sourceId === question._id && entry.userId === id);
        const questionWithResponse: Question = {
          ...question,
          response: foundEntry && foundEntry.response ? foundEntry.response : '',
          responseUpdatedAt: foundEntry && foundEntry.updatedAt ? foundEntry.updatedAt : '',
        };
        return questionWithResponse;
      });

      const questionIdList: string[] = questions.map((question: any): string => question._id);

      if (isExperiment) {
        dispatch(ExperimentAction.setCurrentEntryQuestionIds(questionIdList));
      }

      setQuestionsWithResponse(questions);

      return (): void => {
        if (isExperiment) {
          dispatch(ExperimentAction.resetCurrentEntryQuestionIds());
        }
      };
    }
  }, [journalBlock, journalEntries]);
  
  useEffect(() => {
    let func= async ()=>{
      if (question) {
       const foundEntry = journalEntries.find((entry) => entry.question === question);
      let acitivtyData= await checkCaptureByQuestion(accessToken,question,"journalCapture")  
      if(blockType!='journalPlayback' || ((foundEntry && foundEntry.response.length>0)  || acitivtyData.length>0)){
        const questionWithResponse: Question = {
          question:question,
          response: foundEntry && foundEntry.response ? foundEntry.response : '',
          responseUpdatedAt: foundEntry && foundEntry.updatedAt ? foundEntry.updatedAt : '',
        };

      setQuestionsWithResponse([questionWithResponse]);
      }
      return (): void => {
        if (isExperiment) {
          dispatch(ExperimentAction.resetCurrentEntryQuestionIds());
        }
      };
    }}
    func();
  }, [question, journalEntries]);
  
  return (
    <>
      {journalBlock && blockType === 'insightJournalText' && (
        <CaptureBlock
          icon={'experiment-info'}
          view={blockType}
          title={'Share your insights'}
          introText={journalBlock.introText}
          questions={questionsWithResponse}
          type={'insight'}
          isExperiment={isExperiment}
          activityId={activityId}
          blockId={blockId}
          isInsightWallEditorView={isInsightWallEditorView}
        />
      )}
      {journalBlock && blockType === 'journalCapture' && (
        <CaptureBlock
          icon={journalBlock.icon}
          view={blockType}
          title={journalBlock.blockTitle}
          introText={journalBlock.introText}
          questions={questionsWithResponse}
          type={journalBlock.type}
          activityId={activityId}
          isExperiment={isExperiment}
          blockId={blockId}
        />
      )}
      {question && question.length && blockType === 'journalCapture' && (
        <CaptureBlock
          icon={'reflect'}
          view={blockType}
          questions={questionsWithResponse}
          activityId={activityId}
          isExperiment={isExperiment}
          blockId={blockId}
          question={question}
          journalId={journalId}
        />
      )}
      {!question && journalBlock && blockType === 'journalPlayback' && (
        <JournalPlaybackBlock
          icon={journalBlock.icon}
          view={blockType}
          introText={journalBlock.introText}
          questions={questionsWithResponse}
        />
      )}
      {question && question.length>0 && blockType === 'journalPlayback' && (
        <JournalPlaybackBlock
          icon={'reflection'}
          view={blockType}
          introText={''}
          questions={questionsWithResponse}
          question={question}
          journalId={journalId?journalId:''}      
        />
      )}
    </>
  );
};

export default JournalBlock;
