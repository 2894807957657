import { setSessionLogin } from '../logger';
import logger from '../../utils/logger';
import { getBrowserNameAndVersion, getOSversion, getOS } from '../getUserAgent';

type BodyType = BodyInit | null | undefined;

export type GetUserResponse = {
  email: string;
  externalId: string;
  firstName: string;
  lastName: string;
  id: string;
  organizationId: string;
  roles: Array<string>;
};

const getData: (method: string, url: string, body: BodyType, accessToken: string) => Promise<any> = async (
  method: string,
  url: string,
  body: BodyType,
  accessToken: string,
) => {
  const data: Response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body,
  });
  const response: any = await data.json();
  return response;
};

export const getUserRecords: (ljId: string, uIds: string[], accessToken: string) => Promise<any> = async (
  ljId: string,
  uIds: string[],
  accessToken: string,
) =>
  await getData(
    'POST',
    `${process.env.REACT_APP_API_ROUTE}/journey/report/progress/user-group`,
    JSON.stringify({ journeyId: ljId, users: uIds }),
    accessToken,
  );

// Specific to cohort membership
export const getCohortUsers: (id: string, accessToken: string) => Promise<any> = async (
  id: string,
  accessToken: string,
) =>
  await getData('GET', `${process.env.REACT_APP_API_ROUTE}/reporting/set-query/users-fac/cohort/${id}`, null, accessToken);

// Specific to requested cohort regardless of membership
export const getCohortUsersAnyCohort: (id: string, accessToken: string) => Promise<any> = async (
  id: string,
  accessToken: string,
) =>
  await getData(
    'GET',
    `${process.env.REACT_APP_API_ROUTE}/reporting/set-query/all-users/cohort/${id}`,
    null,
    accessToken,
  );

export const getUser = async (accessToken: string, islogin = false): Promise<GetUserResponse> => {
  logger('info', 'USER_GET_REQUEST');

  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/user-profile/identity/full`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'USER_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: GetUserResponse = await res.json();
  setSessionLogin(json.id);
  logger('info', 'USER_GET_SUCCESS');
  if (islogin) {
    logger('info', 'LOG_IN');
  }
  return json;
};

export const updateUserMeta: (accessToken: string) => Promise<any> = async (accessToken: string) => {
  const browser = getBrowserNameAndVersion();
  const { osv }: { osv: RegExpMatchArray | null } = getOSversion(navigator, browser);
  const { os }: { os: string } = getOS();
  const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/user-meta/user-meta/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      appVersion: process.env.REACT_APP_VERSION,
      os: os.trim() || '',
      osV: osv?.toString().trim() || '',
      browser: browser.split(' ')[0],
      browserVersion: browser.split(' ')[1],
      systemNotificationsStatus: 'n/a',
      genericNotificationsEnabled: false,
      habitNotificationsEnabled: false,
    }),
  });
  if (data.status !== 200) {
    return {};
  }
  const response = await data.json();
  return response;
};
export const getUserById = async (accessToken: string, userId: string): Promise<GetUserResponse> => {
  logger('info', 'USER_GET_REQUEST');
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/user-profile/admin/users/${userId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  if (res.status === 401) {
    logger('error', 'USER_GET_FAILURE');
    throw new Error('Unauthorized');
  }
  const json: GetUserResponse = await res.json();
  return json;
};