import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Dropdown from '../Dropdown';

import { getOrganisations, getProgrammes, getJourneys, getJourney } from './helpers';
import Button from '../Button';

import styles from './styles.module.scss';
import Modal from '../Modal';
import { Tokens } from '../../utils/types';
import { useAppSelector, useViewport } from '../../utils/hooks';
import theme from '../../utils/theme';

interface Props {
  isOpen: boolean;
  tokens: Tokens;
  setJourneyPreview: any;
  onClose: () => void;
}

const JourneySelectModal: FC<Props> = ({ isOpen, onClose, tokens, setJourneyPreview }: Props) => {
  const history = useHistory();
  const { cohortId } = useAppSelector((state) => state.cohorts.cohorts[0]);
  const [orgs, setOrgs] = useState<any>(null);
  const [programmes, setProgrammes] = useState<any>(null);
  const [journeys, setJourneys] = useState<any>(null);
  const [selectedOrg, setSelectedOrg] = useState<string>('');
  const [selectedOrgName, setSelectedOrgName] = useState<string>('');
  const [selectedProgramme, setSelectedProgramme] = useState<string>('');
  const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>('');
  const [selectedJourney, setSelectedJourney] = useState<string>('');
  const [selectedJourneyName, setSelectedJourneyName] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);
  const { width: deviceWidth } = useViewport();

  useEffect(() => {
    if (isOpen) {
      let active = true;
      (async () => {
        const result = await getOrganisations(tokens);
        setTimeout(() => {
          if (active) {
            setOrgs(result);
          }
        }, 300);
      })();
      return () => {
        active = false;
      };
    } else {
      setSelectedOrg('');
      setSelectedOrgName('');
      setOrgs(null);
      setSelectedProgramme('');
      setSelectedProgrammeName('');
      setProgrammes(null);
      setSelectedJourney('');
      setSelectedJourneyName('');
      setJourneys(null);
      setSaving(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }
    let active = true;
    (async () => {
      const result = await getProgrammes(tokens, selectedOrg);
      setTimeout(() => {
        if (active) {
          setProgrammes(result);
        }
      }, 300);
    })();
    return () => {
      active = false;
    };
  }, [tokens, selectedOrg]);

  useEffect(() => {
    if (!selectedProgramme) {
      return;
    }
    let active = true;
    (async () => {
      const result = await getJourneys(tokens, selectedProgramme);
      setTimeout(() => {
        if (active) {
          setJourneys(result);
        }
      }, 300);
    })();
    return () => {
      active = false;
    };
  }, [tokens, selectedProgramme]);

  useEffect(() => {
    if (!saving) {
      return;
    }
    let active = true;
    (async () => {
      const result = await getJourney(tokens, selectedJourney, cohortId);
      setTimeout(() => {
        if (active) {
          setJourneyPreview(result);
          onClose();
          history.push('/app/journey');
        }
      }, 2000);
    })();
    return () => {
      active = false;
    };
  }, [saving, selectedJourney, tokens]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={deviceWidth > theme.devices.tablet ? 'small' : 'default'}
      containsDropdown>
      <div className={styles.title}>Choose a journey!</div>
      <Dropdown
        loading={!orgs}
        label="Select Organisation"
        placeholder={selectedOrgName || 'Name of organisation'}
        emptyLabel="Failed to load organisations"
        onValueChange={({ label, value }: any) => {
          setSelectedProgramme('');
          setProgrammes(null);
          setSelectedJourney('');
          setJourneys(null);
          setSelectedOrg(value || '');
          setSelectedOrgName(label);
        }}
        items={(orgs || []).map((org: any) => ({
          label: org.name,
          value: org.id,
        }))}
        value={selectedOrg}
      />
      {selectedOrg && (
        <Dropdown
          loading={!programmes}
          label="Select programme"
          placeholder={selectedProgrammeName || 'Name of programme'}
          emptyLabel="No programmes available"
          onValueChange={({ label, value }: any) => {
            setSelectedJourney('');
            setJourneys(null);
            setSelectedProgramme(value || '');
            setSelectedProgrammeName(label);
          }}
          items={(programmes || []).map((programme: any) => ({
            label: programme.name,
            value: programme._id,
          }))}
          value={selectedProgramme}
        />
      )}
      {selectedProgramme && (
        <Dropdown
          loading={!journeys}
          label="Select learning journey"
          placeholder={selectedJourneyName || 'Name of learning journey'}
          emptyLabel="No learning journeys available"
          onValueChange={({ label, value }: any) => {
            setSelectedJourney(value || '');
            setSelectedJourneyName(label);
          }}
          items={(journeys || []).map((journey: any) => ({
            label: journey.title,
            value: journey._id,
          }))}
          value={selectedJourney}
        />
      )}
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => {
            setSaving(true);
          }}
          disabled={selectedJourney === ''}
          loading={saving}>
          View
        </Button>
      </div>
    </Modal>
  );
};

export default JourneySelectModal;
