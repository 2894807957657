import styled, { css } from 'styled-components';
import { JourneyProgressContainerStyleProps } from './types';
import { buildStyles } from 'react-circular-progressbar';
import theme from '../../../utils/theme';

export const JourneyProgressContainer = styled.div<JourneyProgressContainerStyleProps>`
  display: flex;
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: center;
  width: 100%;
  border-radius: ${(p) => p.theme.borders.radius};
  background-color: rgba(236, 236, 236, 0.6);
  padding: ${(p) => p.theme.spacing(3)};
  margin-bottom: ${(p) => p.theme.spacing(2)};

  ${(p) =>
    p.isMobile &&
    css`
      > div:first-child {
        margin-bottom: ${(p) => p.theme.spacing(3)};
      }
    `}
`;

export const ProgressBarContainer = styled.div`
  position: relative;
  width: 160px;
  height: 160px;

  justify-content: center;
  align-items: center;

  .CircularProgressbar > * {
    display: block;
  }
`;

export const ProgressbarContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SubProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  width: 100%;
`;

export const ProgressSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(p) => p.theme.colours.darkGrey};
`;

export const ProgressText = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.Xlarge};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
`;

export const ProgressFieldText = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.small};
  text-align: center;
  line-height: 16px;
`;

export const CircularProgress = buildStyles({
  strokeLinecap: 'butt',
  pathTransitionDuration: 0.5,
  pathColor: theme.colours.primary,
  trailColor: theme.colours.white,
});
