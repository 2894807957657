import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

import styles from './styles.module.scss';

import AnimatedPage from '../../components/AnimatedPage';
import LoginEmail from '../../containers/LoginEmail';
import LoginCode from '../../containers/LoginCode';

const AuthModal: FC = () => {
  return (
    <>
      <div className={styles.backdrop}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <img src="/images/strata_logo.svg" alt="" />
          </div>
          <div className={styles.modalBody}>
            <Switch>
              <Route path="/auth/code" exact component={AnimatedPage(LoginCode, 'SlideIn')} />
              <Route path="/auth/email" exact component={AnimatedPage(LoginEmail, 'SlideIn')} />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthModal;
