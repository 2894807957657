import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { JourneyState } from '../../redux/journey.slice';
import { ActivityDisplayData, adaptModuleById, ModuleDisplayData } from '../../screens/Module/adapters';
import ActivityCard from '../ActivityCard';
import ExperimentActivityCard from '../ExperimentActivityCard';
import Icon from '../Icon';
import styles from './styles.module.scss';
import ModuleHeader from '../ModuleHeader';
import { useAppSelector, useViewport } from '../../utils/hooks';
import { isDesktop } from '../../utils/breakpoints';
import List from '../List';
import { Experiment } from '../../utils/types';
import theme from '../../utils/theme';

interface Props {
  activityId?: string;
}

const ActivityNavigation: FC<Props> = ({ activityId }) => {
  const { width: deviceWidth } = useViewport();
  const [module, setModule] = useState<ModuleDisplayData | null>(null);
  const journey: JourneyState = useSelector((state: any) => state.journey);
  const { cohorts, selectedCohort } = useAppSelector((state) => state.cohorts);
  const { id: moduleParamId } = useParams<{ id: string }>();
  const moduleId = new URLSearchParams(window.location.search).get('moduleId') || moduleParamId;
  const history = useHistory();
  const { experiments } = useAppSelector((state) => state.experiment);
  const { roles } = useAppSelector((state) => state.user);
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const backToModulesLink:string = isFacilitator ? `/app/journey?cohortId=${selectedCohort.cohortId}` : "/app/journey";
  useEffect(() => {
    setModule(adaptModuleById(journey.modules, moduleId));
  }, [journey]);

  useEffect(() => {
    if (typeof module === 'undefined') {
      history.push('/app/journey');
    }
  }, [module]);

  return (
    <div className={styles.activityNavigation}>
      {module && (
        <>
          {isDesktop(deviceWidth) && (
            <Link to={backToModulesLink}>
              <div className={styles.backToJourney}>
                <Icon name="chevron-left" />
                <span style={{ color: theme.colours.primary }}>BACK TO ALL MODULES</span>
              </div>
            </Link>
          )}
          <ModuleHeader {...module} progress={(module.completedActivities / module.totalActivities) * 100} />

          <List>
            {module.activities.map((activity, i: number) => {
              if (activity.type == 'experiment') {
                const experiment = experiments.find(
                  (experiment: Experiment) => experiment.id === activity.experimentId,
                );
                let deadline = '';
                if(cohorts && cohorts.length>0){
                  cohorts.forEach((cohort: any) => {
                    if (cohort.cohortId == selectedCohort.cohortId) {
                      if(cohort.experiments && cohort.experiments.length>0){
                        cohort.experiments.forEach((experiment: any) => {
                        if(experiment.experimentId == activity.id) {
                          deadline = experiment.deadlineDate;
                        }
                      });
                     }
                    }
                  });
                }
                
                return (
                  <List.Item key={activity.id}>
                    <ExperimentActivityCard
                      deadline={deadline}
                      experiment={experiment}
                      moduleName={module.title}
                      moduleId={moduleId}
                      moduleBackground={module.iconColor}
                      currentActivityId={activityId}
                      isStarted={activity.isExperimentStarted}
                      {...activity}
                    />
                  </List.Item>
                );
              } else {
                return (
                  <List.Item key={activity.id}>
                    <ActivityCard
                      moduleName={module.title}
                      moduleId={moduleId}
                      moduleBackground={module.iconColor}
                      currentActivityId={activityId}
                      {...activity}
                    />
                  </List.Item>
                );
              }
            })}
          </List>

          <div className={styles.moduleActivities}></div>
        </>
      )}
    </div>
  );
};

export default ActivityNavigation;
