import React, { FC, PropsWithChildren, useState } from 'react';

import Icon from '../../../../Icon';
import { WidgetHeaderContainer, InfoWrapper, Title, Link, Divider, StoryBookLink } from './styles';
import { WidgetHeaderProps } from './types';
import { actions } from '../../../../../redux/notification.slice';
import { useDispatch } from 'react-redux';
import { Dispatch } from '@reduxjs/toolkit';
import Button from '../../../../Button';
import { useHistory } from 'react-router-dom';
import Modal from '../../../../Modal';
import FilterModal from '../../../../InsightWallFilter/components/FilterModal';
import { FilterDetails } from '../../../../InsightWallFilter/types';
import { useAppSelector } from '../../../../../utils/hooks';
const { insightNotificationsDismissAll, setVisitedInsightsPage } = actions;

const WidgetHeader: FC<WidgetHeaderProps> = ({
  title,
  iconName = 'experiment-info',
  RouterLink,
  isComponentView = false,
  isFacilitator = false,
}: PropsWithChildren<WidgetHeaderProps>): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  
  const [showInsightsWallFilterModal, setShowInsightsWallFilterModal] = useState<boolean>(false);
  const history = useHistory();
  const handleInsightWallAccess = (): void => {
    setShowInsightsWallFilterModal(true);
  };
  const { selectedCohort} = useAppSelector((state): any => state.cohorts);
  const linkToShow: (link: string | undefined) => JSX.Element = (link: string | undefined): JSX.Element => {
    if (!!RouterLink && !isComponentView) {
      return (
        <Link
          to={RouterLink}
          onClick={() =>
            RouterLink == 'insightswall'
              ? (dispatch(insightNotificationsDismissAll(null)), dispatch(setVisitedInsightsPage()))
              : null
          }>
          View
        </Link>
      );
    } else if (isComponentView) {
      return <StoryBookLink>View</StoryBookLink>;
    }

    return <></>;
  };
  const facilitatorLinkToShow: (link: string | undefined) => JSX.Element = (link: string | undefined): JSX.Element => {
    return (
        <Button
          style={{backgroundColor:'transparent',color:'blue', padding:'0', textDecoration:'underline'}}   
          onClick={(): void => handleInsightWallAccess()}
          >
          View
        </Button>
      );
        }
  return (
    <>
      <WidgetHeaderContainer>
        <InfoWrapper>
          <Icon style={{ marginRight: 10 }} name={iconName} size="small" />
          <Title>{title}</Title>
        </InfoWrapper>
        {!isFacilitator && linkToShow(RouterLink)}
        {isFacilitator && facilitatorLinkToShow(RouterLink)}
      </WidgetHeaderContainer>
      <Divider />
    
    <Modal isOpen={showInsightsWallFilterModal} onClose={(): void => setShowInsightsWallFilterModal(false)}>
      <FilterModal
        isOpen={showInsightsWallFilterModal}
        filter={(filters: FilterDetails): void => localStorage.setItem('insightFilters', JSON.stringify(filters))}
        onClose={(cId:any = '', aId:any = '', eId:any = ''): void => {
          setShowInsightsWallFilterModal(false);
          const cohortId = (cId) ? cId : selectedCohort.cohortId;
          let qParam = `cohortId=${cohortId}`;
          qParam = aId ? `${qParam}&activityId=${aId}`: qParam;
          qParam = eId ? `${qParam}&experimentId=${eId}`: qParam;
          (isFacilitator) ? history.push(`/app/insightswall?${qParam}`):history.push('/app/insightswall');          
        }}
        isFacilitator={isFacilitator}
      />
    </Modal>
    </>
  );
};
export default WidgetHeader;
