import React, { FC, PropsWithChildren } from 'react';
import { ExperimentInsightEditorProps } from './types';
import { ExperimentInisghtEditorContainer } from './styles';
import InsightCard from '../InsightCard';
import InsightEditOptions from '../InsightEditOptions';
import { FinalContent } from '../InsightEditOptions/types';
import theme from '../../utils/theme';

const mockDataSet = {
  colorSet: [
    theme.colours.primary,
    theme.colours.moduleFushia,
    theme.colours.orange,
    theme.colours.moduleAmber,
    theme.colours.moduleCyan,
    theme.colours.moduleBlue,
  ],
  iconSet: [
    'assessment',
    'goal',
    'group',
    'habit',
    'habit-create',
    'habit-list',
    'info',
    'journal-action',
    'speech-bubble',
    'reflection',
    'journey',
  ],
  imageSet: [
    'image_10.png',
    'image_9.png',
    'image_8.png',
    'image_7.png',
    'image_6.png',
    'image_5.png',
    'image_4.png',
    'image_3.png',
    'image_2.png',
    'image_1.png',
  ],
};

const ExperimentInsightEditor: FC<ExperimentInsightEditorProps> = ({
  entry,
  experimentTitle,
  userName,
  setCardStyleInfo,
  isInsightWallEditorView = false,
  insightWallEditorMetadata,
}: PropsWithChildren<ExperimentInsightEditorProps>): JSX.Element => {
  return (
    <ExperimentInisghtEditorContainer>
      <InsightCard
        title={experimentTitle}
        publisherName={userName}
        content={entry.response}
        color={entry.meta?.color || theme.colours.primary}
        iconName={entry.meta?.icon}
        imageUrl={entry.meta?.image}
        type={entry.meta?.type}
        createdAt={entry.meta?.createdAt}
        activityId={''} // not needed here
      />
      <InsightEditOptions
        colorSet={mockDataSet.colorSet}
        iconSet={mockDataSet.iconSet}
        imageSet={mockDataSet.imageSet}
        getContent={(content: FinalContent) => setCardStyleInfo(content)}
        isInsightWallEditorView={isInsightWallEditorView}
        insightWallEditorMetadata={insightWallEditorMetadata}
      />
    </ExperimentInisghtEditorContainer>
  );
};

export default ExperimentInsightEditor;
