import React, { FC } from 'react';
import { useForm } from 'react-hook-form';

import CreateHabitWrapper from '../CreateHabitWrapper';

import RepetitionRadio from '../../components/RepetitionRadio';
import Button from '../../components/Button';

import styles from './styles.module.scss';

type Props = {
  update: (habit: any) => void;
  habit: any;
  goalId: string;
  setStep: (step: number) => void;
  createHabit: any;
};

type CreateHabitTargerFormPayload = {
  practice: string;
};

const CreateHabitTarget: FC<Props> = ({ update, habit, setStep }: Props) => {
  const { handleSubmit, register } = useForm({
    defaultValues: {
      practice: habit.practice ? `${habit.practice}` : '20',
    },
  });

  const onSubmit = ({ practice }: CreateHabitTargerFormPayload) => {
    update({ practice: parseInt(practice) });
    setStep(2);
  };

  return (
    <CreateHabitWrapper title={habit.title}>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <legend>How many times do you want to practice this habit?</legend>

          <div className={styles.radioWrap}>
            <RepetitionRadio name="practice" register={register} value="10" />
            <RepetitionRadio name="practice" register={register} value="20" />
            <RepetitionRadio name="practice" register={register} value="30" />
            <RepetitionRadio name="practice" register={register} value="40" />
            <RepetitionRadio name="practice" register={register} value="50" />
            <RepetitionRadio name="practice" register={register} value="60" />
          </div>
        </fieldset>

        <div className={styles.infoCard}>
          <strong>What should i select?&nbsp;&nbsp;</strong> Some habits are easier to form than others, and some people
          may find it easier to develop new behaviors. There&apos;s no right or wrong choice, but 20 repetitions is a
          good start.
        </div>
        <div className={styles.buttonContainer}>
          <Button testId="back" type="button" variant="outline" onClick={() => setStep(0)}>
            Back
          </Button>
          <Button testId="continue" type="submit">
            Next
          </Button>
        </div>
      </form>
    </CreateHabitWrapper>
  );
};

export default CreateHabitTarget;
