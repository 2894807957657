import { connect } from 'react-redux';

import { RootState } from '../../redux';

import { actions as goalActions } from '../../redux/goals.slice';

import HabitCreate from './HabitCreate';

const { createHabit } = goalActions;

const mapState = (state: RootState, props: any) => {
  try {
    const { id } = props.match.params;
    return {
      goalId: id,
    };
  } catch (err) {}
  return {};
};

export default connect(mapState, { createHabit })(HabitCreate);
