export const getUserAgent = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return 'android';
  }
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }
  return 'unknown';
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const getBrowserNameAndVersion = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|edg|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edg)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg', 'Edge');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appCodeName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(' ');
};

export const getOSversion = (naviagtor: any, browser: string): {  osv: RegExpMatchArray | null } => ({
    osv: (browser.split(' ')[0] !== 'Firefox') ? naviagtor.appVersion.match(/\(([^)]+)\)/)[1].split(';')[1] : naviagtor.userAgent.split(';')[1].trim(),
});

export const getOS = ():{os:string} => {
  var userAgent = navigator.userAgent,
      platform = navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return {os: os};
}