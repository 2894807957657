import React, { FC, Fragment, PropsWithChildren, useEffect, useState } from 'react';
import theme from '../../../utils/theme';
import Icon, { IconName } from '../../Icon';
import { CircularProgressbar } from 'react-circular-progressbar';
import {
  JourneyProgressContainer,
  ProgressBarContainer,
  ProgressbarContent,
  SubProgressContainer,
  ProgressSection,
  ProgressText,
  ProgressFieldText,
  CircularProgress,
} from './styles';
import { JourneyProgressProps } from './types';
import { useAppSelector, useViewport } from '../../../utils/hooks';
import { getIcon, journeyComplete } from './helpers';
import { RootState } from '../../../redux';
import { JourneyState } from '../../../redux/journey.slice';
import { JourneyProgressRecord } from '../../../utils/types';
import Loader from '../../Loader';

const JourneyProgress: FC<JourneyProgressProps> = ({}: PropsWithChildren<JourneyProgressProps>): JSX.Element => {
  // const journeyId: string | undefined = useAppSelector(
  //   (state: RootState): string | undefined => state.cohorts.selectedCohort?.learningJourneyId,
  // );
  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  const journeyId: string | undefined = selectedCohort?.learningJourneyId;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayProgress, setDisplayProgress] = useState<JourneyProgressRecord>({});
  const { progress }: JourneyState = useAppSelector((state: RootState): JourneyState => state.journey);
  const fields: string[] = ['modules', 'activities', 'experiments'];
  const { width: deviceWidth } = useViewport();

  useEffect((): (() => void) | undefined => {
    if (!journeyId) return;
    //@ts-ignore
    setDisplayProgress(progress.records[journeyId]);
    setIsLoading(false);
    return (): void => {};
  }, [journeyId]);

  //@ts-ignore
  const displayJourneyComplete: number = journeyComplete(displayProgress['modules']);

  if (isLoading || !journeyId) {
    return (
      <JourneyProgressContainer
        isMobile={deviceWidth < theme.devices.mobile}
        style={{ alignItems: 'center', justifyContent: 'center', minHeight: 208 }}>
        <Loader size="large" color="purple" />
      </JourneyProgressContainer>
    );
  }

  return (
    <JourneyProgressContainer isMobile={deviceWidth < theme.devices.mobile}>
      <ProgressBarContainer>
        <CircularProgressbar styles={CircularProgress} value={displayJourneyComplete} strokeWidth={8} />
        <ProgressbarContent>
          <ProgressSection>
            <Icon
              style={{ marginBottom: theme.spacing(1) }}
              name={getIcon(fields[0]) as IconName}
              size="small"
              fill={theme.colours.darkGrey}
            />
            {/* @ts-ignore */}
            <ProgressText>{`${displayProgress[fields[0]].completed} / ${displayProgress[fields[0]].all}`}</ProgressText>
            <ProgressFieldText>
              <>
                {fields[0][0].toUpperCase() + fields[0].substring(1)} {<br />} completed
              </>
            </ProgressFieldText>
          </ProgressSection>
        </ProgressbarContent>
      </ProgressBarContainer>
      <SubProgressContainer>
        {displayProgress &&
          fields.map((field: string, index: number): JSX.Element => {
            if (index === 0) return <></>;
            return (
              <Fragment key={`${field}-${index}`}>
                <ProgressSection>
                  <Icon
                    style={{ marginBottom: theme.spacing(1) }}
                    name={getIcon(field) as IconName}
                    size="small"
                    fill={theme.colours.darkGrey}
                  />
                  {/* @ts-ignore */}
                  <ProgressText>{`${displayProgress[field].completed} / ${displayProgress[field].all}`}</ProgressText>
                  <ProgressFieldText>
                    <>
                      {field[0].toUpperCase() + field.substring(1)} {<br />} completed
                    </>
                  </ProgressFieldText>
                </ProgressSection>
              </Fragment>
            );
          })}
      </SubProgressContainer>
    </JourneyProgressContainer>
  );
};

export default JourneyProgress;
