import React, { FC } from 'react';
import dayjs from 'dayjs';
import styles from './styles.module.scss';
import Icon from '../Icon';
import IconButton from '../IconButton';

interface Props {
  comment: string;
  createdOn: string;
  rating: number;
  onClickEdit: any;
}

const ReflectionListItem: FC<Props> = ({ comment, createdOn, rating, onClickEdit }) => {
  const formattedDate = dayjs(createdOn).format('D MMM YYYY');

  return (
    <div className={styles.ReflectionListItem}>
      <IconButton className={styles.editButton} onClick={onClickEdit} aria-label="Edit reflection">
        <Icon name="edit" />
      </IconButton>
      <img className={styles.emoji} src={`/images/emojis/emoji-${rating}.png`} />

      <div className={styles.createOn}>{formattedDate}</div>

      <div className={styles.comment}>{comment}</div>
    </div>
  );
};

export default ReflectionListItem;
