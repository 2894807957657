import React, { FC, useEffect, useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.module.scss';
import Icon from '../../../Icon';
import { useAppSelector} from '../../../../utils/hooks';
import { Cohort, Organisation } from '../../../../utils/types';
import Loader from '../../../Loader';

const AccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    borderRadius: '4px 4px 0px 0px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
    }
  },
  content: {
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const FacilitatingJourneys: FC = ({ }): JSX.Element => {
  const { cohortsOfFacilitator } = useAppSelector((state) => state.cohorts);
  const { organisations } = useAppSelector((state) => state.organisations);
  const [finalList, setFinalList] = useState<Array<any>>([]);

  const setFacCohort = (cohort: Cohort) => {  
    window.location.href = `/app/journey?cohortId=${cohort.cohortId}`;
  }

  useEffect((): (() => void) | undefined => {
    let orgArr:any = []
    let orgs:string[] = []
    cohortsOfFacilitator.map((item1:Cohort) => {
      if(!orgs.includes(item1.organisationId)){
        let filteredSameorgs = cohortsOfFacilitator.filter((item2:Cohort, index2:number) => {
          return item2.organisationId === item1.organisationId;
        });
        orgs.push(item1.organisationId);
        if(filteredSameorgs.length)
          orgArr.push(filteredSameorgs)      
      }
    });
    let prgArr:any[] = []
    orgArr.map((cohortArr:Cohort[], index:number) => {
      prgArr[index]=[]
      let prgs:string[] = []
      cohortArr.map((item3:Cohort) => {
        const pname = item3.learningJourney.programmeName
        if(!prgs.includes(pname)){
          let filteredSameprgs = cohortArr.filter((item4:Cohort) => {
            return item4.learningJourney.programmeName === pname;
          });
          prgs.push(pname);
          prgArr[index].push(filteredSameprgs)
        }
      });
    });
    let finalArr:any[] = []
    prgArr.map((currentOrgItem:any[], curIndex:number) => {
      finalArr[curIndex] = []        
      currentOrgItem.map((cohortsArr:Cohort[], pindex:number) => {
        finalArr[curIndex][pindex] = []
        let lids:string[] = []
        cohortsArr.map((cohort:Cohort) => {
          if(!lids.includes(cohort.learningJourneyId)){          
            lids.push(cohort.learningJourneyId);
            let filteredSamelids = cohortsArr.filter((item4:Cohort) => {
              return item4.learningJourneyId === cohort.learningJourneyId;
            });
            finalArr[curIndex][pindex].push(filteredSamelids)
          }
        });        
      });          
    });
    setFinalList(finalArr)
    return (): void => { };
  }, []);
 
  const programmeItem: (curprogrammearr: any[]) => JSX.Element = (curprogrammearr: any): JSX.Element => {
    let programmename:string = (curprogrammearr[0].length)?curprogrammearr[0][0].learningJourney.programmeName:''
    return (
      <>
        <AccordionDetails className={styles.acoordDetails}>
        <Typography>
        <div className={styles.custProgramText}>{programmename}</div>
          {curprogrammearr.map((journeyarr: any[]): JSX.Element => {
            return journeyItem(journeyarr);
        })}
        </Typography>
        </AccordionDetails>
      </>
    );
  }

const journeyItem: (CohortArr: any[]) => JSX.Element = (CohortArr: any): JSX.Element => {
  let journey:string = (CohortArr.length)?CohortArr[0].learningJourney.title:''
  return (
    <>
    <hr className={styles.custHr} />				  
    <div className={styles.journeyContainer}>
      <div className={styles.journeyWrapper}>
      <div><Icon name="journey" size="large" /></div>
      <div className={styles.paddingL20}>
        <div className={styles.journeyText}>Learning journey: </div>
        <div className={styles.journeyName}>{journey}</div>
      </div>
      </div>
      {CohortArr.map((cohort: Cohort): JSX.Element => {
        return cohortItem(cohort)								
      })}
    </div>
    </>
  );
}
const cohortItem: (cohort: Cohort) => JSX.Element = (cohort: Cohort): JSX.Element => {
  return (
    <>
    <div className={styles.cardContainer}>
    <div className={styles.cohortcard} onClick={() => { setFacCohort(cohort); }} >
      <p className={styles.cohortText}>{cohort.title} ({cohort.userIds.length} Members)
      <span className={styles.custicon}><Icon name="chevron-right" size='small' /></span>
      </p>
    </div>
    </div>
    </>
    );
}

if(!finalList.length){
  return (
    <div className={styles.loading}>
      <Loader size="large" />
    </div>
  );
} else {
  return (
    <>    
    {finalList.map((programmeArr:any[], index:number): JSX.Element  => {
      const curOgId = programmeArr[0][0][0].organisationId;
      const foundOrganisation: any = organisations.find((organisation: Organisation): boolean =>
        organisation.id === curOgId
      );
      let organisationName = (foundOrganisation) ? foundOrganisation.name : '';
      return (
            <>
            <div className={styles.cohortDetailsContainer}>
            <Accordion defaultExpanded>
              <AccordionSummary
                className={styles.accheader}
                expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={styles.orgheader}>{organisationName}</Typography>
              </AccordionSummary>		
              {programmeArr.map((curprogrammearr: any[]): JSX.Element => {
                return programmeItem(curprogrammearr);				  
              })}
            </Accordion>
            </div>
            </>
        );
      })
    }
    </>
  );
}
}; 
export default FacilitatingJourneys;
