import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import AnimatedPage from '../../components/AnimatedPage';
import Loader from '../../components/Loader';

import GoalScreen from '../Goal';
import GoalEditName from '../GoalEditName';
import GoalReflection from '../GoalReflection';
import GoalReflections from '../GoalReflections';
import GoalOneOffTasks from '../GoalOneOffTasks';
import GoalCreate from '../GoalCreate';
import Goals from '../Goals';
import Habit from '../Habit';
import HabitCreate from '../HabitCreate';
import HabitEditName from '../HabitEditName';
import HabitEditRepetitions from '../HabitEditRepetitions';
import HabitEditSuccessPlan from '../HabitEditSuccessPlan';

import { Goal } from '../../utils/types';

import styles from './styles.module.scss';

type Props = {
  goals: Array<Goal> | null;
  getGoals: any;
};

const GoalsTab = ({ goals, getGoals }: Props) => {
  const hasGoals = !!goals;
  const [isLoading, setIsLoading] = useState<boolean>(!hasGoals);

  useEffect(() => {
    if (hasGoals) return;
    (async () => {
      await getGoals();
      setIsLoading(false);
    })();
  }, [hasGoals]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loader size="large" color="purple" />
      </div>
    );
  }

  return (
    <>
      <Switch>
        <Route path="/app/goals/habit/:id/edit/repetitions" component={AnimatedPage(HabitEditRepetitions)} />
        <Route path="/app/goals/habit/:id/edit/success" component={AnimatedPage(HabitEditSuccessPlan)} />
        <Route path="/app/goals/habit/:id/edit/name" component={AnimatedPage(HabitEditName)} />
        <Route path="/app/goals/habit/:id" component={AnimatedPage(Habit)} />
        <Route path="/app/goals/goal/:id/createhabit" component={AnimatedPage(HabitCreate)} />
        <Route path="/app/goals/goal/:id/reflections/:rid" component={AnimatedPage(GoalReflection)} />
        <Route path="/app/goals/goal/:id/reflections" component={AnimatedPage(GoalReflections)} />
        <Route path="/app/goals/goal/:id/tasks" component={AnimatedPage(GoalOneOffTasks)} />
        <Route path="/app/goals/goal/:id/edit/name" component={AnimatedPage(GoalEditName)} />
        <Route path="/app/goals/goal/:id" component={AnimatedPage(GoalScreen)} />
        <Route path="/app/goals/new" component={AnimatedPage(GoalCreate)} />
        <Route path="/app/goals" component={AnimatedPage(Goals)} />
      </Switch>
    </>
  );
};

export default GoalsTab;
