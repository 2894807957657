import styled from 'styled-components';
import { SwitchJourneyModalContainerStyleProps } from './types';

export const SwitchJourneyModalContainer = styled.div<SwitchJourneyModalContainerStyleProps>`
  display: flex;
  width: 100%;
`;

export const JourneyList = styled.ul`
  list-style-type: none;
  width: 100%;

  > * {
    margin: ${(p) => p.theme.spacing(1)} 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
