import React, { PropsWithChildren } from 'react';
import {
  CompletedButtonWrapper,
  CompletedButtonText,
  IncompletedButtonText,
} from '../../../shared/cardStartButtonStyles';
import Icon from '../../../../../../components/Icon';
import IconButton from '../../../../../../components/IconButton';
import theme from '../../../../../../utils/theme';
import Button from '../../../../../../components/Button';
import { CardStartButtonProps } from './types';

const CardStartButton: React.FC<CardStartButtonProps> = ({
  status,
  onClick,
  cardIndex,
  isLoading,
}: PropsWithChildren<CardStartButtonProps>): JSX.Element => {
  const { completed, expired, closed } = status;
  const buttonStyle: object = { paddingHorizontal: `0px`, paddingVertical: `6px`, minWidth: `160px` };
  const staticButtonProperties: object = {
    style: buttonStyle,
    testID: 'startButton',
  };
  const incompleteText = 'Assessment \n Incomplete';

  if (!completed && expired && !closed) {
    return (
      <Button
        loading={isLoading}
        disabled={isLoading}
        onClick={(e: any) => onClick('start', cardIndex)}
        {...staticButtonProperties}>
        Start
      </Button>
    );
  }

  if (!completed && closed) {
    return <IncompletedButtonText data-test-id={'incompleteText'}>{incompleteText}</IncompletedButtonText>;
  }

  if (completed) {
    return (
      <CompletedButtonWrapper data-test-id={'completedText'}>
        <CompletedButtonText>Completed</CompletedButtonText>
        <IconButton color={theme.colours.secondary}>
          <Icon name="check" size="small" fill={theme.colours.white} />
        </IconButton>
      </CompletedButtonWrapper>
    );
  }

  return (
    <Button
      loading={isLoading}
      disabled={isLoading}
      onClick={(e: any) => onClick('start', cardIndex)}
      {...staticButtonProperties}>
      Start
    </Button>
  );
};

export default CardStartButton;
