import { createContext } from 'react';

interface ModalHandlers {
  openModal: () => void;
  closeModal: () => void;
}

const defaultValue: ModalHandlers = {
  openModal: () => {},
  closeModal: () => {},
};

const ModalContext = createContext<ModalHandlers>(defaultValue);

export default ModalContext;
