import { getOrganisations, getProgrammes } from '../../components/JourneySelectModal/helpers';
import { InsightResponse } from '../../redux/experiment.slice';
import { Cohort, Programme } from '../../utils/types';

// Fetch all the insights from store or network if cohortId, ljId provided
export const getAllInsights = async (
  storeInsights: InsightResponse[] | null,
  cohortId: string | null,
  getExperimentInsights: Function,
): Promise<InsightResponse[] | null> => {
  let response: InsightResponse[] | null = [];
  // if (storeInsights && storeInsights.length && !cohortId) {
    if (storeInsights && storeInsights.length>0) {
    const insightList: InsightResponse[] = storeInsights;
    const reversedInsightList: InsightResponse[] = [...insightList].reverse();
    response = reversedInsightList && reversedInsightList.length ? reversedInsightList : null;
  } else {
    const insights = await getExperimentInsights({ cohortId: cohortId });
    const insightList: InsightResponse[] = insights.payload;
    const reversedInsightList: InsightResponse[] = insightList && [...insightList].reverse();
    response = reversedInsightList && reversedInsightList.length ? reversedInsightList : null;
  }
  return response;
};

// get default filters for insights wall filter modal
// default filtering includes only org, programme, cohort selection
export const getDefultFilters = async (accessToken: string, selectedCohort: Cohort, organizationId: string) => {
  const orgs: any = await getOrganisations({ accessToken });
  if (orgs) {
    const foundOrg: { id: string; name: string } = orgs.find((org: any): boolean => org.id === organizationId);
    if (foundOrg) {
      const programmes: any[] = await getProgrammes({ accessToken }, foundOrg.id);
      const foundProgramme: Programme = programmes.find((programme: Programme): boolean =>
        programme.journeys.includes(selectedCohort.learningJourneyId),
      );
      if (foundProgramme) {
        return {
          org: { id: foundOrg.id, title: foundOrg.name },
          programme: { id: foundProgramme._id, title: foundProgramme.name, journeys: foundProgramme.journeys },
          cohort: {
            id: selectedCohort.cohortId,
            title: selectedCohort.title,
            ljId: selectedCohort.learningJourneyId,
          },
          activity: { id: '', title: '', contentBody: [] },
          experiment: { id: '', title: '' },
        };
      }
    }
  }
  return null;
};
