import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions as goalActions } from '../../redux/goals.slice';
import GoalsTab from './GoalsTab';

const { getGoals } = goalActions;

const mapState = (state: RootState) => ({
  goals: get(state, 'goals.goals', null),
});

export default connect(mapState, { getGoals })(GoalsTab);
