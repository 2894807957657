import { Experiment, ExperimentUser, Insight } from '../types';

type BodyType = BodyInit | null | undefined;

export const getData: (method: string, url: string, body: BodyType, accessToken: string) => Promise<any> = async (
  method: string,
  url: string,
  body: BodyType,
  accessToken: string,
) => {
  let response: any;
  try {
    const data: Response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    const response: any = await data.json();
    return response;
  } catch {
    response = null;
  }

  return response;
};

export const getExperiment: (id: string, accessToken: string) => Promise<Experiment> = async (
  id: string,
  accessToken: string,
) => await getData('GET', `${process.env.REACT_APP_API_ROUTE}/experiments/experiment/${id}`, null, accessToken);

export const getExperimentsByIds: (ids: string[], accessToken: string) => Promise<Experiment[]> = async (
  ids: string[],
  accessToken: string,
) =>
  (await getData(
    'POST',
    `${process.env.REACT_APP_API_ROUTE}/experiments/experiment/ids`,
    JSON.stringify({ experimentIds: ids }),
    accessToken,
  )) || [];

export const getAllExperimentsInCohort: (cohortId: string, accessToken: string) => Promise<Experiment[]> = async (
  cohortId: string,
  accessToken: string,
) =>
  await getData(
    'POST',
    `${process.env.REACT_APP_API_ROUTE}/experiments/experiment/search`,
    JSON.stringify({ filter: cohortId }),
    accessToken,
  );

export const getExperimentUsers: (
  cohortId: number,
  experimentId: string,
  accessToken: string,
) => Promise<ExperimentUser[]> = async (cohortId: number, experimentId: string, accessToken: string) =>
  await getData(
    'GET',
    `${process.env.REACT_APP_API_ROUTE}/experiments/${experimentId}/users?cohortId=${cohortId}`,
    null,
    accessToken,
  );

export const getInsights: (ids: string[], accessToken: string) => Promise<Insight[]> = async (
  ids: string[],
  accessToken: string,
) =>
  await getData(
    'POST',
    `${process.env.REACT_APP_API_ROUTE}/journal/cohort/entries`,
    JSON.stringify({ userIds: ids }),
    accessToken,
  );
