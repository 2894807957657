import styled from 'styled-components';
import { ColorBoxStyleProps, IconBoxStyleProps, BodyContentStyleProps } from './types';

export const EditOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 536px;

  @media (max-width: ${(p) => p.theme.devices.tablet}px) {
    max-width: 446px;
  }

  @media (max-width: ${(p) => p.theme.devices.mobile}px) {
    max-width: 352px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin: ${(p) => p.theme.spacing(4)} 0 ${(p) => p.theme.spacing(2)} 0;
`;

export const HeaderOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const RadioLabel = styled.label`
  margin: auto 5px;
  font-size: ${(p) => p.theme.typography.font.size.small};

  &:not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(2)};
  }
`;

export const Separator = styled.div`
  width: 100%;
  height 0px;
  margin: ${(p) => p.theme.spacing(4)} 0 ${(p) => p.theme.spacing(4)} 0;
  border-bottom: 1px solid ${(p) => p.theme.colours.mediumGrey};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: ${(p) => p.theme.spacing(2)} 0 ${(p) => p.theme.spacing(4)} 0;
`;

export const BodyContent = styled.div<BodyContentStyleProps>`
  display: grid;
  grid-template-columns: ${(p) => (p.contentType == 'image' ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr 1fr 1fr')};
  grid-gap: ${(p) => p.theme.spacing(2)};
  grind-auto-flow: dense;

  @media (max-width: ${(p) => p.theme.devices.mobile}px) {
    grid-gap: ${(p) => (p.contentType == 'image' ? '8px' : '14px')};
  }
`;

export const ColorBox = styled.div<ColorBoxStyleProps>`
  position: relative;
  width: 76px;
  height: 76px;
  background: ${(p) => p.background};
  border-radius: 10px;
  margin: auto;

  &:hover:after ${(p) => p.selected && `, :after`} {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    border: 4px solid ${(p) => p.theme.colours.white};
    border-radius: 10px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    cursor: pointer;
  }

  @media (max-width: ${(p) => p.theme.devices.tablet}px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${(p) => p.theme.devices.mobile}px) {
    width: 46px;
    height: 46px;
  }
`;

export const IconBox = styled.div<IconBoxStyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 76px;
  border-radius: 10px;
  margin: auto;
  background: ${(p) => (p.selected ? p.theme.colours.darkGrey : p.theme.colours.lightGrey)};

  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colours.darkGrey};

    > svg {
      fill: white;
    }
  }

  @media (max-width: ${(p) => p.theme.devices.tablet}px) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${(p) => p.theme.devices.mobile}px) {
    width: 46px;
    height: 46px;
  }
`;

export const ImageBox = styled(ColorBox)`
  width: 96px;
  background: url(${(p) => p.background}) no-repeat center center;
  background-size: cover;
  border-radius: 10px;

  @media (max-width: ${(p) => p.theme.devices.tablet}px) {
    width: 76px;
    height: 56px;
  }

  @media (max-width: ${(p) => p.theme.devices.mobile}px) {
    width: 64px;
    height: 46px;
  }
`;
