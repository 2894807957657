import { connect } from 'react-redux';
import get from 'lodash.get';

import { RootState } from '../../redux';
import { actions } from '../../redux/auth.slice';

import LoginCode from './LoginCode';

const { setTokens } = actions;

const mapState = (state: RootState) => ({
  email: get(state, 'auth.email', ''),
  loginRedirect: get(state, 'auth.loginRedirect', '/app/home'),
});

export default connect(mapState, { setTokens })(LoginCode);
