import get from 'lodash.get';
import { RootState } from '../../redux';
import { Cohort, UserRecord, Module } from '../types';

export const adaptUserRecordForActivity = (
  state: RootState,
  activityId: string,
  experimentId: string | undefined,
): UserRecord | null => {
  const cohort: Cohort = get(state, 'cohorts.selectedCohort', []);
  const { cohortId, learningJourneyId: journeyId } = cohort;

  const modules: Array<Module> = get(state, 'journey.modules', []);
  const module: Module | undefined = modules.find(({ activities }) => activities.find(({ _id }) => _id === activityId));

  if (!module) return null;

  const { _id: moduleId } = module;

  let userRecord: UserRecord = {} as UserRecord;

  if (experimentId !== undefined) {
    userRecord = {
      cohortId,
      activityId,
      journeyId,
      moduleId,
      experimentId,
    };
  } else {
    userRecord = {
      cohortId,
      activityId,
      journeyId,
      moduleId,
    };
  }

  return userRecord;
};

export const isActivityParentModuleComplete = (state: RootState, activityId: string): boolean => {
  const modules: Array<Module> = get(state, 'journey.modules', []);
  const module: Module | undefined = modules.find(({ activities }) => activities.find(({ _id }) => _id === activityId));
  const completedActivies = module?.activities.filter((activity) => activity?.record?.completedDate);
  if (completedActivies?.length === module?.activities?.length) {
    return true;
  }
  return false;
};

export const isJourneyCompleted = (state: RootState): boolean => {
  const modules: Array<Module> = get(state, 'journey.modules', []);
  let hasIncompleteActivity = false;
  modules.forEach((module) => {
    if (!module.active) {
      return;
    }
    module.activities.find((activity) => {
      if (!activity?.record?.completedDate) {
        hasIncompleteActivity = true;
      }
    });
  });
  return !hasIncompleteActivity;
};
