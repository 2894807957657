import React, { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Icon from '../../../Icon';
import styles from './styles.module.scss';

const AccordionSummary = withStyles({
  root: {
    minHeight: 44,
    maxHeight: 44,
    borderRadius: '4px 4px 0px 0px',
    '&.Mui-expanded': {
      minHeight: 44,
      maxHeight: 44,
    }
  },
  content: {
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const NoCohortHomepage: FC = ({ }): JSX.Element => {
  return (
    <div>
      <div className={styles.cohortDetailsContainer}>
        <Accordion expanded={true}>
          <AccordionSummary
            className={styles.grayheader}
            id="panel2a-header"
          >
          </AccordionSummary>
          <AccordionDetails className={styles.acoordDetails}>
            <Typography>
              <div className={styles.journeyWrapper}>
                <div><Icon name="journey" size="large" /></div>
                <div className={styles.paddingLT}>
                  <div className={styles.grayjourneyText}></div>
                  <div className={styles.grayjourneyName}></div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className={styles.cohortDetailsContainer}>
        <Accordion expanded={true}>
          <AccordionSummary
            className={styles.grayheader}
            id="panel3a-header"
          >
          </AccordionSummary>
          <AccordionDetails className={styles.acoordDetails}>
            <Typography>
              <div className={styles.journeyWrapper}>
                <div><Icon name="journey" size="large" /></div>
                <div className={styles.paddingLT}>
                  <div className={styles.grayjourneyText}></div>
                  <div className={styles.grayjourneyName}></div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default NoCohortHomepage;
