import React, { FC } from 'react';
import { WidgetText, ImageConatiner, ImageWrapper, Image } from './styles';
import Widget from '../../components/HomepageWidget';

const FacilitatorInsightsWidget: FC = ({}): JSX.Element => {  
  return (
    <>    
    <Widget title="All journey Insights"  iconName="hourglass-start" RouterLink="/app/insightswall" isComponentView={false} isFacilitator={true}>
      <WidgetText>
      View and filter insights posted across all programmes, journeys or cohorts that you are facilitating.
      </WidgetText>
      <ImageConatiner>
        <ImageWrapper>
          <Image src={'/images/homepage/insights_image.png'} alt="content-library-image" />
        </ImageWrapper>
      </ImageConatiner>
    </Widget>
    </>
  );
};

export default FacilitatorInsightsWidget;
