import { ConfettiConfig } from 'react-dom-confetti';

const confettiConfig: ConfettiConfig = {
  angle: 90,
  spread: 90,
  startVelocity: 40,
  elementCount: 180,
  dragFriction: 0.12,
  duration: 3750,
  stagger: 6,
  width: '10px',
  height: '10px',
  colors: ['#00CAC5', '#5101BB', '#FF4500'],
};

export default confettiConfig;
