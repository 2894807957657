import styled from 'styled-components';

export const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-size: ${(p) => p.theme.typography.font.size.large};
  color: ${(p) => p.theme.colours.darkGrey};
`;

export const Divider = styled.hr`
  margin: ${(p) => p.theme.spacing(3)} 0 ${(p) => p.theme.spacing(3)} 0;
`;
