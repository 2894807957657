import { UserRecord } from '../types';

export const getData: (method: string, url: string, accessToken: string | null) => Promise<any> = async (
  method: string,
  url: string,
  accessToken: string | null,
) => {
  const data: Response = await fetch(url, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const response: any = await data.json();
  return response;
};

export const getUserRecordFormApi: (id: string, token: string) => Promise<UserRecord> = async (
  id: string,
  token: string,
) => await getData('GET', `${process.env.REACT_APP_API_ROUTE}/journey/user-record/by-activity/${id}`, token);

export const checkCaptureByQuestion = async (
  accessToken: string,
  question: string,
  blockType: string,
) => {
  const data: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/activity/content/byQuestion`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      question,
      blockType
    }),
  });
  const response: any = await data.json();
  return response;
};