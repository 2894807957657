import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  condition: boolean;
  to: string;
  className?: string;
}

const ConditionalLink: FC<Props> = ({ children, to, condition, className }) =>
  condition && to ? (
    <Link className={className} to={to}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

export default ConditionalLink;
