import React from 'react';
import styled from 'styled-components';
import Loader from '../../components/Loader';

export const CardsContainer = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: grid;
  grid-template-columns: ${(p) => (p.isTablet ? `1fr 1fr` : p.isMobile ? `1fr` : `1fr 1fr 1fr`)};
  grid-gap: ${(p) => p.theme.spacing(2)};
  width: 100%;
  margin: ${(p) => p.theme.spacing(2)} 0px;
`;

export const IconWrapper = styled.div`
  align-self: center;
  margin-top: ${(p) => p.theme.spacing(5)};
`;

export const EmptyText = styled.p`
  align-self: center;
  text-align: center;
  margin-top: ${(p) => p.theme.spacing(2)};
  color: ${(p) => p.theme.colours.mediumGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  line-height: 23px;
  width: 200px;
`;

export const IntroText = styled.p`
  color: ${(p) => p.theme.colours.darkGrey};
  font-size: ${(p) => p.theme.typography.font.size.regular};
  text-align: left;
`;

export const LoadingContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-top: 20px;
`;

export const LoaderWrapper = styled.div`
  display: inline-block;
  position: absolute;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 52px;
  height: 52px;
  z-index: -1;
`;

export const LoaderForIframe = () => (
  <LoaderWrapper>
    <Loader size="large" color="purple" />
  </LoaderWrapper>
);
