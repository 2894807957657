import React, { FC } from 'react';
import * as Styled from './styles';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';

export interface Props {
  value: number;
  children?: boolean;
  strokeWidth?: number;
  percentageFontSize?: 'large' | 'Xlarge';
}

const ProgressBarCircle: FC<Props> = ({ value, children = false, strokeWidth, percentageFontSize = 'large' }) => {
  return children ? (
    <CircularProgressbarWithChildren value={value} strokeWidth={strokeWidth} styles={Styled.CircularProgress}>
      <Styled.Percentage percentageFontSize={percentageFontSize}>{value}%</Styled.Percentage>
      <Styled.Label>Complete</Styled.Label>
    </CircularProgressbarWithChildren>
  ) : (
    <CircularProgressbar styles={Styled.CircularProgress} value={value} strokeWidth={strokeWidth} />
  );
};

export default ProgressBarCircle;
