import React, { FC, useEffect, useState } from 'react';
import theme from '../../../../utils/theme';
import {
  LearningJourneysContainer
} from './styles';
import styles from './styles.module.scss';
import { useAppSelector, useViewport } from '../../../../utils/hooks';
import { RootState } from '../../../../redux';
import Loader from '../../../Loader';
import Icon from '../../../Icon';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Cohort } from '../../../../utils/types';
import { JourneyState } from '../../../../redux/journey.slice';

const LearningJourneys: FC = ({ }): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { cohorts } = useAppSelector((state) => state.cohorts);
  const { organisations } = useAppSelector((state) => state.organisations);
  const [finalCohortList, setFinalCohortList] = useState<any>([]);
  const { width: deviceWidth } = useViewport();
  const { progress } = useAppSelector((state: RootState): JourneyState => state.journey);

  const AccordionSummary = withStyles({
    root: {
      minHeight: 44,
      maxHeight: 44,
      borderRadius: '4px 4px 0px 0px',
      pointerEvents: 'none',
      '&.Mui-expanded': {
        minHeight: 44,
        maxHeight: 44,
      }
    },
    content: {
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const setFacCohort = (cohort: Cohort) => {
    window.location.href = `/app/journey?cohortId=${cohort.cohortId}`;
  }

  useEffect(  () => {
    (async () => {
      let finalData=[] as Array<any>  
      for( let doc of cohorts){
        const foundOrganisation: any = organisations.filter((org: any) =>org.id === doc.organisationId);
        const currentJourneyModules: { all: number; completed: number } = progress.records[doc.learningJourneyId].modules;
        const currentJourneyCompletion: number = Math.ceil((currentJourneyModules.completed / currentJourneyModules.all) * 100) || 0;
        finalData.push({
          organisationName:foundOrganisation[0].name,
          journeyName:doc.learningJourney && doc.learningJourney.title?doc.learningJourney.title:'',
          completed:currentJourneyCompletion,
          cohortId:doc.cohortId,
        })
      }
      if(finalData && finalData.length>0){
        setFinalCohortList(finalData)
      }
      setIsLoading(false)
      })();
      return (): void => { };
  }, []);

  if (isLoading) {
    return (
      <LearningJourneysContainer
        isMobile={deviceWidth < theme.devices.mobile}
        style={{ alignItems: 'center', justifyContent: 'center', minHeight: 208 }}>
        <Loader size="large" color="purple" />
      </LearningJourneysContainer>
    );
  }
  return (
    <LearningJourneysContainer isMobile={deviceWidth < theme.devices.mobile}>
      <>
        <div className={styles.overviewcontainer}>
          <div className={styles.heading}>
            Continue with your learning
          </div>
          <p className={styles.detail}>
            Please select the learning journey you would like to continue with.
          </p>
        </div>
        {finalCohortList && finalCohortList.length>0 &&
        <div className={styles.cohortDetailsContainer}>
          <Accordion defaultExpanded>
            <AccordionSummary
              className={styles.accheader}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={styles.orgheader}>{finalCohortList[0].organisationName}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.acoordDetails}>
              <Typography>
                <div className={styles.journeyContainer}>
                {finalCohortList.map((journey: any): JSX.Element => {
                  	return (
                      <div className={styles.cardContainer}>
                      <div className={styles.cohortcard} onClick= {() => { setFacCohort(journey); }}>
                        <div className={styles.detailBox}>
                          <div>
                            <div className={styles.jouneyName}>{journey.journeyName}</div>
                            <div className={styles.completionStatus}>{journey.completed}% complete</div>
                          </div>
                          <div className={styles.custicon}>
                            <Icon name="chevron-right" size='small' />
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                })}
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>}
      </>
    </LearningJourneysContainer>
  );
};

export default LearningJourneys;
