import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { resizeImage } from '../../utils/helpers';
import Icon, { IconName } from '../Icon';
import ProgressBarDetail from '../ProgressBarDetail';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { IconButton } from '../IconButton/styles';
import theme from '../../utils/theme';
import { colourExistsInTheme } from '../../utils/theme/helpers';
import * as Styled from './styles';

type Props = {
  title: string;
  imageUrl?: string;
  id: string;
  active: boolean;
  iconName: IconName;
  iconColor: string;
  totalActivities: number;
  completedActivities: number;
  index: number;
  previewFeatures: boolean;
  testID: string;
};

const ModuleListItem: FC<Props> = ({
  title,
  imageUrl,
  iconName,
  id,
  active,
  previewFeatures,
  totalActivities,
  completedActivities,
  iconColor,
  index,
  testID,
}: Props) => {
  const completed = totalActivities === completedActivities && active;

  const isLinkActive = previewFeatures || (!previewFeatures && active);

  if (!colourExistsInTheme(iconColor, 'module')) iconColor = theme.colours.moduleFushia;

  const ariaLabel = `Module: ${title}, (${completedActivities} out of ${totalActivities} activites ${
    completed ? 'complete' : 'incomplete'
  })`;

  
  const getAriaLabel = () => {
    let label = `Module: ${title}`;

    if (!active) return (label += `(coming soon)`);

    if (completed) return (label += `(completed)`);

    if (!completed) return (label += `(${completedActivities} out of ${totalActivities} activites completed)`);
  };

  return (
    <Link
      to={`/app/module/${id}`}
      className={classnames('block', {
        [styles.disabled]: !isLinkActive,
      })}
      data-test-id={testID}
      aria-label={getAriaLabel()}>
      <div className={styles.moduleListItem} data-test-id={id}>
        {completed ? (
          <div className={styles.image} data-test-id={'completedModuleIcon'}>
            <Icon name="rosette-module" width={56} height={56} />
          </div>
        ) : (
          <IconButton as="div" color={iconColor} size="medium">
            <Icon name={iconName} color="white" type="module" />
          </IconButton>
        )}

        <div className={styles.moduleListItemContent}>
          <Styled.Title>{title}</Styled.Title>

          <p className={styles.moduleCompletedText} data-test-id={'completedText'}>
            {completed && 'completed'}
            {!completed && !active && 'coming soon'}
          </p>

          {!completed && active && (
            <ProgressBarDetail
              completedActivities={completedActivities}
              totalActivities={totalActivities}
              data-test-id={'progressBar'}
            />
          )}
        </div>

        <IconButton
          as="div"
          className={styles.navIcon}
          data-testid={previewFeatures && !active ? 'comingSoonIcon' : 'chevronright'}>
          {active && <Icon name="chevron-right" color="primary" />}
          {previewFeatures && !active && <Icon name="search" color="primary" />}
        </IconButton>
      </div>
    </Link>
  );
};

export default ModuleListItem;
