import styled from 'styled-components';
//import {} from './types';

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: ${(p) => p.theme.spacing(2)};
  margin-bottom: ${(p) => p.theme.spacing(2)};
  border-bottom: 1px solid ${(p) => p.theme.colours.mediumGrey};
  width: 100%;
`;

export const Title = styled.h1`
  font-size: ${(p) => p.theme.typography.font.size.large};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  margin-top: ${(p) => p.theme.spacing(2)};
`;

export const LeftButton = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justofy-content: flex-start;
`;

export const ButtonText = styled.span`
  color: ${(p) => p.theme.colours.primary};
  text-transform: uppercase;
  font-size: 0.75rem;
`;
