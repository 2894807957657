import React, { FC, useState } from 'react';
import { JourneyState } from '../../../redux/journey.slice';
import styles from './styles.module.scss';
import JourneysFacilitate from '../../../components/Homepage/FacilitatorHomePage/JourneysFacilitate';
import { useAppSelector } from '../../../utils/hooks';
import { RootState } from '../../../redux';
import { UserState } from '../../../redux/user.slice';
import LearningJourneys from '../../../components/Homepage/FacilitatorHomePage/LearningJourneys';

type Props = {
  journey: JourneyState;
};

const ToggleJourneys: FC<Props> = () => {
  const [isActive, setIsActive] = useState(true);
  const { roles } = useAppSelector((state: RootState): UserState => state.user);
  const isFacilitator: boolean = roles.includes('cirrus-facilitator') && roles.includes('cohort-list') && roles.includes('learning-journey-read') && roles.includes('organisation-list') && roles.includes('previewfeatures');
  const { cohorts, cohortsOfFacilitator } = useAppSelector((state) => state.cohorts);
  const isFacilitatorAssignedToCohort: boolean = cohortsOfFacilitator && cohortsOfFacilitator.length > 0
  const isLearnerAssignedToCohort: boolean = cohorts && cohorts.length > 0
  const isFacWithLearner: boolean = isFacilitator && isLearnerAssignedToCohort
  const isFacWithNocohortNotLearner: boolean = isFacilitator && !isFacilitatorAssignedToCohort
  const handleClick = () => {
    setIsActive(current => !current);
  };

  return (
    <>
      {!isFacWithLearner ?
        <JourneysFacilitate isFacWithNocohortNotLearnerN={isFacWithNocohortNotLearner} /> :
        <>
          <div className={styles.container}>
            <div className={styles.mainRow}>
              <div className={isActive ? styles.selectedBox : styles.custBox} onClick={handleClick}>Journeys you facilitate</div>
              <div className={isActive ? styles.custBox : styles.selectedBox} onClick={handleClick}>Your learning journeys</div>
            </div>
          </div>
          {isActive ?
            <div className={styles.detailsContainer}>
              <JourneysFacilitate isFacWithNocohortNotLearnerN={isFacWithNocohortNotLearner} />
            </div>
            :
            <div className={styles.detailsContainer}>
              <LearningJourneys />  
            </div>}
        </>
      }
    </>
  );
};

export default ToggleJourneys;
