import React, { FC, useState } from 'react';
import ProgressBarCircle from '../ProgressBarCircle';
import Icon from '../Icon';
import theme from '../../utils/theme';
import * as Styled from './styles';
import { colourExistsInTheme } from '../../utils/theme/helpers';
import ModuleOverlay from '../Homepage/ModuleOverlay';
import { useAppSelector } from '../../utils/hooks';
import { Module } from '../../utils/types';
import { useHistory } from 'react-router-dom';

interface Props {
  title?: string;
  icon: any;
  moduleId?: string;
  progress: number;
  color: string;
  comingSoon?: boolean;
  previewFeatures?: boolean;
  testID: string;
  index: number;
}

const ModuleProgress: FC<Props> = ({
  moduleId,
  title,
  icon,
  color: backgroundColor,
  progress = 0,
  comingSoon = false,
  previewFeatures = false,
  testID,
  index,
}) => {
  const history = useHistory();
  const [overlayOpen, setOverlayOpen] = useState<boolean>(false);
  const modules: Module[] = useAppSelector((state) => state.journey.modules);
  const module = modules.find((module: Module): boolean => module._id === moduleId);
  if (!colourExistsInTheme(backgroundColor, 'module')) backgroundColor = theme.colours.moduleFushia;
  if (comingSoon) progress = 0;
  const moduleCompleted = progress === 100;

  const onClickAction = () => {
    if (process.env.REACT_APP_NEW_HOMEPAGE === 'true') {
      setOverlayOpen(!overlayOpen);
      return;
    } else {
      history.push(`/app/module/${moduleId}`);
      return;
    }
  };

  const renderComingSoonModule = () => (
    <Styled.ComingSoon>
      <span>
        Coming <br /> Soon
      </span>

      {previewFeatures && (
        <Styled.ComingSoonIcon size="small" color={theme.colours.primary} data-testid={'comingSoonIcon'}>
          <Icon name="search" color="white" size="small" />
        </Styled.ComingSoonIcon>
      )}
    </Styled.ComingSoon>
  );

  const renderModuleIcon = () => (
    <Styled.ModuleIcon completed={moduleCompleted} color={moduleCompleted ? undefined : backgroundColor} size="large">
      <Icon name={moduleCompleted ? 'rosette-module' : icon} color="white" type="module" />
    </Styled.ModuleIcon>
  );

  const isModuleViewable = !moduleId || (comingSoon && !previewFeatures) ? false : true;

  return (
    <Styled.OverlayButton
      aria-label={`Module: ${module?.title} - ${overlayOpen ? 'open' : 'closed'}`}
      id={moduleId}
      onClick={() => onClickAction()}
      disabled={!isModuleViewable}>
      <Styled.ModuleProgress data-test-id={testID}>
        {progress !== 100 && <ProgressBarCircle value={progress} strokeWidth={4} />}
        {comingSoon ? renderComingSoonModule() : renderModuleIcon()}
      </Styled.ModuleProgress>

      {title && <Styled.Title>{title}</Styled.Title>}

      {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && module && isModuleViewable && (
        <ModuleOverlay
          isOpen={overlayOpen}
          onClose={(): void => setOverlayOpen(false)}
          index={index}
          module={module}
          moduleId={moduleId}
          linkTo={isModuleViewable ? `/app/module/${moduleId}` : null}
        />
      )}
    </Styled.OverlayButton>
  );
};

export default ModuleProgress;
