import { Journey, Programme, UserRecord, ReportingUserData } from '../../utils/types';
import logger from '../../utils/logger';
import { GetJourneyPayload } from '../../redux/journey.slice';

const apiBaseUrl = `${process.env.REACT_APP_API_ROUTE}/journey`;

export type GetJourneyResponse = Journey;

export const getJourney = async (accessToken: string, payload: GetJourneyPayload): Promise<GetJourneyResponse> => {
  const res = await fetch(
    `${process.env.REACT_APP_API_ROUTE}/journey/journey/${payload.learningJourneyId}/full/?cohortId=${payload.cohortId}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  if (res.status === 401) {
    throw new Error('Unauthorized');
  }
  const json: Journey = await res.json();
  return json;
};

export const updateActivityProgress = async (
  accessToken: string,
  userRecord: UserRecord,
  create: boolean,
  isExperimentCompleted: boolean | undefined,
): Promise<UserRecord | null> => {
  let res;
  if (create && isExperimentCompleted !== undefined) {
    logger('info', 'RECORD_CREATE_REQUEST', userRecord);
    res = await fetch(
      `${process.env.REACT_APP_API_ROUTE}/journey/user-record/${
        isExperimentCompleted ? `?completed=true` : `?completed=false`
      }`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userRecord),
      },
    );
  } else if (create) {
    logger('info', 'RECORD_CREATE_REQUEST', userRecord);
    res = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/user-record/?completed=true`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userRecord),
    });
  } else {
    logger('info', 'RECORD_DELETE_REQUEST', userRecord);
    res = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/user-record/by-activity/${userRecord.activityId}`, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return null;
  }
  if (res.status === 401) {
    if (create) {
      logger('info', 'RECORD_CREATE_FAILURE', { ...userRecord, xId: res.headers.get('x-request-id') });
    } else {
      logger('info', 'RECORD_DELETE_FAILURE', { ...userRecord, xId: res.headers.get('x-request-id') });
    }
    throw new Error('Unauthorized');
  }

  if (create) {
    logger('info', 'RECORD_CREATE_SUCCESS', { ...userRecord, xId: res.headers.get('x-request-id') });
  } else {
    logger('info', 'RECORD_DELETE_SUCCESS', { ...userRecord, xId: res.headers.get('x-request-id') });
  }
  return await res.json();
};

export const getProgrammes = async (orgId: string): Promise<Programme[]> => {
  const response = await fetch(`${apiBaseUrl}/programme/org-id/${orgId}`);
  const data = await response.json()
  return data;
};
export const getAllProgrammes = async (accessToken: string, orgIds: string[]): Promise<Programme[]> => {
  const response = await fetch(`${apiBaseUrl}/programme/allorg`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ orgIds: orgIds }),
  });
  const data = await response.json()
  return data.records;
};
export const updateReporting = async (
  accessToken: string,
  userRecord: UserRecord,
  userData: ReportingUserData,
  create: boolean,
): Promise<boolean> => {
  const type = 'user';
  const payload = {
    type,
    ...userRecord,
    ...userData,
    completed: create,
  };
  let res;

  logger('info', 'REPORTING_RECORD_CREATE_REQUEST', payload);
  res = await fetch(`${process.env.REACT_APP_API_ROUTE}/reporting/report/add`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (res.status === 400) {
    if (create) {
      logger('info', 'RECORD_CREATE_FAILURE', { payload, xId: res.headers.get('x-request-id') });
    } else {
      logger('info', 'RECORD_DELETE_FAILURE', { payload, xId: res.headers.get('x-request-id') });
    }
    throw new Error('Unauthorized');
  }

  if (create) {
    logger('info', 'RECORD_CREATE_SUCCESS', { payload, xId: res.headers.get('x-request-id') });
  } else {
    logger('info', 'RECORD_DELETE_SUCCESS', { payload, xId: res.headers.get('x-request-id') });
  }
  return true;
};

export const getJourneyUserProgress: (accessToken: string, journeyIds: string[], userId: string) => Promise<any> =
  async (accessToken: string, journeyIds: string[], userId: string): Promise<any> => {
    const res: Response = await fetch(`${process.env.REACT_APP_API_ROUTE}/journey/report/progress/journeys`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ journeyIds: journeyIds, user: userId }),
    });

    if (res.status === 401) {
      throw new Error('Unauthorized');
    }
    const json: any = await res.json();
    return json;
  };

export const deleteActivityProgress = async (accessToken: string, activityId: string): Promise<null> => {
  const res: Response = await fetch(
    `${process.env.REACT_APP_API_ROUTE}/journey/user-record/by-activity/${activityId}`,
    {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

  return null;
};
