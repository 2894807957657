import React, { FC } from 'react';
import { Onboarding } from '../../utils/types';
import styles from './styles.module.scss';

const OnboardingCarouselItem: FC<Onboarding> = ({ title, image, description }) => {
  return (
    <div className={styles.carouselItem}>
      <div className={styles.meta}>
        <h1 className={styles.title}>{title}</h1>
        <img className={styles.mobileImage} src={image} alt={title} width={210} />
        <p className={styles.description}>{description}</p>
      </div>

      <img className={styles.desktopImage} src={image} alt={title} width={210} />
    </div>
  );
};

export default OnboardingCarouselItem;
