import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import GoalCard from '../../components/GoalCard';
import Icon from '../../components/Icon';
import { FormattedGoalOverview } from '../../utils/adapters/goals';
import logger from '../../utils/logger';

import styles from './styles.module.scss';

type Props = {
  goals: Array<FormattedGoalOverview>;
  completed: boolean;
};

const GoalList: FC<Props> = ({ goals, completed }: Props) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      {goals.map((goal) => (
        <GoalCard key={goal.id} {...goal} />
      ))}
      {goals.length === 0 && (
        <div className={styles.empty}>
          <Icon name="goal" size="large" />
          <div className={styles.message}>You do not have any {completed ? 'completed' : 'active'} goals.</div>
        </div>
      )}
      {!completed && (
        <div className={styles.buttonContainer}>
          <Button
            testId="addGoal"
            onClick={() => {
              logger('info', 'ADD_A_GOAL');
              history.push('/app/goals/new');
            }}>
            Add {goals.length === 0 ? 'Your First Goal' : 'Goal'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GoalList;
