import React, { useState, useEffect, ReactElement } from 'react';

import CmsContent from '../../components/CmsContent';
import BreadCrumbs from '../../components/BreadCrumbs';

import { resizeImage } from '../../utils/helpers';
import { Media } from '../../utils/types';
import logger from '../../utils/logger';

import styles from './styles.module.scss';

type Props = {
  match: any;
  getContentById: any;
  showBreadcrumb?: boolean;
};

const ContentItem = ({ getContentById, match, showBreadcrumb }: Props): ReactElement | null => {
  const [item, setItem] = useState<Media | null>(null);
  const { id } = match.params;

  useEffect(() => {
    (async () => {
      const content = await getContentById(id);
      setItem(content.payload);
    })();
  }, [id]);

  useEffect(() => {
    logger('info', 'CONTENT_MEDIA_OPEN', { _id: id });
    return () => {
      logger('info', 'CONTENT_MEDIA_CLOSe', { _id: id });
    };
  }, []);

  if (!item) return null;

  return (
    <>
      {showBreadcrumb !== false && (
        <BreadCrumbs crumbs={[{ text: 'Content', path: '/app/content' }, { text: item.title }]} hideOnMobile={true} />
      )}
      <div className={styles.image} style={{ backgroundImage: `url(${resizeImage(item.imageUrl, 640, 300)})` }} />
      <div className={styles.tag}>{item.type}</div>
      <div className={styles.title}>{item.title}</div>
      <CmsContent content={item.content} />
    </>
  );
};

export default ContentItem;
