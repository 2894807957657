import styled, { DefaultTheme } from 'styled-components';
import { Color } from '../../utils/types/theme';

interface FillProps {
  width: number;
  background: Color;
}

export const ProgressBarNotched = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  background-color: #d8d8d8;
  ${({ theme }) => theme.colours.lightGrey};
`;

export const Notch = styled.div`
  top: 0px;
  position: absolute;
  height: 10px;
  border-left: 1px solid white;
  z-index: 10;
`;

export const Fill = styled.div<FillProps>`
  background: ${({ theme, background }) => theme.colours[background]};
  height: 10px;
  width: ${({ width }) => `${width}%`};
`;
