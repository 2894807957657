import React, { FC, PropsWithChildren } from 'react';
import { RootState } from '../../redux';
import { CohortsState } from '../../redux/cohorts.slice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import theme from '../../utils/theme';
import Icon from '../Icon';
import { ModalListItemContainer, ModalListItemLinkContainer, InfoWrapper, Title, Progress } from './styles';
import { ModalListItemProps } from './types';
import { actions } from '../../redux/cohorts.slice';

const ModalListItem: FC<ModalListItemProps> = ({
  type = 'modal',
  cohortId,
  journeyId,
  title,
  iconName,
  onClick,
  route,
  progress = 0,
  cohortTitle = ''
}: PropsWithChildren<ModalListItemProps>): JSX.Element => {
  const dispatch = useAppDispatch();
  const { selectedCohort } = useAppSelector((state: RootState): CohortsState => state.cohorts);
  const { setSelectedCohort, setSelectedFacCohort } = actions;

  const isCurrentJourney: boolean = journeyId === selectedCohort?.learningJourneyId;

  if (type === 'menu') {
    if (route) {
      const isCurrentTab: boolean = window.location.pathname.includes(route);
      return (
        <ModalListItemLinkContainer to={route} isCurrent={isCurrentTab} onClick={(): void => onClick && onClick()}>
          <Icon
            style={{ flex: '0.2', alignSelf: 'start' }}
            name={iconName || 'default'}
            fill={isCurrentTab ? theme.colours.primary : theme.colours.darkGrey}
            size="small"
          />
          <Title isSelected={isCurrentTab} type="menu">
            {title}
          </Title>
          {!isCurrentTab && <Icon name="chevron-right" size="small" fill={theme.colours.primary} />}
        </ModalListItemLinkContainer>
      );
    } else {
      return (
        <ModalListItemContainer isCurrent={false} onClick={(): void => onClick && onClick()}>
          <Icon
            style={{ flex: '0.2', alignSelf: 'start' }}
            name={iconName || 'default'}
            fill={theme.colours.darkGrey}
            size="small"
          />
          <Title isSelected={false} type="menu">
            {title}
          </Title>
        </ModalListItemContainer>
      );
    }
  }

  if (type === 'menuButton') {
    return (
      <ModalListItemContainer isCurrent={false} onClick={(): void => onClick && onClick()}>
        <Icon
          style={{ flex: '0.2', alignSelf: 'start' }}
          name={iconName || 'default'}
          fill={theme.colours.darkGrey}
          size="small"
        />
        <Title isSelected={false} type="menu">
          {title}
        </Title>
      </ModalListItemContainer>
    );
  }

  return (
    <ModalListItemContainer isCurrent={isCurrentJourney} onClick={() =>{
      if(cohortTitle !== '')
        dispatch(setSelectedFacCohort(cohortId))
      else
        dispatch(setSelectedCohort(cohortId))
      } }>
      <>
        <InfoWrapper>
          <Title style={{fontWeight: 700}}>{title}</Title>          
            {cohortTitle !== '' ?
            <Progress>{cohortTitle}</Progress>
            :
            <Progress>{progress}% complete</Progress>
            }          
        </InfoWrapper>
        {!isCurrentJourney && <Icon name="chevron-right" size="small" fill={theme.colours.primary} />}
      </>
    </ModalListItemContainer>
  );
};
export default ModalListItem;
