import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import Button from '../Button';
import { InsightWallFilterContainer, SelectionBackground, SelectionDisplay } from './InsightWallFilter.styles';
import { FilterDetails, InsightWallFilterProps } from './types';
import theme from '../../utils/theme';
import { renderPersonalFilterOption } from './components/UIElements/components';
import Modal from '../Modal';
import FilterModal from './components/FilterModal';

const InsightWallFilter: FC<InsightWallFilterProps> = ({
  isFacilitator,
  numberOfInsights,
  filter,
  filterDetails,
  personalFilter,
  learningJourneyFilter,
  cohortIdFilter,
}: PropsWithChildren<InsightWallFilterProps>): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [personalInsightsChecked, setPersonalInsightsChecked] = useState<boolean>(false);
  const [lastFilteredItem, setLastFilteredItem] = useState<string | null>(null);

  useEffect((): (() => void) => {
    if (personalInsightsChecked) {
      personalFilter(true);
    } else {
      personalFilter(false);
    }
    return (): void => {};
  }, [personalInsightsChecked]);

  useEffect((): (() => void) => {
    const filter: FilterDetails = JSON.parse(localStorage.getItem('insightFilters')!) || {};
    setLastFilteredItemFormFilters(filter);
    return (): void => {};
  }, []);

  const handleFilter = ({ cohort, activity, experiment }: FilterDetails): void => {
    const setFilters = {
      cohort: { id: cohort.id, title: cohort.title, ljId: cohort.ljId},
      activity: { id: activity.id, title: activity.title, contentBody: [] },
      experiment: { id: experiment.id, title: experiment.title },
    };

    filter(setFilters);
    learningJourneyFilter(cohort.ljId);
    cohortIdFilter(cohort.id);

    setLastFilteredItemFormFilters({ cohort, activity, experiment } as FilterDetails);
  };

  const setLastFilteredItemFormFilters = (filters: FilterDetails): void => {
    if (filters?.experiment?.title) {
      setLastFilteredItem(filters.experiment.title);
      return;
    }

    if (filters?.activity?.title) {
      setLastFilteredItem(filters.activity.title);
      return;
    }

    if (filters?.cohort?.title) {
      setLastFilteredItem(filters.cohort.title);
      return;
    }
  };

  return (
    <>
      <InsightWallFilterContainer>
        <SelectionBackground>
          <SelectionDisplay>
            {filterDetails?.activity.id || filterDetails?.experiment.id ? (
              <span style={{ fontSize: theme.typography.font.size.small }}>
                <strong>{numberOfInsights} Insights</strong>{' '}
                {isFacilitator && lastFilteredItem && `: ${lastFilteredItem}`}
              </span>
            ) : (
              <span style={{ fontSize: theme.typography.font.size.small }}>
                {isFacilitator && <strong>{numberOfInsights} Insights</strong>}
                {!isFacilitator && <strong>Showing all {numberOfInsights} Insights</strong>}
                {isFacilitator && lastFilteredItem && `: ${lastFilteredItem}`}
              </span>
            )}
          </SelectionDisplay>
          <Button onClick={(): void => setIsModalOpen(true)} style={{ marginRight: '32px', marginLeft: '32px' }}>
            Filter
          </Button>
        </SelectionBackground>
        {!isFacilitator && renderPersonalFilterOption(personalInsightsChecked, setPersonalInsightsChecked)}
      </InsightWallFilterContainer>

      <Modal isOpen={isModalOpen} onClose={(): void => setIsModalOpen(false)}>
        <FilterModal
          isOpen={isModalOpen}
          isFacilitator={isFacilitator}
          filter={handleFilter}
          onClose={(): void => setIsModalOpen(false)}
        />
      </Modal>
    </>
  );
};
export default InsightWallFilter;
