import React, { FC, Fragment } from 'react';
import { ModuleDisplayData } from '../../../../screens/LearningJourney/adapters';
import theme from '../../../../utils/theme';
import Divider from '../../../Divider';
import ModuleProgress from '../../../ModuleProgress';
import styles from './styles.module.scss';
interface Props {
  modules: ModuleDisplayData[];
  previewFeatures: boolean;
}

const FlexibleJourney: FC<Props> = ({ modules, previewFeatures }) => {
  return (
    // journey wrapper ID needed for module overlay
    <div id="flexible_journey_wrapper" className={styles.flexibleModules} style={{ padding: `0 ${theme.spacing(3)}` }}>
      {modules.map((module, index: number) => {
        return (
          <Fragment key={module.id}>
            {module.isNextRow && <Divider id={'divider'} text={module.rowTitle} hidden={!module.rowTitle} />}

            <div id="moduleWrapper" className={styles.moduleProgressWrapper}>
              <ModuleProgress
                icon={module.iconName}
                moduleId={module.id}
                title={module.title}
                color={module.iconColor}
                progress={(module.completedActivities / module.totalActivities) * 100}
                previewFeatures={previewFeatures}
                comingSoon={!module.active}
                testID={'module-progress'}
                index={index}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default FlexibleJourney;
