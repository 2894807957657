import { connect } from 'react-redux';
import get from 'lodash.get';
import ExperimentInsightModal from './ExperimentInsightModal';
import { RootState } from '../../redux';
import { actions as journeyActions } from '../../redux/journey.slice';
import { actions as modalActions } from '../../redux/modals.slice';
import { createUserJournalEntry, updateUserJournalEntry } from '../../redux/userJournal.slice';
import { actions as experimentActions } from '../../redux/experiment.slice';

const mapState = (state: RootState) => {
  try {
    return {
      auth: get(state, 'auth'),
      createUserJournalEntry,
      updateUserJournalEntry,
    };
  } catch (err) {}
  return {};
};

const { updateActivityProgress } = journeyActions;
const { setModalState } = modalActions;
const { getExperimentInsights } = experimentActions;

export default connect(mapState, { updateActivityProgress, getExperimentInsights, setModalState })(
  ExperimentInsightModal,
);
