import React, { FC, PropsWithChildren, useState, useEffect } from 'react';
import { OtherParticipantsModalProps } from './types';
import { ParticipantsModalContainer } from './styles';
import ExperimentModalHeader from '../ExperimentModalHeader';
import { ExperimentUser } from '../../utils/types';
import ParticipantInfoTile from '../ParticipantInfoTile';
import Button from '../Button';
import { useAppSelector } from '../../utils/hooks';

const OtherParticipantsModal: FC<OtherParticipantsModalProps> = ({
  currentUserId,
  activityId,
  experimentId,
  onClose,
}: PropsWithChildren<OtherParticipantsModalProps>): JSX.Element => {
  const [experimentUsers, setExperimentUsers] = useState<ExperimentUser[]>([]);
  const { cohortUserRecords } = useAppSelector((state) => state.cohorts);

  useEffect(() => {
    if (!cohortUserRecords) return;
    const experimnetUsers = cohortUserRecords.filter((userRecord: any) => userRecord.experimentId === experimentId);
    const activityUsers = experimnetUsers.filter((userRecord: any) => userRecord.activityId === activityId);
    const excludedCurrentUser = activityUsers.filter((userRecord: any) => userRecord.userId !== currentUserId);
    const adaptedList = excludedCurrentUser.map((user: any) => ({
      _id: user._id,
      name: user.userName,
      endDate: user.completedDate,
      startDate: user.startDate,
    }));

    setExperimentUsers(adaptedList as ExperimentUser[]);

    return () => {};
  }, []);

  return (
    <ParticipantsModalContainer>
      <ExperimentModalHeader iconName="user" title="Other Participants" />
      {experimentUsers.map((user: ExperimentUser, index: number) => (
        <ParticipantInfoTile
          key={user._id || index}
          name={user.name}
          startDate={user.startDate}
          endDate={user.endDate}
        />
      ))}

      <Button
        style={{ width: '160px', alignSelf: 'center' }}
        variant="outline"
        color="primary"
        onClick={() => onClose()}>
        Close
      </Button>
    </ParticipantsModalContainer>
  );
};

export default OtherParticipantsModal;
