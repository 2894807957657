import { connect } from 'react-redux';
import get from 'lodash.get';
import Assessments from './Assessments';
import { RootState } from '../../redux';
import { actions as assessmentActions } from '../../redux/assessments.slice';

const mapState = (state: RootState) => {
  return {};
};

// const { getAssessments, getRequests } = assessmentActions;
// const { getRequests } = assessmentActions;

// export default connect(mapState, { getAssessments, getRequests })(Assessments);
export default connect(mapState, {  })(Assessments);
