import React, { FC, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import OnboardingCarouselItem from '../../components/OnboardingCarouselItem/OnboardingCarouselItem';
import { Onboarding } from '../../utils/types';
import Button from '../../components/Button';

import styles from './styles.module.scss';
import OnboardingCarouselArrow from '../../components/OnboardingCarouselArrow/OnboardingCarouselArrow';

interface Props {
  items: Onboarding[];
  closeOnboardingModal: Function;
}

const OnboardingCarousel: FC<Props> = ({ items, closeOnboardingModal }) => {
  const [nextArrow, setNextArrow] = useState(<OnboardingCarouselArrow next />);
  const [prevArrow, setPrevArrow] = useState(<OnboardingCarouselArrow />);

  const settings: Settings = {
    className: 'onboardingCarousel',
    infinite: false,
    dots: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    dotsClass: styles.button__bar,
    nextArrow,
    prevArrow,

    afterChange: (currentSlide) => {
      const totalSlides = items.length - 1;

      if (currentSlide === totalSlides)
        setNextArrow(<OnboardingCarouselArrow next done closeModal={closeOnboardingModal} />);

      if (currentSlide < totalSlides) setNextArrow(<OnboardingCarouselArrow next />);
    },
  };

  if (items.length === 1)
    return (
      <>
        <OnboardingCarouselItem {...items[0]} />
        <div className={styles.doneButtonWrapper}>
          <Button onClick={() => closeOnboardingModal()}>Done</Button>
        </div>
      </>
    );

  return (
    <Slider {...settings}>
      {items.map((item, i) => (
        <OnboardingCarouselItem {...item} key={i} />
      ))}
    </Slider>
  );
};

export default OnboardingCarousel;
