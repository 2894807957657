import React, { PropsWithChildren } from 'react';
import { CardDescriptionAlert as DescriptionAlert, DescriptionText } from './styles';
import { CardDescription as Description } from '../../../shared/cardStyles';
import { CardDescriptionProps } from './types';
import {
  completedAlert,
  completedDescription,
  expirationAlert,
  expirationDescription,
  closeAlert,
  closeDescription,
  assessmentCardDescrition,
} from './messages';

const CardDescription: React.FC<CardDescriptionProps> = ({
  status,
  onClick,
  cardIndex,
}: PropsWithChildren<CardDescriptionProps>): JSX.Element => {
  const { completed, closed, expired, report } = status;

  if (completed && report && closed) {
    return (
      <>
        <Description data-test-id={'descriptionText'}>
          <DescriptionAlert data-test-id={'alertText'}>{closeAlert}</DescriptionAlert>
          <DescriptionText>{closeDescription}</DescriptionText>
        </Description>
      </>
    );
  }

  if (!completed && closed) {
    return (
      <>
        <Description data-test-id={'descriptionText'}>
          <DescriptionAlert data-test-id={'alertText'}>{closeAlert}</DescriptionAlert>
          <DescriptionText>{closeDescription}</DescriptionText>
        </Description>
      </>
    );
  }

  if (completed && closed) {
    return (
      <>
        <Description data-test-id={'descriptionText'}>
          <DescriptionAlert data-test-id={'alertText'}>{closeAlert}</DescriptionAlert>
          <DescriptionText>{closeDescription}</DescriptionText>
        </Description>
      </>
    );
  }

  if (!completed && expired && !closed) {
    return (
      <>
        <Description data-test-id={'descriptionText'}>
          <DescriptionAlert isAlert data-test-id={'alertText'}>
            {expirationAlert}
          </DescriptionAlert>
          {expirationDescription}
        </Description>
      </>
    );
  }

  if (completed) {
    return (
      <>
        <Description data-test-id={'descriptionText'}>
          <DescriptionAlert data-test-id={'alertText'}>{completedAlert}</DescriptionAlert>
          {completedDescription}
        </Description>
      </>
    );
  }

  return <Description data-test-id={'descriptionText'}>{assessmentCardDescrition}</Description>;
};

export default CardDescription;
