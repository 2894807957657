import React, { FC } from 'react';
import { ActivityContentBlock } from '../../utils/types';
import JournalBlock from '../JournalBlock';

interface Props {
  block: ActivityContentBlock;
  isExperiment?: boolean;
  activityId?: string;
  isInsightWallEditorView?: boolean;
}

const ActivityJournals: FC<Props> = ({ block, isExperiment = false, activityId, isInsightWallEditorView = false }) => {
  return (
    <JournalBlock
      blockId={block.text}
      blockType={block.blockType}
      questionId={block.url}
      isExperiment={isExperiment}
      activityId={activityId}
      isInsightWallEditorView={isInsightWallEditorView}
      question={block.question}
      journalId={block._id}
    />
  );
};

export default ActivityJournals;
