import styled from 'styled-components';
import {
  HeaderWapperStyleProps,
  TypeStyleProps,
  ImageBackgroundStyleProps,
  SvgBackgroundStyleProps,
  OptionsDropdownProps,
} from './types';

export const CardContainer = styled.div<{ isMobile: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${(p) => p.theme.colours.white};
  box-shadow: ${(p) => p.theme.shadows[0]};
  width: 100%;
  max-width: ${(p) => (p.isMobile ? '100%' : '288px')};
`;

export const HeaderWrapper = styled.div<HeaderWapperStyleProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => p.color};
  padding: ${(p) => p.theme.spacing(3)};
  padding-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
  min-height: 110px;
`;

export const Title = styled.h2`
  font-size: ${(p) => p.theme.typography.font.size.small};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.white};
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 10;
`;

export const ImageBackground = styled.div<ImageBackgroundStyleProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url('${(p) => p.imageUrl}') no-repeat center center;
  background-size: 100% 110px;
  border-radius: 10px 10px 0px 0px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    font-size: 66px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0%) 33%, rgba(0, 0, 0, 90%));
    border-radius: 10px 10px 0px 0px;
  }
`;

export const SvgBackground = styled.div<SvgBackgroundStyleProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -1px;
  background: url('${(p) => p.imageUrl}') no-repeat;
  background-position-x: 0%;
  background-position-y: 101%;
  background-size: contain;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-ites: start;
  padding: ${(p) => p.theme.spacing(3)};
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 0px 0px 10px 10px;
`;

export const Type = styled.span<TypeStyleProps>`
  font-transform: uppercase;
  color: ${(p) => p.color};
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
`;

export const PublishDate = styled.span`
  font-size: ${(p) => p.theme.typography.font.size.xSmall};
  color: ${(p) => p.theme.colours.darkGrey};
  line-height: 12px;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

export const Publisher = styled.h3`
  font-size: ${(p) => p.theme.typography.font.size.large};
  font-weight: ${(p) => p.theme.typography.font.weight.bold};
  color: ${(p) => p.theme.colours.darkGrey};
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

export const Content = styled.div`
  > h1,
  h2,
  h3,
  h4 {
    margin-top: 8px;
    margin-bottom: 12px;
  }

  > p {
    font-size: ${(p) => p.theme.typography.font.size.small};
    line-height: 16px;
    margin-bottom: 15px;
  }

  > ul,
  ol {
    margin-left: 25px;
    margin-bottom: 15px;
    padding-left: ${(p) => p.theme.spacing(2)};

    > li {
      font-size: ${(p) => p.theme.typography.font.size.small};
      line-height: 16px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.spacing(3)};
  padding-top: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > div {
    > span {
      font-size: ${(p) => p.theme.typography.font.size.xSmall};
      color: ${(p) => p.theme.colours.primary};
    }
  }
`;

export const OptionsDropdown = styled.ul<OptionsDropdownProps>`
  list-style-type: none;
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 120px;
  padding: ${(p) => p.theme.spacing(1)};
  background: ${(p) => p.theme.colours.white};
  border-radius: ${(p) => p.theme.borders.radius};
  box-shadow: ${(p) => p.theme.shadows};
  z-index: 20;
`;

export const Option = styled.li`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(1)};
  padding: ${(p) => p.theme.spacing(0.5)};
  border-radius: 6px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const OptionButton = styled.button`
  border: none;
  outline: none;
  background-color: ${(p) => p.theme.colours.white};
`;
