import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from '.';

import { refreshToken as refreshTokenFromApi } from '../utils/api/auth';

export type AuthState = {
  accessToken: string;
  refreshToken: string;
  email: string;
  loginRedirect: string;
};

export type SetTokensPayload = {
  accessToken: string;
  refreshToken: string;
};

export type SetUserEmailPayload = {
  email: string;
};

export type SetLoginRedirectPayload = {
  pathname: string;
};

const refreshToken = createAsyncThunk<
  string,
  null,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>('auth/refreshToken', async (_, thunkAPI): Promise<string> => {
  const state: RootState = thunkAPI.getState();
  const { refreshToken } = state.auth;
  const accessToken = await refreshTokenFromApi(refreshToken);
  return accessToken;
});

const auth = createSlice({
  name: 'auth',
  initialState: {
    accessToken: localStorage.getItem('access_token') || '',
    refreshToken: localStorage.getItem('refresh_token') || '',
    email: '',
    loginRedirect: '/app/home',
  },
  reducers: {
    setTokens: (state: AuthState, action: PayloadAction<SetTokensPayload>) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      localStorage.setItem('access_token', action.payload.accessToken);
      localStorage.setItem('refresh_token', action.payload.refreshToken);
    },
    setEmail: (state: AuthState, action: PayloadAction<SetUserEmailPayload>) => {
      state.email = action.payload.email;
      localStorage.setItem('email', action.payload.email);
    },
    setLoginRedirect: (state: AuthState, action: PayloadAction<SetLoginRedirectPayload>) => {
      state.loginRedirect = action.payload.pathname;
    },
    logout: (state: AuthState) => {
      state.accessToken = '';
      state.refreshToken = '';
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('email');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshToken.fulfilled, (state, { payload }) => {
      state.accessToken = payload;
      localStorage.setItem('access_token', payload);
    });
  },
});

export default auth.reducer;

export const actions = {
  ...auth.actions,
  refreshToken,
};
