import React, { FC, useState, useEffect } from 'react';
import { Switch, Route, useLocation, Link } from 'react-router-dom';
import { WidgetContainer, HomepageHeaderContainer, GrayWrapper } from './styles';

import AnimatedPage from '../../components/AnimatedPage';
import Loader from '../../components/Loader';
import ActivityNavigation from '../../components/ActivityNavigation';

import NavigationHeader from '../../containers/NavigationHeader';

import Activity from '../Activity';
import ContentTab from '../ContentTab';
import GoalsTab from '../GoalsTab';
import LearningJourney from '../LearningJourney';
import Module from '../Module';
import Assessments from '../Assessments';
import styles from './styles.module.scss';

import { useAppDispatch, useAppSelector, useViewport } from '../../utils/hooks';
import { getUserJournal } from '../../redux/userJournal.slice';
import AddJournal from '../AddJournal';
import { getProgrammes } from '../../redux/programmes.slice';
import { ActivityContentBlock, Module as ModuleType, Activity as ActivityType, Cohort, Organisation } from '../../utils/types';

import InsightsWidget from '../../components/Homepage/HomepageWidgets/InsightsWidget';
import ContentLibraryWidget from '../../components/Homepage/HomepageWidgets/ContentLibraryWidget';
import MembersWidget from '../../components/Homepage/HomepageWidgets/MembersWidget';
import HomepageHeader from '../../components/Homepage/HomepageHeader';

import SwitchJourneyModal from '../../components/Homepage/SwitchJourneyModal';
import theme from '../../utils/theme';
// import AssessmentNotificationHeader from '../../components/Homepage/AssessmentNotificationHeader';
import ExperimentNotificationHeader from '../../components/Homepage/ExperimentNotificationHeader';
import { RootState } from '../../redux';
// import { AssessmentNotification, NotificationState } from '../../redux/notification.slice';
import { JourneyState } from '../../redux/journey.slice';
import { ModalKey } from '../../utils/modals';

import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { actions } from '../../redux/app.slice';
import { getDefultFilters } from '../InsightsWall/helpers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InsightsWall from '../InsightsWall';

import ToggleJourneys from '../Facilitator/ToggleJourneys';
import FacilitatorInsightsWidget from '../../components/Homepage/HomepageWidgets/FacilitatorInsightsWidget';

import Icon from '../../components/Icon';
import SwitchFacJourneyModal from '../../components/Homepage/FacilitatorHomePage/SwitchFacJourneyModal';


const accId = `${process.env.REACT_APP_ACCENTURE_ORG_ID}`;

type Props = {
  refreshToken: any;
  getUser: any;
  getCohorts: any;
  getCohortMeta: any;
  getCohortUserRecords: any;
  getJourney: any;
  getAllContents: any;
  getJourneyProgress: any;
  getExperiments: any;
  getExperimentInsights: any;
  // getAssessments: any;
  getRequests: any;
  // getAssessmentNotifications: any;
  getInsightNotifications: any;
  setModalState: any;
  restoreJourneyPreviewFromStorage: any;
  getCohortsOfFacilitator: any;
  getAllProgrammes: any;
  getOrganisations: any;
  getUserRecords: any;
  setSelectedFacCohort: any;
};

const App: FC<Props> = ({
  getUser,
  getCohorts,
  getCohortMeta,
  getCohortUserRecords,
  getJourney,
  getAllContents,
  getJourneyProgress,
  getExperiments,
  getExperimentInsights,
  // getAssessments,
  getRequests,
  // getAssessmentNotifications,
  getInsightNotifications,
  setModalState,
  restoreJourneyPreviewFromStorage,
  getCohortsOfFacilitator,
  getAllProgrammes,
  getOrganisations,
  getUserRecords,
  setSelectedFacCohort,
}: Props): JSX.Element => {
  const { tracking } = actions;
  const appInsights = useAppInsightsContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const journey = useAppSelector((state) => state.journey);
  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  const { accessToken } = useAppSelector((state) => state.auth);
  const { organizationId, roles, id } = useAppSelector((state) => state.user);
  const { isNativeWebView } = useAppSelector<any>((state) => state.app);
  const dispatch = useAppDispatch();
  const { width: deviceWidth } = useViewport();
  // const { assessments: assessmentNotifications, insights: insightNotifications } = useAppSelector(
  //   (state: RootState): NotificationState => state.notifications,
  // );
  const { isPreview } = useAppSelector((state: RootState): JourneyState => state.journey);
  const mobileView: boolean = deviceWidth < theme.devices.tablet;

  const userRoles = roles;
  const isFacilitator: boolean = userRoles.includes('cirrus-facilitator') && userRoles.includes('cohort-list') && userRoles.includes('learning-journey-read') && userRoles.includes('organisation-list') && userRoles.includes('previewfeatures');
   const [isFacilitatorAssignedToCohort, setIsFacilitatorAssignedToCohort] = useState<boolean>(false);
  const [curOrgName, setCurOrgName] = useState<string>('');
  const { organisations } = useAppSelector((state) => state.organisations); 
  const contentWrapperPadding = (deviceWidth: number, tabletWidth: number, isPreview: boolean) => {
    const isHomepageView = process.env.REACT_APP_NEW_HOMEPAGE === 'true';
    const isDesktopView = deviceWidth < tabletWidth;

    if (isHomepageView && isNativeWebView && !isPreview) {
      return '40px';
    }

    if (!isHomepageView && isDesktopView) {
      if (isPreview) {
        return theme.spacing(3);
      } else {
        return '124px';
      }
    }

    return '24px';
  };

  const location = useLocation();

  useEffect(() => {
    sessionStorage.removeItem('activityMarkedAsComplete');
  }, [location]);
  
  // get initial data of user and cohorts
  useEffect(() => {
    (async () => {
      const user = await getUser();

      if (user.payload === undefined) {
        window.location.pathname = '/unauthorized';
        return;
      } else {
        dispatch(
          tracking({
            appInsights,
            name: 'debug-user',
            payload: user ? { type: 'getUser', data: user } : { type: 'no getUser' },
          }),
        );

        const userRoles = user.payload.roles;
        const isFac: boolean = userRoles.includes('cirrus-facilitator') && userRoles.includes('cohort-list') && userRoles.includes('learning-journey-read') && userRoles.includes('organisation-list') && userRoles.includes('previewfeatures');        
        if (location.pathname == '/app/home' && !isFac) {
          window.location.pathname = '/app/journey';
          return;
        }
        
        const cohorts = await getCohorts();
        dispatch(
          tracking({
            appInsights,
            name: 'debug-user',
            payload: cohorts ? { type: 'getCohorts', data: cohorts?.payload } : { type: 'no getCohorts' },
          }),
        );
        
        let journeyIdsFac: string[] = [];
        let cohortsAsFacilitatorList:Cohort[]= [];
        if (isFac) {
          const cohortsAsFacilitator = await getCohortsOfFacilitator();
          const isFacAssignedToCohort: boolean = cohortsAsFacilitator?.payload && cohortsAsFacilitator?.payload.length>0
          cohortsAsFacilitatorList = cohortsAsFacilitator?.payload;
          setIsFacilitatorAssignedToCohort(isFacAssignedToCohort);
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: cohortsAsFacilitator ? { type: 'getCohortsOfFacilitator', data: cohortsAsFacilitator?.payload } : { type: 'no getCohortsOfFacilitator' },
            }),
          );
          const organizationId = user.payload.organizationId;
          let orgIds:string[] = []          
          
          if(organizationId !== accId) {
            orgIds = [organizationId]
          } else if(cohortsAsFacilitator?.payload !== undefined && cohortsAsFacilitator?.payload.length){
            const cohortsOfFac = cohortsAsFacilitator.payload;
            const returnValue = cohortsOfFac.map((element:Cohort) => {
              return element.organisationId;
            }).reverse();
            orgIds = returnValue.filter((item:Cohort, index:number) => returnValue.indexOf(item) === index)          
            if(!orgIds.includes(accId)){
              orgIds.push(accId);
            }              
          } else {
            orgIds.push(accId)
          }
          try {            
            dispatch(getAllProgrammes(orgIds));
          } catch (e) {
            dispatch(
              tracking({
                appInsights,
                name: 'debug-user',
                payload: { type: 'failed getAllProgrammes' },
              }),
            );
          }
          const organisations = await getOrganisations();
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: organisations ? { type: 'getOrganisations', data: organisations?.payload } : { type: 'no getOrganisations' },
            }),
          );
          try {
            dispatch(getUserRecords());
          } catch (e) {
            dispatch(
              tracking({
                appInsights,
                name: 'debug-user',
                payload: { type: 'failed getUserRecords' },
              }),
            );
          }
          if(cohortsAsFacilitator?.payload !== undefined && cohortsAsFacilitator?.payload.length){
            journeyIdsFac = cohortsAsFacilitator.payload.map((cohort: Cohort): string => cohort.learningJourneyId);
          }
        }
        
        if (cohorts?.payload?.length == 0 && !isFac) {
          window.location.pathname = '/learning-journey-error';
          return;
        } else {
          const queryParameters = new URLSearchParams(window.location.search);
          const cohortId = queryParameters.get('cohortId');
          const moduleId = queryParameters.get('moduleId');
          if(cohortId && cohortId !== null && isFac){
              dispatch(setSelectedFacCohort(cohortId));
          } else {
            if (moduleId == null && cohorts?.payload?.length) {  
              dispatch(setSelectedFacCohort(cohorts.payload[0].cohortId));
            } else {
              if((cohorts?.payload?.length || cohortsAsFacilitatorList.length) && moduleId !== null){
                  if(cohorts?.payload?.length){
                    cohorts.payload.map((cohort: any) => {
                      cohort.learningJourney.modules.map(async (module: any) => {
                        if (moduleId == module) {
                          dispatch(setSelectedFacCohort(cohort.cohortId));                       
                        }
                      });       
                    });
                  }
                  if(cohortsAsFacilitatorList.length){
                    cohortsAsFacilitatorList.map((cohort: any) => {
                      cohort.learningJourney.modules.map((module: any) => {
                        if (moduleId == module) {
                          dispatch(setSelectedFacCohort(cohort.cohortId));
                        }
                      });
                    });
                  }                 
              } else {
                setIsLoading(false);
                return;
              }
            }
          }
        }
        dispatch(getUserJournal(user.payload.id));
        let journeyIdsLer: string[] = []        
        if(cohorts?.payload !== undefined && cohorts?.payload?.length){
          journeyIdsLer = cohorts.payload.map((cohort: Cohort): string => cohort.learningJourneyId);
        }
        let journeyIds = journeyIdsFac.concat(journeyIdsLer);
        
        if(journeyIds.length){
          const progress = await getJourneyProgress(journeyIds);
          
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: progress ? { type: 'getJourneyProgress', data: progress?.payload } : { type: 'no getJourneyProgress' },
            }),
          );
        }
      }
    })();  
  }, []);

  // get cohort related informations when cohort changes
  // this will support the swap between cohorts functionality
  useEffect(() => {
    if (selectedCohort.cohortId !== undefined && selectedCohort.learningJourneyId !== undefined) {   
      const foundOrganisation: any = organisations.find((organisation: Organisation): boolean =>
          organisation.id === selectedCohort.organisationId
      );
      let organisationName = (foundOrganisation) ? foundOrganisation.name : '';
      setCurOrgName(organisationName);
      setIsLoading(true);
      // @ts-ignore
      (async () => {
        setModalState({ key: ModalKey.HOMEPAGE_JOURNEY_SWITCHJOURNEYMODAL, isOpen: false });
        try {
          await getCohortMeta();
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getCohortMeta' },
            }),
          );
        }

        try {
          await getCohortUserRecords();
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getCohortUserRecords' },
            }),
          );
        }

        try {
          await getExperimentInsights({ cohortId: selectedCohort.cohortId });
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getExperimentInsights' },
            }),
          );
        }

        // try {
        //   await getAssessments();
        // } catch (e) {
        //   dispatch(
        //     tracking({
        //       appInsights,
        //       name: 'debug-user',
        //       payload: { type: 'failed getAssessments' },
        //     }),
        //   );
        // }

        try {
          await getRequests();
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getRequests' },
            }),
          );
        }

        // try {
        //   await getAssessmentNotifications();
        // } catch (e) {
        //   dispatch(
        //     tracking({
        //       appInsights,
        //       name: 'debug-user',
        //       payload: { type: 'failed getAssessmentNotifications' },
        //     }),
        //   );
        // }

        try {
          await getAllContents();
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getAllContents' },
            }),
          );
        }

        try {
          await getJourney({ learningJourneyId: selectedCohort.learningJourneyId, cohortId: selectedCohort.cohortId });
        } catch (e) {
          dispatch(
            tracking({
              appInsights,
              name: 'debug-user',
              payload: { type: 'failed getJourney' },
            }),
          );
        }
        if(!isFacilitator) {
          try {
            dispatch(getProgrammes());
          } catch (e) {
            dispatch(
              tracking({
                appInsights,
                name: 'debug-user',
                payload: { type: 'failed getProgrammes' },
              }),
            );
          }
        }
        // set journey preview if left during prview
        await restoreJourneyPreviewFromStorage();

        // all done
        setIsLoading(false);
      })();      
    }
    return () => {};
  }, [selectedCohort]);

  useEffect(() => {
    if (!journey) return;
    (async () => {
      // ALL EXPERIMENTS IN JOURNEY
      const experimentIdList: string[] = [];
      journey.modules.map((module: ModuleType) => {
        module.activities.map((activity: ActivityType) => {
          activity.content.body.map((activityContent: ActivityContentBlock) => {
            if (activityContent.blockType == 'experiment') {
              experimentIdList.push(activityContent.text);
            }
          });
        });
      });
      await getExperiments(experimentIdList);
      await getInsightNotifications();
    })();
    return () => {};
  }, [journey]);

  // init saved insight fiters upon reload
  useEffect((): (() => void) => { 
    (async (): Promise<void> => {
      const orgId = (isFacilitator) ? selectedCohort?.organisationId : organizationId;
      const defaultInsightFilters = await getDefultFilters(accessToken, selectedCohort, orgId);
      if (defaultInsightFilters) localStorage.setItem('defaultInsightFilters', JSON.stringify(defaultInsightFilters));
      localStorage.setItem('insightFilters', JSON.stringify(defaultInsightFilters));
    })();
    return (): void => {};
  }, [accessToken, selectedCohort, organizationId]);

  // const renderNotification: (notifications: AssessmentNotification[]) => JSX.Element | null = (
  //   notifications: AssessmentNotification[],
  // ): JSX.Element | null => {
  //   let shouldRender: boolean;
  //   let content: any;
  //   const notifiable = notifications.find((notification): boolean => !notification.seen);

  //   shouldRender = !!notifications.length && !!notifiable;
  //   content = notifiable as AssessmentNotification;

  //   if (shouldRender) {
  //     return <AssessmentNotificationHeader />;
  //   }
  //   return null;
  // };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loader size="large" />
      </div>
    );
  }

  return (
    <>
    {(location.pathname == '/app/home' && isFacilitator) ? 
      (
      <div className={styles.page}>
        <NavigationHeader isFacilitator = {isFacilitator} isFacilitatorHomePage={true} />
        {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && (
          <HomepageHeaderContainer isMobile={mobileView} isPreview={isPreview} isWebView={isNativeWebView}>
            <Switch>
              <Route
                render={({ location }) => {
                  if (location.pathname == '/app/home') {
                    return (
                      <>
                        <HomepageHeader isFacilitatorHomePage={true} />                       
                      </>
                    );
                  }
                }}
              />
            </Switch>
          </HomepageHeaderContainer>
        )}

        <div
          className={styles.wrapper}
          style={{
            flexDirection: deviceWidth > theme.devices.tablet ? 'row' : 'column',
            paddingTop: contentWrapperPadding(deviceWidth, theme.devices.tablet, isPreview),
          }}>
            
          <div className={styles.content}>
            <Switch>
              <Route path="/app/home" component={AnimatedPage(ToggleJourneys)} />
              <Route path="/app/journey/:cohortId" component={AnimatedPage(LearningJourney)} />
              <Route path="/app/insightswall" component={AnimatedPage(InsightsWall)} />
            </Switch>
          </div>
          <div className={styles.sidebar}>
            {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && (
              <Switch>
                <Route
                  render={({ location }) => {
                    if (location.pathname == '/app/home') {
                      return (
                        <WidgetContainer isMobile={mobileView}>
                          <div className={!isFacilitatorAssignedToCohort ? styles.insightDisable : ''}>                   
                           <FacilitatorInsightsWidget />    
                          </div>                                               
                        </WidgetContainer>
                      );
                    }
                  }}
                />
              </Switch>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
      )
    : (     
      <div className={styles.page}>
        <NavigationHeader isFacilitator={isFacilitator}/>
        {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && (
          <>
          { selectedCohort.cohortId !== undefined && !selectedCohort.userIds.includes(id) && ( 
           <GrayWrapper>            
              <div style={{flexDirection: 'row', width: '100%'}}>
                <Icon name="human-male-board" color="white" />
                <div className={styles.facViewHeading}>Facilitator view: </div>
                <div className={styles.facViewSubHeading}>{curOrgName} | {selectedCohort.learningJourney.programmeName} | {selectedCohort.learningJourney.title} | {selectedCohort.title}</div>                
                <div className={styles.flexAuto}>
                  <Link to={`/app/home`}><div className={styles.facViewSubHeading2}>Facilitator homepage</div></Link>
                </div>
              </div>              
            </GrayWrapper>
          )
          }
        <HomepageHeaderContainer isMobile={mobileView} isPreview={isPreview} isWebView={isNativeWebView}>
            <Switch>
              <Route
                render={({ location }) => {
                  if(location.pathname.includes('/app/journey')) {
                    return (
                      <>
                        <HomepageHeader />
                        {/* {renderNotification(assessmentNotifications)} */}
                        <ExperimentNotificationHeader />
                      </>
                    );
                  }
                }}
              />
            </Switch>
          </HomepageHeaderContainer>
          </>
        )}

        <div
          className={styles.wrapper}
          style={{
            flexDirection: deviceWidth > theme.devices.tablet ? 'row' : 'column',
            paddingTop: contentWrapperPadding(deviceWidth, theme.devices.tablet, isPreview),
          }}>
          <Switch>
            <Route
              path="/app/activity/:activityId"
              render={(props) => (
                <nav aria-label="activity" className={styles.moduleNavigation}>
                  <ActivityNavigation activityId={props.match.params.activityId} />
                </nav>
              )}
            />
          </Switch>

          <div className={styles.content}>
            <Switch>
              <Route path="/app/journey" component={AnimatedPage(LearningJourney)} />
              <Route path={'/app/module/:id'} component={AnimatedPage(Module)} />
              <Route path="/app/activity/:id" component={AnimatedPage(Activity)} />
              <Route path="/app/add-journal/:questionId" component={AnimatedPage(AddJournal)} />
              <Route path="/app/goals" component={AnimatedPage(GoalsTab)} />
              <Route path="/app/content" component={AnimatedPage(ContentTab)} />
              <Route path="/app/assessments" component={AnimatedPage(Assessments)} />
              <Route path="/app/insightswall" component={AnimatedPage(InsightsWall)} />
            </Switch>
          </div>
          <div className={styles.sidebar}>
            {process.env.REACT_APP_NEW_HOMEPAGE === 'true' && (
              <Switch>
                <Route
                  render={({ location }) => {
                    if(location.pathname.includes('/app/journey')) {
                      return (
                        <WidgetContainer isMobile={mobileView}>
                          <InsightsWidget />
                          <ContentLibraryWidget />
                          <MembersWidget />
                        </WidgetContainer>
                      );
                    }
                  }}
                />
              </Switch>
            )}
          </div>
        </div>
        {/* Using the store, modals should be appended here */}
        {isFacilitator &&  <SwitchFacJourneyModal/> }
        {!isFacilitator && <SwitchJourneyModal />  }
        <ToastContainer />
      </div>
    )
    }
    </>
  );
};

export default App;
