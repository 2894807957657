import { JournalEntryPayload } from '../../redux/experiment.slice';
import { NewJournalEntry, UpdatedJournalEntry } from '../../redux/userJournal.slice';
import { JournalBlock, JournalEntry, Tokens, UserJournal } from '../../utils/types';

const apiBaseUrl = `${process.env.REACT_APP_API_ROUTE}/journal`;

type RequestHandler<ReturnType, Payload = {}> = (
  tokens: Tokens,
  userId: string,
  payload?: Payload,
) => Promise<ReturnType>;

export const getUserJournal: RequestHandler<UserJournal> = async (tokens, userId) => {
  const { accessToken } = tokens;

  const response = await fetch(`${apiBaseUrl}/user/${userId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return await response.json();
};

export const createUserJournalEntry: RequestHandler<any, NewJournalEntry> = async (tokens, userId, payload) => {
  const { accessToken } = tokens;

  const response = await fetch(`${apiBaseUrl}/user/${userId}/journal-entry`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  });

  return await response.json();
};

export const updateUserJournalEntry: RequestHandler<JournalEntry, UpdatedJournalEntry> = async (
  tokens,
  userId,
  payload,
) => {
  const { accessToken } = tokens;
  const journalEntryId = payload?.journalEntryId;

  const response = await fetch(`${apiBaseUrl}/user/${userId}/journal-entry/${journalEntryId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  });

  return await response.json();
};

export const updateUserJournalEntryByUserId = async (tokens: Tokens, payload: JournalEntryPayload): Promise<any> => {
  const { accessToken } = tokens;
  const journalEntryId = payload?.journalEntryId;
  const userId = payload.userId;

  const response = await fetch(`${apiBaseUrl}/user/${userId}/journal-entry-by-userid/${journalEntryId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    body: JSON.stringify(payload),
  });

  return await response.json();
};

export const getJournalBlock = async (journalBlockId: string, tokens: Tokens): Promise<JournalBlock> => {
  const { accessToken } = tokens;

  const response = await fetch(`${apiBaseUrl}/journal-block/${journalBlockId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return await response.json();
};

export const deleteJournalEntryByUserId = async (
  tokens: Tokens,
  payload: { entryId: string | undefined; userId: string | undefined },
) => {
  const { accessToken } = tokens;
  const { entryId, userId } = payload;
  if (!entryId || !userId) return;

  const url = `${apiBaseUrl}/user/${userId}/journal-entry-by-userid/${entryId}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
    });

    return await response.json();
  } catch (e) {
    const err = new Error();
    err.message = 'Failed to delete insight data';
    return;
  }
};
