import React, { FC } from 'react';
import { useDebounce } from '../../utils/hooks';
import { BlockType } from '../../utils/types';
import JournalCaptureEntry from '../JournalCaptureEntry';
import JournalPlaybackEntry from '../JournalPlaybackEntry';

interface Props {
  questionId: string;
  question?: string;
  view: BlockType;
  blockId?: string;
  response?: string;
  updateDate?: string | Date;
  isExperiment?: boolean;
  activityId?: string;
  isInsightWallEditorView?: boolean;
  journalId?:string;
}

const JournalEntry: FC<Props> = ({
  blockId,
  questionId,
  question='',
  response,
  updateDate,
  view,
  isExperiment = false,
  activityId,
  isInsightWallEditorView = false,
  journalId
}) => {
  return (
    <>
      {(view === 'journalCapture' || view === 'insightJournalText') && (
        <JournalCaptureEntry
          questionText={question}
          questionId={questionId}
          response={response}
          isExperiment={isExperiment}
          isInsightWallEditorView={isInsightWallEditorView}
          view={view}
          activityId={activityId}
          debounce={isExperiment ? useDebounce : undefined}
          blockId={blockId}
        />
      )}
      {view === 'journalPlayback' && (
        <JournalPlaybackEntry question={question} response={response} updateDate={updateDate} />
      )}
    </>
  );
};

export default JournalEntry;
