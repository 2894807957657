import React, { FC, PropsWithChildren, useState } from 'react';
import Button from '../Button';
import Modal from '../Modal';
import {
  ConfirmationInformations,
  ConfirmationQuestion,
  ConsentButtonLabel,
  Divider,
  InsightConfirmationModalContainer,
  InsightConfirmationModalHeader,
  OptionsWrapper,
} from './InsightConfirmationModal.styles';
import { InsightConfirmationModalProps } from './types';
import theme from '../../utils/theme/theme';

const InsightConfirmationModal: FC<InsightConfirmationModalProps> = ({
  isOpen,
  type,
  onCancel,
  onSave,
}: PropsWithChildren<InsightConfirmationModalProps>): JSX.Element => {
  const [consent, setConsent] = useState<boolean>(false);

  const getInsightConfirmationModalContent: () => JSX.Element = (): JSX.Element => {
    if (type === 'delete') {
      return (
        <>
          <InsightConfirmationModalHeader>Delete post?</InsightConfirmationModalHeader>
          <ConfirmationQuestion>Are you sure you want to delete this post?</ConfirmationQuestion>
          <div style={{ width: '100%' }}>
            <span style={{ textAlign: 'left' }}>
              <strong>Please note:</strong>
            </span>
          </div>
          <ConfirmationInformations>
            <li>Post will be removed from insights wall</li>
            <li>Experiment will be marked as incomplete</li>
          </ConfirmationInformations>

          <Divider />
          <div style={{ flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }}>
            <input
              id="consent"
              style={{ borderRadius: '2px', width: theme.spacing(2), height: theme.spacing(2) }}
              type="checkbox"
              checked={consent}
              onClick={(): void => setConsent(!consent)}
            />
            <ConsentButtonLabel htmlFor="consent">
              <span>I confirm I understand and wish to continue</span>
            </ConsentButtonLabel>
          </div>
          <OptionsWrapper>
            <Button variant="outline" onClick={(): void => onCancel()}>
              Cancel
            </Button>
            <Button disabled={!consent} onClick={(): void => onSave()}>
              Delete
            </Button>
          </OptionsWrapper>
        </>
      );
    }

    return (
      <>
        <InsightConfirmationModalHeader>Update post?</InsightConfirmationModalHeader>
        <ConfirmationQuestion style={{ width: '75%' }}>
          Are you sure you want to make changes to this post?
        </ConfirmationQuestion>
        <Divider />
        <OptionsWrapper>
          <Button variant="outline" onClick={(): void => onCancel()}>
            Cancel
          </Button>
          <Button onClick={(): void => onSave()}>Update</Button>
        </OptionsWrapper>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} width="small">
      <InsightConfirmationModalContainer>{getInsightConfirmationModalContent()}</InsightConfirmationModalContainer>
    </Modal>
  );
};
export default InsightConfirmationModal;
