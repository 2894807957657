import React, { FC, PropsWithChildren } from 'react';
import * as Styled from './styles';
import { ExperimentCardProps } from './types';
import Button from '../Button';
import Icon from '../Icon';
import theme from '../../utils/theme';

const ExperimentCard: FC<ExperimentCardProps> = ({
  experiment,
  personCount,
  isPreview = false,
  onClick,
  isLoading,
}: PropsWithChildren<ExperimentCardProps>): JSX.Element => {
  const { approxDuration, title, overview, goal, reasoning } = experiment;

  return (
    <Styled.Container isPreview={isPreview}>
      <Styled.MainLabel>{`Experiment | ${approxDuration} mins`.toUpperCase()}</Styled.MainLabel>
      <Styled.Section>
        <Styled.MainTitle>{title}</Styled.MainTitle>
        <Styled.SectionText>{overview}</Styled.SectionText>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>Goal</Styled.SectionTitle>
        <Styled.SectionText>{goal}</Styled.SectionText>
      </Styled.Section>

      <Styled.Section>
        <Styled.SectionTitle>Why do this?</Styled.SectionTitle>
        <Styled.SectionText>{reasoning}</Styled.SectionText>
      </Styled.Section>

      {!isPreview && personCount > 0 && (
        <Styled.PersonCount>
          <Icon name="user" size="small" fill={theme.colours.primary} />
          <p>
            <strong style={{ marginLeft: theme.spacing(1) }}>
              {personCount} {personCount > 1 ? 'people' : 'person'}{' '}
            </strong>{' '}
            {personCount > 1 ? 'have' : 'has'} selected this experiment
          </p>
        </Styled.PersonCount>
      )}

      <Styled.ButtonContainer>
        <Button loading={isLoading} onClick={() => onClick()}>
          Select
        </Button>
      </Styled.ButtonContainer>
    </Styled.Container>
  );
};

export default ExperimentCard;
