import React, { PropsWithChildren, useEffect, useState } from 'react';
import { ExperimentInsightModalProps } from './types';
import { InsightModalContainer } from './styles';
import PageHeader from '../PageHeader';
import { HeaderButton } from '../PageHeader/PageHeader';
import { IconName } from '../Icon';
import theme from '../../utils/theme';
import ActivityJournals from '../ActivityJournals';
import { ActivityContentBlock } from '../../utils/types';
import Button from '../Button';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import dayjs from 'dayjs';
import ExperimentModalHeader from '../ExperimentModalHeader';
import ExperimentInsightEditor from '../ExperimentInsightEditor';

import { actions as activityActions } from '../../redux/experiment.slice';
import { FinalContent, UIType } from '../InsightEditOptions/types';
import { ModalKey } from '../../utils/modals';
import { LoadingContainer } from './styles';
import Loader from '../../components/Loader';
const ExperimentInsightModal: React.FC<ExperimentInsightModalProps> = ({
  isCompleted,
  activityId,
  activityContent,
  experiment,
  updateUserJournalEntry,
  createUserJournalEntry,
  getExperimentInsights,
  updateActivityProgress,
  onCancel,
  onCompletion,
  setModalState,
  iconName,
  deadline,
  isInsightWallEditorView = false,
  openConfirmationModal,
  insightWallEditorMetadata,
}: PropsWithChildren<ExperimentInsightModalProps>): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentEntry = useAppSelector((state) => state.experiment.currentEntryResponse);
  const { firstName, lastName } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAnswered, setIsAnswered] = useState<boolean>(false);
  const [cardStyleInfo, setCardStyleInfo] = useState<FinalContent | null>(null);
  const displayDeadline: string = deadline ? `| Deadline: ${dayjs(deadline).format('DD/MM/YYYY')}` : '';
  const clearText = (text: string | undefined): string | undefined => text?.replace(/<\/?[^>]+(>|$)/g, '');
  const isDisabled: boolean = !clearText(currentEntry.response) || clearText(currentEntry.response)?.length === 0;
  const { selectedCohort } = useAppSelector((state) => state.cohorts);
  useEffect(() => {
    return () => {
      if (isInsightWallEditorView) return;
      dispatch(activityActions.setJournalEntries({}));
    };
  }, []);

  useEffect(() => {
    let metaCardStyle: { type: UIType; icon?: string; image?: string; color: string } = {
      type: 'icon',
      icon: '',
      image: '',
      color: '',
    };

    if (cardStyleInfo?.icon) {
      metaCardStyle = {
        type: cardStyleInfo.type,
        icon: cardStyleInfo.icon,
        color: cardStyleInfo.color,
      };
    } else if (cardStyleInfo?.image) {
      metaCardStyle = {
        type: cardStyleInfo.type,
        image: cardStyleInfo.image,
        color: cardStyleInfo.color,
      };
    }

    dispatch(activityActions.setCurrentEntryMeta(metaCardStyle));
    return () => {};
  }, [cardStyleInfo]);

  if (!experiment) return <></>;

  const saveJournalEntries: () => void = async (): Promise<void> => {
    if (isInsightWallEditorView) {
      openConfirmationModal && openConfirmationModal();
      onCancel();
      return;
    }
    if (!currentEntry.journalEntryId) {
      dispatch(createUserJournalEntry(currentEntry));
    } else {
      dispatch(updateUserJournalEntry(currentEntry));
    }
    //@ts-ignore
    (async () => {
      setIsLoading(true);
      await getExperimentInsights({ cohortId: selectedCohort.cohortId });
      await updateActivityProgress({
        activityId: activityId,
        completed: true,
        experimentId: experiment.id,
        isExperimentCompleted: true,
      });
      setIsLoading(false);
      if(!isLoading){
        setModalState({ key: ModalKey.EXPERIMENTS_EXPERIMENTCONTENT_INSIGHT_CONGRATULATIONSMODAL, isOpen: true });
        onCompletion({ variant: 'experiment', show: true, iconName: (iconName as IconName) || 'rosette-module' });
        onCancel();
      }
    })();
  };

  const rightButton: HeaderButton = {
    iconName: isCompleted ? 'check' : ('in-progress' as IconName),
    background: theme.colours.secondary,
    ariaLabel: `Experiment - Incomplete`,
  };

  const { body } = activityContent;
  const JournalCaptureCMSBlock: ActivityContentBlock = body.filter(
    (cms: ActivityContentBlock) => cms.blockType == 'insightJournalText',
  )[0];
if (isLoading) {
    return (
      <LoadingContainer>
        <Loader size="large" color="purple" />
      </LoadingContainer>
    );
} else { 
  return (
    <InsightModalContainer>
      {isAnswered ? (
        <>
          <ExperimentModalHeader title="Shared insight preview" iconName="experiment-info" />
          <ExperimentInsightEditor
            entry={currentEntry}
            experimentTitle={experiment.title}
            userName={`${firstName} ${lastName}`}
            setCardStyleInfo={setCardStyleInfo}
            insightWallEditorMetadata={insightWallEditorMetadata}
            isInsightWallEditorView={isInsightWallEditorView}
          />
          {!isCompleted && (
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button variant="outline" onClick={() => setIsAnswered(false)}>
                Edit Text
              </Button>
              <Button loading={isLoading} onClick={() => saveJournalEntries()}>
                {isInsightWallEditorView ? 'Done' : 'Post Insight'}
              </Button>
            </div>
          )}
        </>
      ) : (
        <>
          <PageHeader
            title={experiment.title}
            subTitle={`Experiment ${displayDeadline}`}
            rightButton={rightButton}
            type="activity"
          />
          <p style={{ marginBottom: '32px' }}>
            <strong>This question will be shared with others on the insights wall.</strong> By sharing and being able to
            discuss your experience with peers is one of the fastest ways to achieve continued success.{' '}
          </p>
          <ActivityJournals
            block={JournalCaptureCMSBlock}
            isExperiment={true}
            isInsightWallEditorView={isInsightWallEditorView}
            activityId={activityId}
          />
          {!isCompleted && (
            <div
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Button
                variant="outline"
                onClick={(): void => {
                  onCancel();
                  dispatch(activityActions.setJournalEntries({}));
                }}>
                Cancel
              </Button>
              <Button disabled={isDisabled} loading={isLoading} onClick={() => setIsAnswered(true)}>
                Preview Insight
              </Button>
            </div>
          )}
        </>
      )}
    </InsightModalContainer>
  );
}
};

export default ExperimentInsightModal;
