import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import AnimatedPage from '../../components/AnimatedPage';
import Loader from '../../components/Loader';

import ContentItem from '../ContentItem';
import Content from '../Content';

import { MediaCollection } from '../../utils/types';

import styles from './styles.module.scss';

type Props = {
  collections: Array<MediaCollection>;
  getContent: any;
  journeyId: string;
};

const ContentTab = ({ collections, getContent, journeyId }: Props) => {
  const hasCollections = collections && collections.length > 0;

  const [isLoading, setIsLoading] = useState<boolean>(!hasCollections);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await getContent(journeyId);
      setIsLoading(false);
    })();
  }, [hasCollections, journeyId]);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loader size="large" color="purple" />
      </div>
    );
  }
  return (
    <>
      <Switch>
        <Route path="/app/content/:id" component={AnimatedPage(ContentItem)} />
        <Route path="/app/content" component={AnimatedPage(Content)} />
      </Switch>
    </>
  );
};

export default ContentTab;
