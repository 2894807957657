import React, { FC, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

type Props = {
  barriers: string;
  enablers: string;
  reward: string;
  shareWith: string;
  trigger: string;
  isHabitCompleted?: boolean;
};

const HabitSuccessPlan: FC<Props> = ({ isHabitCompleted, barriers, enablers, reward, shareWith, trigger }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  if (`${barriers}${enablers}${reward}${shareWith}${trigger}`.length === 0) {
    return null;
  }
  return (
    <div className={classnames(styles.container, { [styles.containerCompleted]: isHabitCompleted })}>
      <button className={styles.link} onClick={() => setOpen(!open)}>{`${
        open ? 'Hide' : 'View'
      } success plan information`}</button>
      <div className={classnames(styles.content, { [styles.contentOpen]: open })}>
        {trigger && trigger.length && (
          <>
            <strong>What will trigger your new habit?</strong>
            <p>{trigger}</p>
          </>
        )}
        {reward && reward.length && (
          <>
            <strong>What&apos;s your reward?</strong>
            <p>{reward}</p>
          </>
        )}
        {barriers && barriers.length && (
          <>
            <strong>What barriers might get in the way?</strong>
            <p>{barriers}</p>
          </>
        )}
        {enablers && enablers.length && (
          <>
            <strong>What enablers could help you succeed?</strong>
            <p>{enablers}</p>
          </>
        )}
        {shareWith && shareWith.length && (
          <>
            <strong>Who will you share your plan with?</strong>
            <p>{shareWith}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default HabitSuccessPlan;
