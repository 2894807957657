import { connect } from 'react-redux';
import get from 'lodash.get';
import ToggleJourneys from './ToggleJourneys';
import { RootState } from '../../../redux';

const mapState = (state: RootState) => {
  return {
    journey: get(state, 'journey', []),
  };
};

export default connect(mapState, null)(ToggleJourneys);
